import React, { useState } from 'react';

import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LabelProps } from '@radix-ui/react-label';

interface Props extends LabelProps {
  children?: string;
}
const CustomRadioLabel: React.FC<Props> = (props) => {
  const [duration, setDuration] = useState<number | null>(null);
  return (
    <Label
      {...props}
      className='flex flex-row justify-center gap-2 items-center'
    >
      {props.children}
      <div className='flex flex-row justify-center items-center gap-2'>
        (For
        <Input
          className='h-6 text-sm w-20 bg-simplyViolet text-center'
          value={duration || ''}
          onChange={(e) => setDuration(Number(e.target.value))}
          type='number'
          min='1'
        />
        Months)
      </div>
    </Label>
  );
};

export default CustomRadioLabel;
