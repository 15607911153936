import React from 'react';

import { PAGE_SIZE_OPTIONS } from '@/constants';
import { strings } from '@/locales';
import { Table } from '@tanstack/react-table';

import ReactDropdown from '../Dropdown';
import { Typography } from '../Typography';

interface Props<TData> {
  tableState: Table<TData> | null;
  setTableState: React.Dispatch<React.SetStateAction<Table<TData> | null>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}

const TableSize = <TData,>({
  tableState,
  setTableState,
  pageSize,
  setPageSize,
}: Props<TData>) => {
  const staticText = strings.candidateScreen;

  const handlePageSizeChange = (size: number) => {
    if (tableState) {
      setPageSize(size);
      tableState?.setPageSize(size);

      setTableState({
        ...tableState,
        initialState: {
          ...tableState.initialState,
          pagination: {
            ...tableState.initialState.pagination,
            pageSize: size,
          },
        },
      });
    }
  };

  return (
    <div className='flex items-center gap-1'>
      <Typography className='md:text-sm text-greyishBlack'>
        {staticText.rowsPerPage}
      </Typography>
      <ReactDropdown
        options={PAGE_SIZE_OPTIONS}
        value={pageSize?.toString()}
        onChange={(selectedOption) =>
          handlePageSizeChange(Number(selectedOption))
        }
        name='pageSize'
        className='bg-white text-black'
        inputHeight='38px'
        defaultValue={pageSize?.toString()}
      />
      <Typography className='md:text-sm text-greyishBlack'>
        {staticText.of10}
      </Typography>
    </div>
  );
};

export default TableSize;
