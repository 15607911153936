import React from 'react';

import { FilterOptions, variantType } from '@/@types';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import {
  APPLIED_FILTERS_PILLS,
  CandidateStatusList,
} from '@/constants/candidate';
import { TICKET_STATUS_OPTIONS } from '@/constants/ticket';
import { ROLE_DROPDOWN_OPTIONS } from '@/constants/users';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import {
  capitalizeWord,
  getTagVariant,
  getVariantByRole,
  snakeToTitleCase,
} from '@/utils/common';

interface AppliedFilterSectionProps {
  handleRemoveTag?: (tag: string) => void;
  tags: FilterOptions[];
  className?: string;
}

const AppliedFilterSection: React.FC<AppliedFilterSectionProps> = ({
  handleRemoveTag,
  tags,
  className,
}) => {
  const staticText = strings.candidateScreen;

  const getPillsVariant = (
    isRoleTag: boolean,
    isDataStatusTag: boolean,
    isTicketStatusTag: boolean,
    tag: string,
    key: string,
  ): variantType => {
    switch (true) {
      case isRoleTag:
        return getVariantByRole(
          ROLE_DROPDOWN_OPTIONS.filter((item) => item.label === tag).map(
            (item) => item.value,
          )?.[0],
        );
      case isDataStatusTag:
      case isTicketStatusTag:
        return getTagVariant(tag);
      default:
        return APPLIED_FILTERS_PILLS[key] || 'green';
    }
  };

  return (
    <div className={cn('flex flex-col lg:flex-row w-full gap-1', className)}>
      <Typography className='md:text-[15px] font-bold whitespace-nowrap'>
        {staticText.appliedFilter}
      </Typography>
      <div className='flex gap-2 flex-wrap w-full'>
        {tags.map(({ key, value: tag }) => {
          const isTagInList = (list: string[]) =>
            list.some(
              (item) => item.toLowerCase() === (tag as string)?.toLowerCase(),
            );
          const isRoleTag = isTagInList(
            ROLE_DROPDOWN_OPTIONS.map((item) => item.label),
          );
          const isDataStatusTag = isTagInList(CandidateStatusList);
          const isTicketStatusTag = isTagInList(
            TICKET_STATUS_OPTIONS.map((item) => item.label),
          );
          return (
            <Tags
              text={snakeToTitleCase(capitalizeWord(tag as string))}
              tagHeading={(key as string)
                .replace(/filter/i, '')
                .replace(/([A-Z])/g, ' $1')
                .trim()}
              variant={getPillsVariant(
                isRoleTag,
                isDataStatusTag,
                isTicketStatusTag,
                tag as string,
                key as string,
              )}
              key={tag as string}
              handleClose={() => handleRemoveTag?.(key as string)}
              containerClassName='py-1 !text-xs h-auto w-fit text-sm'
            />
          );
        })}
      </div>
    </div>
  );
};

export default AppliedFilterSection;
