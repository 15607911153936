import { GroupBase, StylesConfig } from 'react-select';

import { IDropdownOption } from '@/@types';

export const customStyles: StylesConfig<
  string | IDropdownOption,
  boolean,
  GroupBase<string | IDropdownOption>
> = {
  control: (provided) => ({
    ...provided,
    '[type="text"]': {
      fontFamily: 'Helvetica, sans-serif !important',
      fontSize: 13,
      fontWeight: 900,
    },
    'display': 'flex',
    'minHeight': '48px',
    'maxHeight': '48px',
    'maxWidth': '100%',
    'padding': '10px',
    'overflowX': 'scroll',
    'flexWrap': 'nowrap',
    'outline': 'none',
    'borderRadius': '6px',
    'borderColor': '#E4E6E9',
    'boxShadow': undefined,
    '&:hover': {
      borderColor: '#E4E6E9',
    },
    'cursor': 'pointer',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: '#fff',
    zIndex: 9999,
    borderRadius: '12px',
    padding: '5px 0',
  }),
  option: (provided) => ({
    ...provided,
    'color': '',
    'fontSize': '13px',
    '&:hover': {
      backgroundColor: '#EAF3F8',
    },
  }),
  indicatorsContainer: () => ({
    display: 'flex',
    flexDirection: 'row',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#A7A7A7',
    fontSize: '15px',
  }),
};
