import React from 'react';

import DocxIcon from '@/assets/svgs/docxIcon.svg';
import PDFIcon from '@/assets/svgs/pdfIcon.svg';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';

interface Props {
  name: string;
  url?: string;
  className?: string;
}

const DownloadableFileRow: React.FC<Props> = ({ name, url, className }) => {
  const fileExtension = url?.split('.').pop();
  const isUploaded = Boolean(url);

  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) =>
    isUploaded ? (
      <a
        href={url}
        download
        target='_blank'
        className='h-full'
        rel='noreferrer'
      >
        {children}
      </a>
    ) : (
      <div className='h-full'>{children}</div>
    );

  return (
    <Wrapper>
      <Container
        height='h-10'
        className={cn(
          'bg-simplyViolet flex justify-between items-center overflow-hidden md:px-2 gap-1',
          { 'opacity-50': !isUploaded },
          className,
        )}
      >
        <Typography className='font-semibold capitalize truncate md:text-sm'>
          {name}
        </Typography>
        {isUploaded && (
          <div className='min-w-5'>
            <img
              src={fileExtension?.includes('pdf') ? PDFIcon : DocxIcon}
              alt={fileExtension}
            />
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

export default DownloadableFileRow;
