import * as React from 'react';
import { FieldErrors } from 'react-hook-form';
import { HiMiniCalendarDays } from 'react-icons/hi2';
import { format } from 'date-fns';

import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

export interface Props {
  value: Date | undefined;
  setValue: React.Dispatch<React.SetStateAction<Date | undefined>>;
  contanierClassName?: string;
  errors?: FieldErrors;
  name?: string;
  fromDate?: Date;
  toDate?: Date;
  disabled?: boolean;
}

export const DatePickerField: React.FC<Props> = ({
  value,
  setValue,
  contanierClassName,
  errors,
  name,
  fromDate = new Date(),
  toDate = new Date('2100-12-31'),
  disabled,
}) => {
  const staticText = strings.common;

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            icon={
              <HiMiniCalendarDays className='ml-2 size-4 shrink-0 text-quickSilver' />
            }
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal border-greyWhite w-[200px] rounded-xl h-[42px] text-sm text-primaryBlack px-3 bg-white border-[1px]',
              !value && 'text-muted-foreground',
              contanierClassName,
            )}
            textWithIconClassName='justify-between'
          >
            {value ? (
              format(value, 'PPP')
            ) : (
              <span className='text-quickSilver text-[15px]'>
                {staticText.select}
              </span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='flex w-auto flex-col space-y-2 p-2'>
          <Calendar
            mode='single'
            selected={value}
            onSelect={setValue}
            fromDate={fromDate}
            toDate={toDate}
            disabled={disabled}
          />
        </PopoverContent>
      </Popover>
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </>
  );
};

export default DatePickerField;
