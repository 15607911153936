import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@/provider/AuthProvider';

import AuthWrapper from '../pages/Auth/components/AuthWrapper';

import { ROUTES } from '.';

interface PublicRouteProps {
  element: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const { token, user, isAdmin, isCompanyClient } = useAuth();
  const location = useLocation();

  if (token && user) {
    const redirectTo =
      location.state?.from ||
      (isAdmin && ROUTES.USERS) ||
      (isCompanyClient && ROUTES.TICKETS) ||
      ROUTES.DASHBOARD;

    return <Navigate to={redirectTo} />;
  }

  return <AuthWrapper>{element}</AuthWrapper>;
};

export default PublicRoute;
