import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { FilterOptions } from '@/@types';
import { ROUTES } from '@/routes';

interface IFiltersContextType<T> {
  ticketSearchText: string;
  setTicketSearchText: Dispatch<SetStateAction<string>>;
  ticketFilters: T;
  setTicketFilters: Dispatch<SetStateAction<T>>;
  userSearchText: string;
  setUserSearchText: Dispatch<SetStateAction<string>>;
  userFilters: T;
  setUserFilters: Dispatch<SetStateAction<T>>;
  requestSearchText: string;
  setRequestSearchText: Dispatch<SetStateAction<string>>;
  requestFilters: T;
  setRequestFilters: Dispatch<SetStateAction<T>>;
  searchAiCandidateText: string;
  setSearchAiCandidateText: Dispatch<SetStateAction<string>>;
  candidateFilters: T;
  setCandidateFilters: Dispatch<SetStateAction<T>>;
  approvalFilters: T;
  setApprovalFilters: Dispatch<SetStateAction<T>>;
  approvalSearchText: string;
  setApprovalSearchText: Dispatch<SetStateAction<string>>;
}

const defaultFiltersContext: IFiltersContextType<FilterOptions> = {
  ticketSearchText: '',
  setTicketSearchText: () => {},
  ticketFilters: {},
  setTicketFilters: () => {},
  userSearchText: '',
  setUserSearchText: () => {},
  userFilters: {},
  setUserFilters: () => {},
  requestSearchText: '',
  setRequestSearchText: () => {},
  requestFilters: {},
  setRequestFilters: () => {},
  searchAiCandidateText: '',
  setSearchAiCandidateText: () => {},
  candidateFilters: {},
  setCandidateFilters: () => {},
  approvalFilters: {},
  setApprovalFilters: () => {},
  approvalSearchText: '',
  setApprovalSearchText: () => {},
};

const FiltersContext = createContext<IFiltersContextType<FilterOptions>>(
  defaultFiltersContext,
);

export const FiltersProvider = ({ children }: { children: ReactNode }) => {
  const [ticketFilters, setTicketFilters] = useState<FilterOptions>({});
  const [ticketSearchText, setTicketSearchText] = useState('');
  const [userFilters, setUserFilters] = useState<FilterOptions>({});
  const [userSearchText, setUserSearchText] = useState('');
  const [requestFilters, setRequestFilters] = useState<FilterOptions>({});
  const [requestSearchText, setRequestSearchText] = useState('');
  const [candidateFilters, setCandidateFilters] = useState<FilterOptions>({});
  const [searchAiCandidateText, setSearchAiCandidateText] = useState('');
  const [approvalFilters, setApprovalFilters] = useState<FilterOptions>({});
  const [approvalSearchText, setApprovalSearchText] = useState('');
  const location = useLocation();

  useEffect(() => {
    const resetFilters = () => {
      if (!location.pathname.includes(ROUTES.TICKETS)) {
        setTicketSearchText('');
        setTicketFilters({});
      }
      if (!location.pathname.includes(ROUTES.USERS)) {
        setUserSearchText('');
        setUserFilters({});
      }
      if (!location.pathname.includes('request')) {
        setRequestSearchText('');
        setRequestFilters({});
      }
      if (!location.pathname.includes(ROUTES.CANDIDATE)) {
        setSearchAiCandidateText('');
        setCandidateFilters({});
      }
    };
    resetFilters();
  }, [location.pathname]);

  const value = {
    ticketSearchText,
    setTicketSearchText,
    ticketFilters,
    setTicketFilters,
    userSearchText,
    setUserSearchText,
    userFilters,
    setUserFilters,
    requestSearchText,
    setRequestSearchText,
    requestFilters,
    setRequestFilters,
    candidateFilters,
    setCandidateFilters,
    searchAiCandidateText,
    setSearchAiCandidateText,
    approvalFilters,
    setApprovalFilters,
    approvalSearchText,
    setApprovalSearchText,
  };

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

export const useFilters = () => {
  return useContext(FiltersContext);
};
