import { Fragment } from 'react';

import {
  IInterviewEvaluationAndComments,
  IInterviewEvaluationFormData,
} from '@/@types/tickets';
import { Typography } from '@/components/Typography';
import { Separator } from '@/components/ui/separator';
import { INTERVIEW_EVALUATION_HISTORY_SECTION_HEADINGS } from '@/constants/ticket';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

interface IInterviewEvaluationHistorySection {
  title: string;
  data: Record<string, IInterviewEvaluationAndComments | undefined>;
}

const InterviewEvaluationHistorySection: React.FC<
  IInterviewEvaluationHistorySection
> = ({ title, data }) => {
  const staticText = strings.ticketDetailScreen.interviewEvaluation;

  return (
    <>
      <div className='sm:grid sm:grid-cols-6 rounded-lg gap-5'>
        {INTERVIEW_EVALUATION_HISTORY_SECTION_HEADINGS(title).map((item) => (
          <Typography
            key={item.label}
            className={cn(
              'font-bold md:text-lg xl:text-xl block',
              item.className,
            )}
            variant='subheading'
          >
            {item.label}
          </Typography>
        ))}
        {Object.entries(data).map(([key, value]) => (
          <Fragment key={key}>
            <Typography className='col-span-2 font-semibold text-base sm:font-normal sm:text-auto'>
              {
                staticText.contentAppearanceFields[
                  key as keyof IInterviewEvaluationFormData
                ]
              }
            </Typography>
            <div className='col-span-1'>
              <Typography className='block sm:hidden font-bold'>
                {staticText.evaluation}
              </Typography>
              <Typography>{value?.evaluation}</Typography>
            </div>
            <div className='col-span-3 mb-5 sm:mb-0'>
              <Typography className='block sm:hidden font-bold'>
                {staticText.comments}
              </Typography>
              <Typography>{value?.comments}</Typography>
            </div>
          </Fragment>
        ))}
      </div>
      <Separator className='my-5' />
    </>
  );
};
export default InterviewEvaluationHistorySection;
