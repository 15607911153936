/* eslint-disable max-lines */
import React from 'react';

import { IUser } from '@/@types/auth';
import { IEducationRecord } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import { Typography } from '@/components/Typography';
import { createRecruiterInterviewEvaluationFormConstantData } from '@/constants/candidate';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { formatCurrency, GetFormattedName } from '@/utils/common';

interface IFormConstantDataSection {
  mrfNumber: string;
  job: string;
  showEducation?: boolean;
}

const FormConstantDataSection: React.FC<IFormConstantDataSection> = ({
  mrfNumber,
  job,
  showEducation,
}) => {
  const { recruiterInterviewEvaluationForm: staticText } =
    strings.candidateScreen;

  const { user } = useAuth();
  const { editableCandidateData } = useCandidate();

  const formConstantData = createRecruiterInterviewEvaluationFormConstantData({
    candidate: GetFormattedName(editableCandidateData?.userDetails),
    assignedConsultant: GetFormattedName(user as IUser),
    jobPosition: job,
    expectedSalary: formatCurrency(editableCandidateData?.expectedSalary),
  });

  const findLatestYearEntry = () => {
    const data =
      editableCandidateData?.academicHistory as unknown as IEducationRecord[];
    if (data?.length === 0) return null;
    return data?.reduce((latest, current) => {
      // Destructure with default values to avoid undefined
      const [, currentEndYearStr = ''] = current.yearCovered
        .split('-')
        .map((year) => year.trim());
      const [, latestEndYearStr = ''] = latest.yearCovered
        .split('-')
        .map((year) => year.trim());
      // Convert year strings to numbers, default to 0 if conversion fails
      const currentEndYear = parseInt(currentEndYearStr, 10) || 0;
      const latestEndYear = parseInt(latestEndYearStr, 10) || 0;
      return currentEndYear > latestEndYear ? current : latest;
    });
  };

  const latestEntry = findLatestYearEntry();

  return (
    <div>
      <div className='flex justify-between'>
        <NameAndDesc
          title={staticText.mrfNumber}
          description={`Tk#${mrfNumber}`}
          className='space-y-0 flex gap-1'
        />
      </div>
      {formConstantData.map((item) => (
        <NameAndDesc
          key={item.label}
          title={item.label}
          description={item.value}
          className='space-y-0 flex gap-1'
        />
      ))}
      {showEducation ? (
        <div>
          <Typography className='block font-bold md:text-[15px] truncate'>
            {staticText.educationalBackground}
          </Typography>
          {[
            {
              label: staticText.schoolCollege,
              value:
                latestEntry?.college ||
                latestEntry?.vocational ||
                latestEntry?.highSchool ||
                latestEntry?.elemantary ||
                '-',
            },
            { label: staticText.course, value: latestEntry?.course || '-' },
            {
              label: staticText.yearCovered,
              value: latestEntry?.yearCovered || '-',
            },
          ].map(({ label, value }, index) => (
            <Typography
              key={index}
              className='block md:text-[15px] truncate font-semibold'
            >
              {label}
              <span className='font-normal ml-1'>{value}</span>
            </Typography>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FormConstantDataSection;
