import React from 'react';
import { IoMdClose } from 'react-icons/io';

import { variantType } from '@/@types';
import { ITagType } from '@/@types/candidate';
import { cn } from '@/lib/utils';

import { Skeleton } from './ui/skeleton';
interface ILoader {
  variant?: variantType;
  item?: ITagType;
  text?: string | null;
  handleClose?: (itemName: string) => void;
  containerClassName?: string;
  textClassName?: string;
  handleClick?: (value: ITagType) => void;
  disabled?: boolean;
  tagHeading?: string;
}

const variantStyles = {
  green: 'bg-signalWhite text-fernGreen border-fernGreen',
  red: 'bg-flawedWhite text-tomatoRed border-tomatoRed',
  blue: 'bg-trafficWhite text-primary border-primary',
  yellow: 'bg-lightYellow text-maizeYellow border-maizeYellow',
  purple: 'bg-purple-50 text-blueMagenta border-blueMagenta',
  orange: 'bg-cream text-honeyYellow border-honeyYellow',
  gray: 'text-mouseGrey border-greyWhite bg-gray-100',
  pink: 'text-pink-800 bg-pink-50 border-pink-800',
  brown: 'text-amber-900 bg-orange-50 border-amber-900',
  teal: 'bg-teal-50 text-teal-800 border-teal-800',
};

const Tags: React.FC<ILoader> = ({
  variant = 'blue',
  item,
  text = '',
  handleClose,
  containerClassName,
  textClassName,
  handleClick,
  disabled,
  tagHeading,
}) => {
  const handleClickTag = () => {
    if (handleClick && item) {
      handleClick(item);
    }
  };
  return (
    <div
      className={cn(
        'px-1.5 w-fit h-8 flex justify-center items-center rounded-md border whitespace-nowrap',
        {
          'justify-between': handleClose,
          'opacity-60': disabled,
        },
        variantStyles[variant],
        containerClassName,
      )}
      onClick={handleClickTag}
    >
      {tagHeading ? (
        <span className='text-[15px] font-semibold capitalize mr-0.5'>
          {tagHeading}:
        </span>
      ) : null}
      <span className={cn('text-[15px] capitalize', textClassName)}>
        {text ? text : <Skeleton className='w-20 h-4 bg-black/10' />}
      </span>
      {text && handleClose ? (
        <IoMdClose
          className='ml-0.5 cursor-pointer p-0.5'
          onClick={() => handleClose(text)}
          color='#666666'
          size={14}
        />
      ) : null}
    </div>
  );
};

export default Tags;
