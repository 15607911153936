import { Dispatch, SetStateAction } from 'react';
import { MdOutlineEdit } from 'react-icons/md';

import { variantType } from '@/@types';
import { ICandidate, ICandidateTag, ITagType } from '@/@types/candidate';
import Tags from '@/components/Tags';
import { Button } from '@/components/ui/button';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { EXTRACT_TYPE, getLabelOrValue, getTagVariant } from '@/utils/common';

interface TagsCellProps {
  tags: ITagType[] | string;
  setEditData?: React.Dispatch<React.SetStateAction<ICandidate | null>>;
  data?: ICandidate;
  variant?: string;
  className?: string;
  setOpenEditModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleTagEditModal?: (() => void) | null;
  setRowForUpdateTag?:
    | Dispatch<SetStateAction<ICandidateTag | undefined>>
    | undefined;
  rowData?: ICandidateTag;
}

export const TagsCell: React.FC<TagsCellProps> = ({
  tags,
  setEditData,
  setOpenEditModal,
  data,
  variant,
  className,
  setRowForUpdateTag,
  handleToggleTagEditModal,
  rowData,
}) => {
  if (typeof tags === 'string') {
    return (
      <div
        className={cn(
          'flex gap-1 items-center justify-center group',
          className,
        )}
      >
        <Tags
          text={tags}
          variant={(variant as variantType) || getTagVariant(tags)}
          containerClassName='py-1 h-auto min-w-36 w-fit'
          textClassName='capitalize'
        />

        {handleToggleTagEditModal &&
        ![
          strings.common.pendingApproval,
          CANDIDATE_STATUS.HR_ORIENTATION,
        ].includes(tags) ? (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              className='bg-transparent ml-1 size-7 p-1 rounded-none'
              onClick={() => {
                if (handleToggleTagEditModal && setEditData) {
                  handleToggleTagEditModal();
                  setEditData(data as ICandidate);
                  setRowForUpdateTag?.(rowData);
                }
              }}
              icon={<MdOutlineEdit className='w-5 text-xl text-primary' />}
            />
          </div>
        ) : null}
      </div>
    );
  }
  const displayTags = tags.slice(0, 1);
  const remainingCount = tags.length - displayTags.length;

  return (
    <div className='flex gap-1 justify-center'>
      {tags.length > 0 ? (
        <>
          {displayTags.map((item, index) => (
            <Tags
              key={index}
              text={`${
                getLabelOrValue(item.companyName, EXTRACT_TYPE.LABEL) &&
                (getLabelOrValue(item.companyName, EXTRACT_TYPE.LABEL) || '')
                  .length > 12
                  ? `${(getLabelOrValue(item.companyName, EXTRACT_TYPE.LABEL) || '').substring(0, 12)}...`
                  : getLabelOrValue(item.companyName, EXTRACT_TYPE.LABEL)
              } /TK#${item.ticketNumber} / ${item?.isPendingApproval ? strings.common.pendingApproval : getLabelOrValue(item.status, EXTRACT_TYPE.LABEL)}`}
              variant={getTagVariant(
                item?.isPendingApproval
                  ? strings.common.pendingApproval
                  : getLabelOrValue(item.status, EXTRACT_TYPE.LABEL),
              )}
              containerClassName='py-1 h-auto min-w-20 w-auto '
            />
          ))}
          {remainingCount > 0 && setOpenEditModal && setEditData && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setOpenEditModal(true);
                setEditData(data || null);
              }}
            >
              <Tags
                text={`+${remainingCount}`}
                variant='blue'
                containerClassName='rounded-full size-[30px] cursor-pointer'
              />
            </div>
          )}
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
