import React from 'react';

import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import { strings } from '@/locales';

interface DashboardCardProps {
  title: string;
  count?: string;
  text: string;
  countUnit?: string;
  isLoading?: boolean;
  totalHiredCandidate?: number;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  count = 0,
  text,
  countUnit,
  isLoading = false,
  totalHiredCandidate,
}) => {
  return (
    <Container className='flex flex-col gap-y-3 bg-trafficWhite border-none rounded-lg shadow-md md:p-5'>
      <Typography className='text-primaryBlack font-bold text-xl md:text-[26px]'>
        {title}
      </Typography>
      <div className='flex items-baseline'>
        {isLoading ? (
          <Skeleton className='h-7 my-2 w-20' />
        ) : (
          <>
            <Typography
              className='text-primary font-black md:text-[40px]'
              variant='heading'
            >
              {count || 0}
            </Typography>
            {countUnit ? (
              <span className='ml-2 text-xs md:text-base text-primaryBlack font-bold'>
                {countUnit}
              </span>
            ) : null}
          </>
        )}
      </div>
      <Typography className='text-mouseGrey text-base md:text-lg font-bold'>
        {title === strings.dashboard.sales &&
          (isLoading ? (
            <Skeleton className='w-4 h-5 inline-block' />
          ) : (
            (totalHiredCandidate ?? 0)
          ))}
        {text}
      </Typography>
    </Container>
  );
};

export default DashboardCard;
