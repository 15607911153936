import { cn } from '@/lib/utils';
import { useSidebarContext } from '@/provider/SidebarProvider';

interface ISidebarContainer {
  children: React.ReactNode;
}

const SidebarContainer = ({ children }: ISidebarContainer) => {
  const { sidebarExpand, isSmallScreen, sidebarClosing } = useSidebarContext();

  return (
    <div className='relative z-50'>
      {/* Sidebar */}
      <div
        className={cn(
          'md:relative fixed overflow-y-scroll no-scrollbar border-r border-r-greyWhite bg-white transition-all duration-300 ease-in-out w-16',
          {
            'w-full md:w-48': sidebarExpand,
            'slide-in-left': isSmallScreen && sidebarExpand,
            'slide-out-left': isSmallScreen && sidebarClosing,
          },
        )}
      >
        <div className='flex flex-col h-screen mx-auto max-w-screen-xl'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarContainer;
