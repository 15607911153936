/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import Container from '@/components/Container';
import ReactDropdown from '@/components/Dropdown';
import FormField from '@/components/FormField';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { INPUT_ERRORS, USER_TYPE } from '@/constants';
import { ROLE_DROPDOWN_OPTIONS } from '@/constants/users';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { ROUTES } from '@/routes';
import { createUser } from '@/services/users';
import { EmailRegex, validatePassword } from '@/utils/common';

import SuccessAnimation from '../../assets/animations/SuccessAnimation.json';

export interface IUserForm extends Partial<IUser> {
  password: string;
  reEnterPassword?: string;
}

const initailValue = {
  firstName: '',
  lastName: '',
  email: '',
  profileType: ROLE_DROPDOWN_OPTIONS[0]?.value,
  password: '',
  reEnterPassword: '',
  companyName: '',
  override: null,
};

const CreateNewUserScreen = () => {
  const staticText = strings.createUsersScreen;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, isValid, isSubmitted },
  } = useForm<IUserForm>({
    mode: 'onChange',
    defaultValues: initailValue,
  });

  const watchPassword = watch('password');
  const email = watch('email');
  const isCompanyClient = watch('profileType') === USER_TYPE.COMPANY_CLIENT;

  const handleCancel = () => navigate(-1);

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate(ROUTES.USERS);
  };

  const createUserMutation = useGenericMutation<IUserForm, boolean>(
    createUser,
    {
      onSuccess: (response) => {
        if (response) {
          setIsOpen(true);
        }
      },
    },
  );

  const handleCreateUser: SubmitHandler<IUserForm> = (formData) => {
    const formDataWithCompanyName = {
      ...formData,
      companyName:
        typeof formData.companyName === 'object'
          ? (formData.companyName as IDropdownOption).value
          : formData.companyName,
    };
    const payload =
      isCompanyClient && !formData.override
        ? {
            ...formDataWithCompanyName,
            override: false,
          }
        : formDataWithCompanyName;

    delete payload.reEnterPassword;

    createUserMutation.mutate(payload);
  };

  useEffect(() => {
    if (watch('password') && watch('reEnterPassword')) {
      trigger('reEnterPassword');
    }
  }, [watch('password'), trigger]);

  useEffect(() => {
    if (!isOpen && isSubmitted) navigate(-1);
  }, [isOpen]);

  const {
    companies,
    haveMoreCompanyPage,
    setCompanyPage,
    loadingCompanies,
    setCompanySearch,
  } = useCommonDataContext();

  return (
    <div className='flex flex-col md:h-full'>
      <Container className='md:flex-grow md:overflow-y-auto'>
        <div className='w-full justify-between flex gap-2 lg:items-center flex-col sm:flex-row sm:items-center'>
          <Typography className='text-xl md:text-[22px] font-bold py-4'>
            {staticText.createNewUser}
          </Typography>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          <Controller
            control={control}
            name='firstName'
            rules={{
              required: INPUT_ERRORS.FIRST_NAME.required,
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.fields.firstName}
                placeholder={staticText.typeHere}
                name={name}
                value={value}
                isRequired
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <Controller
            control={control}
            name='lastName'
            rules={{
              required: INPUT_ERRORS.LAST_NAME.required,
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.fields.lastName}
                placeholder={staticText.typeHere}
                name={name}
                value={value}
                isRequired
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <Controller
            control={control}
            name='email'
            rules={{
              required: INPUT_ERRORS.EMAIL.required,
              pattern: {
                value: EmailRegex,
                message: INPUT_ERRORS.EMAIL.pattern,
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.fields.email}
                placeholder={staticText.typeHere}
                name={name}
                value={value}
                isRequired
                onChange={onChange}
                errors={errors}
              />
            )}
          />
          <Controller
            control={control}
            name='profileType'
            render={({ field: { onChange, value, name } }) => (
              <ReactDropdown
                options={ROLE_DROPDOWN_OPTIONS}
                value={ROLE_DROPDOWN_OPTIONS.find(
                  (option) => option.value === value,
                )}
                onChange={onChange}
                name={name}
                title={staticText.fields.role}
                placeholder={strings.common.select}
                className='w-full h-12 rounded-md'
                isRequired
                labelClassName='mb-0'
              />
            )}
          />
          {isCompanyClient ? (
            <Controller
              control={control}
              name='companyName'
              rules={{
                required: isCompanyClient,
              }}
              render={({ field: { onChange, value, name } }) => (
                <ReactDropdown
                  options={companies}
                  value={value}
                  onChange={onChange}
                  isLoading={!!loadingCompanies}
                  name={name}
                  title={staticText.fields.companyName}
                  isSearchable
                  placeholder={staticText.searchOrCreate}
                  creatable
                  className='w-full h-12 rounded-md'
                  setPage={setCompanyPage}
                  haveMoreOptions={!!haveMoreCompanyPage}
                  setInputSearch={setCompanySearch}
                  isRequired
                  labelClassName='mb-0'
                />
              )}
            />
          ) : null}
          <Controller
            control={control}
            name='password'
            rules={{
              required: INPUT_ERRORS.PASSWORD.required,
              minLength: { value: 8, message: INPUT_ERRORS.PASSWORD.minLength },
              validate: (value) =>
                validatePassword(value) ||
                value === watch('reEnterPassword') ||
                INPUT_ERRORS.CONFIRM_PASSWORD.match,
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.fields.password}
                placeholder={staticText.typeHere}
                name={name}
                value={value}
                isRequired
                onChange={onChange}
                type='password'
                errors={errors}
              />
            )}
          />
          <Controller
            control={control}
            name='reEnterPassword'
            rules={{
              required: INPUT_ERRORS.PASSWORD.required,
              validate: (value) =>
                value === watchPassword || INPUT_ERRORS.CONFIRM_PASSWORD.match,
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.fields.reEnterPassword}
                placeholder={staticText.typeHere}
                name={name}
                value={value}
                type='password'
                isRequired
                onChange={onChange}
                errors={errors}
                hideInfoIcon
              />
            )}
          />
        </div>
        {isCompanyClient ? (
          <div className='my-6'>
            <Controller
              control={control}
              name='override'
              render={({ field: { onChange, value, name } }) => (
                <div className='items-top flex space-x-2'>
                  <Checkbox
                    id={name}
                    checked={!!value}
                    onCheckedChange={(value) => onChange(!!value)}
                    className='mt-1'
                  />
                  <div className='flex flex-col'>
                    <label htmlFor={name} className='font-bold'>
                      {staticText.overridePermisson}
                    </label>
                    <Typography className='md:text-sm'>
                      {staticText.overrideDescription}
                    </Typography>
                  </div>
                </div>
              )}
            />
          </div>
        ) : null}
      </Container>
      <div className='flex-col flex justify-end items-center mt-6 md:flex-row'>
        <Button
          variant='outline'
          onClick={handleCancel}
          className='mb-3 font-bold text-base md:mr-3 md:w-32 md:mb-0'
          disabled={createUserMutation.status === 'pending'}
        >
          {staticText.cancel}
        </Button>
        <Button
          onClick={handleSubmit(handleCreateUser)}
          className='font-bold text-base md:w-32'
          disabled={!isValid || createUserMutation.status === 'pending'}
          loading={createUserMutation.status === 'pending'}
        >
          {staticText.submit}
        </Button>
      </div>
      <Modal
        open={isOpen}
        handleCloseModal={handleCloseModal}
        title={staticText.sendCredentials}
      >
        <div className='flex flex-col items-center'>
          <Lottie
            animationData={SuccessAnimation}
            loop={false}
            className='w-1/5 items-center'
          />

          <div className='flex flex-col gap-4'>
            <Typography className='text-center mb-8'>
              {staticText.modalText}
            </Typography>
            <Typography className='text-base font-extrabold'>
              {staticText.fields.email}
            </Typography>
            <div className='border p-4 rounded-xl opacity-65 cursor-not-allowed'>
              <Typography>{email}</Typography>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateNewUserScreen;
