import React, { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FiFile, FiUploadCloud } from 'react-icons/fi';
import { HiOutlineTrash } from 'react-icons/hi';

import { IEditFormFieldsTypes } from '@/@types/request';
import { mimeTypes } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { CreateTicketType } from '@/pages/CreateTicket';
import { getMimeTypes } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { Button } from './ui/button';
import { Input } from './ui/input';
import Container from './Container';
import { Typography } from './Typography';

interface Props {
  buttonText?: string;
  iconClassName?: string;
  setFile?: React.Dispatch<React.SetStateAction<File | null>>; // For single
  setValue?: UseFormSetValue<CreateTicketType>; // For multiple
  watch?: UseFormWatch<CreateTicketType>;
  fileSize?: number;
  acceptedFileFormat?: string[] | string;
  dropzoneClassName?: string;
  uploadingDivClassName?: string;
  fileName?: string;
  progress?: number;
  error?: FieldErrors<IEditFormFieldsTypes>;
  fieldName?: string;
  multiple?: boolean;
}

const FileDropContainer: React.FC<Props> = ({
  buttonText,
  iconClassName,
  setFile,
  setValue,
  watch,
  fileSize = 5,
  acceptedFileFormat = '.docx',
  dropzoneClassName,
  uploadingDivClassName,
  fileName,
  progress = 0,
  error,
  fieldName,
  multiple = false,
}) => {
  const { common, ticketScreen: staticText } = strings;
  const FILE_SIZE_LIMIT = fileSize * 1024 * 1024;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const validFiles = acceptedFiles.filter(
        (file) => file.size <= FILE_SIZE_LIMIT,
      );

      if (validFiles.length !== acceptedFiles.length) {
        toast.error(common.fileSizeError);
      }

      if (validFiles.length > 0) {
        // If multiple is enabled, append new files to the existing selection
        if (multiple && watch) {
          setValue?.('qualifications', [
            ...((watch('qualifications') as File[]) || []),
            ...validFiles,
          ]);
        } else {
          // For single file uploads, replace the existing file
          const [updatedFile] = validFiles;
          setSelectedFile(updatedFile as File);
          setFile?.(updatedFile as File);
        }
      }
    },
    [
      FILE_SIZE_LIMIT,
      setFile,
      selectedFile,
      common.fileSizeError,
      fieldName,
      multiple,
    ],
  );

  const acceptConfig = useMemo(() => {
    const mimeTypesArray = getMimeTypes(
      Array.isArray(acceptedFileFormat)
        ? acceptedFileFormat
        : [acceptedFileFormat],
    );

    return mimeTypesArray.reduce(
      (acc, mimeType) => {
        if (mimeType) {
          acc[mimeType] = Object.keys(mimeTypes).filter(
            (ext) => mimeTypes[ext] === mimeType,
          );
        }
        return acc;
      },
      {} as Record<string, string[]>,
    );
  }, [acceptedFileFormat]);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    accept: acceptConfig,
    multiple,
  });

  const handleClearFileUpload = () => {
    setFile?.(null);
    setSelectedFile(null);
  };

  const isImage = (file: File | null) => file?.type.startsWith('image/');

  const renderFilePreview = () => (
    <div
      className={cn(
        'mt-1 h-44 flex gap-2 justify-center items-center bg-simplyViolet rounded-xl cursor-pointer focus:outline-none',
        { 'h-24': buttonText },
        dropzoneClassName,
      )}
    >
      {isImage(selectedFile) ? (
        <img
          src={URL.createObjectURL(selectedFile!)}
          alt={selectedFile?.name}
          className='size-12 object-cover rounded-lg'
        />
      ) : (
        <div className='p-2 rounded-full bg-primary'>
          <FiFile className='text-2xl text-white' />
        </div>
      )}

      <Typography className='md:text-sm font-semibold truncate max-w-full'>
        {fileName && fileName.length > 30
          ? `${fileName.slice(0, 30)}...`
          : fileName}
      </Typography>

      <div
        className='p-1 rounded-full bg-tomatoRed/15'
        onClick={handleClearFileUpload}
      >
        <HiOutlineTrash className='text-tomatoRed text-base' />
      </div>

      {renderUploadError()}
    </div>
  );

  const renderUploadError = () =>
    error && (
      <HookFormErrorMessage
        errors={error}
        name={fieldName || 'jobDescription'}
        render={({ message }) => (
          <p className='text-redColor text-xs'>{message}</p>
        )}
      />
    );

  const renderFileDropArea = () => (
    <div
      {...getRootProps()}
      className={cn(
        'mt-1 h-44 flex flex-col justify-center items-center bg-simplyViolet rounded-xl cursor-pointer drag-area focus:outline-none',
        { 'flex-row gap-2 h-24': buttonText },
        dropzoneClassName,
      )}
      onClick={open}
    >
      <Input {...getInputProps()} />
      <FiUploadCloud className={cn('text-3xl text-primary', iconClassName)} />
      {!buttonText && (
        <Typography className='font-bold text-primary'>
          {staticText['drag&drop']}
        </Typography>
      )}
      <Typography className='md:text-sm text-medium text-mouseGrey flex items-center w-fit'>
        {!buttonText ? staticText.or : ''}
        <Button variant='link' className='p-0 m-1 w-fit h-8' type='button'>
          {buttonText || staticText.browseFile}
        </Button>
        {!buttonText ? staticText.fromDevice : ''}
      </Typography>
      {renderUploadError()}
    </div>
  );

  const renderProgressView = () => (
    <Container height='h-fit' className={cn('mt-1', uploadingDivClassName)}>
      <Typography className='md:text-sm font-semibold truncate max-w-full'>
        {fileName && fileName.length > 30
          ? `${fileName.slice(0, 30)}...`
          : fileName}
      </Typography>
      <div className='flex items-center gap-2'>
        <Typography className='md:text-sm font-semibold text-quickSilver'>
          {Math.min(Math.max(progress, 0), 100)}%
        </Typography>
        <div className='size-1 rounded-full bg-quickSilver' />
        <Typography className='md:text-sm font-semibold text-quickSilver'>
          {progress < 100
            ? `${Math.ceil(((100 - progress) / 100) * 30)} seconds remaining`
            : common.uploadComplete}
        </Typography>
      </div>
      <div className='mt-4 h-2 overflow-hidden rounded-full bg-quickSilver/60'>
        <div
          className='h-5 transition-all ease-in-out bg-primary'
          style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
        />
      </div>
    </Container>
  );

  if (progress !== 0) return renderProgressView();
  if (!selectedFile || multiple) return renderFileDropArea();
  return renderFilePreview();
};

export default FileDropContainer;
