/* eslint-disable max-lines */
import React, { useCallback, useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Toaster } from 'react-hot-toast';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import { IMessageNotification } from '@/@types/chat';
import Header from '@/components/Header';
import Sidebar from '@/components/Sidebar';
import {
  initializeSocket,
  onConnect,
  onDisconnect,
  socket,
} from '@/config/socketConfig';
import { USER_TYPE } from '@/constants';
import { CHAT_SOCKET_EVENTS } from '@/constants/chat';
import { NOTIFICATIONS_SOCKET_EVENTS } from '@/constants/notifications';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { AuthProvider, useAuth } from '@/provider/AuthProvider';
import { CandidateProvider } from '@/provider/CandidateProvider';
import { ChatProvider, useChatContext } from '@/provider/ChatProvider';
import { CommonDataProvider } from '@/provider/CommonDataProvider';
import { FiltersProvider } from '@/provider/FiltersProvider';
import { PaginationProvider } from '@/provider/PaginationProvider';
import { SidebarProvider, useSidebarContext } from '@/provider/SidebarProvider';
import { PUBLIC_ROUTES, ROUTES } from '@/routes';
import { getChatList } from '@/services/chat';
import { getUnreadNotificationsCount } from '@/services/notifications';
import { messageNotification } from '@/utils/chat';
import { useQueryClient } from '@tanstack/react-query';

import ProtectedRoute from '../../routes/ProtectedRoute';
import PublicRoute from '../../routes/PublicRoute';
import ApprovalsScreen from '../Approvals';
import ForgotPassword from '../Auth/ForgotPassword';
import SetNewPassword from '../Auth/SetNewPassword.tsx';
import SignIn from '../Auth/SignIn';
import CandidateScreen from '../Candidate';
import CandidateDetail from '../CandidateDetails';
import ChatScreen from '../Chat';
import CreateUserScreen from '../CreateNewUser';
import CreateTicket from '../CreateTicket';
import Dashboard from '../Dashboard';
import Notifications from '../Notifications';
import ProfilePage from '../ProfilePage';
import RequestScreen from '../Request';
import RequestDetails from '../RequestDetails';
import TicketDetails from '../TicketDetails';
import TicketsScreen from '../Tickets';
import UserDetails from '../UserDetails';
import UsersScreen from '../Users';

import 'src/assets/styles/App.css';

const App = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { setChatList, allChats, chatList } = useChatContext();
  const { sidebarExpand, setUnreadNotificationsCount } = useSidebarContext();
  const { token, isAdmin, isCompanyClient, isRecruiter, user, hasSubRole } =
    useAuth();
  const hideLayout = Object.values(PUBLIC_ROUTES).includes(pathname) || !token;

  const handleRouteNotFound = () => {
    let redirectTo;

    if (token) {
      if (isAdmin) {
        redirectTo = ROUTES.USERS;
      } else if (isCompanyClient) {
        redirectTo = ROUTES.TICKETS;
      } else {
        redirectTo = ROUTES.DASHBOARD;
      }
    } else {
      redirectTo = ROUTES.LOGIN;
    }

    return <Navigate to={redirectTo} />;
  };

  const { data: notificationCount } = useGenericQuery(
    ['getUnreadNotificationsCount'],
    () => getUnreadNotificationsCount(),
    { enabled: !!token },
  );

  const { data } = useGenericQuery(
    ['chatList', isRecruiter || isAdmin],
    () => getChatList(isRecruiter || isAdmin),
    { enabled: !!token },
  );

  const handleMessageNotification = useCallback(
    (messageAlert: IMessageNotification) => {
      messageNotification(
        messageAlert,
        allChats,
        chatList,
        setChatList,
        queryClient,
      );
    },
    [allChats, chatList, setChatList, queryClient],
  );

  const incrementUnreadNotificationCount = useCallback(() => {
    setUnreadNotificationsCount((prev) => prev + 1);
  }, []);

  useEffect(() => {
    if (typeof notificationCount === 'object')
      setUnreadNotificationsCount(notificationCount.count);
  }, [notificationCount]);

  useEffect(() => {
    if (typeof data === 'object') setChatList(data);
  }, [data]);

  useEffect(() => {
    if (token && user?._id && !socket?.connected) {
      initializeSocket(user?._id);
    }
  }, [token, user?._id]);

  useEffect(() => {
    if (token && user?._id) {
      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on(
        CHAT_SOCKET_EVENTS.MESSAGE_NOTIFICATION,
        handleMessageNotification,
      );
      socket.on(
        NOTIFICATIONS_SOCKET_EVENTS.NEW_NOTIFICATION,
        incrementUnreadNotificationCount,
      );

      return () => {
        socket.off('connect', onConnect);
        socket.off(CHAT_SOCKET_EVENTS.MESSAGE_NOTIFICATION);
        socket.off(NOTIFICATIONS_SOCKET_EVENTS.NEW_NOTIFICATION);
      };
    }
    return () => {};
  }, [
    token,
    handleMessageNotification,
    user?._id,
    socket,
    incrementUnreadNotificationCount,
  ]);

  return (
    <div className='flex h-screen'>
      <div className={cn({ 'hidden md:block': !sidebarExpand })}>
        {hideLayout ? null : <Sidebar />}
      </div>
      <div className='w-full bg-simplyViolet overflow-scroll flex flex-col'>
        <Toaster />
        {hideLayout ? null : <Header />}
        <Routes>
          <Route
            path={ROUTES.DASHBOARD}
            element={
              <ProtectedRoute
                recruiterElement={<Dashboard />}
                requiredRoles={[USER_TYPE.RECRUITER]}
              />
            }
          />
          <Route
            path={ROUTES.USERS}
            element={
              <ProtectedRoute
                adminElement={<UsersScreen />}
                requiredRoles={[USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_USER}
            element={
              <ProtectedRoute
                adminElement={<CreateUserScreen />}
                requiredRoles={[...(hasSubRole ? [] : [USER_TYPE.ADMIN])]}
              />
            }
          />
          <Route
            path={ROUTES.USER_DETAIL}
            element={
              <ProtectedRoute
                adminElement={<UserDetails />}
                requiredRoles={[USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CHAT}
            element={
              <ProtectedRoute
                defaultElement={<ChatScreen />}
                requiredRoles={[
                  USER_TYPE.COMPANY_CLIENT,
                  USER_TYPE.RECRUITER,
                  ...(hasSubRole ? [] : [USER_TYPE.ADMIN]),
                ]}
              />
            }
          />
          <Route
            path={ROUTES.CANDIDATE}
            element={
              <ProtectedRoute
                defaultElement={<CandidateScreen />}
                requiredRoles={[USER_TYPE.ADMIN, USER_TYPE.RECRUITER]}
              />
            }
          />
          {/* <Route
            path={ROUTES.CANDIDATE}
            element={
              <ProtectedRoute
                adminElement={<CandidateScreen />}
                requiredRoles={[USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CANDIDATE_WITH_TABS}
            element={
              <ProtectedRoute
                recruiterElement={<CandidateScreen />}
                requiredRoles={[USER_TYPE.RECRUITER]}
              />
            }
          /> */}
          <Route
            path={ROUTES.PROFILE}
            element={
              <ProtectedRoute
                defaultElement={<ProfilePage />}
                requiredRoles={[
                  USER_TYPE.ADMIN,
                  USER_TYPE.RECRUITER,
                  USER_TYPE.COMPANY_CLIENT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.CANDIDATE_DETAIL}
            element={
              <ProtectedRoute
                defaultElement={<CandidateDetail />}
                requiredRoles={[USER_TYPE.ADMIN, USER_TYPE.RECRUITER]}
              />
            }
          />
          {/* <Route
            path={ROUTES.CANDIDATE_DETAIL}
            element={
              <ProtectedRoute
                adminElement={<CandidateDetail />}
                requiredRoles={[USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.CANDIDATE_DETAIL_WITH_TABS}
            element={
              <ProtectedRoute
                recruiterElement={<CandidateDetail />}
                requiredRoles={[USER_TYPE.RECRUITER]}
              />
            }
          /> */}
          <Route
            path={ROUTES.TICKETS}
            element={
              <ProtectedRoute
                defaultElement={<TicketsScreen />}
                requiredRoles={[USER_TYPE.COMPANY_CLIENT, USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.TICKETS_WITH_TAB}
            element={
              <ProtectedRoute
                recruiterElement={<TicketsScreen />}
                requiredRoles={[USER_TYPE.RECRUITER]}
              />
            }
          />
          <Route
            path={ROUTES.TICKET_DETAIL}
            element={
              <ProtectedRoute
                defaultElement={<TicketDetails />}
                requiredRoles={[USER_TYPE.COMPANY_CLIENT, USER_TYPE.ADMIN]}
              />
            }
          />
          <Route
            path={ROUTES.TICKET_DETAIL_WITH_TAB}
            element={
              <ProtectedRoute
                recruiterElement={<TicketDetails />}
                requiredRoles={[USER_TYPE.RECRUITER]}
              />
            }
          />
          <Route
            path={ROUTES.CREATE_TICKET}
            element={
              <ProtectedRoute
                defaultElement={<CreateTicket />}
                requiredRoles={[
                  ...(hasSubRole ? [] : [USER_TYPE.ADMIN]),
                  USER_TYPE.COMPANY_CLIENT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.NOTIFICATIONS}
            element={
              <ProtectedRoute
                defaultElement={<Notifications />}
                requiredRoles={[
                  USER_TYPE.ADMIN,
                  USER_TYPE.COMPANY_CLIENT,
                  USER_TYPE.RECRUITER,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.REQUEST}
            element={
              <ProtectedRoute
                companyClientElement={<RequestScreen />}
                adminElement={<RequestScreen />}
                requiredRoles={[
                  ...(hasSubRole ? [] : [USER_TYPE.ADMIN]),
                  USER_TYPE.COMPANY_CLIENT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.REQUEST_DETAIL}
            element={
              <ProtectedRoute
                defaultElement={<RequestDetails />}
                requiredRoles={[
                  ...(hasSubRole ? [] : [USER_TYPE.ADMIN]),
                  USER_TYPE.COMPANY_CLIENT,
                ]}
              />
            }
          />
          <Route
            path={ROUTES.REQUEST_DETAIL_WITH_TICKET_ID}
            element={
              <ProtectedRoute
                defaultElement={<RequestDetails />}
                requiredRoles={[USER_TYPE.COMPANY_CLIENT]}
              />
            }
          />
          <Route
            path={ROUTES.APPROVALS}
            element={
              <ProtectedRoute
                adminElement={<ApprovalsScreen />}
                requiredRoles={user?.subRole ? [USER_TYPE.ADMIN] : []}
              />
            }
          />
          <Route
            path={ROUTES.LOGIN}
            element={<PublicRoute element={<SignIn />} />}
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={<PublicRoute element={<ForgotPassword />} />}
          />
          <Route
            path={ROUTES.SET_NEW_PASSWORD}
            element={<PublicRoute element={<SetNewPassword />} />}
          />
          <Route path='*' element={handleRouteNotFound()} />
        </Routes>
      </div>
    </div>
  );
};

const AppWrapper = () => {
  return (
    <Router>
      <CookiesProvider>
        <AuthProvider>
          <SidebarProvider>
            <PaginationProvider>
              <ChatProvider>
                <CandidateProvider>
                  <CommonDataProvider>
                    <FiltersProvider>
                      <App />
                    </FiltersProvider>
                  </CommonDataProvider>
                </CandidateProvider>
              </ChatProvider>
            </PaginationProvider>
          </SidebarProvider>
        </AuthProvider>
      </CookiesProvider>
    </Router>
  );
};

export default AppWrapper;
