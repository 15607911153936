import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { ICandidateTag, IEndorsementFormData, ITag } from '@/@types/candidate';
import FileDropContainer from '@/components/FileDropContainer';
import { Typography } from '@/components/Typography';
import { Textarea } from '@/components/ui/textarea';
import { AcceptedFileFormats, INPUT_ERRORS } from '@/constants';
import { strings } from '@/locales';

import EndorsementHistorySection from './EndorsementHistorySection';

interface IEndorsementForm {
  control: Control<IEndorsementFormData>;
  error: FieldErrors<IEndorsementFormData>;
  data?: ITag | ICandidateTag;
  fileName?: string | File;
  progress?: number;
}

const EndorsementForm: React.FC<IEndorsementForm> = ({
  control,
  error,
  data,
  fileName,
  progress,
}) => {
  const { ticketDetailScreen: staticText } = strings;

  return (
    <>
      {data?.isEndorsed ? (
        <EndorsementHistorySection data={data} />
      ) : (
        <>
          <div>
            <Typography className='font-black sm:text-[15px]'>
              {staticText.description}
              <span className='text-redColor text-xl ml-1'>*</span>
            </Typography>
            <Controller
              name='description'
              rules={{ required: INPUT_ERRORS.DESCREPTION.required }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  rows={4}
                  value={value}
                  onChange={onChange}
                  name='description'
                  errors={error}
                  className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                />
              )}
            />
          </div>
          <div>
            <Typography className='font-black sm:text-[15px]'>
              {staticText.uploadFile}
            </Typography>
            <Controller
              name='document'
              control={control}
              render={({ field: { onChange, name } }) => (
                <FileDropContainer
                  setFile={onChange}
                  fileName={(fileName as File)?.name}
                  acceptedFileFormat={AcceptedFileFormats}
                  error={error}
                  fieldName={name}
                  progress={progress}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EndorsementForm;
