import { Dispatch, SetStateAction } from 'react';

import { USER_TYPE } from '.';

export const ROLE_DROPDOWN_OPTIONS = [
  {
    label: 'Recruiter',
    value: USER_TYPE.RECRUITER,
  },
  {
    label: 'Company Client',
    value: USER_TYPE.COMPANY_CLIENT,
  },
];

export const USER_ACTION = [{ value: 'delete', label: 'Delete' }];

export const UserFilterModalFields = ['role', 'company'];

export const generateUserTableFilterFields = (
  companies: string[],
  haveMoreCompanyPage: boolean | null,
  setCompanyPage: Dispatch<SetStateAction<number>>,
  setCompanySearch: Dispatch<SetStateAction<string>>,
  loadingCompanies: boolean | null,
  userRole: USER_TYPE,
) => [
  {
    name: 'role',
    items: ROLE_DROPDOWN_OPTIONS,
    props: { hasSearchBox: false },
  },
  ...(userRole === USER_TYPE.COMPANY_CLIENT
    ? [
        {
          name: 'company',
          items: companies,
          props: {
            haveMoreOptions: !!haveMoreCompanyPage,
            setPage: setCompanyPage,
            setInputSearch: setCompanySearch,
            isSearchable: true,
          },
          isLoading: loadingCompanies,
        },
      ]
    : []),
];
