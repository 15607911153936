import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import UnreadBadge from '@/components/UnreadBadge';
import { cn } from '@/lib/utils';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';

import OptionText, { IOptionText } from './OptionText';

interface ISidebarOption extends IOptionText {
  location: string;
  icon: ReactElement;
  selected?: boolean;
  name: string;
}

const SidebarOption = ({ selected, name, icon, location }: ISidebarOption) => {
  const { unReadMessageCount } = useChatContext();
  const { setCurrentTab, setSidebarExpand, isSmallScreen, sidebarExpand } =
    useSidebarContext();

  const handleOnClick = () => {
    setCurrentTab(name);
    isSmallScreen ? setSidebarExpand(false) : null;
  };

  return (
    <Link
      to={location}
      onClick={handleOnClick}
      className={cn(
        'flex min-w-fit items-center py-2 px-3 rounded-lg my-1 justify-between',
        {
          'bg-primary text-white font-bold rounded-xl': selected,
        },
      )}
    >
      <div className='flex items-center gap-3'>
        <div className='w-5'>
          {React.cloneElement(icon, {
            color: selected ? 'white' : '#1B1A1B',
          })}
        </div>
        <OptionText name={name} selected={selected} />
      </div>
      {name === 'chat' && unReadMessageCount ? (
        <UnreadBadge
          className={cn({ '-mt-4 -ml-1': !sidebarExpand })}
          count={sidebarExpand ? unReadMessageCount : null}
        />
      ) : null}
    </Link>
  );
};

export default SidebarOption;
