import React, { useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import {
  Cell,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
  Sector,
  SectorProps,
} from 'recharts';

import Container from '@/components/Container';
import ReactDropdown from '@/components/Dropdown';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import {
  DONUT_COLORS,
  LOCAL_RECRUITMENT_ACTIVITIES_DUMMY_DATA,
  RECRUITMENT_SOURCE,
  RecruitmentSourceList,
} from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { fetchDashboardSource } from '@/services/dashboard';

import SourcesKeyList, { ISource } from './SourcesKeyList';

const RADIAN = Math.PI / 180;

interface Props {
  cardDisplay?: boolean;
}

const SourceSection: React.FC<Props> = ({ cardDisplay = false }) => {
  const staticText = strings.dashboard;

  const [activeIndex, setActiveIndex] = useState(-1);
  const [data, setData] = useState<ISource[]>();
  const [selectedSourceList, setSelectedSourceList] = useState(
    RECRUITMENT_SOURCE.PLATFORM,
  );

  const { data: sourceData, isLoading: sourceDataLoading } = useGenericQuery(
    ['fetchDashboardSource'],
    fetchDashboardSource,
  );
  const pieChartWidth = window.innerWidth < 1200 ? 110 : 130;
  const pieChartInnerWidth = window.innerWidth < 1200 ? 60 : 75;
  const totalCount = useMemo(() => {
    return data?.reduce((acc, item) => acc + item.count, 0) || 0;
  }, [data]);

  useEffect(() => {
    if (cardDisplay) {
      setData(LOCAL_RECRUITMENT_ACTIVITIES_DUMMY_DATA);
    } else {
      setData(
        selectedSourceList === RECRUITMENT_SOURCE.PLATFORM
          ? sourceData?.platforms
          : sourceData?.localRecruitmentActivities,
      );
    }
  }, [selectedSourceList, sourceDataLoading, sourceData]);

  const RenderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: PieLabelRenderProps) => {
    if (index !== activeIndex) return null;

    const radius =
      Number(innerRadius) + (Number(outerRadius) - Number(innerRadius)) / 1.2;

    const x = Number(cx) + radius * Math.cos(-Number(midAngle) * RADIAN);
    const y = Number(cy) + radius * Math.sin(-Number(midAngle) * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor='middle'
        dominantBaseline='middle'
      >
        {`${((percent || 0) * 100).toFixed(0)}%`}
      </text>
    );
  };

  const CreateLinearGradients = (colors: string[]) => {
    return colors.map((color, index) => (
      <linearGradient
        key={`gradient${index}`}
        id={`gradient${index}`}
        x1='0%'
        y1='0%'
        x2='100%'
        y2='100%'
      >
        <stop
          offset='0%'
          style={{
            stopColor: `${color}95`,
            stopOpacity: 1,
          }}
        />
        <stop offset='100%' style={{ stopColor: color, stopOpacity: 1 }} />
      </linearGradient>
    ));
  };
  const getSourceText = () => {
    if (cardDisplay) return staticText.LRA;
    return selectedSourceList === RECRUITMENT_SOURCE.LRA
      ? staticText.LRA
      : selectedSourceList;
  };
  return (
    <Container
      className={cn({
        'p-2 md:p-2 pointer-events-none select-none': cardDisplay,
      })}
    >
      <div className='flex justify-between flex-col md:flex-row'>
        <div className='flex flex-col'>
          <Typography
            variant='subheading'
            className={cn('text-lg font-bold', {
              'text-[9px] md:text-[10px]': cardDisplay,
            })}
          >{`${staticText.listOf} ${getSourceText()}`}</Typography>
          <Typography
            className={cn('text-sm md:text-sm', {
              hidden: cardDisplay,
            })}
          >
            {staticText.sourcesSectionDesc}
          </Typography>
        </div>
        <div className='w-full md:w-fit flex justify-end'>
          {cardDisplay ? (
            <div className='border border-greyWhite w-12 px-1 h-4 justify-between text-[8px] rounded-md flex items-center mb-1'>
              {staticText.lra}
              <ChevronDown className='text-primaryBlack ml-0.5' size={8} />
            </div>
          ) : (
            <ReactDropdown
              options={RecruitmentSourceList}
              value={selectedSourceList}
              onChange={(e) => setSelectedSourceList(e as RECRUITMENT_SOURCE)}
              name={selectedSourceList}
              className='w-40 h-12 text-sm'
              inputHeight='38px'
              borderRadius='12px'
              isDisabled={sourceDataLoading}
            />
          )}
        </div>
      </div>
      <div
        className={cn('flex gap-1 items-center flex-col sm:flex-row', {
          'flex-row': cardDisplay,
        })}
      >
        <div
          className={cn(
            'size-80 flex flex-col justify-center w-full sm:w-1/2 lg:min-w-72 xl:min-w-80 items-center sm:items-start',
            {
              'size-[68px]': cardDisplay,
            },
          )}
        >
          {totalCount === 0 || (sourceDataLoading && !cardDisplay) ? (
            <div className='w-full flex justify-center'>
              <Skeleton
                noPulsing={totalCount === 0}
                className='size-52 mx-10 lg:size-52 xl:size-64 rounded-full flex items-center justify-center'
              >
                <div className='size-24 lg:size-28 xl:size-32 bg-white rounded-full' />
              </Skeleton>
            </div>
          ) : (
            <ResponsiveContainer>
              <PieChart>
                <defs>{CreateLinearGradients(DONUT_COLORS)}</defs>
                <Pie
                  data={data}
                  dataKey='count'
                  outerRadius={cardDisplay ? 130 / 4 : pieChartWidth}
                  innerRadius={cardDisplay ? 75 / 4 : pieChartInnerWidth}
                  paddingAngle={3}
                  cornerRadius={cardDisplay ? 2 : 12}
                  label={RenderCustomizedLabel}
                  labelLine={false}
                  activeIndex={activeIndex}
                  isAnimationActive={false}
                  className='focus:outline-none'
                  activeShape={(props: SectorProps) => (
                    <Sector
                      {...props}
                      outerRadius={(props.outerRadius || 0) + 20}
                      innerRadius={(props.innerRadius || 0) + 20}
                    />
                  )}
                  startAngle={90}
                  endAngle={450}
                >
                  {data?.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={`url(#gradient${index})`}
                      radius={'12px'}
                      className='focus:outline-none cursor-pointer'
                      {...(cardDisplay
                        ? {}
                        : {
                            onMouseOver: () => setActiveIndex(index),
                            onMouseOut: () => setActiveIndex(-1),
                          })}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className='w-1/2'>
          {sourceDataLoading && !cardDisplay ? (
            <div className='flex flex-col gap-3 '>
              {Array.from({ length: 6 }).map((_, i) => (
                <div key={i} className='flex gap-3 mb-2'>
                  <Skeleton className='size-3' />
                  <Skeleton className='h-4 w-28' />
                  <Skeleton className='h-4 w-10' />
                </div>
              ))}
            </div>
          ) : (
            <SourcesKeyList data={data} cardDisplay={cardDisplay} />
          )}
        </div>
      </div>
    </Container>
  );
};

export default SourceSection;
