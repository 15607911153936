import React from 'react';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';

import DocxIcon from '@/assets/svgs/docxIcon.svg';
import FileIcon from '@/assets/svgs/fileIcon.svg';
import PDFIcon from '@/assets/svgs/pdfIcon.svg';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { getLocalDate } from '@/utils/dateTime';

interface FileData {
  name: string;
  size: number | string;
  date?: string;
  url: string;
}

interface Props {
  file: FileData | string;
  className?: string;
  isLoading?: boolean;
}

const ResumeCard: React.FC<Props> = ({ file, className, isLoading }) => {
  const staticText = strings.candidateScreen;
  const isFileObject = typeof file !== 'string';
  const fileUrl = isFileObject ? file.url : file;
  const fileName = isFileObject ? file.name : file.toString();
  const fileSize = isFileObject ? file.size : '-';
  const fileDate = isFileObject ? file.date : undefined;
  const fileExtension = fileUrl.split('.').pop();
  const extensionIcon = () => {
    if (fileExtension === 'pdf') return PDFIcon;
    if (fileExtension === 'docx') return DocxIcon;
    return FileIcon;
  };

  const renderSkeleton = (width: string) => (
    <Skeleton className={`h-2 w-${width}`} />
  );

  return (
    <Container
      height='h-auto'
      className={cn('bg-simplyViolet md:py-3 overflow-hidden', className)}
    >
      <div className='flex flex-wrap items-center gap-3 sm:gap-0 justify-between'>
        <div className='flex gap-3 sm:gap-5 w-full'>
          <Container
            height='h-fit'
            width='w-fit'
            className='md:py-2 md:px-2 min-w-fit'
          >
            {isLoading ? (
              <Skeleton className='h-8 w-7' />
            ) : (
              <img
                src={extensionIcon()}
                alt={`${fileExtension} icon`}
                className='h-8 w-7'
              />
            )}
          </Container>

          <div className='flex justify-between items-center w-full overflow-auto'>
            <div
              className={cn('flex flex-col w-[80%]', { 'gap-1': isLoading })}
            >
              <Typography className='font-semibold truncate'>
                {isLoading ? renderSkeleton('10') : fileName}
              </Typography>
              <Typography className='text-xs md:text-xs text-mouseGrey w-fit'>
                {isLoading ? renderSkeleton('10') : fileSize}
              </Typography>
              <Typography className='text-xs md:text-xs text-mouseGrey w-fit'>
                {isLoading
                  ? renderSkeleton('10')
                  : `${staticText.uploadedDate}: ${getLocalDate(fileDate)}`}
              </Typography>
            </div>

            {isLoading ? (
              <Skeleton className='size-10' />
            ) : (
              <a
                href={fileUrl}
                download
                target='_blank'
                rel='noreferrer'
                className='justify-end'
              >
                <Button
                  variant='ghost'
                  size='icon'
                  icon={<HiMiniArrowTopRightOnSquare className='text-lg' />}
                  className='bg-white rounded-md'
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ResumeCard;
