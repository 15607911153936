import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  ForgotPasswordEmailField,
  IOtpFields,
  IUser,
  OtpFieldsResponseType,
} from '@/@types/auth';
import FormField from '@/components/FormField';
import Loader from '@/components/Loader';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { forgotPassword, verifyOtp } from '@/services/auth';
import { EmailRegex, formatEmailAddress } from '@/utils/common';
import { setTokenToSS } from '@/utils/sessionstorage';

import AuthHeadingsAndDesc from '../components/HeadingAndDesc';

const ForgotPassword = () => {
  const staticText = strings.forgotPasswordEmailScreen;

  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(0);
  const [otpStep, setOtpStep] = useState(false);
  const [status, setStatus] = useState<'error' | 'success' | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ForgotPasswordEmailField>({
    mode: 'all',
    defaultValues: {
      email: '',
    },
  });

  const forgotPasswordMutation = useGenericMutation<
    ForgotPasswordEmailField,
    IUser | boolean
  >(forgotPassword, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        toast.success(staticText.otpSend);
        setTimer(60);
        setOtpStep(true);
        setEmail(response.email);
      }
    },
  });

  const otpMutation = useGenericMutation<
    IOtpFields,
    OtpFieldsResponseType | boolean
  >(verifyOtp, {
    onError: (error) => {
      setStatus('error');
      toast.error((error as AxiosError).message);
    },
    onSuccess: (response) => {
      if (typeof response === 'object') {
        setStatus('success');
        toast.success(staticText.otpVerified);
        setTokenToSS(response.accessToken);
        setTimeout(() => {
          navigate(ROUTES.SET_NEW_PASSWORD);
        }, 1000);
      }
    },
  });

  const handleSubmitEmail: SubmitHandler<ForgotPasswordEmailField> = (data) => {
    forgotPasswordMutation.mutate(data);
  };

  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);
  };

  const handleOtpSubmit = () => {
    const payload = {
      email,
      otp: Number(otp),
    };
    otpMutation.mutate(payload);
  };

  const handleResend = () => {
    forgotPasswordMutation.mutate({ email });
    setOtp('');
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs} ${staticText[mins ? 'minute' : 'seconds']}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (status) setStatus(null);
  }, [otp.length]);

  const RenderSeparator = (index: number) => {
    return index === 2 ? <span className='text-2xl'>-</span> : null;
  };

  return (
    <>
      <AuthHeadingsAndDesc
        title={staticText.title}
        desc={
          otpStep
            ? staticText.descWithEmail.replace(
                '{{emailAddress}}',
                formatEmailAddress(email),
              )
            : staticText.desc
        }
      />
      {otpStep ? (
        <div>
          <div className='flex justify-center'>
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              containerStyle='otp-input flex gap-2 md:gap-4 justify-between w-full'
              inputStyle={cn(
                'rounded-md flex-grow focus:outline-primary bg-white border-greyWhite border h-8 xl:h-12 text-center text-lg',
                {
                  'outline outline-greenRevolution': status === 'success',
                  'outline outline-tomatoRed': status === 'error',
                },
              )}
              numInputs={6}
              skipDefaultStyles
              renderSeparator={RenderSeparator}
              renderInput={(props) => (
                <input
                  {...props}
                  className={cn(
                    'flex-grow basis-[30px] max-w-8 xl:min-w-12 xl:max-w-[50px]',
                    props.className,
                  )}
                />
              )}
            />
          </div>
          <div className='flex mt-2'>
            {forgotPasswordMutation.status === 'pending' ? (
              <Loader color='#2B8ABC' />
            ) : (
              <span
                onClick={handleResend}
                className={cn(
                  'text-base w-1/2 text-primary cursor-pointer hidden',
                  {
                    'block ':
                      timer === 0 &&
                      (forgotPasswordMutation.status === 'success' ||
                        forgotPasswordMutation.status === 'idle'),
                  },
                )}
              >
                {staticText.resendOtp}
              </span>
            )}
            <span className='w-full flex justify-end'>{formatTime(timer)}</span>
          </div>
          <Button
            onClick={handleOtpSubmit}
            className='text-base mt-4'
            disabled={otp.length < 6}
            loading={otpMutation.status === 'pending'}
          >
            {staticText.btnText}
          </Button>
          <Typography className='text-center w-full flex items-center justify-center mt-3'>
            {staticText.backTo}
            &nbsp;
            <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
              {staticText.signIn}
            </a>
          </Typography>
        </div>
      ) : (
        <div className='space-y-4'>
          <Controller
            control={control}
            name='email'
            rules={{
              required: INPUT_ERRORS.EMAIL.required,
              pattern: {
                value: EmailRegex,
                message: INPUT_ERRORS.EMAIL.pattern,
              },
            }}
            render={({ field: { onChange, value, name } }) => (
              <FormField
                title={staticText.email}
                placeholder={staticText.emailPlaceholder}
                name={name}
                value={value}
                isRequired
                onChange={onChange}
                errors={isSubmitted ? errors : {}}
              />
            )}
          />
          <Button
            className='text-base'
            onClick={handleSubmit(handleSubmitEmail)}
            disabled={forgotPasswordMutation.status === 'pending'}
            loading={forgotPasswordMutation.status === 'pending'}
          >
            {staticText.btnText}
          </Button>
          <Typography className='text-center w-full flex items-center justify-center mt-3'>
            {staticText.backTo}
            &nbsp;
            <a className='cursor-pointer text-primary' href={ROUTES.LOGIN}>
              {staticText.signIn}
            </a>
          </Typography>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
