import * as React from 'react';
import { SVGProps } from 'react';
const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={18}
    height={20}
    viewBox='0 0 22 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.000132569 19.5842C0.000132569 22.8538 2.75809 25.512 6.14994 25.512H15.8502C19.2422 25.512 22 22.8536 22 19.5842C22 15.7167 19.0135 12.515 15.1472 11.9901C16.7264 10.8191 17.7523 8.98389 17.7523 6.91843C17.7523 3.38718 14.7692 0.511963 11.1058 0.511963C7.44241 0.511963 4.45936 3.3873 4.45936 6.91843C4.45936 8.97058 5.46634 10.7942 7.02829 11.9634C3.07948 12.4139 0.000132569 15.6569 0.000132569 19.5842ZM6.22077 6.91843C6.22077 4.32298 8.41169 2.20803 11.1076 2.20803C13.8037 2.20803 15.9945 4.31984 15.9945 6.91843C15.9945 9.51387 13.8036 11.6288 11.1076 11.6288C8.41151 11.6288 6.22077 9.5137 6.22077 6.91843ZM7.96836 13.6018H14.0394C17.4624 13.6018 20.246 16.285 20.246 19.5842C20.246 21.9163 18.275 23.8161 15.8556 23.8161H6.15047C3.73102 23.8161 1.76004 21.9163 1.76004 19.5842C1.76004 16.2849 4.54549 13.6018 7.96836 13.6018Z'
      fill={props.color || '#1B1A1B'}
    />
  </svg>
);
export default ProfileIcon;
