import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { Input } from '@/components/ui/input';

import { Typography } from 'src/components/Typography';

interface IRankCellProps {
  currentRank: number;
  isEditing: boolean;
  setRank: Dispatch<SetStateAction<number>>;
}

export const RankCell: React.FC<IRankCellProps> = ({
  currentRank,
  isEditing,
  setRank,
}) => {
  // To manage focus at the rank field
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {isEditing ? (
        <Input
          ref={inputRef}
          className='h-8 md:h-8 text-base w-20 bg-simplyViolet'
          value={String(currentRank)}
          onChange={(e) => setRank(Number(e.target.value))}
          type='number'
          min='1'
        />
      ) : (
        <Typography className='md:text-[15px]'>{currentRank || '-'}</Typography>
      )}
    </div>
  );
};
