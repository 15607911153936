import { HiDotsVertical } from 'react-icons/hi';

import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import ProfileBadge from '@/components/ProfileBadge';
import Dropdown from '@/components/SelectDropdown';
import { ResumeCell } from '@/components/Table/CellComponents/ResumeCell';
import SelectCell from '@/components/Table/CellComponents/SelectCell';
import { TagsCell } from '@/components/Table/CellComponents/TagsCell';
import TableHeading from '@/components/Table/TableHeading';
import { Typography } from '@/components/Typography';
import {
  CANDIDATE_TABLE_ACTION,
  CandidateTableActionList,
} from '@/constants/candidate';
import { strings } from '@/locales';
import { GetFormattedName } from '@/utils/common';
import { ColumnDef, Row } from '@tanstack/react-table';

const {
  candidateScreen: { tableColumnsName },
} = strings;

export const CANDIDATE_DATA_COLUMNS = (
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>,
  setEditData: React.Dispatch<React.SetStateAction<ICandidate | null>>,
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>,
  hasSubRole: boolean,
): ColumnDef<ICandidate>[] => [
  { ...SelectCell<ICandidate>() },
  {
    id: 'name',
    header: () => <TableHeading text={tableColumnsName.name} />,
    cell: ({
      row: {
        original: { userDetails },
      },
    }) => (
      <ProfileBadge
        className='justify-start'
        nameClassName='font-medium'
        name={GetFormattedName<IUser>(userDetails)}
        profilePicture={userDetails?.avatar as string}
      />
    ),
  },
  {
    id: 'desiredRole',
    header: () => <TableHeading text={tableColumnsName.positionDesired} />,
    cell: ({ row }) => (
      <Typography className='md:text-[15px]'>
        {row.original.positionDesired?.[0]}
      </Typography>
    ),
  },
  {
    id: 'department',
    header: () => <TableHeading text={tableColumnsName.department} />,
    cell: ({ row: { original } }) => (
      <Typography className='md:text-[15px]'>{original.department}</Typography>
    ),
  },
  {
    id: 'resumeUrl',
    header: () => <TableHeading text={tableColumnsName.resume} />,
    cell: ({ row: { original } }) => (
      <ResumeCell resumeUrl={original.resume} preventPropagation />
    ),
  },

  ...(!hasSubRole
    ? [
        {
          id: 'tags',
          header: () => <TableHeading text={tableColumnsName.tags} />,
          cell: ({ row: { original } }: { row: Row<ICandidate> }) => (
            <TagsCell
              tags={original.tags || []}
              setOpenEditModal={setOpenEditModal}
              setEditData={setEditData}
              data={original}
            />
          ),
        },
        {
          id: 'options',
          cell: ({ row: { original } }: { row: Row<ICandidate> }) => {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <Dropdown
                  items={CandidateTableActionList}
                  setValue={(value) => {
                    if (value === CANDIDATE_TABLE_ACTION.EDIT_TAGS) {
                      setEditData(original);
                      setOpenEditModal(true);
                    } else if (value === CANDIDATE_TABLE_ACTION.DELETE) {
                      setEditData(original);
                      setOpenDeleteModal(true);
                    }
                  }}
                  customButton={<HiDotsVertical className='opacity-60 h-8' />}
                  customButtonClassName='flex justify-center'
                />
              </div>
            );
          },
        },
      ]
    : []),
];
