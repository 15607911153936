import React, { useEffect } from 'react';

import BackHeader from '@/components/BackHeader';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';

import ChatArea from './ChatArea';
import ChatSidebar from './ChatSidebar';
import StartChattingAnimationView from './StartChattingAnimationView';

const ChatScreen = () => {
  const { setSidebarExpand } = useSidebarContext();
  const {
    activeChat,
    setActiveChat,
    isBroadcastMessage,
    setIsBroadcastMessage,
    setSelectedRecipients,
  } = useChatContext();

  const staticText = strings.chatScreen;
  const isSmallScreen = window.innerWidth < 1024;
  const showChatArea = activeChat || isBroadcastMessage;

  const handleGoBack = () => {
    setActiveChat(null);
    setSelectedRecipients([]);
    setIsBroadcastMessage(false);
  };

  useEffect(() => {
    setSidebarExpand(false);

    return () => {
      setSidebarExpand(true);
    };
  }, []);

  return (
    <div className='h-full flex gap-3'>
      {!isSmallScreen ? (
        <>
          <ChatSidebar />
          {showChatArea ? <ChatArea /> : <StartChattingAnimationView />}
        </>
      ) : (
        <>
          {showChatArea ? (
            <div className='flex flex-col gap-2 w-full'>
              <BackHeader
                title={staticText.chat}
                handleBackBtn={handleGoBack}
              />
              <ChatArea />
            </div>
          ) : (
            <ChatSidebar />
          )}
        </>
      )}
    </div>
  );
};

export default ChatScreen;
