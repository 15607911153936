import * as React from 'react';
import { SVGProps } from 'react';
const TicketsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={18}
    viewBox='0 0 24 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.1002 10.512H17.5001M8.70023 14.7473H17.5001M23 7.20737C22.9263 5.57467 22.7206 4.51161 22.142 3.6709C21.8129 3.1919 21.4002 2.77134 20.9221 2.42785C19.6396 1.51196 17.8301 1.51196 14.2089 1.51196H9.79251C6.17247 1.51196 4.3619 1.51196 3.07932 2.42785C2.60413 2.76667 2.19163 3.18702 1.85834 3.6709C1.28085 4.51161 1.07515 5.57467 1.00035 7.20737C0.989352 7.48585 1.23795 7.69973 1.51184 7.69973C3.03642 7.69973 4.2728 8.95867 4.2728 10.512C4.2728 12.0653 3.03642 13.3242 1.51184 13.3242C1.23795 13.3242 0.988252 13.5381 1.00145 13.8176C1.07515 15.4493 1.28085 16.5123 1.85944 17.3541C2.18861 17.8327 2.60132 18.2529 3.07932 18.5961C4.3619 19.512 6.17247 19.512 9.79251 19.512H14.2089C17.829 19.512 19.6396 19.512 20.9221 18.5961C21.3973 18.2573 21.8098 17.8369 22.1431 17.353C22.7206 16.5123 22.9263 15.4493 23 13.8166V7.20737Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={1.7}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default TicketsIcon;
