/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { IEditUser } from '@/@types/auth';
import Container from '@/components/Container';
import FileDropContainer from '@/components/FileDropContainer';
import FormField from '@/components/FormField';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileAvatar from '@/components/ProfileAvatar';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { INPUT_ERRORS, USER_TYPE } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { fetchProfile, updateProfile } from '@/services/profile';
import {
  GetFormattedName,
  PhoneRegex,
  snakeToTitleCase,
  validateNewPasswordFields,
} from '@/utils/common';

const Profile = () => {
  const { profileScreen: staticText } = strings;

  const { isAdmin, setUser, isCompanyClient, hasSubRole } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [currentProfile, setCurrentProfile] = useState<IEditUser>();

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, dirtyFields },
    setValue,
    watch,
  } = useForm<IEditUser>({
    defaultValues: currentProfile,
    mode: 'onChange',
  });
  const uploadedFile = watch('avatar');

  const { newPasswordRules, confirmNewPasswordRules } =
    validateNewPasswordFields(watch('newPassword'));

  const formFields = [
    {
      field: 'firstName',
      label: staticText.firstNameLabel,
      placeholder: staticText.firstNamePlaceholder,
      rules: { required: INPUT_ERRORS.FIRST_NAME.required },
    },
    {
      field: 'lastName',
      label: staticText.lasttNameLabel,
      placeholder: staticText.lastNamePlaceholder,
      rules: { required: INPUT_ERRORS.LAST_NAME.required },
    },
    {
      field: 'phoneNumber',
      label: staticText.phoneLabel,
      placeholder: staticText.phonePlaceholder,
      rules: {
        pattern: {
          value: PhoneRegex,
          message: INPUT_ERRORS.INVALID_PHONE_NUMBER.required,
        },
      },
    },
    {
      field: 'email',
      label: staticText.emailLabel,
      placeholder: staticText.emailPlaceholder,
      disabled: true,
    },
    ...(isCompanyClient
      ? [
          {
            field: 'companyName',
            label: staticText.company,
            disabled: true,
          },
        ]
      : []),
    ...(isAdmin && !hasSubRole
      ? [
          ...(isEditing && updatePassword
            ? [
                {
                  field: 'password',
                  type: 'password',
                  label: staticText.oldPassword,
                  placeholder: staticText.oldPasswordPlaceholder,
                  hideInfoIcon: true,
                },
                {
                  field: 'newPassword',
                  type: 'password',
                  label: staticText.newPassword,
                  placeholder: staticText.newPassword,
                  rules: newPasswordRules,
                  disabled: !watch('password'),
                },
                {
                  field: 'confirmNewPassword',
                  type: 'password',
                  label: staticText.confirmPassword,
                  placeholder: staticText.confirmPassword,
                  rules: confirmNewPasswordRules,
                  hideInfoIcon: true,
                  disabled: !watch('password'),
                },
              ]
            : []),
        ]
      : []),
  ];

  const handlePasswordFileds = () => {
    setUpdatePassword(!updatePassword);
    setValue('password', '');
    setValue('newPassword', '');
    setValue('confirmNewPassword', '');
  };

  const handleCancel = () => {
    setUpdatePassword(!updatePassword);
    setIsEditing(false);
    reset();
  };

  const handleProgressUpdate = useCallback((percentage: number) => {
    setUploadProgress(percentage);
  }, []);

  const getDescriptionText = (
    field: string,
    currentProfile: IEditUser | null,
  ) => {
    if (!currentProfile) {
      return undefined;
    }
    return String(currentProfile[field as keyof IEditUser] || '');
  };

  const handleEditToggle = () => {
    setUploadProgress(0);
    setIsEditing(!isEditing);
    if (!isEditing) {
      if (currentProfile) {
        reset(currentProfile);
      }
    }
  };

  const { data, error, isLoading, refetch } = useGenericQuery(
    ['userProfile'],
    fetchProfile,
  );

  const updateProfileMutation = useGenericMutation<
    { payload: IEditUser; setPercent?: (percent: number) => void },
    IEditUser | boolean
  >(updateProfile, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        refetch();
        setCurrentProfile(response);
        toast.success(staticText.success);
        setUser(response);
        setIsEditing(false);
        setUpdatePassword(false);
      }
    },
  });

  const onSubmit = (data: IEditUser) => {
    updateProfileMutation.mutate({
      payload: data,
      setPercent:
        uploadedFile instanceof File ? handleProgressUpdate : undefined,
    });
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setCurrentProfile(data);
    }
  }, [data]);

  useEffect(() => {
    if (currentProfile) {
      reset(currentProfile);
    }
  }, [currentProfile, reset]);

  useEffect(() => {
    if (watch('newPassword') && watch('confirmNewPassword')) {
      trigger('confirmNewPassword');
    }
  }, [watch('newPassword'), trigger]);

  useEffect(() => {
    if (uploadedFile && isEditing) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(uploadedFile as Blob);
    } else {
      setPreviewUrl(null);
    }
  }, [uploadedFile]);

  if (error) return <StateIndicator state='Error' />;
  return (
    <div className='flex flex-col h-full'>
      <div className='flex-grow'>
        <Container className='relative w-full h-auto space-y-6'>
          <Typography className='font-bold md:text-xl'>
            {staticText.title}
          </Typography>
          <div className='flex gap-4'>
            <ProfileBadge
              name={GetFormattedName(currentProfile)}
              profilePicture={currentProfile?.avatar as string}
              attribute={snakeToTitleCase(
                (currentProfile?.profileType === USER_TYPE.ADMIN
                  ? currentProfile?.subRole
                  : currentProfile?.profileType
                )?.toLowerCase(),
              )}
              className={cn('mb-4 md:mb-0 md:mr-4 justify-start', {
                hidden: isEditing,
              })}
              avatarClassName='size-16'
              isLoading={isLoading}
            />
            {isEditing && (
              <div className='flex gap-3 items-center'>
                <ProfileAvatar
                  name={`${watch('firstName')} ${watch('lastName')}`}
                  src={previewUrl || (currentProfile?.avatar as string)}
                  className='size-24'
                  isLoading={isLoading}
                />
                <Controller
                  name='avatar'
                  control={control}
                  render={({ field: { onChange } }) => (
                    <FileDropContainer
                      setFile={onChange}
                      fileName={(uploadedFile as File)?.name}
                      dropzoneClassName='w-80 h-28 p-2'
                      uploadingDivClassName='w-80 h-28'
                      acceptedFileFormat={['.png', '.jpeg']}
                      progress={
                        uploadedFile instanceof File
                          ? uploadProgress
                          : undefined
                      }
                    />
                  )}
                />
              </div>
            )}
          </div>
          <div className='mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-between w-full'>
            {formFields.map(
              (
                {
                  field,
                  label,
                  placeholder,
                  rules,
                  disabled,
                  type,
                  hideInfoIcon,
                },
                index,
              ) => (
                <React.Fragment key={label}>
                  {index ===
                  formFields.findIndex((f) =>
                    ['password'].includes(f.field),
                  ) ? (
                    <Separator className='bg-flashWhite col-span-1 md:col-span-2 lg:col-span-3 xl:col-span-4 my-4' />
                  ) : null}
                  <div className='w-full md:w-auto'>
                    {isEditing ? (
                      <Controller
                        name={field as keyof IEditUser}
                        control={control}
                        rules={
                          rules as RegisterOptions<IEditUser, keyof IEditUser>
                        }
                        render={({ field: { onChange, value } }) => (
                          <FormField
                            title={label}
                            placeholder={placeholder}
                            name={field}
                            value={value?.toString()}
                            hideInfoIcon={hideInfoIcon}
                            type={type}
                            onChange={onChange}
                            isRequired={!!rules?.required}
                            errors={errors}
                            disabled={disabled}
                            labelClassName='font-bold md:text-base'
                          />
                        )}
                      />
                    ) : (
                      <NameAndDesc
                        title={label}
                        description={getDescriptionText(
                          field,
                          currentProfile as IEditUser,
                        )}
                        isLoading={isLoading}
                      />
                    )}
                  </div>
                </React.Fragment>
              ),
            )}
          </div>
          {isEditing && isAdmin && !hasSubRole ? (
            <Button
              onClick={handlePasswordFileds}
              variant='link'
              className='w-auto px-0 h-8'
            >
              {updatePassword
                ? staticText.cancelUpdatePassword
                : staticText.updatePassword}
            </Button>
          ) : null}
        </Container>
      </div>
      <div className='flex justify-end gap-4 p-4'>
        {isEditing && (
          <Button
            onClick={handleCancel}
            variant='outline'
            className='w-32 h-11'
            disabled={updateProfileMutation.status === 'pending'}
          >
            {staticText.cancel}
          </Button>
        )}
        <Button
          onClick={isEditing ? handleSubmit(onSubmit) : handleEditToggle}
          loading={updateProfileMutation.status === 'pending'}
          className='w-32 h-11'
          disabled={
            (isEditing && !Object.keys(dirtyFields).length) ||
            isLoading ||
            !data ||
            updateProfileMutation.status === 'pending'
          }
        >
          {isEditing ? staticText.save : staticText.edit}
        </Button>
      </div>
    </div>
  );
};

export default Profile;
