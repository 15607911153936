import React, { useMemo, useState } from 'react';
import { Control, Controller, FieldErrors, FieldValues } from 'react-hook-form';

import { FieldType, IDropdownOption } from '@/@types';
import BannerAlert from '@/components/BannerAlert';
import DatePickerField from '@/components/DatePickerField';
import ReactDropdown, { DropdownProps } from '@/components/Dropdown';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Input } from '@/components/ui/input';
import { INPUT_ERRORS } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { OptionalProps } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleResetFilter?: () => void;
  handleApplyFilter: () => void;
  control: Control<FieldValues>;
  filterErrors?: FieldErrors;
  buttonDisabled?: boolean;
  isAnimated?: boolean;
  searchText: string;
  handleClearSearch: () => void;
  fields: {
    name: string;
    items?: IDropdownOption[] | string[];
    props?: OptionalProps<DropdownProps>;
    isLoading?: boolean | null;
    inputType?: FieldType;
    saveBothLabelAndValue?: boolean;
  }[];
}

const FilterModal = ({
  isOpen,
  control,
  onClose,
  handleResetFilter,
  handleApplyFilter,
  filterErrors,
  buttonDisabled,
  isAnimated = true,
  fields,
  searchText,
  handleClearSearch,
}: IFilterModalProps) => {
  const { common, filterModal: staticText } = strings;

  const [isClosing, setIsClosing] = useState(false);

  const truncatedSearchText = useMemo(
    () =>
      searchText.length > 10 ? `${searchText.slice(0, 10)}...` : searchText,
    [searchText],
  );

  const handleDialogClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 1000);
  };

  return (
    <Modal
      open={isOpen}
      secondaryBtnDisabled={buttonDisabled}
      buttonDisabled={buttonDisabled}
      handleCloseModal={handleDialogClose}
      title={staticText.title}
      primaryBtnLabel={staticText.btnTwo}
      primaryBtnAction={handleApplyFilter}
      secondaryBtnLabel={staticText.btnOneText}
      secondaryBtnAction={handleResetFilter}
      contentClassName={cn(
        'max-h-[calc(100vh-10vh)] md:max-h-[calc(100vh-200px)] custom-modal-content',
        {
          'slide-in-right': isOpen && isAnimated,
          'slide-out-right': isClosing && isAnimated,
          ' overflow-scroll md:overflow-visible': fields.length > 4,
        },
      )}
    >
      <>
        {searchText ? (
          <BannerAlert
            text={
              <>
                {staticText.serachInfoText.split('this')[0]}
                <i>{truncatedSearchText}</i>
                {staticText.serachInfoText.split('this')[1]}
                <span
                  onClick={handleClearSearch}
                  className='cursor-pointer font-extrabold hover:underline'
                >
                  {staticText.clear}.
                </span>
              </>
            }
            alertType='info'
          />
        ) : null}
        <div className='gap-x-4 gap-y-5 grid md:grid-cols-2 mb-3 md:mb-8'>
          {fields.map((field, index) => (
            <div key={`field-${index}`}>
              <Typography className='flex md:text-sm capitalize font-semibold mb-2'>
                {staticText[field.name as keyof typeof staticText]}
              </Typography>
              <Controller
                control={control}
                name={field.name}
                rules={{
                  validate: (value) => {
                    const minValue =
                      field.inputType === 'Range'
                        ? value[`${field.name}Min`]
                        : null;
                    const maxValue =
                      field.inputType === 'Range'
                        ? value[`${field.name}Max`]
                        : null;
                    if ((minValue || maxValue) && (!minValue || !maxValue)) {
                      return INPUT_ERRORS.RANGE.required;
                    }
                    if (
                      minValue &&
                      maxValue &&
                      parseFloat(minValue) >= parseFloat(maxValue)
                    ) {
                      return INPUT_ERRORS.RANGE.validate;
                    }
                    return true;
                  },
                }}
                render={({ field: { onChange, value, name } }) => {
                  switch (field.inputType) {
                    case FieldType.DatePicker:
                      return (
                        <DatePickerField
                          value={value}
                          setValue={onChange}
                          contanierClassName='w-full rounded-md h-11'
                          name={name}
                          errors={filterErrors}
                          fromDate={new Date('1900-01-01')}
                          toDate={new Date()}
                        />
                      );
                    case FieldType.Range:
                      return (
                        <>
                          <div key={field.name} className='flex gap-3'>
                            <Input
                              name={`${field.name}Min`}
                              placeholder='Min'
                              value={value[`${field.name}Min`] || ''}
                              className='h-11'
                              onChange={(e) =>
                                onChange({
                                  ...value,
                                  [`${field.name}Min`]: e.target.value,
                                })
                              }
                              type='number'
                            />
                            <Input
                              name={`${field.name}Max`}
                              placeholder='Max'
                              value={value[`${field.name}Max`] || ''}
                              className='h-11'
                              onChange={(e) =>
                                onChange({
                                  ...value,
                                  [`${field.name}Max`]: e.target.value,
                                })
                              }
                              type='number'
                            />
                          </div>
                          {filterErrors && (
                            <HookFormErrorMessage
                              errors={filterErrors}
                              name={String(name)}
                              render={({ message }) => (
                                <p className='text-redColor text-xs'>
                                  {message}
                                </p>
                              )}
                            />
                          )}
                        </>
                      );
                    default: {
                      const dropdownItems = field.items ? field.items : [];
                      return (
                        <ReactDropdown
                          options={dropdownItems}
                          value={value}
                          onChange={onChange}
                          isLoading={field.isLoading || false}
                          name={name}
                          isSearchable={field?.props?.isSearchable}
                          placeholder={common.select}
                          inputHeight='44px'
                          haveMoreOptions={field?.props?.haveMoreOptions}
                          setPage={field?.props?.setPage}
                          tagsDropdown={field?.props?.tagsDropdown}
                          setInputSearch={field?.props?.setInputSearch}
                          isDisabled={field?.props?.isDisabled}
                          errors={filterErrors}
                          saveBothLabelAndValue={
                            field?.props?.saveBothLabelAndValue
                          }
                        />
                      );
                    }
                  }
                }}
              />
            </div>
          ))}
        </div>
      </>
    </Modal>
  );
};

export default FilterModal;
