import { Dispatch, SetStateAction } from 'react';
import { HiDotsVertical } from 'react-icons/hi';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { ICandidate, ICandidateTag } from '@/@types/candidate';
import ProfileBadge from '@/components/ProfileBadge';
import Dropdown from '@/components/SelectDropdown';
import { ResumeCell } from '@/components/Table/CellComponents/ResumeCell';
import SelectCell from '@/components/Table/CellComponents/SelectCell';
import { TagsCell } from '@/components/Table/CellComponents/TagsCell';
import TableHeading from '@/components/Table/TableHeading';
import { TICKET_CANDIDATE_TABLE_ACTION } from '@/constants/ticket';
import { strings } from '@/locales';
import { GetFormattedName } from '@/utils/common';
import { ColumnDef, Row } from '@tanstack/react-table';

import { TicketCandidateActionCell } from './CellComponents';
import { RankCell } from './RankCell';

interface Props {
  tag: ICandidateTag | null;
  setTag: Dispatch<SetStateAction<ICandidateTag | null>>;
  action: TICKET_CANDIDATE_TABLE_ACTION | null;
  setAction: Dispatch<SetStateAction<TICKET_CANDIDATE_TABLE_ACTION | null>>;
  rank: number;
  setRank: Dispatch<SetStateAction<number>>;
  setEditTagData?: Dispatch<SetStateAction<ICandidate | null>>;
  handleToggleTagEditModal: (() => void) | null;
  setEndorsementModalData?: Dispatch<SetStateAction<ICandidateTag | null>>;
  isHigherStatusThanActive: boolean;
  showActions: boolean;
  dropdownOptions?: IDropdownOption[];
  setRowForUpdateTag: Dispatch<SetStateAction<ICandidateTag | null>>;
  setEditableCandidateData: React.Dispatch<
    React.SetStateAction<ICandidate | null>
  >;
}

const {
  ticketDetailScreen: { tableColumnsName },
} = strings;

export const TicketsCandidatesColumns = ({
  tag,
  setTag,
  action,
  setAction,
  rank,
  setRank,
  setEditTagData,
  handleToggleTagEditModal,
  isHigherStatusThanActive,
  dropdownOptions,
  showActions,
  setRowForUpdateTag,
  setEditableCandidateData,
}: Props): ColumnDef<ICandidateTag>[] => {
  const selectCell = !isHigherStatusThanActive
    ? [{ ...SelectCell<ICandidateTag>() }]
    : [];

  return [
    ...(showActions ? selectCell : []),
    {
      id: 'name',
      header: () => <TableHeading text={tableColumnsName.name} />,
      cell: ({
        row: {
          original: { candidate },
        },
      }) => (
        <ProfileBadge
          className='justify-start'
          nameClassName='font-medium'
          name={GetFormattedName<IUser>(candidate?.userDetails)}
          profilePicture={candidate?.userDetails?.avatar as string}
        />
      ),
    },
    {
      id: 'resumeUrl',
      header: () => <TableHeading text={tableColumnsName.resume} />,
      cell: ({
        row: {
          original: { candidate },
        },
      }) => <ResumeCell resumeUrl={candidate?.resume} preventPropagation />,
    },
    ...(isHigherStatusThanActive
      ? [
          {
            id: 'rank',
            header: () => <TableHeading text={tableColumnsName.rank} />,
            cell: ({ row }: { row: Row<ICandidateTag> }) => {
              const editCurrentRow =
                row.original._id === tag?._id &&
                action === TICKET_CANDIDATE_TABLE_ACTION.EDIT_RANK;
              return (
                <RankCell
                  currentRank={editCurrentRow ? rank : row?.original.rank || 0}
                  isEditing={editCurrentRow}
                  setRank={setRank}
                />
              );
            },
          },
        ]
      : []),
    {
      id: 'tags',
      header: () => <TableHeading text={tableColumnsName.tags} />,
      cell: ({ row: { original } }) => (
        <TagsCell
          tags={
            !original?.isPendingApproval
              ? original.status
              : strings.common.pendingApproval
          }
          data={original?.candidate}
          handleToggleTagEditModal={
            isHigherStatusThanActive && showActions
              ? handleToggleTagEditModal
              : null
          }
          setEditData={setEditTagData}
          setRowForUpdateTag={() => setRowForUpdateTag(original)}
          rowData={original}
        />
      ),
    },
    ...(showActions
      ? [
          {
            id: 'actions',
            cell: ({ row: { original } }: { row: Row<ICandidateTag> }) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setTag(original);
                    setEditableCandidateData(original.candidate);
                  }}
                  className='flex gap-4 items-center justify-center'
                >
                  <TicketCandidateActionCell
                    setAction={setAction}
                    isHigherStatusThanActive={isHigherStatusThanActive}
                  />
                  {isHigherStatusThanActive ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setTag(original);
                        setEditableCandidateData(original.candidate);
                      }}
                    >
                      <Dropdown
                        items={dropdownOptions as IDropdownOption[]}
                        setValue={(value) => {
                          setAction(value as TICKET_CANDIDATE_TABLE_ACTION);
                        }}
                        customButton={
                          <HiDotsVertical className='opacity-60 h-8' />
                        }
                        customButtonClassName='flex justify-center'
                      />
                    </div>
                  ) : null}
                </div>
              );
            },
          },
        ]
      : []),
  ];
};
