import React, { useEffect, useState } from 'react';

import { IActiveChat } from '@/@types';
import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import { IChat, ITicketChat } from '@/@types/chat';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import TooltipComponent from '@/components/Tooltip';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import UnreadBadge from '@/components/UnreadBadge';
import { USER_TYPE } from '@/constants';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { GetFormattedName, snakeToTitleCase } from '@/utils/common';

interface Props {
  loading: boolean;
}

const ChatAccordion: React.FC<Props> = ({ loading }) => {
  const staticText = strings.chatScreen;

  const { chatList } = useChatContext();
  const { user, isRecruiter, isAdmin } = useAuth();
  const {
    activeChat,
    setActiveChat,
    setIsBroadcastMessage,
    setSelectedRecipients,
    setConversationId,
  } = useChatContext();

  const [openItems, setOpenItems] = useState<string[]>([]);

  const chats = chatList
    ? [
        ...chatList.tickets,
        ...(chatList?.others?.length
          ? [{ conversations: chatList?.others }]
          : []),
      ]
    : null;

  const handleChatClick = (activeChat: IActiveChat, conversationId: string) => {
    setConversationId(conversationId);
    setActiveChat(activeChat);
    setIsBroadcastMessage(false);
    setSelectedRecipients([]);
  };

  const handleAccordionChange = (value: string[]) => {
    setOpenItems(value);
  };

  const displayRole = (profileType: USER_TYPE, tooltip?: boolean) => {
    switch (profileType) {
      case USER_TYPE.COMPANY_CLIENT:
        return tooltip ? snakeToTitleCase(profileType) : 'CC';
      case USER_TYPE.RECRUITER:
        return tooltip ? snakeToTitleCase(profileType) : 'R';
      default:
        return tooltip ? snakeToTitleCase(profileType) : 'A';
    }
  };

  const findActiveConversation = (chats: IChat[], activeChat: IActiveChat) => {
    return chats.find((chat) => {
      const isUser1 = chat?.user1._id === user?._id;
      const isUser2 = chat?.user2._id === user?._id;

      return (
        (isUser1 &&
          (chat?.user2 as ICandidate)?.userDetails?._id ===
            (activeChat.user as ICandidate)?.userDetails?._id) ||
        (isUser2 &&
          (chat?.user1 as ICandidate)?.userDetails?._id ===
            (activeChat.user as ICandidate)?.userDetails?._id)
      );
    });
  };

  useEffect(() => {
    const defaultOpenItems = () => {
      if (!activeChat) return [];

      if (!activeChat?.ticket) {
        if (chatList?.others) {
          const activeConversation = findActiveConversation(
            chatList?.others,
            activeChat,
          );
          if (activeConversation?.conversationId) {
            setConversationId(activeConversation.conversationId);
          }
        }
        return [...openItems, staticText.others];
      }

      const activeTicket = chatList?.tickets.find(
        ({ ticket }) => ticket?._id === activeChat.ticket?._id,
      );
      const activeConversation = () => {
        if (activeTicket?.conversations)
          return findActiveConversation(
            activeTicket?.conversations,
            activeChat,
          );
        return false;
      };

      setConversationId((activeConversation?.() as IChat)?.conversationId);

      return [...openItems, activeChat.ticket._id!];
    };

    setOpenItems(defaultOpenItems());
  }, [activeChat]);

  if (loading) return <StateIndicator state='Loading' />;
  if (!chats?.length)
    return (
      <StateIndicator
        state='Empty'
        noDataMessage={staticText.noChats}
        className='h-[90%] lg:h-full'
      />
    );

  return chats?.map((ticketChats: ITicketChat) => {
    const { ticket, conversations } = ticketChats;
    const hasUnreadChats = conversations.reduce((accumulator, conversation) => {
      return accumulator + (conversation.unreadCount || 0);
    }, 0);
    const displayText = () => {
      if (!ticket) return staticText.others;
      return `${isRecruiter ? `${ticket.client} / ` : ''}Tk#${ticket.ticketNumber}`;
    };
    return (
      <Accordion
        type='multiple'
        key={ticket?._id || staticText.others}
        value={openItems}
        onValueChange={handleAccordionChange}
      >
        <AccordionItem
          key={ticket?._id || staticText.others}
          value={ticket?._id || staticText.others}
          className='border-b-0 bg-simplyViolet rounded-xl'
        >
          <AccordionTrigger className='bg-primary rounded-xl text-white font-bold text-sm px-4 py-2 hover:no-underline text-start'>
            <div className='flex w-full justify-between items-center mr-1'>
              {displayText()}
              {hasUnreadChats ? <UnreadBadge /> : null}
            </div>
          </AccordionTrigger>
          <AccordionContent className='rounded-xl px-2 flex flex-col gap-1 mt-2'>
            {conversations.map((conversation: IChat) => {
              const { conversationId, unreadCount } = conversation;
              const users = [conversation.user1, conversation.user2];

              const recipient = users.find((user) => {
                const profileType =
                  (user as ICandidate)?.userDetails?.profileType ||
                  (user as IUser)?.profileType;

                if (isRecruiter) return profileType !== USER_TYPE.RECRUITER;
                if (isAdmin) return profileType !== USER_TYPE.ADMIN;
                return profileType !== USER_TYPE.COMPANY_CLIENT;
              });

              const recipientDetails: IUser =
                (recipient as ICandidate)?.userDetails || (recipient as IUser);

              return (
                <div
                  key={`Chat-${conversationId}`}
                  className={cn(
                    'bg-simplyViolet border-none rounded-xl h-10 px-2 flex justify-between items-center gap-2 cursor-pointer',
                    {
                      'bg-naturalGray':
                        activeChat?.ticket?._id === ticket?._id &&
                        (activeChat?.user._id === recipientDetails?._id ||
                          (activeChat?.user as ICandidate)?.userDetails?._id ===
                            recipientDetails?._id),
                    },
                  )}
                  onClick={() => {
                    handleChatClick(
                      {
                        ticket,
                        user: recipient!,
                      },
                      conversationId,
                    );
                  }}
                >
                  <ProfileBadge
                    name={GetFormattedName(recipientDetails)}
                    profilePicture={recipientDetails?.avatar as string}
                    nameClassName='font-semibold'
                  />
                  <div className='flex gap-2 justify-center items-center'>
                    <TooltipComponent
                      text={displayRole(recipientDetails.profileType, true)}
                      align='center'
                      className='capitalize rounded-lg'
                      directionalArrow
                      trigger={
                        <span className='text-xs font-bold text-primary'>
                          {recipientDetails?.profileType !== USER_TYPE.CANDIDATE
                            ? displayRole(recipientDetails.profileType)
                            : null}
                        </span>
                      }
                    />
                    {unreadCount ? <UnreadBadge count={unreadCount} /> : null}
                  </div>
                </div>
              );
            })}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  });
};

export default ChatAccordion;
