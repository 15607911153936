import { FaCircleDot } from 'react-icons/fa6';
import { TooltipProps } from 'recharts';

import { Typography } from '@/components/Typography';

const StatisticsTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
  coordinate,
}) => {
  if (active && payload?.length) {
    return (
      <div
        className='absolute p-3 bg-white rounded-lg min-w-52 -translate-x-1/2 z-10 shadow-lg'
        style={{
          left: coordinate?.x,
          top: coordinate?.y ? coordinate.y - 70 : 0,
        }}
      >
        <div className='flex items-center'>
          <FaCircleDot className='text-primary size-2.5' />
          <Typography className='mx-2 text-sm'>{label}</Typography>
          <Typography className='font-bold text-primary text-lg ml-auto'>{`${payload[0]?.value}%`}</Typography>
        </div>
        <div className='absolute left-1/2 -bottom-1.5 size-4 bg-white rotate-45 transform -translate-x-1/2 -z-20' />
      </div>
    );
  }
  return null;
};

export default StatisticsTooltip;
