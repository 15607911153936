import { Dispatch, SetStateAction } from 'react';

import { IDropdownOption } from '@/@types';

import { CANDIDATE_STATUS, CandidateStatusList } from './candidate';

export enum REQUEST_TABS {
  ENDORSED = 'endorsed',
  CLOSURE = 'closure',
}

export const getRequestFilterModalFields = (
  activeTab: string,
  department: IDropdownOption[],
  loadingDepartments: boolean | null,
  haveMoreDepartmentPage: boolean | null,
  setDepartmentPage: Dispatch<SetStateAction<number>>,
  setDepartmentSearch: Dispatch<SetStateAction<string>>,
  recruiterDropdownValues: IDropdownOption[],
  isLoadingRecruiters: boolean,
  haveMoreRecruitersPage: boolean | null,
  setRecruiterPage: Dispatch<SetStateAction<number>>,
  setRecruitersSearch: Dispatch<SetStateAction<string>>,
) => [
  {
    name: 'department',
    items: department,
    isLoading: loadingDepartments,
    props: {
      haveMoreOptions: !!haveMoreDepartmentPage,
      setPage: setDepartmentPage,
      setInputSearch: setDepartmentSearch,
      isSearchable: true,
    },
  },
  {
    name: 'recruiter',
    items: recruiterDropdownValues,
    isLoading: isLoadingRecruiters,
    props: {
      isSearchable: true,
      saveBothLabelAndValue: true,
      haveMoreOptions: !!haveMoreRecruitersPage,
      setPage: setRecruiterPage,
      setInputSearch: setRecruitersSearch,
    },
  },
  ...(activeTab === REQUEST_TABS.CLOSURE
    ? [
        {
          name: 'status',
          items: CandidateStatusList.filter(
            (item) =>
              item === CANDIDATE_STATUS.HR_ORIENTATION ||
              item === CANDIDATE_STATUS.REJECTED,
          ),
          props: {
            tagsDropdown: true,
          },
        },
      ]
    : []),
];
