import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { Company, Department } from '@/@types/metas';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';

export const fetchDepartments = async (
  page: number,
  departmentSearch?: string,
): Promise<IPaginationResponse<Department> | boolean> => {
  const response = await API.Get<IPaginationResponse<Department>>(
    `${REST_SUB_URL.GET_DEPARTMENTS}?page=${page}&search=${departmentSearch}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchCompanies = async (
  page: number,
  companySearch?: string,
): Promise<IPaginationResponse<Company> | boolean> => {
  const response = await API.Get<IPaginationResponse<Company>>(
    `${REST_SUB_URL.GET_COMPANIES}?page=${page}&search=${companySearch}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};
