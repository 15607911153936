import { IDropdownOption } from '@/@types';
import { APPROVAL_STATUS } from '@/@types/approvals';
import { EnumToArray } from '@/utils/common';

export enum APPROVAL_TABS {
  PENDING = 'pending',
  REVIEWED = 'reviewed',
}

export const ApprovalTabsList = EnumToArray(APPROVAL_TABS);

export enum AccountOfficerStatus {
  ACC_OFFICER_ACCEPTED = 'ACC_OFFICER_ACCEPTED',
  ACC_OFFICER_REJECTED = 'ACC_OFFICER_REJECTED',
}

export enum RecruiterManagerStatus {
  RECRUITER_MANAGER_ACCEPTED = 'RECRUITER_MANAGER_ACCEPTED',
  RECRUITER_MANAGER_REJECTED = 'RECRUITER_MANAGER_REJECTED',
}

export const getApprovalsFilterModalFields = (
  candidateList: IDropdownOption[],
  candidateLoading: boolean,
  candidateHasNextPage: boolean,
  setCandidatePageIndex: React.Dispatch<React.SetStateAction<number>>,
  setCandidateSearch: React.Dispatch<React.SetStateAction<string>>,
  haveMoreRecruitersPage: boolean,
  recruiters: IDropdownOption[],
  setRecruitersSearch: React.Dispatch<React.SetStateAction<string>>,
  loadingRecruiters: boolean,
  setRecruiterPage: React.Dispatch<React.SetStateAction<number>>,
  activeTab: APPROVAL_TABS,
) => [
  {
    name: 'candidate',
    items: candidateList,
    isLoading: candidateLoading,
    props: {
      isSearchable: true,
      haveMoreOptions: candidateHasNextPage,
      setPage: setCandidatePageIndex,
      setInputSearch: setCandidateSearch,
    },
  },
  {
    name: 'assignee',
    items: recruiters,
    isLoading: loadingRecruiters,
    props: {
      isSearchable: true,
      haveMoreOptions: haveMoreRecruitersPage,
      setPage: setRecruiterPage,
      setInputSearch: setRecruitersSearch,
      saveBothLabelAndValue: true,
    },
  },
  ...(activeTab === APPROVAL_TABS.REVIEWED
    ? [
        {
          name: 'status',
          items: EnumToArray(APPROVAL_STATUS),
          props: {
            tagsDropdown: true,
            isSearchable: true,
          },
        },
      ]
    : []),
];
