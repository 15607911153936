import React from 'react';
import { GoLinkExternal } from 'react-icons/go';
import { generatePath, useNavigate } from 'react-router-dom';

import NameAndDesc from '@/components/NameAndDesc';
import Tags from '@/components/Tags';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { TICKET_CANDIDATES_TABS, TICKET_TABS } from '@/constants/ticket';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';
import { ROUTES } from '@/routes';
import { formatCurrency, getTagVariant } from '@/utils/common';

const TicketTooltip = () => {
  const staticText = strings.ticketScreen.tableColumnsName;
  const navigate = useNavigate();
  const { activeChat } = useChatContext();
  const ticket = activeChat?.ticket;

  const path = generatePath(ROUTES.TICKET_DETAIL_WITH_TAB, {
    tab: TICKET_TABS.MY_TICKETS,
    ticketId: ticket?._id,
    innerTab: TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES,
  });

  const renderNameAndDesc = (title: string, description?: string | number) => (
    <NameAndDesc
      title={title}
      description={description}
      className='space-y-0'
      titleClassName='sm:text-xs md:text-xs'
      descriptionClassName='sm:text-xs md:text-xs'
    />
  );

  return (
    <div>
      <TooltipComponent
        align='center'
        className='w-72 p-4'
        trigger={
          <Tags
            text={`Tk#${ticket?.ticketNumber}`}
            variant='blue'
            textClassName='font-bold'
            containerClassName='w-fit'
          />
        }
      >
        <div className='flex flex-col gap-4'>
          <div className='flex justify-between items-center gap-2'>
            <Typography className='font-extrabold'>{`Tk#${ticket?.ticketNumber}`}</Typography>
            <Tags
              text={ticket?.status}
              variant={getTagVariant(ticket?.status)}
              containerClassName='w-fit h-6'
            />
            <GoLinkExternal
              size={14}
              onClick={() => navigate(path)}
              className='cursor-pointer text-primary ml-auto'
            />
          </div>

          <div className='grid grid-cols-2 gap-2'>
            {renderNameAndDesc(
              staticText.department,
              ticket?.department as string,
            )}
            {renderNameAndDesc(staticText.jobTitle, ticket?.jobTitle)}
            {renderNameAndDesc(
              staticText.appliedCandidates,
              ticket?.appliedCandidates?.length,
            )}
            {renderNameAndDesc(staticText.rate, formatCurrency(ticket?.rate))}
          </div>
        </div>
      </TooltipComponent>
    </div>
  );
};

export default TicketTooltip;
