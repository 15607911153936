import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox='0 0 26 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.316 25.9812H3.53015C1.14908 25.9812 0 24.8795 0 22.605V3.88812C0 1.61366 1.16092 0.511963 3.53015 0.511963H8.316C10.6971 0.511963 11.8462 1.61366 11.8462 3.88812V22.605C11.8462 24.8795 10.6852 25.9812 8.316 25.9812ZM3.53015 2.28889C2.02569 2.28889 1.77692 2.69166 1.77692 3.88812V22.605C1.77692 23.8015 2.02569 24.2043 3.53015 24.2043H8.316C9.82046 24.2043 10.0692 23.8015 10.0692 22.605V3.88812C10.0692 2.69166 9.82046 2.28889 8.316 2.28889H3.53015Z'
      fill={props.color || '#1B1A1B'}
    />
    <path
      d='M21.9371 15.3197H17.1512C14.7702 15.3197 13.6211 14.218 13.6211 11.9435V3.88812C13.6211 1.61366 14.782 0.511963 17.1512 0.511963H21.9371C24.3182 0.511963 25.4672 1.61366 25.4672 3.88812V11.9435C25.4672 14.218 24.3063 15.3197 21.9371 15.3197ZM17.1512 2.28889C15.6468 2.28889 15.398 2.69166 15.398 3.88812V11.9435C15.398 13.14 15.6468 13.5427 17.1512 13.5427H21.9371C23.4416 13.5427 23.6903 13.14 23.6903 11.9435V3.88812C23.6903 2.69166 23.4416 2.28889 21.9371 2.28889H17.1512Z'
      fill={props.color || '#1B1A1B'}
    />
    <path
      d='M21.9371 25.9806H17.1512C14.7702 25.9806 13.6211 24.8789 13.6211 22.6044V20.4721C13.6211 18.1976 14.782 17.0959 17.1512 17.0959H21.9371C24.3182 17.0959 25.4672 18.1976 25.4672 20.4721V22.6044C25.4672 24.8789 24.3063 25.9806 21.9371 25.9806ZM17.1512 18.8729C15.6468 18.8729 15.398 19.2756 15.398 20.4721V22.6044C15.398 23.8009 15.6468 24.2036 17.1512 24.2036H21.9371C23.4416 24.2036 23.6903 23.8009 23.6903 22.6044V20.4721C23.6903 19.2756 23.4416 18.8729 21.9371 18.8729H17.1512Z'
      fill={props.color || '#1B1A1B'}
    />
  </svg>
);
export default SvgComponent;
