import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';

interface IManpowerSectionDetailItem {
  label: string;
  value: string | number;
  valueClassName?: string;
  cardDisplay?: boolean;
}

const ManpowerSectionDetailItem: React.FC<IManpowerSectionDetailItem> = ({
  label,
  value,
  valueClassName,
  cardDisplay,
}) => {
  return (
    <div className='flex flex-col'>
      <Typography
        className={cn('text-xs md:text-[13px] font-bold truncate', {
          'text-[6px] md:text-[6px]': cardDisplay,
        })}
      >
        {label}
      </Typography>
      <Typography
        className={cn(
          'text-xs md:text-[13px] font-semibold text-primary mt-1 truncate',
          {
            'text-[7px] md:text-[7px] -mt-2': cardDisplay,
          },
          valueClassName,
        )}
      >
        {value}
      </Typography>
    </div>
  );
};
export default ManpowerSectionDetailItem;
