import React, { useEffect, useRef } from 'react';
import { GoBell } from 'react-icons/go';
import { HiDownload } from 'react-icons/hi';
import { RxHamburgerMenu } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';

import { USER_TYPE } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import Notifications from '@/pages/Notifications';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import { clearUnreadNotificationsCount } from '@/services/notifications';
import { GetFormattedName, snakeToTitleCase } from '@/utils/common';

import Logo from '../assets/images/logo.svg';

import { Button } from './ui/button';
import ProfileBadge from './ProfileBadge';
import UnreadBadge from './UnreadBadge';

const Header = () => {
  const notificationRef = useRef<HTMLDivElement>(null);
  const bellIconRef = useRef<HTMLButtonElement>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    setSidebarExpand,
    sidebarExpand,
    isSmallScreen,
    setShowNotifications,
    showNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
  } = useSidebarContext();

  const notificationsVisible =
    (isSmallScreen && location.pathname.includes(ROUTES.NOTIFICATIONS)) ||
    showNotifications;
  const { avatar, profileType, subRole } = user || {};

  const handleNavigationClick = () => {
    {
      unreadNotificationsCount ? clearUnreadNotifications.mutate(null) : null;
    }
    const toggleNotifications = () => setShowNotifications((prev) => !prev);

    if (!notificationsVisible)
      isSmallScreen ? navigate(ROUTES.NOTIFICATIONS) : toggleNotifications();
    else isSmallScreen ? navigate(-1) : toggleNotifications();
  };

  const clearUnreadNotifications = useGenericMutation<null, boolean>(
    clearUnreadNotificationsCount,
    {
      onSuccess: (response) => {
        if (response) {
          setUnreadNotificationsCount(0);
        }
      },
    },
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showNotifications &&
        !notificationRef.current?.contains(event.target as Node) &&
        !bellIconRef.current?.contains(event.target as Node)
      ) {
        handleNavigationClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showNotifications]);

  return (
    <div className='relative'>
      <div
        className={cn(
          'flex justify-between items-center w-full px-4 md:px-8 h-[72px] bg-white border-b-greyWhite border-b',
          { 'justify-end': sidebarExpand },
        )}
      >
        <div
          className={cn('w-14 md:hidden', {
            hidden: isSmallScreen && sidebarExpand,
          })}
          onClick={() => navigate(ROUTES.DASHBOARD)}
        >
          <img src={Logo} alt='Logo' />
        </div>
        <Button
          variant='ghost'
          size='icon'
          icon={<HiDownload className='-rotate-90 text-primaryBlack text-xl' />}
          className={cn('size-8 ', {
            hidden: sidebarExpand || isSmallScreen,
          })}
          onClick={() => setSidebarExpand((prev) => !prev)}
        />
        <div className='flex gap-4 md:gap-8 justify-between items-center'>
          <div className='flex'>
            <Button
              ref={bellIconRef}
              variant='outline'
              size='icon'
              icon={
                <GoBell
                  className={cn('text-lg text-primaryBlack', {
                    'text-white': notificationsVisible,
                  })}
                />
              }
              className={cn(
                'border-greyWhite rounded-[5px] bg-simplyViolet size-8 border',
                { 'bg-primary': notificationsVisible },
              )}
              onClick={handleNavigationClick}
            />
            {unreadNotificationsCount ? (
              <UnreadBadge
                count={unreadNotificationsCount}
                className='-ml-2 -mt-1'
              />
            ) : null}
          </div>
          <RxHamburgerMenu
            className='md:hidden'
            onClick={() => setSidebarExpand(true)}
          />
          <ProfileBadge
            name={GetFormattedName(user!)}
            profilePicture={avatar as string}
            attribute={snakeToTitleCase(
              (profileType === USER_TYPE.ADMIN
                ? subRole || USER_TYPE.ADMIN
                : profileType
              )?.toLowerCase(),
            )}
            className='hidden md:flex'
          />
        </div>
      </div>
      {showNotifications ? (
        <div
          ref={notificationRef}
          className={cn({
            'fixed right-48 top-14 z-50': !isSmallScreen,
          })}
        >
          <Notifications />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
