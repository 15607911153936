import { HiDownload } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { cn } from '@/lib/utils';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import { snakeToTitleCase } from '@/utils/common';

import Logo from '../../assets/images/logo.svg';
import ProfileBadge from '../ProfileBadge';
import { Button } from '../ui/button';

const SidebarHeader = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { sidebarExpand, setSidebarExpand, setSidebarClosing, isSmallScreen } =
    useSidebarContext();

  const { firstName, lastName, avatar, profileType } = user || {};

  const handleSidebarClose = () => {
    setSidebarClosing(true);
    setTimeout(() => {
      setSidebarExpand((prev) => !prev);
      setSidebarClosing(false);
    }, 1000);
  };

  return (
    <div
      className={cn(
        'cursor-pointer flex justify-center h-[72px] items-center',
        {
          'justify-between mx-3': sidebarExpand,
        },
      )}
    >
      <ProfileBadge
        name={`${firstName} ${lastName}`}
        profilePicture={avatar as string}
        attribute={snakeToTitleCase(profileType?.toLowerCase())}
        className='md:hidden'
      />
      <div
        className={cn('hidden md:block w-12', {
          'w-[70px]': sidebarExpand,
        })}
        onClick={() => navigate(ROUTES.DASHBOARD)}
      >
        <img src={Logo} alt='Logo' />
      </div>
      <Button
        variant='ghost'
        size='icon'
        icon={<HiDownload className='rotate-90 text-primaryBlack text-xl' />}
        className={cn('size-8 mt-2', {
          hidden: !sidebarExpand,
        })}
        onClick={
          isSmallScreen
            ? handleSidebarClose
            : () => setSidebarExpand((prev) => !prev)
        }
      />
    </div>
  );
};

export default SidebarHeader;
