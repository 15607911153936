/* eslint-disable max-lines */
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import { FieldType } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import {
  ICreateCompanyClientIAFPayload,
  IRequestTicketData,
} from '@/@types/request';
import { IInterviewEvaluationFormData } from '@/@types/tickets';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import {
  INTERVIEW_EVALUATION_FIELDS,
  InterviewEvaluationFormInitialState,
} from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import FormConstantDataSection from '@/pages/Candidate/components/EditModal/FormConstantDataSection';
import { useAuth } from '@/provider/AuthProvider';
import { createCompanyClientIAF } from '@/services/request';
import { useQueryClient } from '@tanstack/react-query';

import InterviewEvaluationFormRow from './InterviewEvaluationFormRow';
import InterviewEvaluationHistory from './InterviewEvaluationHistory';

const isInterviewFormValid = (data: IInterviewEvaluationFormData): boolean => {
  return Object.entries(data).every((field) => {
    const value = field?.[1];
    if (typeof value === 'object' && value !== null) {
      return value?.evaluation && value?.evaluation.trim() !== '';
    }
    if (typeof value === 'string') {
      return (value as string)?.trim() !== '';
    }
    return true;
  });
};

interface Props {
  open: boolean;
  ticket?: IRequestTicketData;
  ticketId?: string;
  candidate?: ICandidate;
  handleCloseModal: () => void;
  showHistory: boolean;
  mrfNumber?: string;
  jobPosition?: string;
}

const InterviewEvaluationModal: React.FC<Props> = ({
  ticket,
  open,
  candidate,
  handleCloseModal,
  showHistory,
  ticketId,
  mrfNumber,
  jobPosition,
}) => {
  const { ticketDetailScreen: staticText } = strings;
  const interviewEvaluationText = staticText.interviewEvaluation;

  const { user } = useAuth();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<IInterviewEvaluationFormData>({
    mode: 'all',
    defaultValues: InterviewEvaluationFormInitialState,
  });

  const formValues = watch();

  const isFormValid = useMemo(() => {
    return isInterviewFormValid(formValues);
  }, [formValues]);

  const isButtonDisabled = useMemo(() => {
    return !isDirty || !isFormValid;
  }, [isDirty, isFormValid]);

  const sendInterviewEvaluationMutation = useGenericMutation<
    ICreateCompanyClientIAFPayload,
    ICreateCompanyClientIAFPayload | boolean
  >((payload) => createCompanyClientIAF(payload), {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        toast.success(staticText.clientIAFSuccess);
        queryClient.invalidateQueries({
          queryKey: ['requests'],
        });
      }
    },
  });

  const handleSendInterviewEvaluation = (
    data: IInterviewEvaluationFormData,
  ) => {
    sendInterviewEvaluationMutation.mutate({
      ...data,
      candidate: candidate!._id,
      companyClient: user!._id!,
      ticket: ticket!._id,
    });
    handleCloseModal();
    reset();
  };

  const handleClosingModal = () => {
    reset();
    handleCloseModal();
  };

  // Flatten the array for rendering
  const flattenedFields = INTERVIEW_EVALUATION_FIELDS.flatMap((section) =>
    section.fields.map((field) => ({
      sectionTitle: section.sectionTitle,
      ...field,
    })),
  );

  // Separate fields into three types: select, textarea, and others
  const selectFields = flattenedFields.filter(
    (field) => field.inputType === FieldType.Dropdown,
  );
  const textareaFields = flattenedFields.filter(
    (field) => field.inputType === FieldType.TextArea,
  );
  const otherFields = flattenedFields.filter(
    (field) =>
      field.inputType !== FieldType.Dropdown &&
      field.inputType !== FieldType.TextArea,
  );

  const renderInterviewEvaluationFieldFormRow = (
    field: (typeof flattenedFields)[0],
  ) => (
    <InterviewEvaluationFormRow
      label={field.label}
      inputType={field.inputType}
      control={control}
      name={field.name}
      errors={errors}
    />
  );

  return (
    <Modal
      open={open}
      handleCloseModal={handleClosingModal}
      contentClassName='max-w-3xl'
    >
      <div
        className={cn('flex flex-col lg:flex-row w-full h-fit -mt-5 gap-5', {
          'md:flex-row': !showHistory,
        })}
      >
        <div className='w-full h-[80vh] md:h-auto'>
          <div className='flex flex-col '>
            <Typography className='text-[22px] md:text-[22px] font-extrabold mb-5'>
              {`Client's ${interviewEvaluationText.title}`}
            </Typography>
            <div className='max-h-[calc(80vh-110px)] h-full md:max-h-[60vh] overflow-auto'>
              <FormConstantDataSection
                mrfNumber={mrfNumber || ticket?.ticketNumber || ''}
                job={jobPosition || ticket?.jobTitle || ''}
              />
              {showHistory ? (
                <InterviewEvaluationHistory
                  candidate={candidate as ICandidate}
                  ticketId={ticketId || ticket!._id}
                />
              ) : (
                <div className='mt-4'>
                  {selectFields.map((field, index) => (
                    <div key={index}>
                      {(index > 0 &&
                        selectFields[index - 1]?.sectionTitle !==
                          field.sectionTitle) ||
                      field.inputType === FieldType.TextArea ? (
                        <Separator className='my-4' />
                      ) : null}
                      {(index === 0 ||
                        selectFields[index - 1]?.sectionTitle !==
                          field.sectionTitle) &&
                        field.sectionTitle && (
                          <div className='lg:flex md:mb-3'>
                            <Typography className='font-extrabold block min-w-52 mb-3 md:mb-0 '>
                              {field.sectionTitle}
                            </Typography>
                            <div className='grid grid-cols-2 w-full'>
                              <Typography className='font-extrabold block '>
                                {interviewEvaluationText.evaluation}
                              </Typography>
                              <Typography className='font-extrabold block '>
                                {interviewEvaluationText.comments}
                              </Typography>
                            </div>
                          </div>
                        )}
                      {renderInterviewEvaluationFieldFormRow(field)}
                    </div>
                  ))}
                  <Separator className='my-4 ' />
                  <div className='md:flex gap-5 flex-row'>
                    {otherFields.map((field, index) => (
                      <div key={index} className='md:w-1/2'>
                        {renderInterviewEvaluationFieldFormRow(field)}
                      </div>
                    ))}
                  </div>
                  <Separator className='my-4 ' />
                  {textareaFields.map((field, index) => (
                    <div key={index}>
                      {renderInterviewEvaluationFieldFormRow(field)}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {!showHistory ? (
              <div className='flex gap-2 mt-3 justify-center'>
                <Button
                  variant='outline'
                  className=' disabled:opacity-70 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
                  onClick={handleClosingModal}
                  disabled={
                    sendInterviewEvaluationMutation.status === 'pending'
                  }
                >
                  {strings.common.cancel}
                </Button>
                <Button
                  onClick={handleSubmit(handleSendInterviewEvaluation)}
                  className='flex md:p-3 sm:w-44 font-medium disabled:opacity-70 sm:text-base md:w-32'
                  loading={sendInterviewEvaluationMutation.status === 'pending'}
                  disabled={
                    sendInterviewEvaluationMutation.status === 'pending' ||
                    isButtonDisabled
                  }
                >
                  {showHistory ? '' : staticText.send}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InterviewEvaluationModal;
