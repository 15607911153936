import React, { createContext, ReactNode, useContext, useState } from 'react';

import { ICandidate } from '@/@types/candidate';

interface ICandidateContextType {
  editableCandidateData: ICandidate | null;
  setEditableCandidateData: React.Dispatch<
    React.SetStateAction<ICandidate | null>
  >;
  openEditModal: boolean;
  setOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleCloseEditModal: () => void;
}

const CandidateContext = createContext<ICandidateContextType>({
  editableCandidateData: null,
  setEditableCandidateData: () => {},
  openEditModal: false,
  setOpenEditModal: () => {},
  handleCloseEditModal: () => {},
});

export const CandidateProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [editableCandidateData, setEditableCandidateData] =
    useState<ICandidate | null>(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setEditableCandidateData(null);
  };

  const value = {
    editableCandidateData,
    setEditableCandidateData,
    openEditModal,
    setOpenEditModal,
    handleCloseEditModal,
  };

  return (
    <CandidateContext.Provider value={value}>
      {children}
    </CandidateContext.Provider>
  );
};

export const useCandidate = (): ICandidateContextType =>
  useContext(CandidateContext);
