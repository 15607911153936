/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { MenuPlacement } from 'react-select';

import { IDropdownOption } from '@/@types';
import { ICompaniesAndTicketsResponse, ITagType } from '@/@types/candidate';
import { Department } from '@/@types/metas';
import ReactDropdown from '@/components/Dropdown';
import Tags from '@/components/Tags';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { USER_TYPE } from '@/constants';
import {
  CANDIDATE_STATUS,
  getFieldsForModal,
  statusTransitions,
} from '@/constants/candidate';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { EXTRACT_TYPE, getLabelOrValue, getTagVariant } from '@/utils/common';
import { useQueryClient } from '@tanstack/react-query';

import ModalNameSection from '../ModalNameSection';

interface IEditModalProps {
  control: Control<ITagType>;
  errors: FieldErrors<ITagType>;
  setValue: UseFormSetValue<ITagType>;
  companyName?: string;
  ticketValue?: string | IDropdownOption;
  statusHaveForms: boolean;
  selectedCompany?: string;
  ticketData?: {
    ticketId?: string;
    ticketNumber?: number;
    department?: string;
    tagStatus?: CANDIDATE_STATUS;
    override?: boolean | null;
    _id?: string;
  };
  watchStatus?: string;
  setSelectedTag: React.Dispatch<React.SetStateAction<ITagType | null>>;
  dataList?: ICompaniesAndTicketsResponse[];
  loadingDataList: boolean;
  setCompanyPage: React.Dispatch<React.SetStateAction<number>>;
  moreCompaniesAvailable: boolean;
  isStatusHrOrientation?: boolean;
}

const EditModalTagSection: React.FC<IEditModalProps> = ({
  control,
  errors,
  setValue,
  companyName,
  ticketValue,
  statusHaveForms,
  selectedCompany,
  ticketData,
  watchStatus,
  setSelectedTag,
  dataList,
  loadingDataList,
  setCompanyPage,
  moreCompaniesAvailable,
  isStatusHrOrientation = false,
}) => {
  const { candidateScreen: staticText } = strings;

  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { editableCandidateData, openEditModal } = useCandidate();

  const [showMore, setShowMore] = useState(false);
  const [status, setStatus] = useState('');
  const [isTruncated, setIsTruncated] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isTagClicked, setIsTagClicked] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState('');

  const companyList = dataList?.map((item) => item.name);

  const tickets = dataList?.find(
    (item) => item.name === selectedCompany,
  )?.tickets;

  const ticketDetails = tickets?.map((ticket) => ({
    label: `TK#${ticket.ticketNumber} - ${(ticket.department as Department)?.name}`,
    value: ticket._id || '',
  }));

  const selectedTicket = dataList
    ?.flatMap((item) => item.tickets)
    .find((ticket) => ticket._id === (ticketValue as IDropdownOption)?.value);

  const statusList =
    statusTransitions(
      ticketData?.tagStatus || (status as CANDIDATE_STATUS),
      selectedTicket!,
      selectedTagId,
      ticketData?.override || selectedTicket?.override,
      user?.profileType,
    ) || [];

  const FIELDS_FOR_MODAL = useMemo(
    () =>
      getFieldsForModal({
        companyList,
        ticketDetails,
        isLoading: loadingDataList,
        companyName,
        ticketValue,
        statusList,
        isTicketScreen: !!ticketData?.ticketId,
        setCompanyPage,
        moreCompaniesAvailable,
      }),
    [companyName, ticketValue, companyList, ticketDetails, loadingDataList],
  );

  const handleTagClick = (item: ITagType) => {
    if (
      ((item && item?.ticketAssignee === user?._id) ||
        user?.profileType === USER_TYPE.ADMIN) &&
      !item?.isPendingApproval
    ) {
      setSelectedTag(item);
      setSelectedTagId(item._id!);
      setStatus(item.status!);
      const updateField = (field: 'companyName' | 'status') => {
        setValue(field, item[field]);
      };
      updateField('companyName');
      updateField('status');
      setValue('ticket', {
        value: getLabelOrValue(item.ticket, EXTRACT_TYPE.VALUE) || '',
        label: `TK#${item.ticketNumber} / ${item.ticketDepartment}`,
      });
      setIsTagClicked(true);
    }
  };

  const handleRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setIsTruncated(node.scrollHeight > node.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      handleRef(containerRef.current);
    }
  }, [handleRef]);

  useEffect(() => {
    if (companyName && ticketValue) {
      // Only run if the tag was not clicked recently
      if (!isTagClicked) {
        const filterData = editableCandidateData?.tags?.find(
          (item) =>
            item.companyName === companyName && item.ticket === ticketValue,
        );

        if (filterData) {
          setValue('status', filterData.status);
          setStatus(filterData.status!);
        } else {
          setValue('status', CANDIDATE_STATUS.ACTIVE);
          setStatus(CANDIDATE_STATUS.ACTIVE);
        }
      } else {
        setIsTagClicked(false);
      }
    }
  }, [companyName, ticketValue]);

  useEffect(() => {
    if (openEditModal || !dataList) {
      queryClient.invalidateQueries({
        queryKey: ['chatList'],
      });
    }
  }, [openEditModal, dataList]);

  useEffect(() => {
    if (ticketData) {
      setValue('ticket', {
        value: ticketData?.ticketId || '',
        label: `TK#${ticketData?.ticketNumber} / ${ticketData?.department}`,
      });
      if (ticketData?.tagStatus) {
        // Compare ticketData.status with the values of the enum
        const statusEntry = Object.entries(CANDIDATE_STATUS).find(
          ([, value]) =>
            value.toLowerCase() === (ticketData?.tagStatus || '').toLowerCase(),
        );
        if (statusEntry?.length) {
          setValue('status', statusEntry?.[1]);
          setStatus(statusEntry?.[1]);
        } else {
          setValue('status', CANDIDATE_STATUS.ACTIVE); // Default or fallback status
          setStatus(CANDIDATE_STATUS.ACTIVE);
        }
      }
    }
  }, [ticketData]);

  const getTagProperties = (item: ITagType) => {
    const isDisabled =
      (item?.ticketAssignee !== user?._id &&
        user?.profileType !== USER_TYPE.ADMIN) ||
      item?.isPendingApproval;
    let tooltipText = '';
    if (
      item?.ticketAssignee !== user?._id &&
      user?.profileType !== USER_TYPE.ADMIN
    ) {
      tooltipText = staticText.tooltipTextOne;
    } else if (item?.isPendingApproval) {
      tooltipText = staticText.tooltipTextTwo;
    }
    return { isDisabled, tooltipText };
  };

  return (
    <div className='p-1'>
      <ModalNameSection
        data={editableCandidateData}
        className={cn({ 'md:grid-cols-2 lg:grid-cols-3': statusHaveForms })}
      />
      {!ticketData ? (
        <div className='flex flex-wrap gap-1 md:gap-2 py-3 px-0.5 md:px-1'>
          <Typography className='block font-bold sm:text-[15px] w-full'>
            {staticText.tag}
          </Typography>
          <div className='border p-0.5 md:p-2 rounded-md w-full'>
            <div
              ref={handleRef}
              className={cn(
                'flex flex-wrap gap-y-2 overflow-y-hidden toolTipButton',
                {
                  'max-h-28': !showMore,
                },
              )}
            >
              {editableCandidateData?.tags?.length
                ? editableCandidateData?.tags?.map((item) => {
                    const { isDisabled, tooltipText } = getTagProperties(item);
                    return (
                      <TooltipComponent
                        key={item._id}
                        text={tooltipText}
                        align='center'
                        showTooltip={!!isDisabled}
                        className='capitalize rounded-lg max-w-52'
                        directionalArrow
                        trigger={
                          <Tags
                            text={`${getLabelOrValue(item?.companyName, EXTRACT_TYPE.LABEL)} /TK#${item.ticketNumber} /${!item?.isPendingApproval ? getLabelOrValue(item?.status, EXTRACT_TYPE.LABEL) : strings.common.pendingApproval}`}
                            variant={getTagVariant(
                              item?.isPendingApproval
                                ? strings.common.pendingApproval
                                : getLabelOrValue(
                                    item?.status,
                                    EXTRACT_TYPE.LABEL,
                                  ) || '',
                            )}
                            key={`${getLabelOrValue(item?.companyName, EXTRACT_TYPE.LABEL)}-${item.ticketNumber}-${item.status}-${Math.random()}`}
                            textClassName='text-[12px] md:text-sm'
                            containerClassName={cn(
                              'w-auto p-0.5 mx-1 md:mx-2 hover:scale-102 transition-transform duration-300 cursor-pointer',
                              {
                                'cursor-not-allowed hover:scale-100':
                                  isDisabled,
                              },
                            )}
                            handleClick={handleTagClick}
                            item={item}
                            disabled={isDisabled}
                          />
                        }
                      />
                    );
                  })
                : '-'}
            </div>
            {isTruncated ? (
              <div className='flex justify-end w-full'>
                <div
                  className='w-auto h-auto p-1 text-primary hover:underline'
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? staticText.showLess : staticText.showMore}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {FIELDS_FOR_MODAL.map((field) => (
        <Controller
          key={field.name}
          name={field.name as keyof ITagType}
          control={control}
          rules={{ required: field.error }}
          render={({ field: { onChange, value } }) => (
            <ReactDropdown
              name={field.name}
              title={field.title}
              options={field.options || []}
              isLoading={field.isLoading}
              value={value as string | IDropdownOption}
              isSearchable={field.isSearchable}
              onChange={onChange}
              errors={errors}
              isDisabled={field.isDisabled}
              tagsDropdown={field.tagsDropdown}
              menuPlacement={field.menuPlacement as MenuPlacement}
            />
          )}
        />
      ))}
      {watchStatus === CANDIDATE_STATUS.HR_ORIENTATION &&
      !isStatusHrOrientation ? (
        <Typography className='text-xs md:text-xs text-gray-700'>
          {staticText.HrOrientationNote}
        </Typography>
      ) : null}
    </div>
  );
};

export default EditModalTagSection;
