import { HiOutlineTrash } from 'react-icons/hi';

import { IUser } from '@/@types/auth';
import ProfileBadge from '@/components/ProfileBadge';
import { TagsCell } from '@/components/Table/CellComponents/TagsCell';
import TableHeading from '@/components/Table/TableHeading';
import { Typography } from '@/components/Typography';
import { strings } from '@/locales';
import {
  GetFormattedName,
  getVariantByRole,
  snakeToTitleCase,
} from '@/utils/common';
import { ColumnDef, Row } from '@tanstack/react-table';

const {
  usersScreen: { tableColumnsName },
} = strings;

const columnDefinitions = [
  {
    id: 'email',
    name: tableColumnsName.email,
  },
  {
    id: 'companyName',
    name: tableColumnsName.companyName,
  },
];

const textDisplayColumns = columnDefinitions.map((column) => ({
  id: column.id,
  header: () => <TableHeading text={column.name} />,
  cell: ({ row }: { row: Row<IUser> }) => (
    <Typography className='md:text-[15px]'>
      {(row.original[column.id as keyof IUser] as string) || '-'}
    </Typography>
  ),
}));

export const USERS_TABLE_COLUMNS = (
  setIdForDelete: React.Dispatch<React.SetStateAction<string>>,
  hasSubRole: boolean,
): ColumnDef<IUser>[] => [
  {
    id: 'name',
    header: () => <TableHeading text={tableColumnsName.name} />,
    cell: ({ row: { original } }) => (
      <ProfileBadge
        className='justify-start'
        nameClassName='font-medium'
        name={GetFormattedName<IUser>(original)}
        profilePicture={original.avatar as string}
      />
    ),
  },
  ...textDisplayColumns,
  {
    id: 'role',
    header: () => <TableHeading text={tableColumnsName.role} />,
    cell: ({ row: { original } }) => {
      const roleName = snakeToTitleCase(
        (original?.subRole || (original.profileType as string)).toLowerCase(),
      );
      return (
        <TagsCell
          tags={roleName || ''}
          variant={getVariantByRole(original?.subRole || original?.profileType)}
        />
      );
    },
  },
  ...(!hasSubRole
    ? [
        {
          id: 'delete',
          cell: ({ row: { original } }: { row: Row<IUser> }) => (
            <HiOutlineTrash
              className='text-redColor text-lg'
              onClick={(e) => {
                e.stopPropagation();
                setIdForDelete(original._id!);
              }}
            />
          ),
        },
      ]
    : []),
];
