import React from 'react';

import { IRecruiterInterviewEvaluationFormData } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import RatingReview from '@/components/RatingReview';
import { Typography } from '@/components/Typography';
import { getRecruiterInterviewEvaluationFormHistory } from '@/constants/candidate';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { fetchInterviewEvaluationFormDetail } from '@/services/candidate';
import { getLocalDate } from '@/utils/dateTime';

import FormConstantDataSection from './FormConstantDataSection';

interface IRecruiterInterviewEvaluationHistory {
  data: {
    mrfNumber?: string;
    job?: string;
    candidateId: string;
    ticketId: string;
  };
  isTicketScreen?: boolean;
}

const RecruiterInterviewEvaluationHistory: React.FC<
  IRecruiterInterviewEvaluationHistory
> = ({ data, isTicketScreen }) => {
  const { mrfNumber, job, candidateId, ticketId } = data;

  const { data: interviewEvaluationDetail, isLoading } = useGenericQuery(
    ['interview-evaluation-modal', candidateId, ticketId],
    () =>
      fetchInterviewEvaluationFormDetail({
        candidate: candidateId,
        ticket: ticketId,
      }),
  );

  const evaluationItems = getRecruiterInterviewEvaluationFormHistory(
    interviewEvaluationDetail as IRecruiterInterviewEvaluationFormData,
  );

  return (
    <div
      className={cn({
        'max-h-[70vh] overflow-y-auto scrollbarHidden': isTicketScreen,
      })}
    >
      {isTicketScreen ? (
        <FormConstantDataSection
          mrfNumber={mrfNumber || ''}
          job={job || ''}
          showEducation
        />
      ) : null}
      {interviewEvaluationDetail?.dateOfAssessment ? (
        <NameAndDesc
          title={
            strings.candidateScreen.recruiterInterviewEvaluationForm
              .dateOfAssessment
          }
          description={getLocalDate(
            interviewEvaluationDetail?.dateOfAssessment,
          )}
          className='space-y-0 flex gap-1'
        />
      ) : null}
      <div className='space-y-3'>
        {evaluationItems.map(
          ({ title, description, isRating, isHeading, className }, index) => {
            switch (true) {
              case isHeading:
                return (
                  <Typography
                    key={index}
                    className='capitalize mb-2 pt-3 block font-bold md:text-lg my-2 truncate'
                  >
                    {title}
                  </Typography>
                );

              case isRating:
                return (
                  <div key={index} className='flex items-center'>
                    <Typography className='block font-bold md:text-[15px] mr-3'>
                      {title}:
                    </Typography>
                    <RatingReview rating={description || 0} size='14px' />
                  </div>
                );

              default:
                return (
                  <NameAndDesc
                    key={index}
                    title={title}
                    description={description || ''}
                    className={cn('space-y-0 ', className)}
                    isLoading={isLoading}
                    avoidTruncate={true}
                  />
                );
            }
          },
        )}
      </div>
    </div>
  );
};

export default RecruiterInterviewEvaluationHistory;
