import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ILoginResponse, ISignInFields } from '@/@types/auth';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { ROUTES } from '@/routes';
import { login } from '@/services/auth';
import { EmailRegex } from '@/utils/common';
import { setTokenToLS, setUserDetailToLS } from '@/utils/localstorage';

import AuthHeadingsAndDesc from '../components/HeadingAndDesc';

const SignIn = () => {
  const staticText = strings.signIn;

  const navigate = useNavigate();
  const { setUser, setToken } = useAuth();

  const [cookies, setCookie] = useCookies(['email']);
  const [isRemember, setIsRemember] = useState(false);

  const toggleRemember = () => setIsRemember(!isRemember);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ISignInFields>({
    mode: 'all',
    defaultValues: {
      email: cookies.email || '',
      password: '',
    },
  });

  const loginMutation = useGenericMutation<
    ISignInFields,
    ILoginResponse | boolean
  >(login, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        if (isRemember) {
          setCookie('email', response.user.email, { path: '/' });
        }
        setUser(response?.user);
        setToken(response?.accessToken);
        toast.success(staticText.success);
        setUserDetailToLS(response?.user);
        setTokenToLS(response?.accessToken);
        navigate(ROUTES.DASHBOARD);
      }
    },
  });

  const handleSignIn: SubmitHandler<ISignInFields> = (formData) => {
    loginMutation.mutate(formData);
  };

  return (
    <>
      <AuthHeadingsAndDesc title={staticText.title} desc={staticText.desc} />
      <div className='space-y-4'>
        <Controller
          control={control}
          name='email'
          rules={{
            required: INPUT_ERRORS.EMAIL.required,
            pattern: {
              value: EmailRegex,
              message: INPUT_ERRORS.EMAIL.pattern,
            },
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.email}
              placeholder={staticText.emailPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              errors={isSubmitted ? errors : {}}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={{
            required: INPUT_ERRORS.PASSWORD.required,
          }}
          render={({ field: { onChange, value, name } }) => (
            <FormField
              title={staticText.password}
              placeholder={staticText.passwodPlaceholder}
              name={name}
              value={value}
              isRequired
              onChange={onChange}
              type='password'
              errors={errors}
              hideInfoIcon
            />
          )}
        />
        <div className='flex justify-between'>
          <div className='flex items-center gap-1'>
            <Checkbox onClick={toggleRemember} />
            <Typography className='text-oceanBlue'>
              {staticText.rememberMe}
            </Typography>
          </div>
          <a
            className='text-base text-primary cursor-pointer'
            href={ROUTES.FORGOT_PASSWORD}
          >
            {staticText.forgotPass}
          </a>
        </div>
        <Button
          className='text-base'
          onClick={handleSubmit(handleSignIn)}
          loading={loginMutation.status === 'pending'}
        >
          {staticText.btnText}
        </Button>
      </div>
    </>
  );
};

export default SignIn;
