import React from 'react';

import { ICandidate } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

interface IModalNameSection {
  data: ICandidate | null;
  className?: string;
}

const ModalNameSection: React.FC<IModalNameSection> = ({ data, className }) => {
  const staticText = strings.candidateScreen;

  return (
    <div
      className={cn('flex flex-wrap sm:grid sm:grid-cols-3 gap-4', className)}
    >
      <div>
        <Typography className='block font-bold mb-1'>
          {staticText.tableColumnsName.name}
        </Typography>
        <ProfileBadge
          nameClassName='md:text-[15px] font-semibold'
          name={`${data?.userDetails?.firstName} ${data?.userDetails?.lastName}`}
          profilePicture={data?.userDetails?.avatar as string}
          className='justify-start'
        />
      </div>
      <NameAndDesc
        title={staticText.tableColumnsName.positionDesired}
        description={data?.positionDesired?.[0]}
      />
      <NameAndDesc
        title={staticText.tableColumnsName.department}
        description={data?.department}
      />
    </div>
  );
};

export default ModalNameSection;
