import toast from 'react-hot-toast';
import { saveAs } from 'file-saver';

import { IPaginationResponse } from '@/@types';
import {
  FetchCandidateParams,
  FetchCandidateResponse,
  FetchCandidatFormsHistoryParams,
  IAddCandidateResponse,
  ICandidate,
  ICompaniesAndTicketsResponse,
  IEndorsementFormDataResponse,
  IPersonnelActionFormData,
  IRecruiterInterviewEvaluationFormData,
  ITagsPayload,
  UpdatedNotes,
} from '@/@types/candidate';
import { API } from '@/api/ApiInstance';
import { COMMON_ERROR } from '@/constants';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';

export const fetchCandidates = async (
  params: FetchCandidateParams,
): Promise<FetchCandidateResponse | boolean> => {
  const queryString = buildQueryParams({
    ...params,
  });

  const response = await API.Get<FetchCandidateResponse>(
    `${REST_SUB_URL.CANDIDATES}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchCandidateDetail = async (
  id: string,
): Promise<ICandidate | boolean> => {
  const response = await API.Get<ICandidate>(
    `${REST_SUB_URL.CANDIDATES}/${id}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const updateNotesDetail = async (
  id: string,
  payload: UpdatedNotes,
): Promise<UpdatedNotes | boolean> => {
  const response = await API.Patch<UpdatedNotes, UpdatedNotes>(
    `${REST_SUB_URL.UPDATE_NOTES}/${id}`,
    payload,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const deleteCandidates = async (payload: {
  ids: string[];
}): Promise<boolean> => {
  const response = await API.Delete<{ ids: string[] }, string>(
    REST_SUB_URL.CANDIDATES,
    payload,
  );
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};

// export const removeFromShortlistedCandidate = async (payload: {
//   ids: string[];
// }): Promise<boolean> => {
//   const response = await API.Post<{ ids: string[] }, boolean>(
//     REST_SUB_URL.REMOVE_FROM_SHORTLISTED_CANDIDATE,
//     payload,
//   );
//   if (response.status) {
//     return true;
//   }
//   toast.error(response.message);
//   return false;
// };

// export const moveToShortlistedCandidate = async (payload: {
//   ids: string[];
// }): Promise<boolean> => {
//   const response = await API.Post<{ ids: string[] }, boolean>(
//     REST_SUB_URL.MOVE_TO_SHORTLISTEDED_CANDIDATE,
//     payload,
//   );
//   if (response.status) {
//     return true;
//   }
//   toast.error(response.message);
//   return false;
// };

export const downloadCandidateResume = async (
  ids: string[],
): Promise<boolean> => {
  const param = ids.join(',');

  const response = await API.Get<Blob>(
    `${REST_SUB_URL.DOWNLOAD_CANDIDATE_RESUMES}?ids=${param}`,
    {
      responseType: 'blob',
    },
  );

  if (response.status && response.data) {
    const blob = new Blob([response?.data || ''], {
      type: 'application/zip',
    });
    saveAs(blob, 'resumes.zip');
    return true;
  }
  throw new Error(response.message);
};

export const addCandidateList = async ({
  payload,
  setPercent,
}: {
  payload: File;
  setPercent?: (percent: number) => void;
}): Promise<string | boolean> => {
  const formData = new FormData();
  formData.append('candidate', payload);

  const response = await API.Post<FormData, IAddCandidateResponse>(
    REST_SUB_URL.CANDIDATE_BULK_INSERTION,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setPercent?.(percentage);
        }
      },
    },
  );

  if (response.status) {
    return response?.message;
  }
  toast.error(response.message);
  return false;
};

export const updateTagsDetail = async (
  id: string,
  payload: ITagsPayload,
): Promise<ITagsPayload | boolean> => {
  const response = await API.Patch<ITagsPayload, ITagsPayload>(
    `${REST_SUB_URL.UPDATE_CANDIDATE_TAGS}/${id}`,
    payload,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchCompanyAndTickets = async (
  page: number,
): Promise<IPaginationResponse<ICompaniesAndTicketsResponse>> => {
  try {
    const response = await API.Get<
      IPaginationResponse<ICompaniesAndTicketsResponse>
    >(`${REST_SUB_URL.GET_TICKET_WITH_COMPANIES}?page=${page}`);

    if (response.status && response.data?.results) {
      return response?.data;
    }
    throw new Error(response.message);
  } catch (error) {
    throw new Error(error instanceof Error ? error.message : COMMON_ERROR);
  }
};

export const submitPersonnelActionForm = async (
  payload: IPersonnelActionFormData,
): Promise<boolean> => {
  const response = await API.Post<IPersonnelActionFormData, boolean>(
    REST_SUB_URL.PAN_FORM,
    payload,
  );
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const submitRecruiterInterviewEvaluationForm = async (
  payload: IRecruiterInterviewEvaluationFormData,
): Promise<boolean> => {
  const response = await API.Post<
    IRecruiterInterviewEvaluationFormData,
    boolean
  >(REST_SUB_URL.IEF_FORM, payload);
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};
export const fetchEndorsementDetail = async (
  params: FetchCandidatFormsHistoryParams,
): Promise<IEndorsementFormDataResponse | null> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IEndorsementFormDataResponse>(
    `${REST_SUB_URL.CANDIDATES_ENDORSEMENTS_DETAIL}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return null;
};
export const fetchPanFormDetail = async (
  params: FetchCandidatFormsHistoryParams,
): Promise<IPersonnelActionFormData | null> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IPersonnelActionFormData>(
    `${REST_SUB_URL.CANDIDATES_PAN_FORM_DETAIL}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return null;
};

export const fetchInterviewEvaluationFormDetail = async (
  params: FetchCandidatFormsHistoryParams,
): Promise<IRecruiterInterviewEvaluationFormData | null> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IRecruiterInterviewEvaluationFormData>(
    `${REST_SUB_URL.CANDIDATES_IAF_DETAIL}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return null;
};
