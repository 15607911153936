/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoMdCloseCircle } from 'react-icons/io';
import { PiWarning } from 'react-icons/pi';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { FilterOptions, IDropdownOption, IPaginationResponse } from '@/@types';
import { IRequest, UpdateRequestStatusPayload } from '@/@types/request';
import { ClientData } from '@/@types/tickets';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import HeaderWithSearchAndFilter from '@/components/HeaderWithSearchAndFilter';
import Modal from '@/components/Modal';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { COMMON_SUCCESS } from '@/constants';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import { getRequestFilterModalFields, REQUEST_TABS } from '@/constants/request';
import {
  REQUEST_TABLE_ACTION,
  TICKET_CANDIDATE_TABLE_ACTION,
  TICKET_STATUS,
} from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useCandidate } from '@/provider/CandidateProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { usePagination } from '@/provider/PaginationProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import { fetchRequests, updateRequestStatus } from '@/services/request';
import {
  createInitialFilterState,
  GetFormattedName,
  hasValidFilters,
} from '@/utils/common';
import { Table } from '@tanstack/react-table';

import AppliedFilterSection from '../Candidate/components/AppliedFilterSection';
import EndorsementHistorySection from '../Candidate/components/EditModal/EndorsementHistorySection';
import PersonnelActionHistory from '../Candidate/components/EditModal/PersonnelActionFormHistorySection';
import RecruiterInterviewEvaluationHistory from '../Candidate/components/EditModal/RecruiterInterviewEvaluationHistory';
import FilterModal from '../Candidate/components/FilterModal';
import InterviewEvaluationModal from '../TicketDetails/components/InterviewEvaluationModal';

import { REQUEST_TABLE_COLUMNS } from './components/table/columns';

type RequestScreenProp = {
  ticketId?: string;
  ticketStatus?: TICKET_STATUS;
};

const RequestScreen: React.FC<RequestScreenProp> = ({
  ticketId,
  ticketStatus,
}) => {
  const staticText = strings.requestScreen;
  const { tab } = useParams<{ tab: REQUEST_TABS }>();

  const navigate = useNavigate();
  const { setEditableCandidateData } = useCandidate();
  const {
    requestFilters,
    setRequestFilters,
    requestSearchText,
    setRequestSearchText,
  } = useFilters();

  const { setFromRequestScreen } = useSidebarContext();

  const {
    pageSize,
    setPageSize,
    pageCount,
    setPageCount,
    pageIndex,
    setPageIndex,
    resetPagination,
  } = usePagination();
  const {
    department,
    haveMoreDepartmentPage,
    setDepartmentPage,
    loadingDepartments,
    setDepartmentSearch,
    loadingRecruiters,
    recruiters,
    haveMoreRecruitersPage,
    setRecruiterPage,
    setRecruitersSearch,
  } = useCommonDataContext();

  const [request, setRequest] = useState<IRequest | null>(null);
  const [nestedPageSize, setNestedPageSize] = useState(5);
  const [nestedPageIndex, setNestedPageIndex] = useState(0);
  const [nestedPageCount, setNestedPageCount] = useState(-1);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [tableState, setTableState] = useState<Table<IRequest> | null>(null);
  const [action, setAction] = useState<
    | TICKET_CANDIDATE_TABLE_ACTION
    | REQUEST_TABLE_ACTION
    | CANDIDATE_STATUS
    | null
  >(null);
  const [activeTab, setActiveTab] = useState<REQUEST_TABS>(
    tab || REQUEST_TABS.ENDORSED,
  );

  const tags = useMemo(() => {
    return Object.entries(requestFilters)
      .filter(([, value]) => value)
      .map(([key, value]) => {
        return {
          key,
          value:
            typeof value === 'object'
              ? ((value as IDropdownOption).label ?? '')
              : value || '',
        };
      });
  }, [requestFilters]);

  const REQUEST_FILTER_MODAL_FIELDS = getRequestFilterModalFields(
    activeTab,
    department,
    loadingDepartments,
    haveMoreDepartmentPage,
    setDepartmentPage,
    setDepartmentSearch,
    recruiters,
    !!loadingRecruiters,
    haveMoreRecruitersPage,
    setRecruiterPage,
    setRecruitersSearch,
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isSubmitted },
  } = useForm<FilterOptions>({
    mode: 'all',
    defaultValues:
      Object.keys(requestFilters)?.length > 0
        ? requestFilters
        : createInitialFilterState(REQUEST_FILTER_MODAL_FIELDS),
  });

  const handleResetFilter = () => {
    reset({
      ...createInitialFilterState(REQUEST_FILTER_MODAL_FIELDS),
    });
    setRequestFilters({});
    refetch();
  };

  const handleToggleFilterModal = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  const type =
    activeTab === REQUEST_TABS.ENDORSED
      ? REQUEST_TABS.ENDORSED
      : REQUEST_TABS.CLOSURE;

  const updatedRequestFilters = {
    ...requestFilters,
    recruiter: (requestFilters?.recruiter as IDropdownOption)?.value,
  };

  const { data, isLoading, refetch } = useGenericQuery(
    [
      'requests',
      requestSearchText,
      type,
      ...(ticketId ? [nestedPageSize, nestedPageIndex] : [pageSize, pageIndex]),
      ...Object.values(requestFilters),
    ],
    () =>
      fetchRequests({
        ...(ticketId
          ? { pageSize: nestedPageSize, page: nestedPageIndex + 1 }
          : { pageSize, page: pageIndex + 1 }),
        search: requestSearchText,
        type,
        ...updatedRequestFilters,
      }),
  );

  const handleRowClick = (data: IRequest) => {
    if (location.pathname.includes(ROUTES.TICKETS)) {
      const path = generatePath(`${location.pathname}/${data._id}`);
      navigate(path, {
        replace: true,
      });
    } else {
      const path = generatePath(ROUTES.REQUEST_DETAIL, {
        tab: activeTab,
        requestId: data._id,
      });
      navigate(path, {
        replace: true,
      });
    }
  };

  const handleSubmitFilter: SubmitHandler<FilterOptions> = (data) => {
    setRequestFilters(data);
    setFilterModalOpen(false);
  };

  const handleTabChange = (newTab: REQUEST_TABS) => {
    setActiveTab(newTab);

    if (location.pathname.includes('request')) {
      const path = generatePath(ROUTES.REQUEST, { tab: newTab });
      navigate(path);
    }
    tableState?.resetRowSelection();
    if (ticketId) {
      setPageSize(5);
      setPageIndex(0);
      setPageCount(-1);
    } else resetPagination();
  };

  const updateStatusMutation = useGenericMutation<
    UpdateRequestStatusPayload,
    UpdateRequestStatusPayload | boolean
  >(updateRequestStatus, {
    onSuccess: (response) => {
      if (response) {
        refetch();
        tableState?.resetRowSelection();
        toast.success(COMMON_SUCCESS);
        setAction(null);
      }
    },
  });

  const handleHireOrReject = () => {
    const payload = {
      ids: [request!._id],
      status: action as string,
    };
    updateStatusMutation.mutate(payload);
  };

  const tableData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    if (ticketId && typeof data === 'object') {
      return data?.results.filter(
        (item) => item.ticket?._id === ticketId,
      ) as IRequest[];
    }
    return (data as IPaginationResponse<IRequest>)?.results;
  }, [isLoading, ticketId, data]);

  const handleRemoveTag = (keyToRemove: string) => {
    setRequestFilters({ ...requestFilters, [keyToRemove]: '' });
    reset({ ...requestFilters, [keyToRemove]: '' });
  };

  useEffect(() => {
    if (!location.pathname.includes(ROUTES.TICKETS)) {
      if (tab && Object.values(REQUEST_TABS).includes(tab)) {
        setActiveTab(tab);
      } else {
        const defaultTab = REQUEST_TABS.ENDORSED;
        setActiveTab(defaultTab);
        navigate(generatePath(ROUTES.REQUEST, { tab: defaultTab }), {
          replace: true,
        });
      }
    }
  }, [tab, navigate]);

  useEffect(() => {
    if (typeof data === 'object') {
      ticketId
        ? setNestedPageCount(data?.totalPages)
        : setPageCount(data?.totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (hasValidFilters(requestFilters) || requestSearchText.length) {
      setPageIndex(0);
    }
  }, [requestSearchText, requestFilters]);

  return (
    <>
      <Container className='flex flex-col h-[calc(100vh-180px)]'>
        <HeaderWithSearchAndFilter
          searchText={requestSearchText}
          setSearchText={setRequestSearchText}
          toggleFilterModal={handleToggleFilterModal}
          haveFilterData={
            (isDirty && isSubmitted) || hasValidFilters(requestFilters)
          }
          title={staticText.title}
          placeholder={staticText.headerSearchPlaceholder}
        />
        {hasValidFilters(requestFilters) ? (
          <AppliedFilterSection
            tags={tags}
            className='mb-3'
            handleRemoveTag={handleRemoveTag}
          />
        ) : null}
        <Tabs
          value={activeTab}
          onValueChange={(value) => {
            handleTabChange(value as REQUEST_TABS);
          }}
          className='flex flex-col flex-grow mt-5'
        >
          <TabsList className='p-0 h-auto gap-2 bg-transparent w-full flex justify-between'>
            <div className='flex gap-2'>
              <TabsTrigger value={REQUEST_TABS.ENDORSED} className='capitalize'>
                {REQUEST_TABS.ENDORSED}
              </TabsTrigger>
              <TabsTrigger value={REQUEST_TABS.CLOSURE} className='capitalize'>
                {REQUEST_TABS.CLOSURE}
              </TabsTrigger>
            </div>
          </TabsList>
          <div className='flex-1 min-h-0 relative'>
            <div className='absolute inset-0 overflow-auto scrollbarHidden'>
              <TabsContent value={activeTab} className='h-full'>
                <DataTable
                  columns={REQUEST_TABLE_COLUMNS(
                    activeTab === REQUEST_TABS.CLOSURE,
                    setRequest,
                    setAction,
                    setEditableCandidateData,
                    ticketStatus,
                    setFromRequestScreen,
                  )}
                  data={tableData}
                  cellClassName='h-14 py-0'
                  setTablesState={setTableState}
                  handleRowClick={handleRowClick}
                  pageCount={ticketId ? nestedPageCount : pageCount}
                  loading={isLoading}
                />
              </TabsContent>
            </div>
          </div>
        </Tabs>
      </Container>
      {isLoading ? null : (
        <div className='flex justify-between items-center mt-5'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={ticketId ? nestedPageSize : pageSize}
            setPageSize={ticketId ? setNestedPageSize : setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={ticketId ? nestedPageIndex : pageIndex}
            setPageIndex={ticketId ? setNestedPageIndex : setPageIndex}
          />
        </div>
      )}
      {filterModalOpen ? (
        <FilterModal
          isOpen={filterModalOpen}
          onClose={handleToggleFilterModal}
          handleApplyFilter={handleSubmit(handleSubmitFilter)}
          control={control}
          handleResetFilter={handleResetFilter}
          buttonDisabled={Object.values(watch()).every((value) => !value)}
          fields={REQUEST_FILTER_MODAL_FIELDS}
          searchText={requestSearchText}
          handleClearSearch={() => setRequestSearchText('')}
        />
      ) : null}
      <Modal
        open={[
          TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY,
          TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY,
          TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY,
        ].includes(action as TICKET_CANDIDATE_TABLE_ACTION)}
        handleCloseModal={() => setAction(null)}
        title={action?.split(' ').slice(1).join(' ')}
      >
        <>
          {action ===
          TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY ? (
            <RecruiterInterviewEvaluationHistory
              isTicketScreen
              data={{
                mrfNumber: request?.ticket?.ticketNumber || '',
                job: request?.ticket?.jobTitle || '',
                candidateId: request?.candidate?._id || '',
                ticketId: request?.ticket?._id || '',
              }}
            />
          ) : null}
          {action === TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY ? (
            <EndorsementHistorySection
              isTicketScreen
              candidateId={request?.candidate?._id}
              ticketId={request?.ticket?._id}
              mrfNumber={request?.ticket?.ticketNumber}
              job={request?.ticket?.jobTitle}
            />
          ) : null}
          {action ===
          TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY ? (
            <PersonnelActionHistory
              candidateId={request?.candidate?._id || ''}
              ticketId={request?.ticket?._id || ''}
              mrfNumber={String(request?.ticket?.ticketNumber) || ''}
              job={request?.ticket?.jobTitle || ''}
              info={{
                ticketRate: request?.ticket?.rate || 0,
                typeOfEmployment: request?.ticket?.typeOfEmployment || '',
                author: GetFormattedName(
                  (request?.ticket?.client as ClientData)?.companyClientId
                    ? (request?.ticket?.client as ClientData)
                    : request?.ticket?.author,
                ),
              }}
            />
          ) : null}
        </>
      </Modal>
      {action === REQUEST_TABLE_ACTION.CLIENTS_IAF_SUBMISSION ||
      action === REQUEST_TABLE_ACTION.CLIENTS_IAF_HISTORY ||
      action ===
        TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY ? (
        <InterviewEvaluationModal
          open={
            action === REQUEST_TABLE_ACTION.CLIENTS_IAF_SUBMISSION ||
            action === REQUEST_TABLE_ACTION.CLIENTS_IAF_HISTORY ||
            action ===
              TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY
          }
          handleCloseModal={() => setAction(null)}
          ticket={request?.ticket}
          candidate={request?.candidate}
          showHistory={!!request?.isCompanyClientIAF}
        />
      ) : null}
      {action === CANDIDATE_STATUS.PRE_DEPLOY ||
      action === CANDIDATE_STATUS.REJECTED ? (
        <ConfirmationModal
          open={[
            CANDIDATE_STATUS.PRE_DEPLOY,
            CANDIDATE_STATUS.REJECTED,
            TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY,
          ].includes(action as CANDIDATE_STATUS)}
          handleCloseModal={() => setAction(null)}
          primaryBtnLabel={
            action === CANDIDATE_STATUS.PRE_DEPLOY
              ? staticText.approve
              : strings.common.reject
          }
          primaryBtnAction={handleHireOrReject}
          icon={
            action === CANDIDATE_STATUS.PRE_DEPLOY ? (
              <PiWarning className='size-6 text-primary' />
            ) : (
              <IoMdCloseCircle className='size-6 text-tomatoRed' />
            )
          }
          textDesc={
            action === CANDIDATE_STATUS.PRE_DEPLOY
              ? staticText.approveDesc
              : staticText.rejectDesc
          }
          containerClassName='py-5'
          isLoading={updateStatusMutation.status === 'pending'}
        />
      ) : null}
    </>
  );
};

export default RequestScreen;
