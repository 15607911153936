import * as React from 'react';

import { cn } from 'src/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-10 w-full rounded-md border border-greyWhite bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-quickSilver focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-[#666666] dark:focus-visible:ring-slate-300',
          { 'bg-[#E4E6E9] border-quickSilver/50': props.disabled },
          className,
        )}
        ref={ref}
        onFocus={(e) =>
          e.target.addEventListener(
            'wheel',
            (e) => {
              e.preventDefault();
            },
            { passive: false },
          )
        }
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';

export { Input };
