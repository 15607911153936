/* eslint-disable max-lines */
import React from 'react';
import { FieldErrors } from 'react-hook-form';
import {
  components,
  GroupBase,
  MenuPlacement,
  OptionProps as ReactSelectOptionProps,
  OptionsOrGroups,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import Creatable from 'react-select/creatable';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { IDropdownOption } from '@/@types';
import { cn } from '@/lib/utils';
import { getTagVariant } from '@/utils/common';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { customStyles } from './customStyle';
import ProfileBadge from './ProfileBadge';
import Tags from './Tags';
import { Typography } from './Typography';

export interface DropdownProps {
  title?: string;
  options: IDropdownOption[] | string[];
  defaultValue?: string | IDropdownOption;
  value?: IDropdownOption | null | string;
  placeholder?: string;
  isLoading?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelClassName?: string;
  creatable?: boolean;
  onChange?: (selectedOption: string | IDropdownOption) => void;
  className?: string;
  name?: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  haveMoreOptions?: boolean;
  inputHeight?: string;
  inputWidth?: string;
  valueHide?: boolean;
  tagsDropdown?: boolean;
  errors?: FieldErrors;
  borderRadius?: string;
  icon?: React.ReactNode;
  setInputSearch?: React.Dispatch<React.SetStateAction<string>>;
  saveBothLabelAndValue?: boolean;
  menuPlacement?: MenuPlacement;
}

const ReactDropdown: React.FC<DropdownProps> = ({
  title,
  defaultValue,
  options,
  value: propValue = null,
  placeholder,
  isSearchable = false,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  labelClassName,
  creatable = false,
  name,
  className,
  onChange,
  haveMoreOptions = false,
  setPage,
  inputHeight = '48px',
  inputWidth = '100%',
  valueHide = false,
  tagsDropdown = false,
  borderRadius = '6px',
  errors,
  setInputSearch,
  saveBothLabelAndValue = false,
  menuPlacement = 'auto',
}) => {
  // if string array in options then convert it to value label object
  const normalizedOptions: IDropdownOption[] = Array.isArray(options)
    ? options.map((opt) =>
        typeof opt === 'string' ? { label: opt, value: opt } : opt,
      )
    : [];
  // for showing placeholder if value is empty string
  const convertedValue =
    typeof propValue === 'string'
      ? (normalizedOptions.find((option) => option.value === propValue) ?? null)
      : propValue;

  const customStylesWithHeight: StylesConfig<
    string | IDropdownOption,
    boolean,
    GroupBase<string | IDropdownOption>
  > = {
    ...customStyles,
    control: (provided) => ({
      ...provided,
      minHeight: inputHeight,
      maxHeight: inputHeight,
      minWidth: inputWidth,
      borderRadius: borderRadius,
    }),
  };

  const getSelectValue = () => {
    switch (true) {
      case valueHide:
        return '';
      case !convertedValue?.value:
        return null;
      default:
        return convertedValue;
    }
  };

  const CustomOption: React.FC<
    ReactSelectOptionProps<IDropdownOption | string>
  > = (props) => {
    const renderOptionContent = () => {
      if (tagsDropdown) {
        return (
          <Tags
            text={typeof props === 'object' ? props.label : props}
            variant={getTagVariant(props.label)}
            containerClassName='py-1 text-xs h-auto min-w-20 text-sm'
          />
        );
      }
      if (
        typeof options[0] === 'object' &&
        'avatar' in (options[0] as IDropdownOption)
      ) {
        return (
          <ProfileBadge
            name={props.label}
            className='justify-start'
            avatarClassName='size-7 text-[10px]'
            profilePicture={(props.data as IDropdownOption)?.avatar}
          />
        );
      }
      return props.label;
    };
    return (
      <div>
        <components.Option {...props}>
          {renderOptionContent()}
        </components.Option>
      </div>
    );
  };

  const SingleValue = ({
    children,
    ...props
  }: SingleValueProps<IDropdownOption | string>) => {
    let content;
    if (tagsDropdown) {
      content = (
        <Tags
          text={convertedValue?.label}
          variant={getTagVariant(convertedValue?.label)}
          containerClassName='py-1 text-xs h-auto min-w-20 text-sm mx-5'
        />
      );
    } else if ('avatar' in (convertedValue as IDropdownOption)) {
      content = (
        <ProfileBadge
          name={convertedValue?.label || ''}
          className='justify-start'
          avatarClassName='size-7 text-[10px]'
          profilePicture={convertedValue?.avatar}
        />
      );
    } else {
      content = children;
    }
    return (
      <components.SingleValue {...props}>{content}</components.SingleValue>
    );
  };

  const onChangeValue = (selected: IDropdownOption) => {
    if (onChange) {
      onChange(saveBothLabelAndValue ? selected : selected?.value);
    }
  };

  const loadMore = () => {
    if (haveMoreOptions) {
      setPage?.((prev) => prev + 1);
    }
  };

  const handleInputChange = (input: string) => {
    setInputSearch?.(input);
  };

  const loadOptions: LoadOptions<
    string | IDropdownOption,
    GroupBase<string | IDropdownOption>,
    unknown
  > = (
    _search: string,
    prevOptions: OptionsOrGroups<
      string | IDropdownOption,
      GroupBase<string | IDropdownOption>
    >,
  ) => {
    const hasMore = normalizedOptions.length > prevOptions.length + 10;
    const filteredOptions = normalizedOptions
      .slice(prevOptions.length, prevOptions.length + 10)
      .map((option) =>
        typeof option === 'string'
          ? { label: option, value: option }
          : {
              label: option.label,
              value: option.value,
              ...(option.avatar ? { avatar: option.avatar } : {}),
            },
      );

    return {
      options: filteredOptions as OptionsOrGroups<
        string | IDropdownOption,
        GroupBase<string | IDropdownOption>
      >,
      hasMore,
    };
  };

  const handleCreate = (inputValue: string) => {
    const newOption: IDropdownOption = { label: inputValue, value: inputValue };
    onChange?.(newOption);
  };

  const commonProps = {
    classNamePrefix: 'select',
    styles: customStylesWithHeight,
    placeholder,
    value: getSelectValue(),
    isDisabled,
    isLoading,
    isSearchable,
    menuPlacement,
    maxMenuHeight: 150,
    onChange: onChangeValue as never,
    onMenuScrollToBottom: loadMore,
    components: {
      SingleValue,
      Option: CustomOption,
      IndicatorSeparator: () => null,
    },
    onInputChange: handleInputChange,
    autoFocus: false,
    controlShouldRenderValue: true,
  };
  return (
    <div>
      {title ? (
        <Typography
          className={cn(
            'flex md:text-sm capitalize font-semibold my-2',
            labelClassName,
          )}
        >
          {title}
          {isRequired && <span className='text-redColor text-xl ml-1'>*</span>}
        </Typography>
      ) : null}
      {creatable ? (
        <Creatable
          key={convertedValue?.value}
          options={normalizedOptions}
          onCreateOption={handleCreate}
          {...commonProps}
        />
      ) : (
        <AsyncPaginate
          key={convertedValue?.value}
          className={className}
          cacheUniqs={[JSON.stringify(normalizedOptions)]}
          defaultValue={
            typeof defaultValue === 'string'
              ? { label: defaultValue, value: defaultValue }
              : defaultValue
          }
          loadOptions={loadOptions}
          defaultOptions={true}
          {...commonProps}
        />
      )}
      {errors && (
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      )}
    </div>
  );
};

export default ReactDropdown;
