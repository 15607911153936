export const ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  SET_NEW_PASSWORD: '/set-new-password',
  DASHBOARD: '/dashboard',
  PROFILE: '/profile',
  CHAT: '/chat',
  USERS: '/users',
  CREATE_USER: '/users/create',
  USER_DETAIL: '/users/:id',
  CANDIDATE: '/candidate',
  CANDIDATE_DETAIL: '/candidate/:id',
  //   CANDIDATE_WITH_TABS: '/candidate-list/:tab',
  // CANDIDATE_DETAIL_WITH_TABS: '/candidate-list/:tab/:id',
  CREATE_TICKET: '/create-ticket',
  TICKETS: '/tickets',
  TICKET_DETAIL: '/tickets/:ticketId',
  TICKETS_WITH_TAB: '/tickets-list/:tab',
  TICKET_DETAIL_WITH_TAB: '/tickets-list/:tab/:ticketId/:innerTab',
  REQUEST: '/request/:tab',
  REQUEST_DETAIL: '/request/:tab/:requestId',
  REQUEST_DETAIL_WITH_TICKET_ID: '/tickets/:ticketId/:requestId',
  NOTIFICATIONS: '/notifications',
  APPROVALS: '/approvals/:tab',
};

export const PUBLIC_ROUTES = {
  LOGIN: '/login',
  SIGNUP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  SET_NEW_PASSWORD: '/set-new-password',
};
