/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { HiDownload } from 'react-icons/hi';

import { ISelectorDrawerFormValues } from '@/@types/dashboard';
import CircularLoader from '@/components/ClipLoader';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { SelectorDrawerFormInitialValues } from '@/constants/dashboard';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
// import { useSidebarContext } from '@/provider/SidebarProvider';
import {
  fetchDashboardSettings,
  updateDdashboardSettings,
} from '@/services/dashboard';

import LayoutSelectorDrawer from './components/LayoutSelectorDrawer';
import ManpowerRequestFormSection from './components/ManpowerRequestFormSection';
import SourceSection from './components/SourceSection';
import StatisticsChart from './components/StatisticsChart';
import StatsCard from './components/StatsCard';

const Dashboard = () => {
  const staticText = strings.dashboard;
  // const { sidebarExpand } = useSidebarContext();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const {
    data: dashboardSettings,
    isLoading: dashboardSettingLoading,
    refetch,
  } = useGenericQuery(['fetchDashboardSettings'], fetchDashboardSettings);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<ISelectorDrawerFormValues>({
    defaultValues: SelectorDrawerFormInitialValues,
  });

  const updateSettingsMutation = useGenericMutation<
    ISelectorDrawerFormValues,
    ISelectorDrawerFormValues | boolean
  >(updateDdashboardSettings, {
    onSuccess: (response) => {
      if (response) {
        toast.success(staticText.settingUpdated);
        refetch();
        reset(response as ISelectorDrawerFormValues);
        handleOpenDrawer();
      }
    },
  });

  const onSubmit = (data: ISelectorDrawerFormValues) => {
    updateSettingsMutation.mutate(data);
  };

  const handleOpenDrawer = useCallback(() => {
    if (openDrawer) {
      setIsClosing(true);
      setTimeout(() => {
        setOpenDrawer(false);
        setIsClosing(false);
      }, 1000);
    } else {
      setOpenDrawer(true);
    }
  }, [openDrawer]);

  // const toogleAllStatisticsSelect = () => {
  //   const currentValues = watch('statistics');
  //   const areAllSelected = Object.values(currentValues).every((value) => value);
  //   // For selecting all statistics at once
  //   Object.keys(currentValues).forEach((key) => {
  //     setValue(
  //       `statistics.${key}` as `statistics.${keyof ISelectorDrawerFormValues['statistics']}`,
  //       !areAllSelected,
  //       {
  //         shouldDirty: true,
  //       },
  //     );
  //   });
  // };

  useEffect(() => {
    if (dashboardSettings) {
      Object.entries(dashboardSettings).forEach(([key, value]) => {
        setValue(key as keyof ISelectorDrawerFormValues, value);
      });
    }
  }, [dashboardSettings, setValue]);

  return (
    <div className='min-h-full flex flex-col'>
      <div className='flex items-center justify-between'>
        <Typography className='text-xl md:text-[22px] font-bold'>
          {staticText.title}
        </Typography>
        <Button
          className='w-auto'
          variant='outline'
          iconPosition='left'
          icon={<HiDownload size={20} className='rotate-90' />}
          onClick={handleOpenDrawer}
        >
          {staticText.cards}
        </Button>
      </div>
      <div className='space-y-4'>
        <StatsCard />
        {dashboardSettingLoading ? (
          <CircularLoader className='h-40' />
        ) : (
          <div className='lg:flex gap-3'>
            {/* Left Section */}
            <div
              className={cn(
                ' flex flex-col gap-4 xl:w-3/5 xxl:flex-row xxl:w-11/12',
                {
                  '2xl:w-full 2xl:flex-row xxl:w-full':
                    !dashboardSettings?.manpowerFormVisibility,
                  'xxl:w-fit w-full':
                    !dashboardSettings?.statistics ||
                    !dashboardSettings?.listOfLocalRecruitmentActivities,
                  'hidden':
                    !dashboardSettings?.statistics &&
                    !dashboardSettings?.listOfLocalRecruitmentActivities,
                },
              )}
            >
              {dashboardSettings?.listOfLocalRecruitmentActivities ? (
                <div
                  className={cn('xxl:w-1/2', {
                    '2xl:w-1/2': !dashboardSettings?.manpowerFormVisibility,
                    'xxl:w-fit':
                      !dashboardSettings?.statistics ||
                      !dashboardSettings.listOfLocalRecruitmentActivities,
                  })}
                >
                  <SourceSection />
                </div>
              ) : null}
              {dashboardSettings?.statistics ? (
                // {Object.values(dashboardSettings?.statistics || {}).some(
                //   (value) => value,
                // ) ? (
                <div
                  className={cn('xxl:w-1/2', {
                    '2xl:w-1/2': !dashboardSettings?.manpowerFormVisibility,
                    'xxl:w-fit':
                      !dashboardSettings?.statistics ||
                      !dashboardSettings.listOfLocalRecruitmentActivities,
                  })}
                >
                  <StatisticsChart
                  // statisticsDataKeys={
                  //   Object.fromEntries(
                  //     // Convert statistics object to an array of key-value pairs and filter for values that are true
                  //     Object.entries(
                  //       dashboardSettings?.statistics || {},
                  //     ).filter(([, value]) => value),
                  //   ) as IStatistics
                  // }
                  />
                </div>
              ) : null}
            </div>
            {/* right Section */}
            {dashboardSettings?.manpowerFormVisibility ? (
              <div
                className={cn('mt-4 lg:mt-0 lg:w-2/5 xxl:w-1/2', {
                  'xxl:w-1/3 ':
                    !dashboardSettings?.statistics ||
                    !dashboardSettings?.listOfLocalRecruitmentActivities,
                  'lg:w-full xl:w-2/5 h-full':
                    !dashboardSettings?.statistics &&
                    !dashboardSettings?.listOfLocalRecruitmentActivities,
                })}
              >
                <div
                  className={cn(
                    'relative lg:min-h-96 h-[500px] lg:h-[calc(100%)]',
                    {
                      ' lg:min-h-[60vh] ':
                        !dashboardSettings?.statistics &&
                        !dashboardSettings?.listOfLocalRecruitmentActivities &&
                        window.innerHeight > 1024,
                    },
                  )}
                >
                  <div className='absolute inset-0'>
                    <ManpowerRequestFormSection
                      haveCharts={
                        dashboardSettings?.statistics ||
                        dashboardSettings?.listOfLocalRecruitmentActivities
                      }
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      <LayoutSelectorDrawer
        control={control}
        setValue={setValue}
        isClosing={isClosing}
        openDrawer={openDrawer}
        onSubmit={handleSubmit(onSubmit)}
        handleOpenDrawer={handleOpenDrawer}
        // toogleAllStatisticsSelect={toogleAllStatisticsSelect}
        buttonDisabled={updateSettingsMutation.status === 'pending' || !isDirty}
        isLoading={updateSettingsMutation.status === 'pending'}
      />
    </div>
  );
};

export default Dashboard;
