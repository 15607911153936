import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { MdOutlineEdit } from 'react-icons/md';

import { ICandidate, IFamilyMember, NotesType } from '@/@types/candidate';
import Container from '@/components/Container';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Textarea } from '@/components/ui/textarea';
import { getInfoItems } from '@/constants/candidate';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { EXTRACT_TYPE, getLabelOrValue, getTagVariant } from '@/utils/common';

interface Props {
  isEdit: boolean;
  data?: ICandidate;
  control: Control<NotesType>;
  isLoading?: boolean;
  handleEditTagModalOpen: () => void;
}

const CandidateInfo: React.FC<Props> = ({
  data,
  isEdit,
  control,
  isLoading,
  handleEditTagModalOpen,
}) => {
  const { candidateDetails: staticText } = strings;
  const { isAdmin, hasSubRole } = useAuth();

  const getFamilyMemberLabelAndName = (member: IFamilyMember) => {
    if (member.nameOfSpouse) {
      return { label: staticText.spouse, name: member.nameOfSpouse };
    } else if (member.nameOfFather) {
      return { label: staticText.father, name: member.nameOfFather };
    } else if (member.nameOfMother) {
      return { label: staticText.mother, name: member.nameOfMother };
    }
    return { label: '', name: '' };
  };

  return (
    <Container>
      <div className='flex flex-col gap-8 p-4 md:p-7'>
        <div className='space-y-8'>
          <div
            className={cn(
              'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-7 gap-y-6 md:gap-y-10',
              { 'xl:grid-cols-3': isAdmin },
            )}
          >
            {getInfoItems(data)?.map((item, index) => (
              <div key={index} className='col-span-1'>
                {item.title === staticText.name ||
                item.title === staticText.recruiterName ? (
                  <div className='space-y-2'>
                    <Typography className='block font-bold sm:text-[15px]'>
                      {staticText.candidateName}
                    </Typography>
                    <ProfileBadge
                      name={item.description || ''}
                      profilePicture={data?.userDetails?.avatar as string}
                      className='justify-start'
                      isLoading={isLoading}
                    />
                  </div>
                ) : (
                  <NameAndDesc
                    title={item.title}
                    description={item.description}
                    className='col-span-1'
                    isLoading={isLoading}
                  />
                )}
              </div>
            ))}
            <div className='col-span-2'>
              <Typography className='block font-bold md:text-[15px] truncate'>
                {staticText.familyBackground}
              </Typography>
              {(
                data?.familyEducationDetails as unknown as IFamilyMember[]
              )?.map((member) => {
                const { label, name } = getFamilyMemberLabelAndName(member);
                return (
                  <Typography
                    key={label}
                    className='block md:text-[15px] truncate font-semibold'
                  >
                    {label}
                    <span className='font-normal ml-1'>
                      {name && member.occupation
                        ? `${name}, ${member.occupation}`
                        : '-'}
                    </span>
                  </Typography>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div className='flex items-center gap-3 group'>
            <Typography className='font-bold'>{staticText.tags}</Typography>
            {!hasSubRole ? (
              <MdOutlineEdit
                onClick={handleEditTagModalOpen}
                className='w-5 text-xl text-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300 cursor-pointer'
              />
            ) : null}
          </div>
          <div className='mt-5 flex gap-3 flex-wrap'>
            {Array.isArray(data?.tags) && data?.tags.length ? (
              data?.tags.map((item) => (
                <Tags
                  key={`${item.companyName}-${item.ticket}`}
                  text={`${getLabelOrValue(item?.companyName, EXTRACT_TYPE.LABEL)}/ TK#${item.ticketNumber} / ${item?.isPendingApproval ? strings.common.pendingApproval : getLabelOrValue(item.status, EXTRACT_TYPE.LABEL)}`}
                  variant={getTagVariant(
                    item?.isPendingApproval
                      ? strings.common.pendingApproval
                      : (getLabelOrValue(
                          item?.status,
                          EXTRACT_TYPE.VALUE,
                        ) as string),
                  )}
                  containerClassName='w-fit'
                />
              ))
            ) : (
              <p>-</p>
            )}
          </div>
        </div>
        <div>
          <Typography className='font-bold'>{staticText.notes}</Typography>
          {isEdit ? (
            <Controller
              control={control}
              name='notes'
              render={({ field: { onChange, value } }) => (
                <Textarea
                  rows={6}
                  value={value}
                  onChange={onChange}
                  className='resize-none mt-5 text-sm md:text-sm rounded-xl font-medium min-h-10 w-full text-primaryBlack max-h-80'
                />
              )}
            />
          ) : (
            <Typography className='mt-5 text-base block'>
              {data?.notes || '-'}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CandidateInfo;
