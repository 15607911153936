/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BiSlider } from 'react-icons/bi';

import { FilterOptions } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import ReactDropdown from '@/components/Dropdown';
import SearchBar from '@/components/SearchBar';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import {
  CANDIDATE_FILTER_MODAL_FILEDS,
  CANDIDATE_TOPBAR_ACTION,
  CandidateTopBarActionList,
  generateCandidateFilterFields,
} from '@/constants/candidate';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { downloadCandidateResume } from '@/services/candidate';
import { createInitialFilterState } from '@/utils/common';
import { Table } from '@tanstack/react-table';

import AiIcon from '../../../assets/svgs/AiIcon.svg';

import AppliedFilterSection from './AppliedFilterSection';
import FilterModal from './FilterModal';

interface ICandidateTopbarType {
  hasSelectedRows: boolean;
  selectedTopbarOption: () => void;
  // activeTab: CANDIDATE_TABS;
  selectedRows?: ICandidate[];
  setSelectedRows: React.Dispatch<React.SetStateAction<ICandidate[]>>;
  tableState: Table<ICandidate> | null;
  isFetching: boolean;
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const CandidateTopbar: React.FC<ICandidateTopbarType> = ({
  hasSelectedRows,
  selectedTopbarOption,
  // activeTab,
  setSelectedRows,
  tableState,
  selectedRows,
  isFetching,
  searchText,
  setShouldRefetch,
  setSearchText,
}) => {
  const staticText = strings.candidateScreen;
  const isSmallScreen = window.innerWidth > 640;

  const {
    candidateFilters,
    setCandidateFilters,
    setSearchAiCandidateText,
    searchAiCandidateText,
  } = useFilters();

  const [value, setValue] = useState('');
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  // const [dropdownDataList, setDropdownDataList] = useState<string[]>();

  const {
    department,
    haveMoreDepartmentPage,
    setDepartmentPage,
    loadingDepartments,
    setDepartmentSearch,
    companies,
    loadingCompanies,
    setCompanyPage,
    setCompanySearch,
    haveMoreCompanyPage,
  } = useCommonDataContext();

  const filterModalFields = generateCandidateFilterFields(
    companies,
    haveMoreCompanyPage,
    setCompanyPage,
    setCompanySearch,
    loadingCompanies,
    department,
    haveMoreDepartmentPage,
    setDepartmentPage,
    setDepartmentSearch,
    loadingDepartments,
  );

  const handleFilter: SubmitHandler<FilterOptions> = (data) => {
    setCandidateFilters({
      ...data,
      filterCompany: data?.company as string,
      filterDepartment: data?.department as string,
    });
    setFilterModalOpen(false);
    setShouldRefetch(true);
  };

  const {
    control,
    reset,
    watch,
    setValue: setFiltersFormValue,
    formState: { errors },
    handleSubmit,
  } = useForm<FilterOptions>({
    mode: 'all',
    defaultValues: {
      ...createInitialFilterState(filterModalFields),
    },
  });

  const handleToggleFilterModal = () => {
    setFilterModalOpen(!filterModalOpen);
  };

  const handleResetFilter = () => {
    setCandidateFilters({});
    reset(createInitialFilterState(filterModalFields));
    setShouldRefetch(true);
  };

  // useEffect(() => {
  //   let filterList;
  //   if (isAdmin) {
  //     filterList = [
  //       CANDIDATE_TOPBAR_ACTION.DELETE,
  //       CANDIDATE_TOPBAR_ACTION.DOWNLOAD_RESUME,
  //     ];
  //   } else if (activeTab === CANDIDATE_TABS.SHORTLISTED) {
  //     filterList = CandidateTopBarActionList.filter(
  //       (item) => item !== CANDIDATE_TOPBAR_ACTION.MOVE_TO_SHORTLISTED,
  //     );
  //   } else {
  //     filterList = CandidateTopBarActionList.filter(
  //       (item) => item !== CANDIDATE_TOPBAR_ACTION.REMOVE_FROM_SHORTLISTED,
  //     );
  //   }

  //   setDropdownDataList(filterList);
  // }, [activeTab]);

  // const removeFromShortlistedCandidateMutation = useGenericMutation<
  //   { ids: string[] },
  //   boolean
  // >(removeFromShortlistedCandidate, {
  //   onSuccess: (response) => {
  //     if (response) {
  //       toast.success(staticText.removeFromShortlisted);
  //       tableState?.resetRowSelection();
  //       setSelectedRows([]);
  //     }
  //   },
  // });

  // const moveToShortlistedCandidateMutation = useGenericMutation<
  //   { ids: string[] },
  //   boolean
  // >(moveToShortlistedCandidate, {
  //   onSuccess: (response) => {
  //     if (response) {
  //       toast.success(staticText.moveToShortlistedSuccess);
  //       tableState?.resetRowSelection();
  //       setSelectedRows([]);
  //     }
  //   },
  // });

  const downloadResumeCandidateMutation = useGenericMutation<string[], boolean>(
    downloadCandidateResume,
    {
      onSuccess: (response) => {
        if (response) {
          tableState?.resetRowSelection();
          setSelectedRows([]);
        }
      },
    },
  );

  const handleSelectedValue = (selectedAction: string) => {
    // const payload = {
    //   ids: selectedRows?.map((item) => item._id) || [],
    // };
    if (selectedAction === CANDIDATE_TOPBAR_ACTION.DELETE) {
      selectedTopbarOption();
      // } else if (
      //   selectedAction === CANDIDATE_TOPBAR_ACTION.REMOVE_FROM_SHORTLISTED
      // ) {
      //   removeFromShortlistedCandidateMutation.mutate(payload);
      // } else if (selectedAction === CANDIDATE_TOPBAR_ACTION.MOVE_TO_SHORTLISTED) {
      //   moveToShortlistedCandidateMutation.mutate(payload);
    } else if (selectedAction === CANDIDATE_TOPBAR_ACTION.DOWNLOAD_RESUME) {
      const candidateIds = selectedRows?.map((item) => item._id) || [];
      downloadResumeCandidateMutation.mutate(candidateIds);
      toast.promise(downloadResumeCandidateMutation.mutateAsync(candidateIds), {
        loading: staticText.downloading,
        success: staticText.downloadedSuccess,
        error: (error) => error.message,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(candidateFilters).length > 0) {
      reset(candidateFilters);
    }
  }, [reset, candidateFilters]);

  const handleClearSearch = () => {
    setSearchAiCandidateText('');
    setSearchText('');
  };

  const handleSearchButton = () => {
    setSearchAiCandidateText(searchText);
    setShouldRefetch(true);
  };

  const tags = useMemo(() => {
    return Object.entries(candidateFilters)
      .filter(
        ([key, value]) =>
          value &&
          !key.startsWith(CANDIDATE_FILTER_MODAL_FILEDS.COMPANY) &&
          !key.startsWith(CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT),
      )
      .map(([key, value]) => ({ key, value }));
  }, [candidateFilters]);

  const handleRemoveTag = (keyToRemove: string) => {
    setCandidateFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      delete updatedFilters[keyToRemove];
      setFiltersFormValue(keyToRemove, '');
      // Handle special cases for 'company' and 'department'
      if (
        [
          CANDIDATE_FILTER_MODAL_FILEDS.COMPANY,
          CANDIDATE_FILTER_MODAL_FILEDS.FILTER_COMPANY,
        ].includes(keyToRemove as CANDIDATE_FILTER_MODAL_FILEDS)
      ) {
        updatedFilters.company = '';
        updatedFilters.filterCompany = '';
        setFiltersFormValue(CANDIDATE_FILTER_MODAL_FILEDS.COMPANY, '');
        setFiltersFormValue(CANDIDATE_FILTER_MODAL_FILEDS.FILTER_COMPANY, '');
      }
      if (
        [
          CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT,
          CANDIDATE_FILTER_MODAL_FILEDS.FILTER_DEPARTMENT,
        ].includes(keyToRemove as CANDIDATE_FILTER_MODAL_FILEDS)
      ) {
        updatedFilters.department = '';
        updatedFilters.filterDepartment = '';
        setFiltersFormValue(CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT, '');
        setFiltersFormValue(
          CANDIDATE_FILTER_MODAL_FILEDS.FILTER_DEPARTMENT,
          '',
        );
      }
      return updatedFilters;
    });
    setShouldRefetch(true);
  };

  return (
    <div className='flex flex-col md:flex-row justify-between mb-4'>
      <div className='space-y-4 w-full'>
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          searchIcon={<img src={AiIcon} className='size-4 sm:size-auto' />}
          placeholder={staticText.searchPlaceholder}
          button={
            <Button
              className='sm:text-base rounded-md h-9 w-20 disabled:opacity-70'
              onClick={handleSearchButton}
              disabled={isFetching || !searchText.length}
              loading={isFetching && !!searchText.length}
            >
              {staticText.search}
            </Button>
          }
          containerClassName='md:pr-1 md:w-3/5'
        />
        <div className='flex gap-2 md:gap-5 lg:items-center flex-col'>
          <div className='flex justify-between items-center w-full'>
            <Typography className='text-xl md:text-[22px] font-bold'>
              {staticText.title}
            </Typography>
            <div className='flex gap-3 mt-3 sm:mt-0'>
              <ReactDropdown
                // options={dropdownDataList || []}
                options={CandidateTopBarActionList}
                value={value}
                onChange={(selectedOption) => {
                  setValue(selectedOption as string);
                  handleSelectedValue(selectedOption as string);
                }}
                name='pageSize'
                placeholder='Action'
                className='bg-white text-black'
                inputWidth={isSmallScreen ? '170px' : '130px'}
                inputHeight={isSmallScreen ? '42px' : '32px'}
                isDisabled={hasSelectedRows}
                valueHide
                setInputSearch={setDepartmentSearch}
              />
              <Button
                variant='outline'
                className='border-[1.5px] relative p-0 size-8 rounded-md sm:rounded-auto sm:size-[42px]'
                onClick={handleToggleFilterModal}
              >
                {candidateFilters?.company ||
                candidateFilters.department ||
                candidateFilters.tags ? (
                  <div className='size-2 rounded-full absolute -top-1 -right-1 bg-tomatoRed' />
                ) : null}
                <BiSlider className='text-xl' />
              </Button>
            </div>
          </div>
          {Object.keys(candidateFilters).some(
            (key) => candidateFilters[key] !== '',
          ) ? (
            <AppliedFilterSection
              handleRemoveTag={handleRemoveTag}
              tags={tags as FilterOptions[]}
            />
          ) : null}
        </div>
      </div>
      {filterModalOpen ? (
        <FilterModal
          isOpen={filterModalOpen}
          onClose={handleToggleFilterModal}
          handleApplyFilter={handleSubmit(handleFilter)}
          control={control}
          handleResetFilter={handleResetFilter}
          filterErrors={errors}
          buttonDisabled={Object.values(watch()).every((value) => !value)}
          fields={filterModalFields}
          searchText={searchAiCandidateText}
          handleClearSearch={handleClearSearch}
        />
      ) : null}
    </div>
  );
};

export default CandidateTopbar;
