import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IUser } from '@/@types/auth';
import BackHeader from '@/components/BackHeader';
import Container from '@/components/Container';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { USER_TYPE } from '@/constants';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { fetchUserDetail } from '@/services/users';
import {
  GetFormattedName,
  getVariantByRole,
  removeSegmentsFromUrl,
  snakeToTitleCase,
} from '@/utils/common';

const UserDetails = () => {
  const staticText = strings.usersDetails;

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useGenericQuery(['users-detail', id], () =>
    fetchUserDetail(id!),
  );

  const userDetails = (data: IUser) => [
    { label: staticText.firstName, value: data?.firstName },
    { label: staticText.lastName, value: data?.lastName },
    { label: staticText.email, value: data?.email },
    ...(data?.profileType === USER_TYPE.COMPANY_CLIENT
      ? [
          {
            label: staticText.companyName,
            value: data?.companyName as string,
          },
        ]
      : []),
  ];

  const role = (data as IUser)?.subRole || (data as IUser)?.profileType;

  return (
    <>
      <BackHeader
        title={staticText.title}
        handleBackBtn={() => removeSegmentsFromUrl(navigate, location.pathname)}
      />
      <div className='flex flex-col gap-6 mt-7'>
        <Container className='w-full'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
            <div className='lg:col-span-3 justify-start flex'>
              <ProfileBadge
                profilePicture={(data as IUser)?.avatar as string}
                name={GetFormattedName(data as IUser)}
                isLoading={isLoading}
                avatarClassName='size-12'
                nameClassName='text-lg'
              />
            </div>
            {userDetails(data as IUser).map(({ label, value }) => (
              <NameAndDesc
                key={label}
                title={label}
                description={value}
                isLoading={isLoading}
              />
            ))}
            <div>
              <Typography className='block font-bold mb-1'>
                {staticText.userType}
              </Typography>
              <Tags
                text={snakeToTitleCase(role?.toLowerCase())}
                variant={getVariantByRole(role)}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default UserDetails;
