import { SiGoogleforms } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';

import { APPROVAL_STATUS, IApprovals } from '@/@types/approvals';
import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import { IAssigneeData } from '@/@types/tickets';
import ProfileBadge from '@/components/ProfileBadge';
import SelectCell from '@/components/Table/CellComponents/SelectCell';
import TableHeading from '@/components/Table/TableHeading';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { SUB_ROLE } from '@/constants';
import { APPROVAL_TABS } from '@/constants/approval';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { GetFormattedName, getTagVariant } from '@/utils/common';
import { ColumnDef, Row } from '@tanstack/react-table';

const {
  approvalsScreen: { tableColumnsName },
} = strings;

export const APPROVAL_TABLE_COLUMNS = (
  setPanFormId: React.Dispatch<React.SetStateAction<string>>,
  activeTab: APPROVAL_TABS,
  subRole: SUB_ROLE,
  setFromApprovalScreen: React.Dispatch<React.SetStateAction<string>>,
  setEditableCandidateData: React.Dispatch<
    React.SetStateAction<ICandidate | null>
  >,
): ColumnDef<IApprovals>[] => {
  const navigate = useNavigate();
  const handleClickTicket = (id: string) => {
    setFromApprovalScreen(location.pathname);
    navigate(`${ROUTES.TICKETS}/${id}`);
  };
  const handleClickCandidate = (id: string) => {
    setFromApprovalScreen(location.pathname);
    navigate(`${ROUTES.CANDIDATE}/${id}`);
  };
  const panFormClick = (id: string, data: ICandidate) => {
    setEditableCandidateData(data);
    setPanFormId(id);
  };

  return [
    ...(activeTab === APPROVAL_TABS.PENDING ? [SelectCell<IApprovals>()] : []),
    {
      id: 'ticketNo',
      header: () => (
        <TableHeading
          text={strings.ticketDetailScreen.tableColumnsName.ticketNo}
        />
      ),
      cell: ({ row }: { row: Row<IApprovals> }) => (
        <div
          onClick={() =>
            handleClickTicket(row?.original?.ticket?._id as string)
          }
          className='cursor-pointer'
        >
          <Typography className='md:text-[15px] text-primary hover:text-primary'>
            {`Tk#${row?.original.ticket.ticketNumber}`}
          </Typography>
        </div>
      ),
    },
    {
      id: 'candidate',
      header: () => <TableHeading text={tableColumnsName.candidate} />,
      cell: ({ row: { original } }) => (
        <div
          onClick={() => handleClickCandidate(original.candidate._id as string)}
          className='cursor-pointer'
        >
          <ProfileBadge
            className='justify-start'
            nameClassName='font-medium'
            name={GetFormattedName<IUser>(original?.candidate?.userDetails)}
            profilePicture={original?.candidate?.userDetails?.avatar as string}
          />
        </div>
      ),
    },
    {
      id: 'assignee',
      header: () => <TableHeading text={tableColumnsName.assignee} />,
      cell: ({ row: { original } }) => (
        <ProfileBadge
          className='justify-start'
          nameClassName='font-medium'
          name={GetFormattedName<IAssigneeData>(original!.ticket.assignee!)}
          profilePicture={original?.ticket?.assignee?.avatar}
        />
      ),
    },
    {
      id: 'PanForm',
      header: () => <TableHeading text={tableColumnsName.panForm} />,
      cell: ({ row: { original } }) => (
        <Button
          onClick={() =>
            panFormClick(original._id, original?.candidate as ICandidate)
          }
          variant='link'
          size='icon'
          icon={<SiGoogleforms size={18} />}
        />
      ),
    },
    ...(activeTab === APPROVAL_TABS.REVIEWED
      ? [
          {
            id: 'approval',
            header: () => <TableHeading text={tableColumnsName.approval} />,
            cell: ({ row }: { row: Row<IApprovals> }) => {
              const getText = () => {
                const isApproved =
                  subRole === SUB_ROLE.ACCOUNT_OFFICER
                    ? row.original.isAcceptedByAccOfficer
                    : row.original.isAcceptedByRecruManager;

                return isApproved
                  ? APPROVAL_STATUS.APPROVED
                  : APPROVAL_STATUS.REJECTED;
              };
              return (
                <Tags
                  text={getText()}
                  variant={getTagVariant(getText())}
                  containerClassName='py-1 text-xs h-auto min-w-20 text-sm'
                />
              );
            },
          },
        ]
      : []),
  ];
};
