import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { IUser } from '@/@types/auth';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { IUserForm } from '@/pages/CreateNewUser';
import { buildQueryParams } from '@/utils/common';

interface FetchUsersParams {
  pageSize: number;
  page: number;
  search?: string;
  company?: string;
  role?: string;
}

export const fetchUsers = async (
  params: FetchUsersParams,
): Promise<IPaginationResponse<IUser> | boolean> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IPaginationResponse<IUser>>(
    `${REST_SUB_URL.USER_LIST}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchUserDetail = async (id: string): Promise<IUser | boolean> => {
  const response = await API.Get<IUser>(`${REST_SUB_URL.USER_DETAIL}/${id}`);
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const createUser = async (payload: IUserForm): Promise<boolean> => {
  const response = await API.Post<IUserForm, boolean>(
    REST_SUB_URL.REGESTER_ACCOUNT,
    payload,
  );
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const fetchRecruiters = async (
  page: number,
  departmentSearch?: string,
): Promise<IPaginationResponse<IUser> | boolean> => {
  const response = await API.Get<IPaginationResponse<IUser>>(
    `${REST_SUB_URL.GET_RECRUITERS}?page=${page}&search=${departmentSearch}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const deleteUser = async (id: string): Promise<boolean> => {
  const response = await API.Delete<string, string | boolean>(
    `${REST_SUB_URL.DELETE_USER}/${id}`,
  );
  if (response.status) {
    return true;
  }
  toast.error(response.message);
  return false;
};
