import React from 'react';
import { FaBell } from 'react-icons/fa';

import { INotification } from '@/@types/notification';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import { NOTIFICATIONS_ACTION } from '@/constants/notifications';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import {
  capitalizeWord,
  GetFormattedName,
  getTagVariant,
  snakeToTitleCase,
} from '@/utils/common';
import { formatTimestamp } from '@/utils/dateTime';

interface NotificationItemProps {
  notification: INotification;
  onClick: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onClick,
}) => {
  const isSmallScreen = window.innerWidth < 768;
  const { sender, message, createdAt, seen, action } = notification;

  const getLastWords = (statuses: CANDIDATE_STATUS[]) => {
    const words = message.replace(/\.$/, '').trim().split(' ');

    if (statuses.some((status) => message.includes(status))) {
      return words.slice(-2).join(' ');
    }

    return words.slice(-1)[0] || '';
  };

  const isStatusUpdationNotification =
    action === NOTIFICATIONS_ACTION.STATUS_UPDATE;
  const status = isStatusUpdationNotification
    ? getLastWords([
        CANDIDATE_STATUS.INTERVIEW_CLEARED,
        CANDIDATE_STATUS.ON_HOLD,
        CANDIDATE_STATUS.HR_ORIENTATION,
      ])
    : '';

  return (
    <div
      className={cn('flex px-4 py-3 border-t border-greyWhite cursor-pointer', {
        'bg-iceBlue': !seen,
      })}
      onClick={onClick}
    >
      <div className='mr-3 flex-shrink-0 '>
        {sender ? (
          <ProfileBadge
            name={GetFormattedName(sender)}
            profilePicture={sender.avatar as string}
            // Hides the last div of profile badge
            className='[&>div:last-child]:hidden'
          />
        ) : (
          <div className='h-[34px] w-[34px] rounded-full shrink-0 bg-greyWhite flex justify-center items-center'>
            <FaBell />
          </div>
        )}
      </div>
      <div>
        <Typography className='md:text-sm'>
          {sender ? (
            <span className='font-bold'>{GetFormattedName(sender)}</span>
          ) : (
            <span className='font-bold'>
              {strings.notificationScreen.reminder}
            </span>
          )}
          <span className='mr-2'> {message}</span>
          {isStatusUpdationNotification ? (
            <Tags
              text={status}
              variant={getTagVariant(status)}
              containerClassName={cn('w-fit inline-block', {
                'h-6': !isSmallScreen,
              })}
            />
          ) : null}
        </Typography>
        <div className='flex mt-3'>
          <Typography className='text-[10px] md:text-[10px] text-mouseGrey block'>
            {formatTimestamp(createdAt)}
          </Typography>
          {sender ? (
            <Typography className='text-[10px] md:text-[10px] text-mouseGrey ml-auto'>
              {`${strings.notificationScreen.issuerRole} ${capitalizeWord(snakeToTitleCase(sender.subRole || sender.profileType))}`}
            </Typography>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
