import { SUB_ROLE } from '@/constants';
import {
  AccountOfficerStatus,
  APPROVAL_TABS,
  RecruiterManagerStatus,
} from '@/constants/approval';

import { IUser } from './auth';
import { ICandidate } from './candidate';
import { ITicket } from './tickets';

export interface FetchApprovalsParams {
  tab: APPROVAL_TABS;
  subRole: SUB_ROLE;
  pageSize: number;
  page: number;
  type: string;
  search?: string;
  ticketNumber?: string;
  candidate?: string;
  assignee?: string;
  status?: string;
}

export interface IApprovals {
  candidate: ICandidate;
  createdAt: string;
  isAcceptedByAccOfficer: boolean;
  isAcceptedByRecruManager: boolean;
  ticket: ITicket;
  assignee: IUser;
  jobTitle: string;
  ticketNumber: string;
  _id: string;
  updatedAt: string;
}

export enum APPROVAL_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface IApprovalPayload {
  action: string;
  ids: string[];
  status: RecruiterManagerStatus | AccountOfficerStatus;
}
