import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { IRequest } from '@/@types/request';
import BackHeader from '@/components/BackHeader';
import Container from '@/components/Container';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { REQUEST_TABS } from '@/constants/request';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { fetchRequestDetail } from '@/services/request';
import {
  getFileNameFromUrl,
  getTagVariant,
  removeSegmentsFromUrl,
} from '@/utils/common';

import ResumeCard from '../CandidateDetails/components/ResumeCard';
import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';

const RequestDetails = () => {
  const staticText = strings.requestDetailScreen;

  const location = useLocation();
  const navigate = useNavigate();
  const { requestId } = useParams<{ requestId: string }>();

  const { data, isLoading } = useGenericQuery(
    ['users-detail', requestId],
    () => fetchRequestDetail(requestId!),
    { enabled: !!requestId },
  );

  const title = useMemo(() => {
    if (location.pathname.includes(REQUEST_TABS.CLOSURE)) {
      return strings.requestDetailScreen.titleForClosure;
    } else if (location.pathname.includes(REQUEST_TABS.ENDORSED)) {
      return strings.requestDetailScreen.titleForEndorsed;
    }
    return staticText.requestDetail;
  }, [location.pathname]);

  const {
    candidate,
    status,
    description,
    document,
    recruiter,
    isPendingApproval,
  } = (data || {}) as IRequest;

  return (
    <>
      <BackHeader
        title={title}
        handleBackBtn={() =>
          removeSegmentsFromUrl(navigate, location.pathname, 1)
        }
      />
      <div className='flex flex-col gap-6 mt-7'>
        <Container className='h-auto md:px-9 md:py-7'>
          <ResumeCard
            file={{
              name: getFileNameFromUrl(candidate?.resume || ''),
              size: candidate?.fileSize || '-',
              url: candidate?.resume || '',
              date: candidate?.uploadedDate,
            }}
            isLoading={isLoading}
          />
        </Container>
        <Container className='p-4 md:p-7 grid grid-cols-1 h-full sm:grid-cols-2 lg:grid-cols-3 gap-y-9'>
          <div>
            <Typography className='block font-bold mb-1'>
              {staticText.candidateName}
            </Typography>
            <ProfileBadge
              name={`${candidate?.userDetails?.firstName} ${candidate?.userDetails?.lastName}`}
              className='justify-start'
              isLoading={isLoading}
            />
          </div>
          <div>
            <Typography className='block font-bold mb-1'>
              {staticText.recruiterName}
            </Typography>
            <ProfileBadge
              name={`${recruiter?.firstName} ${recruiter?.lastName}`}
              className='justify-start'
              isLoading={isLoading}
            />
          </div>
          <NameAndDesc
            title={staticText.positionDesired}
            description={candidate?.positionDesired?.[0]}
            isLoading={isLoading}
          />
          <NameAndDesc
            title={staticText.department}
            description={candidate?.department}
            isLoading={isLoading}
          />
          <div className='w-fit px-0'>
            <Typography className='block font-bold mb-2'>
              {staticText.tags}
            </Typography>
            <Tags
              text={isPendingApproval ? strings.common.pendingApproval : status}
              variant={getTagVariant(
                isPendingApproval ? strings.common.pendingApproval : status,
              )}
            />
          </div>
          <NameAndDesc
            title={staticText.description}
            description={description}
            avoidTruncate
            className='col-span-1 sm:col-span-2 lg:col-span-3'
            isLoading={isLoading}
          />
          {document ? (
            <div className='space-y-2'>
              <Typography variant='heading' className='md:text-2xl'>
                {staticText.document}
              </Typography>
              <AttachmentPreview attachment={document} />
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default RequestDetails;
