import DocxIcon from '../../../assets/svgs/docxIcon.svg';
import FileIcon from '../../../assets/svgs/fileIcon.svg';
import PdfIcon from '../../../assets/svgs/pdfIcon.svg';
import { Typography } from '../../Typography';

interface ResumeCellProps {
  resumeUrl?: string;
  preventPropagation?: boolean;
}

export const ResumeCell: React.FC<ResumeCellProps> = ({
  resumeUrl,
  preventPropagation,
}) => {
  if (!resumeUrl) {
    return <div className='min-w-full text-center'>-</div>;
  }
  const fileExtension = resumeUrl.split('.').pop()?.toLowerCase();

  const getIconByFileExtension = () => {
    if (fileExtension?.includes('pdf'))
      return <img src={PdfIcon} alt={`Resume ${fileExtension}`} />;
    else if (fileExtension?.includes('docx'))
      return <img src={DocxIcon} alt={`Resume ${fileExtension}`} />;
    return <img src={FileIcon} alt={`Resume ${fileExtension}`} />;
  };

  return (
    <a
      href={resumeUrl}
      target='_blank'
      rel='noopener noreferrer'
      onClick={preventPropagation ? (e) => e.stopPropagation() : undefined}
      className='flex items-center justify-center'
    >
      <Typography className='md:text-[15px]'>
        {getIconByFileExtension()}
      </Typography>
    </a>
  );
};
