import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { IPaginationResponse } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import CircularLoader from '@/components/ClipLoader';
import Modal from '@/components/Modal';
import ProfileBadge from '@/components/ProfileBadge';
import SearchBar from '@/components/SearchBar';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Checkbox } from '@/components/ui/checkbox';
import useDebounce from '@/hooks/useDebounce';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';
import { getCandidatesListForChat } from '@/services/chat';
import { arraysEqual, GetFormattedName } from '@/utils/common';
import { useInfiniteQuery } from '@tanstack/react-query';

interface Props {
  open: boolean;
  handleClose: () => void;
  newChat?: boolean;
}

const RecipientModal: React.FC<Props> = ({
  open,
  handleClose,
  newChat = false,
}) => {
  const staticText = strings.chatScreen;

  const {
    selectedRecipients,
    setSelectedRecipients,
    setIsBroadcastMessage,
    setActiveChat,
    setConversationId,
  } = useChatContext();

  const [searchText, setSearchText] = useState('');
  const [recipients, setRecipients] = useState<ICandidate[]>(
    newChat ? [] : selectedRecipients,
  );

  const debouncedSearchText = useDebounce(searchText);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: ['getCandidatesListForChat', debouncedSearchText],
    queryFn: ({ pageParam = 1 }) =>
      getCandidatesListForChat(pageParam.toString(), debouncedSearchText),
    initialPageParam: 1,
    enabled: open,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const handleAddRecipient = () => {
    setActiveChat(null);
    setIsBroadcastMessage(true);
    setSelectedRecipients(recipients);
    handleClose();
    setConversationId('');
  };

  const handleCloseButton = () => {
    handleClose();
    newChat ? setRecipients([]) : null;
  };

  const handleSelect = (candidate: ICandidate) => {
    if (candidate?.userDetails) {
      const { _id } = candidate.userDetails;

      if (recipients.some((selected) => selected?.userDetails?._id === _id)) {
        setRecipients(
          recipients.filter(
            (recipient) =>
              recipient?.userDetails?._id !== candidate.userDetails?._id,
          ),
        );
      } else setRecipients((prev) => [...prev, candidate]);
    }
  };

  const candidates = useMemo(() => {
    return (
      data?.pages.flatMap(
        (page) => (page as IPaginationResponse<ICandidate>).results,
      ) || []
    );
  }, [data?.pages]);

  const Content = () => {
    if (isLoading) return <StateIndicator state='Loading' />;
    if (!candidates.length) return <StateIndicator state='Empty' />;
    return (
      <>
        <div className='flex justify-between items-center mb-1'>
          <Typography className='font-extrabold'>
            {staticText.candidates}
          </Typography>
          {/* Uncomment to enable select all/deselect all functionality */}
          {/* <Button
            variant='link'
            size='icon'
            className='font-medium text-sm w-fit'
            onClick={handleSelectAll}
          >
            {allSelected ? staticText.deselectAll : staticText.selectAll}
          </Button> */}
        </div>
        <div id='scrollableDiv' className='h-full overflow-y-auto'>
          <InfiniteScroll
            dataLength={candidates.length}
            next={fetchNextPage}
            hasMore={hasNextPage}
            loader={
              <div className='col-span-2'>
                <CircularLoader />
              </div>
            }
            scrollableTarget='scrollableDiv'
            className='grid sm:grid-cols-2 gap-4'
          >
            {candidates?.map((recipient, index) => (
              <div
                key={`Recipient-${recipient._id}-${index}`}
                className='flex items-center gap-4 h-fit'
              >
                <Checkbox
                  checked={recipients.some(
                    (selected) =>
                      selected?.userDetails?._id ===
                      recipient?.userDetails?._id,
                  )}
                  onClick={() => handleSelect(recipient)}
                  className='bg-simplyViolet border-greyWhite'
                />
                <ProfileBadge
                  name={GetFormattedName(recipient?.userDetails)}
                  profilePicture={recipient?.userDetails?.avatar as string}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </>
    );
  };

  // Temporarily commented to manage pagination without the "select all" feature.
  // const allSelected = candidates.every((recipient) =>
  //   candidates.includes(recipient._id),
  // );
  // const handleSelectAll = () => {
  //   if (allSelected) {
  //     setCandidates(
  //       candidates.filter((id) =>
  //         candidates.every((recipient) => recipient._id !== id),
  //       ),
  //     );
  //   } else {
  //     setCandidates(candidates.map((recipient) => recipient._id));
  //   }
  // };

  return (
    <Modal
      open={open}
      handleCloseModal={handleCloseButton}
      title={staticText.addRecipient}
      primaryBtnLabel={strings.common.add}
      primaryBtnAction={handleAddRecipient}
      buttonDisabled={arraysEqual(recipients, selectedRecipients)}
    >
      <div className='flex flex-col gap-4 sm:h-72'>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
        {Content()}
      </div>
    </Modal>
  );
};

export default RecipientModal;
