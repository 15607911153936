import { HiDotsVertical, HiOutlineTrash } from 'react-icons/hi';

import { Department } from '@/@types/metas';
import { ClientData, IAssigneeData, ITicket } from '@/@types/tickets';
import ProfileBadge from '@/components/ProfileBadge';
import Dropdown from '@/components/SelectDropdown';
import SelectCell from '@/components/Table/CellComponents/SelectCell';
import { TagsCell } from '@/components/Table/CellComponents/TagsCell';
import TableHeading from '@/components/Table/TableHeading';
import { Typography } from '@/components/Typography';
import {
  TICKET_TABS,
  TICKETS_ACTION,
  TICKETS_ACTION_ENUM,
  TicketTableColumnDefinitions,
} from '@/constants/ticket';
import { strings } from '@/locales';
import {
  formatCurrency,
  GetFormattedName,
  getTagVariant,
  snakeToTitleCase,
} from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { ColumnDef, Row } from '@tanstack/react-table';

const {
  ticketScreen: { tableColumnsName },
} = strings;

const textDisplayColumns = TicketTableColumnDefinitions.map((column) => ({
  id: column.id,
  header: () => <TableHeading text={column.name} />,
  cell: ({ row: { original } }: { row: Row<ITicket> }) => {
    const value = original[column.id as keyof ITicket]?.toString();

    const columnValue = () => {
      if (column.id === 'ticketNumber') return `TK#${value}`;
      else if (column.id === 'department')
        return (original.department as Department).name;
      else if (column.id === 'rate') return formatCurrency(original.rate);
      else if (column.id === 'appliedCandidates')
        return (
          (original.appliedCandidates?.length || 0) +
          (original.shortlistedAppliedCandidates?.length || 0)
        );
      else if (column.id === 'hired')
        return `${original.candidatesHired || 0} / ${original.numberOfHC}`;
      else if (
        column.id.includes('At') ||
        column.id.toLowerCase().includes('date') ||
        column.id === 'targetOnBoard'
      )
        return getLocalDate(value);
      return value;
    };

    return (
      <Typography className='md:text-[15px]'>
        {columnValue() ?? <div>-</div>}
      </Typography>
    );
  },
}));

export const TicketsTableColumns = (
  isAdmin: boolean,
  isRecruiter: boolean,
  activeTab: TICKET_TABS,
  handleAction: (action: string, ticketId: string) => void,
  hasSubRole: boolean,
): ColumnDef<ITicket>[] => {
  const columns = [
    ...(activeTab === TICKET_TABS.ALL_TICKETS
      ? [{ ...SelectCell<ITicket>() }]
      : []),
    ...textDisplayColumns,
    {
      id: 'assignee',
      header: () => <TableHeading text={tableColumnsName.assignee} />,
      cell: ({ row: { original } }: { row: Row<ITicket> }) => {
        return original.assignee ? (
          <ProfileBadge
            className='justify-start'
            nameClassName='font-medium'
            name={GetFormattedName<IAssigneeData>(original.assignee)}
            profilePicture={original.assignee.avatar}
          />
        ) : (
          <Typography>-</Typography>
        );
      },
    },
    {
      id: 'company',
      header: () => <TableHeading text={tableColumnsName.company} />,
      cell: ({ row: { original } }: { row: Row<ITicket> }) => (
        <Typography className='md:text-[15px]'>
          {(original.client as ClientData).companyName}
        </Typography>
      ),
    },
    {
      id: 'status',
      header: () => <TableHeading text={tableColumnsName.status} />,
      cell: ({ row: { original } }: { row: Row<ITicket> }) => (
        <TagsCell
          tags={snakeToTitleCase(original.status)!}
          variant={getTagVariant(snakeToTitleCase(original.status))}
        />
      ),
    },
    ...(!hasSubRole
      ? [
          {
            id: 'options',
            cell: ({ row: { original } }: { row: Row<ITicket> }) => (
              <div onClick={(e) => e.stopPropagation()}>
                {!isAdmin ? (
                  <HiOutlineTrash
                    className='text-redColor text-lg'
                    onClick={() =>
                      handleAction(TICKETS_ACTION_ENUM.DELETE, original._id!)
                    }
                  />
                ) : (
                  <Dropdown
                    items={
                      original.assignee === null
                        ? TICKETS_ACTION.slice(1)
                        : TICKETS_ACTION
                    }
                    onChange={(value) => handleAction(value, original._id!)}
                    customButton={<HiDotsVertical className='opacity-60 h-8' />}
                    customButtonClassName='flex justify-center'
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  const commonColumns = [
    'ticketNumber',
    'jobTitle',
    'department',
    'hired',
    'appliedCandidates',
    'status',
  ];

  const CompanyClientAndAdminColumns = [
    ...commonColumns,
    'rate',
    'assignee',
    'options',
    ...(isAdmin ? ['company'] : []),
  ];
  const RecruiterColumns = [
    'select',
    ...commonColumns,
    'targetOnBoard',
    'company',
  ];
  const getColumnsForRole = () => {
    if (isRecruiter) {
      return columns.filter((col) => RecruiterColumns.includes(col.id));
    }
    return columns.filter((col) =>
      CompanyClientAndAdminColumns.includes(col.id),
    );
  };

  return getColumnsForRole();
};
