import React from 'react';

import Container from '@/components/Container';
import { getDashboardCardsData } from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { useSidebarContext } from '@/provider/SidebarProvider';
import {
  fetchDashboardAllTicketsCount,
  fetchDashboardMyTicketsCount,
  fetchDashboardSales,
  ISalesResponse,
} from '@/services/dashboard';

import DashboardCard from './DashboardCard';

const StatsCard = () => {
  const { sidebarExpand } = useSidebarContext();

  const { data: allTicketsCount, isLoading: allTicketsLoading } =
    useGenericQuery(
      ['fetchDashboardAllTicketsCount'],
      fetchDashboardAllTicketsCount,
    );
  const { data: myTicketsCount, isLoading: myTicketsLoading } = useGenericQuery(
    ['fetchDashboardMyTicketsCount'],
    fetchDashboardMyTicketsCount,
  );
  const { data: sales, isLoading: salesLoading } = useGenericQuery(
    ['fetchDashboardSales'],
    fetchDashboardSales,
  );

  const DASHBOARD_CARDS_DATA = getDashboardCardsData(
    allTicketsCount,
    allTicketsLoading,
    myTicketsCount,
    myTicketsLoading,
    (sales as ISalesResponse)?.totalSales,
    (sales as ISalesResponse)?.totalHiredCandidate,
    salesLoading,
  );

  return (
    <Container
      className={cn(
        'mt-4 grid gap-7 p-5 md:p-5 grid-cols-1 lg:grid-cols-3',
        sidebarExpand ? 'sm:grid-cols-2' : 'xs:grid-cols-2',
      )}
    >
      {DASHBOARD_CARDS_DATA.map((card, index) => (
        <DashboardCard
          key={index}
          title={card.title}
          count={card.count}
          countUnit={card.countUnit}
          text={card.text}
          isLoading={card.isLoading}
          totalHiredCandidate={card.totalHiredCandidate}
        />
      ))}
    </Container>
  );
};

export default StatsCard;
