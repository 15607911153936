/* eslint-disable max-lines */
import React from 'react';

import { ICandidate } from '@/@types/candidate';
import { Typography } from '@/components/Typography';
import {
  AVAILABILITY_FIELDS_EVALUATION_FORM,
  INTERVIEW_EVALUATION_DATA,
} from '@/constants/ticket';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { fetchClientsIAFDetail } from '@/services/request';

import InterviewEvaluationHistorySection from './InterviewEvaluationHistorySection';

interface Props {
  ticketId: string;
  candidate: ICandidate;
}

const InterviewEvaluationHistory: React.FC<Props> = ({
  candidate,
  ticketId,
}) => {
  const { ticketDetailScreen: staticText } = strings;
  const interviewEvaluationText = staticText.interviewEvaluation;

  const { data: IAFDetail } = useGenericQuery(
    ['client-iaf-history', candidate?._id, ticketId],
    () => fetchClientsIAFDetail(candidate!._id, ticketId),
  );

  return (
    <div className='mt-4'>
      <InterviewEvaluationHistorySection
        title={interviewEvaluationText.contentAppearance}
        data={INTERVIEW_EVALUATION_DATA(IAFDetail).contentAppearance}
      />
      <InterviewEvaluationHistorySection
        title={interviewEvaluationText.contentCharacteristics}
        data={INTERVIEW_EVALUATION_DATA(IAFDetail).contentCharacteristics}
      />
      <div className='mt-8 mb-5 sm:flex gap-5'>
        {AVAILABILITY_FIELDS_EVALUATION_FORM(IAFDetail).map((item) => (
          <div key={item.label} className='mb-5'>
            <Typography className='font-bold block mb-2'>
              {item.label}:
            </Typography>
            {item.value || '-'}
          </div>
        ))}
      </div>
      <div>
        <Typography className=' font-bold block mb-2 sm:mb-0'>
          {interviewEvaluationText.contentAppearanceFields.recommendation}:
        </Typography>
        {INTERVIEW_EVALUATION_DATA(IAFDetail).recommendation || '-'}
      </div>
    </div>
  );
};

export default InterviewEvaluationHistory;
