import toast from 'react-hot-toast';

import {
  IDashboardManpowerSectionCompany,
  ISelectorDrawerFormValues,
  IStatisticValues,
} from '@/@types/dashboard';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { ISource } from '@/pages/Dashboard/components/SourcesKeyList';

interface IAllTicketsCountResponse {
  allTicketsCount: number;
}
interface IMyTicketsCountResponse {
  myTicketsCount: number;
}
export interface ISalesResponse {
  totalSales: number;
  totalHiredCandidate: number;
}
export interface ISourceResponse {
  platforms: ISource[];
  localRecruitmentActivities: ISource[];
}

export const fetchDashboardAllTicketsCount = async (): Promise<
  number | boolean
> => {
  const response = await API.Get<IAllTicketsCountResponse>(
    REST_SUB_URL.DASHBOARD_ALL_TICKETS_COUNTS,
  );
  if (response.status && response.data) {
    return response.data.allTicketsCount;
  }
  toast.error(response.message);
  return false;
};
export const fetchDashboardMyTicketsCount = async (): Promise<
  number | boolean
> => {
  const response = await API.Get<IMyTicketsCountResponse>(
    REST_SUB_URL.DASHBOARD_MY_TICKETS_COUNTS,
  );
  if (response.status && response.data) {
    return response.data.myTicketsCount;
  }
  toast.error(response.message);
  return false;
};
export const fetchDashboardSales = async (): Promise<
  ISalesResponse | boolean
> => {
  const response = await API.Get<ISalesResponse>(
    REST_SUB_URL.DASHBOARD_TOTAL_HIRED_SALES,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchDashboardSource =
  async (): Promise<ISourceResponse | null> => {
    const response = await API.Get<ISourceResponse>(
      REST_SUB_URL.DASHBOARD_SOURCES,
    );
    if (response.status && response.data) {
      return response.data;
    }
    toast.error(response.message);
    return null;
  };

// This function is used for sending only required fileds in setting response
const cleanDashboardSettingsResponse = (
  data: ISelectorDrawerFormValues,
): ISelectorDrawerFormValues => {
  return {
    allTickets: data.allTickets,
    myTickets: data.myTickets,
    sales: data.sales,
    listOfLocalRecruitmentActivities: data.listOfLocalRecruitmentActivities,
    manpowerFormVisibility: data.manpowerFormVisibility,
    statistics: data.statistics,
    // statistics: {
    //   endorsed: data.statistics?.endorsed,
    //   onProcess: data.statistics?.onProcess,
    //   backedOut: data.statistics?.backedOut,
    //   placed: data.statistics?.placed,
    //   replacement: data.statistics?.replacement,
    //   onCall: data.statistics?.onCall,
    //   temporaryDeployed: data.statistics?.temporaryDeployed,
    // },
  };
};

export const fetchDashboardSettings =
  async (): Promise<ISelectorDrawerFormValues | null> => {
    const response = await API.Get<ISelectorDrawerFormValues>(
      REST_SUB_URL.DASHBOARD_SETTINGS,
    );
    if (response.status && response.data) {
      return cleanDashboardSettingsResponse(response.data);
    }
    toast.error(response.message);
    return null;
  };

export const updateDdashboardSettings = async (
  payload: ISelectorDrawerFormValues,
): Promise<ISelectorDrawerFormValues | boolean> => {
  const response = await API.Post<
    ISelectorDrawerFormValues,
    ISelectorDrawerFormValues
  >(REST_SUB_URL.DASHBOARD_SETTINGS, payload);
  if (response.status && response.data) {
    return cleanDashboardSettingsResponse(response.data);
  }
  toast.error(response.message);
  return false;
};

export const manpowerRequestFormDdashboard = async (): Promise<
  IDashboardManpowerSectionCompany | boolean
> => {
  const response = await API.Get<IDashboardManpowerSectionCompany>(
    REST_SUB_URL.DASHBOARD_MANPOWER_REQUEST_FORM,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const dashboardStatistics = async (
  companyName: string,
): Promise<IStatisticValues[] | boolean> => {
  const response = await API.Get<IStatisticValues[]>(
    `${REST_SUB_URL.DASHBOARD_STATISTICS}/?companyName=${companyName}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};
