import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BiChat } from 'react-icons/bi';
import { FaCheckCircle } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { IoMdCloseCircle } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';

import { ICandidate, NotesType, UpdatedNotes } from '@/@types/candidate';
import BackHeader from '@/components/BackHeader';
import Container from '@/components/Container';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import { fetchCandidateDetail, updateNotesDetail } from '@/services/candidate';
import { getFileNameFromUrl, removeSegmentsFromUrl } from '@/utils/common';

import EditModal from '../Candidate/components/EditModal';

import CandidateInfo from './components/CandidateInfo';
import DownloadableFileRow from './components/DownloadableFileRow';
import ResumeCard from './components/ResumeCard';

const CandidateDetail = () => {
  const staticText = strings.candidateDetails;

  const { hasSubRole } = useAuth();
  const navigate = useNavigate();
  const { setActiveChat } = useChatContext();
  const { id } = useParams<{ id: string }>();
  const {
    fromTicketScreen,
    setFromTicketScreen,
    fromApprovalScreen,
    setFromApprovalScreen,
  } = useSidebarContext();

  const {
    editableCandidateData,
    setEditableCandidateData,
    setOpenEditModal,
    openEditModal,
  } = useCandidate();

  const [isEdit, setIsEdit] = useState(false);
  const [candidateData, setCandidateData] = useState<ICandidate | null>(null);

  const { data, isLoading, refetch } = useGenericQuery(
    ['candidate-detail', id],
    () => fetchCandidateDetail(id!),
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<NotesType>({
    mode: 'all',
    defaultValues: {
      notes: (candidateData as ICandidate)?.notes || '',
    },
  });

  const updateNotesMutation = useGenericMutation<
    { id: string; notes: UpdatedNotes },
    UpdatedNotes | boolean
  >(({ id, notes }) => updateNotesDetail(id, notes), {
    onSuccess: (response) => {
      if (response) {
        refetch();
        toast.success(staticText.notesSuccess);
        setIsEdit(false);
      }
    },
  });

  const onSubmit: SubmitHandler<NotesType> = (data) => {
    if (!id) return;
    const payload = {
      notes: data.notes,
    };
    updateNotesMutation.mutate({ id, notes: payload });
  };

  const handleSaveOrChatButton = () => {
    if (isEdit) {
      handleSubmit(onSubmit)();
    } else if (candidateData) {
      // setActiveChat(null);
      navigate(ROUTES.CHAT);
      setActiveChat({ user: candidateData, ticket: null });
    }
  };

  const handleEditOrCancelButton = () => {
    if (isEdit) {
      setIsEdit(false);
      reset({ notes: (data as ICandidate).notes });
    } else {
      setIsEdit(true);
    }
  };

  const handleEditTagModalOpen = () => {
    if (candidateData) {
      setEditableCandidateData(candidateData);
      setOpenEditModal(!openEditModal);
    }
  };

  useEffect(() => {
    if (typeof data === 'object') {
      setCandidateData(data);
      reset({ notes: (data as ICandidate).notes });
    }
  }, [data]);

  const handleBackButton = () => {
    if (fromTicketScreen) {
      navigate(fromTicketScreen);
      setFromTicketScreen('');
    } else if (fromApprovalScreen) {
      navigate(fromApprovalScreen);
      setFromApprovalScreen('');
    } else {
      removeSegmentsFromUrl(navigate, location.pathname);
    }
  };

  if (!isLoading && !data) return <StateIndicator state='Error' />;
  return (
    <>
      <div className='flex flex-col sm:flex-row gap-5 sm:gap-0 justify-between mt-5'>
        <BackHeader title={staticText.title} handleBackBtn={handleBackButton} />
        {!hasSubRole ? (
          <div className='flex gap-3 justify-end sm:justify-normal'>
            <Button
              variant='outline'
              className={cn(
                'rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base',
                { 'px-2 text-mouseGrey border-mouseGrey': isEdit },
              )}
              icon={
                !isEdit ? <FiEdit size={20} /> : <IoMdCloseCircle size={20} />
              }
              onClick={handleEditOrCancelButton}
              disabled={updateNotesMutation.status === 'pending'}
            >
              {!isEdit ? staticText.edit : strings.cancel}
            </Button>
            <Button
              variant='outline'
              className='rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base'
              icon={
                !isEdit ? <BiChat size={21} /> : <FaCheckCircle size={16} />
              }
              onClick={handleSaveOrChatButton}
              disabled={
                isEdit
                  ? !isDirty || updateNotesMutation.status === 'pending'
                  : false
              }
              loading={updateNotesMutation.status === 'pending'}
            >
              {!isEdit ? staticText.chat : strings.save}
            </Button>
          </div>
        ) : null}
      </div>
      <div className='flex flex-col gap-6 mt-7'>
        <Container className='h-auto md:px-9 md:py-7'>
          <ResumeCard
            file={{
              name: isLoading
                ? ''
                : getFileNameFromUrl(candidateData?.resume || ''),
              size: candidateData?.fileSize || '-',
              url: candidateData?.resume || '',
              date: candidateData?.uploadedDate,
            }}
            isLoading={isLoading}
          />
        </Container>
        <div className='lg:grid grid-cols-3 gap-7 space-y-7 lg:space-y-0'>
          <div className='col-span-2'>
            <CandidateInfo
              data={candidateData!}
              isEdit={isEdit}
              control={control}
              isLoading={isLoading}
              handleEditTagModalOpen={handleEditTagModalOpen}
            />
          </div>
          <Container className='col-span-1'>
            <Typography variant='subheading' className='font-bold'>
              {staticText.uploadedDocument}
            </Typography>
            <div className='mt-3 flex flex-col gap-y-2'>
              {candidateData?.documents?.length ? (
                candidateData?.documents
                  ?.sort((a) => (a.url ? -1 : 1))
                  .map((item) => (
                    <DownloadableFileRow
                      key={item.name}
                      name={item.name}
                      url={item.url}
                    />
                  ))
              ) : (
                <Typography className='my-10 text-center'>
                  {staticText.noFiles}
                </Typography>
              )}
            </div>
          </Container>
        </div>
      </div>
      {openEditModal && editableCandidateData ? (
        <EditModal refetch={refetch} />
      ) : null}
    </>
  );
};

export default CandidateDetail;
