import React, { useEffect, useState } from 'react';

import Container from '@/components/Container';
import SearchBar from '@/components/SearchBar';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import useDebounce from '@/hooks/useDebounce';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { getChatList } from '@/services/chat';

import ChatAccordion from './chatAccordion';
import RecipientModal from './recipientModal';

const ChatSidebar = () => {
  const staticText = strings.chatScreen;

  const { isRecruiter, isAdmin } = useAuth();
  const { setChatList } = useChatContext();

  const [searchText, setSearchText] = useState('');
  const [openRecipientsModal, setOpenRecipientsModal] = useState(false);

  const debouncedSearchText = useDebounce(searchText);

  const { data, isLoading } = useGenericQuery(
    ['chatList', isRecruiter || isAdmin, debouncedSearchText],
    () => getChatList(isRecruiter || isAdmin, debouncedSearchText),
  );

  const toggleRecipientsModal = () => {
    setOpenRecipientsModal((prev) => !prev);
  };

  useEffect(() => {
    if (typeof data === 'object') setChatList(data);
  }, [data]);

  return (
    <>
      {/* Added openRecipientsModal because the modal is not resetting the state on
      close */}
      {openRecipientsModal ? (
        <RecipientModal
          open={openRecipientsModal}
          handleClose={toggleRecipientsModal}
          newChat
        />
      ) : null}
      <Container width='w-full lg:w-2/6' className='flex flex-col gap-4'>
        <div className='flex justify-between xs:items-center flex-col xs:flex-row'>
          <Typography variant='heading' className='md:text-2xl'>
            {staticText.chat}
          </Typography>

          {isRecruiter ? (
            <Button
              variant='link'
              className='w-fit h-auto no-underline gap-1 p-0'
              onClick={toggleRecipientsModal}
            >
              +<span className='underline'>{staticText.newChat}</span>
            </Button>
          ) : null}
        </div>
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          placeholder={staticText.sidebarSearchPlaceholder}
        />
        <div className='flex flex-col h-full gap-2 overflow-scroll scrollbarHidden'>
          <ChatAccordion loading={isLoading} />
        </div>
      </Container>
    </>
  );
};

export default ChatSidebar;
