import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { IActiveChat } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import { IChat, IChatResponse } from '@/@types/chat';

import { useSidebarContext } from './SidebarProvider';

interface IChatContext {
  activeChat: IActiveChat | null;
  setActiveChat: React.Dispatch<React.SetStateAction<IActiveChat | null>>;
  selectedRecipients: ICandidate[];
  setSelectedRecipients: React.Dispatch<React.SetStateAction<ICandidate[]>>;
  isBroadcastMessage: boolean;
  setIsBroadcastMessage: React.Dispatch<React.SetStateAction<boolean>>;
  conversationId: string;
  setConversationId: React.Dispatch<React.SetStateAction<string>>;
  chatList: IChatResponse | null;
  setChatList: React.Dispatch<React.SetStateAction<IChatResponse | null>>;
  unReadMessageCount: number;
  allChats: IChat[];
}

const ChatContext = createContext<IChatContext>({
  activeChat: null,
  setActiveChat: () => {},
  selectedRecipients: [],
  setSelectedRecipients: () => {},
  isBroadcastMessage: false,
  setIsBroadcastMessage: () => {},
  conversationId: '',
  setConversationId: () => {},
  chatList: null,
  setChatList: () => {},
  unReadMessageCount: 0,
  allChats: [],
});

interface IChatProvider {
  children: React.ReactNode;
}

export const ChatProvider = ({ children }: IChatProvider) => {
  const { currentTab } = useSidebarContext();

  const [conversationId, setConversationId] = useState('');
  const [unReadMessageCount, setUnReadMessageCount] = useState(0);
  const [isBroadcastMessage, setIsBroadcastMessage] = useState(false);
  const [chatList, setChatList] = useState<IChatResponse | null>(null);
  const [activeChat, setActiveChat] = useState<IActiveChat | null>(null);
  const [selectedRecipients, setSelectedRecipients] = useState<ICandidate[]>(
    [],
  );

  const allChats = useMemo(() => {
    const conversations =
      chatList?.tickets?.flatMap((ticket) => ticket.conversations) || [];
    return [...(chatList?.others || []), ...conversations];
  }, [JSON.stringify(chatList)]);

  const hasUnreadMessages = () => {
    if (!chatList) return;

    const totalUnreadCount = allChats.reduce(
      (acc, message) => acc + (message.unreadCount || 0),
      0,
    );
    setUnReadMessageCount(totalUnreadCount);
  };

  useEffect(() => {
    if (currentTab !== 'chat') {
      setActiveChat(null);
      setConversationId('');
    }
  }, [currentTab]);

  useEffect(() => {
    hasUnreadMessages();
  }, [chatList]);

  const value = {
    activeChat,
    setActiveChat,
    selectedRecipients,
    setSelectedRecipients,
    isBroadcastMessage,
    setIsBroadcastMessage,
    conversationId,
    setConversationId,
    chatList,
    setChatList,
    unReadMessageCount,
    allChats,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => useContext(ChatContext);
