/* eslint-disable max-lines */
import { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IStatisticValues } from '@/@types/dashboard';
import Container from '@/components/Container';
import ReactDropdown from '@/components/Dropdown';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import {
  barsWidthPercentages,
  // STATISTICS_DATA_KEY_NAME,
  STATISTICS_VALUES,
} from '@/constants/dashboard';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { dashboardStatistics } from '@/services/dashboard';

import StatisticsTooltip from './StatisticsTooltip';
interface YAxisTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}
interface Props {
  cardDisplay?: boolean;
  // statisticsDataKeys?: IStatistics;
}
const StatisticsChart: React.FC<Props> = ({
  cardDisplay,
  // statisticsDataKeys,
}) => {
  const staticText = strings.dashboard;

  const {
    companies,
    loadingCompanies,
    setCompanyPage,
    setCompanySearch,
    haveMoreCompanyPage,
  } = useCommonDataContext();
  const [companyName, setCompanyName] = useState(companies[0] ?? '');
  // const [filteredData, setFilteredData] = useState<
  //   IStatisticValues[] | undefined
  // >(undefined);

  const { data: statisticsData, isLoading } = useGenericQuery(
    ['dashboardStatistics', companyName],
    () => dashboardStatistics(companyName),
  );

  // When data or statisticsDataKeys changes, recalculate and set the filtered data
  // useEffect(() => {
  //   if (statisticsData && statisticsDataKeys) {
  //     const filtered = (filteredData as IStatisticValues[])?.filter((item) =>
  //       Object.entries(statisticsDataKeys).some(
  //         ([key, value]) =>
  //           STATISTICS_DATA_KEY_NAME[key] === item.name && value === true,
  //       ),
  //     );
  //     setFilteredData(filtered);
  //   }
  // }, [statisticsData, statisticsDataKeys]);

  // const filteredStatisticsData = (
  //   (statisticsData || []) as IStatisticValues[]
  // )?.filter((item) => {
  //   return Object.entries(statisticsDataKeys || {}).some(
  //     ([key, value]) =>
  //       STATISTICS_DATA_KEY_NAME[key] === item.name && value === true,
  //   );
  // });

  return (
    <Container
      className={cn({
        'p-2 md:p-2 pointer-events-none select-none': cardDisplay,
      })}
    >
      <div
        className={cn('xs:flex justify-between items-center', {
          'mb-6': !cardDisplay,
        })}
      >
        <div className='flex flex-col'>
          <Typography
            variant='subheading'
            className={cn('text-lg font-bold', {
              'text-[10px] md:text-[10px]': cardDisplay,
            })}
          >
            {staticText.statistics}
          </Typography>
          <Typography
            className={cn('text-sm md:text-sm', {
              hidden: cardDisplay,
            })}
          >
            {staticText.statisticsSectionDesc}
          </Typography>
        </div>
        {cardDisplay ? (
          <div className='border border-greyWhite w-16 opacity-70 px-1 h-4 justify-between text-[8px] rounded-md flex items-center mb-1'>
            {staticText.filterBy}
            <ChevronDown className='text-primaryBlack ml-0.5' size={8} />
          </div>
        ) : (
          <div className=' justify-end xs:justify-start flex xs:inline'>
            <ReactDropdown
              options={companies.map((company) => ({
                value: company,
                label: company,
              }))}
              value={companyName}
              onChange={(selectedOption) => {
                setCompanyName(selectedOption as string);
              }}
              name='companyFilter'
              placeholder={staticText.filterBy}
              className='bg-white text-black w-48'
              inputWidth='100%'
              inputHeight='36px'
              borderRadius='12px'
              isLoading={loadingCompanies || false}
              setPage={setCompanyPage}
              haveMoreOptions={haveMoreCompanyPage || false}
              setInputSearch={setCompanySearch}
              isDisabled={isLoading}
            />
          </div>
        )}
      </div>
      <div className={cn('h-80', { 'card h-32': cardDisplay })}>
        {isLoading && !cardDisplay ? (
          // loading skeleton
          <div className='relative mt-10'>
            <div className='w-full grid grid-cols-7 items-center absolute'>
              <div className='w-20 h-full col-span-2' />
              <div className='flex justify-between col-span-5 w-[98%]'>
                {[...Array(7)].map((_, index) => (
                  <div
                    key={index}
                    className='border border-dashed w-[1px] h-64'
                  />
                ))}
              </div>
            </div>
            <div className='w-full space-y-6 h-64'>
              {barsWidthPercentages.map((widthPercentage, index) => (
                <div
                  key={index}
                  className='w-full grid grid-cols-7 items-center'
                >
                  <Skeleton className='h-5 w-20 col-span-2' />
                  <Skeleton
                    className='h-[27px]  col-span-5'
                    style={{ width: widthPercentage }}
                  />
                </div>
              ))}
            </div>
            <div className='grid grid-cols-7 mt-5 relative'>
              <div className='col-span-2 h-full' />
              <div className='col-span-5 flex justify-between'>
                {[...Array(5)].map((_, index) => (
                  <Skeleton className='h-4 w-12' key={index} />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <ResponsiveContainer width='100%'>
            {/* filteredStatisticsData willbe used if it have seprate keys */}
            {!cardDisplay && !(statisticsData as IStatisticValues[])?.length ? (
              <div className='w-full h-full flex justify-center items-center'>
                {strings.common.noDataAvailable}
              </div>
            ) : (
              <BarChart
                data={
                  cardDisplay
                    ? STATISTICS_VALUES
                    : (statisticsData as IStatisticValues[])
                }
                layout='vertical'
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid
                  horizontal={false}
                  strokeDasharray='3 3'
                  stroke='#E4E6E9'
                />
                <XAxis
                  type='number'
                  domain={[0, 100]}
                  tickCount={6}
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#000000',
                    fontSize: window.innerWidth < 640 || cardDisplay ? 6 : 14,
                  }}
                  tickFormatter={(tick) => `${tick}${staticText.percentSign}`}
                />
                <YAxis
                  dataKey='name'
                  type='category'
                  width={cardDisplay ? 65 : 160}
                  axisLine={false}
                  tickLine={false}
                  interval={0}
                  tickMargin={cardDisplay ? 5 : 20}
                  tick={({ x, y, payload }: YAxisTickProps) => (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={cardDisplay ? -70 : -150}
                        y={0}
                        dy={4}
                        textAnchor='start'
                        fill='#000000'
                        className={cn('text-xs md:text-base font-semibold', {
                          'text-[6px] md:text-[6px]': cardDisplay,
                        })}
                      >
                        {payload.value}
                      </text>
                    </g>
                  )}
                />
                <Tooltip
                  content={<StatisticsTooltip />}
                  cursor={false}
                  position={{ x: 0, y: 0 }}
                />
                <Bar
                  dataKey='count'
                  fill='url(#colorGradient)'
                  barSize={cardDisplay ? 8 : 27}
                  radius={cardDisplay ? 2 : 4}
                  className='cursor-pointer'
                />
                <defs>
                  <linearGradient
                    id='colorGradient'
                    x1='0'
                    y1='0'
                    x2='0'
                    y2='1'
                  >
                    <stop offset='0%' stopColor='#6BC1D7' />
                    <stop offset='100%' stopColor='#9AD9E8' />
                  </linearGradient>
                </defs>
              </BarChart>
            )}
          </ResponsiveContainer>
        )}
      </div>
    </Container>
  );
};

export default StatisticsChart;
