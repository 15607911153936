import * as React from 'react';
import { SVGProps } from 'react';
const CandidateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20.5024 25.2195C19.4391 25.5337 18.1824 25.6787 16.7082 25.6787H9.45823C7.98406 25.6787 6.7274 25.5337 5.66406 25.2195C5.9299 22.0778 9.15615 19.6007 13.0832 19.6007C17.0103 19.6007 20.2366 22.0778 20.5024 25.2195Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.7083 1.51196H9.45833C3.41667 1.51196 1 3.92863 1 9.9703V17.2203C1 21.7878 2.3775 24.289 5.66417 25.2195C5.93 22.0778 9.15625 19.6007 13.0833 19.6007C17.0104 19.6007 20.2367 22.0778 20.5025 25.2195C23.7892 24.289 25.1667 21.7878 25.1667 17.2203V9.9703C25.1667 3.92863 22.75 1.51196 16.7083 1.51196ZM13.0833 16.2174C10.6908 16.2174 8.7575 14.272 8.7575 11.8795C8.7575 9.48698 10.6908 7.55363 13.0833 7.55363C15.4758 7.55363 17.4092 9.48698 17.4092 11.8795C17.4092 14.272 15.4758 16.2174 13.0833 16.2174Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.4095 11.8796C17.4095 14.2721 15.4761 16.2174 13.0836 16.2174C10.6911 16.2174 8.75781 14.2721 8.75781 11.8796C8.75781 9.48706 10.6911 7.55371 13.0836 7.55371C15.4761 7.55371 17.4095 9.48706 17.4095 11.8796Z'
      stroke={props.color || '#1B1A1B'}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export default CandidateIcon;
