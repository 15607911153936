import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import {
  FetchApprovalsParams,
  IApprovalPayload,
  IApprovals,
} from '@/@types/approvals';
import { IPersonnelActionFormData } from '@/@types/candidate';
import { API } from '@/api/ApiInstance';
import { SUB_ROLE } from '@/constants';
import { APPROVAL_TABS } from '@/constants/approval';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';

export const fetchApprovals = async (
  params: FetchApprovalsParams,
): Promise<IPaginationResponse<IApprovals> | boolean> => {
  const url = () => {
    if (params.tab === APPROVAL_TABS.PENDING) return REST_SUB_URL.GET_APPROVALS;
    else if (params.subRole === SUB_ROLE.ACCOUNT_OFFICER)
      return REST_SUB_URL.GET_ACCOUNT_OFFICER_CLOSED_APPROVALS;
    return REST_SUB_URL.GET_RECRUITMENT_MANAGER_CLOSED_APPROVALS;
  };
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IPaginationResponse<IApprovals>>(
    `${url()}?${queryString}`,
  );

  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const accountOfficerApproval = async (
  payload: IApprovalPayload,
): Promise<string | boolean> => {
  const response = await API.Patch<IApprovalPayload, string>(
    REST_SUB_URL.ACCOUNT_OFFICER_APPROVAL,
    payload,
  );
  if (response.status) {
    return response.message;
  }
  toast.error(response.message);
  return false;
};

export const recruiterManagerApproval = async (
  payload: IApprovalPayload,
): Promise<string | boolean> => {
  const response = await API.Patch<IApprovalPayload, string>(
    REST_SUB_URL.RECRUITMENT_MANAGER_APPROVAL,
    payload,
  );
  if (response.status) {
    return response.message;
  }
  toast.error(response.message);
  return false;
};

export const getPanFormById = async (
  id: string,
): Promise<IPersonnelActionFormData | boolean> => {
  const response = await API.Get<IPersonnelActionFormData>(
    `${REST_SUB_URL.GET_APPROVALS}/${id}`,
  );

  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};
