import React, {
  Dispatch,
  HTMLInputTypeAttribute,
  SetStateAction,
  useState,
} from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FieldType, IDropdownOption } from '@/@types';
import DatePickerField from '@/components/DatePickerField';
import ReactDropdown from '@/components/Dropdown';
import FormField from '@/components/FormField';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { strings } from '@/locales';

import { CreateTicketType } from '..';

export interface IFieldSelectorProps {
  name: string;
  control: Control<CreateTicketType>;
  errors: FieldErrors;
  options?: string[] | IDropdownOption[];
  inputType?: FieldType;
  isRequired?: boolean;
  type?: HTMLInputTypeAttribute | undefined;
  loading?: boolean;
  setPage?: Dispatch<SetStateAction<number>>;
  setInputSearch?: React.Dispatch<React.SetStateAction<string>>;
  saveBothLabelAndValue?: boolean;
  disabled?: boolean;
  haveMoreOptions?: boolean;
  tooltipText?: string;
  creatable?: boolean;
  placeholder?: string;
}

const FieldSelector: React.FC<IFieldSelectorProps> = ({
  options,
  name,
  control,
  errors,
  inputType = 'Input',
  isRequired = false,
  type = 'text',
  loading = false,
  setPage,
  haveMoreOptions,
  setInputSearch,
  saveBothLabelAndValue = false,
  disabled,
  tooltipText,
  creatable,
  placeholder,
}) => {
  const { ticketDetailScreen: staticText } = strings;
  const [showTooltip, setShowTooltip] = useState(false);

  const handleDropdownChange = (
    newValue: string | IDropdownOption,
    onChange: (event: string | IDropdownOption) => void,
  ) => {
    onChange(newValue);
    if (tooltipText) {
      const isTooltipVisible =
        !!tooltipText &&
        !!(
          options?.find(
            (option) =>
              (option as IDropdownOption).value ===
              (newValue as IDropdownOption).value,
          ) as IDropdownOption
        )?.extra;

      setShowTooltip(isTooltipVisible);
    }
  };

  return (
    <div className='flex flex-col gap-1 min-w-full sm:min-w-[47%] lg:min-w-[30%]'>
      <div className='flex justify-between items-center'>
        <Typography className='font-extrabold md:text-[15px]'>
          {staticText[name as keyof typeof staticText] as string}
          {isRequired && <span className='text-redColor text-xl ml-1'>*</span>}
        </Typography>
        {showTooltip ? (
          <TooltipComponent
            align='center'
            className='py-2 px-4 w-52 sm:w-60 lg:w-80 text-xs'
          >
            {tooltipText}
          </TooltipComponent>
        ) : null}
      </div>
      <Controller
        control={control}
        name={name as keyof CreateTicketType}
        render={({ field: { onChange, value, name } }) => {
          switch (inputType) {
            case FieldType.DatePicker:
              return (
                <DatePickerField
                  value={value as Date}
                  setValue={onChange}
                  contanierClassName='w-full'
                  name={name}
                  errors={errors}
                />
              );
            case FieldType.Dropdown:
              return (
                <ReactDropdown
                  options={options || []}
                  value={value as string}
                  onChange={(e) => handleDropdownChange(e, onChange)}
                  errors={errors}
                  isLoading={loading}
                  name={name}
                  isSearchable
                  className='w-full mt-2 h-12'
                  inputHeight='42px'
                  borderRadius='12px'
                  setPage={setPage}
                  haveMoreOptions={haveMoreOptions}
                  setInputSearch={setInputSearch}
                  saveBothLabelAndValue={saveBothLabelAndValue}
                  isDisabled={disabled}
                  tagsDropdown={name === 'status'}
                  creatable={creatable}
                  placeholder={placeholder}
                />
              );
            case FieldType.RadioGroup:
              return (
                <RadioButtonGroup
                  name={name}
                  value={value as string}
                  onChange={onChange}
                  options={options as string[]}
                  errors={errors}
                />
              );
            default:
              return (
                <FormField
                  type={type}
                  name={name}
                  value={value as string | number}
                  onChange={(e) =>
                    onChange(
                      type === 'number'
                        ? Number(e.target.value)
                        : e.target.value,
                    )
                  }
                  errors={errors}
                  containerClassName='text-sm placeholder:text-sm font-semibold placeholder:text-quickSilver w-full rounded-xl h-[42px]'
                  disabled={disabled}
                />
              );
          }
        }}
      />
    </div>
  );
};

export default FieldSelector;
