/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import {
  IoIosCloseCircleOutline,
  IoMdCheckmarkCircleOutline,
} from 'react-icons/io';
import { MdPrint } from 'react-icons/md';
import { useReactToPrint, UseReactToPrintOptions } from 'react-to-print';
import { UseReactToPrintHookContent } from 'react-to-print/lib/types/UseReactToPrintHookContent';

import { IPersonnelActionFormData } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import {
  ALLOWANCE,
  createPanFormConstantData,
  getPersonnelActionFormHistoryData,
  HIRE_TYPE,
  RATE,
} from '@/constants/candidate';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useCandidate } from '@/provider/CandidateProvider';
import { fetchPanFormDetail } from '@/services/candidate';
import { EnumToArray } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { JSONParse } from '@/utils/jsonparse';

import FormConstantDataSection from './FormConstantDataSection';

interface IPersonnelActionHistory {
  data?: IPersonnelActionFormData;
  info: { ticketRate: number; author: string; typeOfEmployment: string };
  isLoading?: boolean;
  candidateId: string;
  ticketId: string;
  mrfNumber?: string;
  job: string;
  inFormView?: boolean;
}

interface ICheckable {
  isChecked?: boolean;
  label?: string;
  isLoading?: boolean;
  title?: string;
  type?: string;
  description?: string;
  conditionalContent?: React.ReactNode;
}

const CheckableItem: React.FC<ICheckable> = ({
  isChecked,
  label,
  isLoading,
  conditionalContent,
}) => (
  <div className='flex gap-1 items-center'>
    {isLoading ? (
      <Skeleton className='w-4 h-4' />
    ) : (
      <>
        {isChecked ? (
          <IoMdCheckmarkCircleOutline className='text-sm w-4 mt-1 text-greenRevolution' />
        ) : (
          <IoIosCloseCircleOutline className='text-sm w-4 mt-1 text-tomatoRed' />
        )}
      </>
    )}
    <Typography className='md:text-[15px]'>
      {label}
      {conditionalContent ? ' - ' : ''}
    </Typography>
    {conditionalContent}
  </div>
);

const PersonnelActionHistory: React.FC<IPersonnelActionHistory> = ({
  info,
  data,
  isLoading,
  candidateId,
  ticketId,
  job,
  inFormView,
  mrfNumber,
}) => {
  const staticText = strings.candidateScreen.personnelActionForm;
  const contentRef = useRef<HTMLDivElement>(null);
  const { editableCandidateData } = useCandidate();

  const [panFormItems, setPanFormItems] = useState<ICheckable[] | null>(null);

  const { data: panFormDetail, isLoading: panFormDetailLoading } =
    useGenericQuery(
      ['personnel-action-form-history', candidateId, ticketId],
      () =>
        fetchPanFormDetail({
          candidate: candidateId!,
          ticket: ticketId,
        }),
      {
        enabled: !data && !!candidateId,
      },
    );

  const handlePrint = useReactToPrint({
    contentRef,
  } as UseReactToPrintOptions);

  const employmentDetails = JSONParse(info.typeOfEmployment) || {};
  const [employmentTitle] = Object.entries(employmentDetails)[0] || [];
  const candidateLocation = [
    editableCandidateData?.city,
    editableCandidateData?.country,
  ]
    .filter(Boolean)
    .join(', ');

  const PAN_FORM_CONSTANT_DATA = createPanFormConstantData({
    rate: info.ticketRate,
    client: info.author,
    typeOfEmployment: employmentTitle || '',
    locationOrArea: candidateLocation,
    fillingDate: panFormDetail?.createdAt,
  });

  useEffect(() => {
    if (data) {
      setPanFormItems(getPersonnelActionFormHistoryData(data));
    } else if (panFormDetail) {
      setPanFormItems(getPersonnelActionFormHistoryData(panFormDetail));
    }
  }, [data, panFormDetail, isLoading, panFormDetailLoading]);

  return (
    <div className={cn({ 'mt-6': inFormView })}>
      <Button
        onClick={handlePrint as UseReactToPrintHookContent}
        size='icon'
        variant='link'
        icon={<MdPrint className='text-primaryBlack size-5' />}
        aria-label='print'
        className={cn('absolute z-50 top-20 right-5', { 'top-16': inFormView })}
      />
      <div
        ref={contentRef}
        className={cn('print-content', {
          'max-h-[60vh] overflow-y-auto': !inFormView,
        })}
      >
        <Typography className='print-heading block font-bold text-xl pb-12'>
          {staticText.title}
        </Typography>
        <FormConstantDataSection mrfNumber={mrfNumber || ''} job={job || ''} />
        {PAN_FORM_CONSTANT_DATA?.map((item) => (
          <div key={item.label}>
            <NameAndDesc
              title={item.label}
              description={item.value}
              className='space-y-0 flex gap-1'
              avoidTruncate
            />
          </div>
        ))}
        <div>
          <NameAndDesc
            title={`${staticText.startDate}:`}
            description={getLocalDate(
              (data?.startDate || panFormDetail?.startDate) as string,
            )}
            isLoading={isLoading || panFormDetailLoading}
            className='flex space-y-0 gap-1'
          />
          <NameAndDesc
            title={`${staticText.endDate}:`}
            description={getLocalDate(
              (data?.endDate || panFormDetail?.endDate) as string,
            )}
            isLoading={isLoading || panFormDetailLoading}
            className='flex space-y-0 gap-1'
          />
        </div>
        <div className='mt-3'>
          <Typography className='block font-bold md:text-[15px]'>
            {staticText.rate}
          </Typography>
          <div>
            {panFormItems?.map((detail, index) => (
              <NameAndDesc
                key={index}
                title={detail.title || ''}
                description={detail.description}
                isLoading={isLoading || panFormDetailLoading}
                className='flex items-center space-y-0 gap-1 font-medium'
                titleClassName='font-semibold'
              />
            ))}
          </div>
          <div className='lg:grid grid-cols-2 gap-3'>
            <div>
              {EnumToArray(RATE).map((item, index) => (
                <CheckableItem
                  key={index}
                  isChecked={
                    data?.rateType === item || panFormDetail?.rateType === item
                  }
                  label={item}
                  isLoading={isLoading || panFormDetailLoading}
                  conditionalContent={
                    (data?.dailyRate || panFormDetail?.dailyRate) &&
                    item === RATE.DAILY_RATE ? (
                      <Typography className='md:text-[15px] underline'>
                        {data?.dailyRate || panFormDetail?.dailyRate}
                      </Typography>
                    ) : null
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div className='mt-3'>
          <Typography className='block font-bold md:text-[15px]'>
            {staticText.allowance}
          </Typography>
          {EnumToArray(ALLOWANCE).map((item, index) => (
            <CheckableItem
              key={index}
              isChecked={
                data?.allowance === item || panFormDetail?.allowance === item
              }
              label={item}
              isLoading={isLoading || panFormDetailLoading}
              conditionalContent={
                (data?.otherValue || panFormDetail?.otherValue) &&
                item === ALLOWANCE.OTHER ? (
                  <Typography className='md:text-[15px] underline'>
                    {data?.otherValue || panFormDetail?.otherValue}
                  </Typography>
                ) : null
              }
            />
          ))}
        </div>
        <div className='mt-3'>
          <Typography className='block font-bold md:text-[15px]'>
            {staticText.hireType}
          </Typography>
          {EnumToArray(HIRE_TYPE).map((item, index) => (
            <CheckableItem
              key={index}
              isChecked={
                data?.hireType === item || panFormDetail?.hireType === item
              }
              label={item}
              isLoading={isLoading || panFormDetailLoading}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PersonnelActionHistory;
