/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormResetField,
} from 'react-hook-form';

import { FieldType } from '@/@types';
import {
  ICandidateTag,
  IPersonnelActionFormData,
  ITag,
} from '@/@types/candidate';
import DatePickerField from '@/components/DatePickerField';
import FormField from '@/components/FormField';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import { Typography } from '@/components/Typography';
import { INPUT_ERRORS } from '@/constants';
import {
  ALLOWANCE,
  DAILY_RATE_OPTIONS,
  HIRE_TYPE,
  PAN_FIELDS,
  RATE,
} from '@/constants/candidate';
import { strings } from '@/locales';
import { EnumToArray, GetFormattedName } from '@/utils/common';

import PersonnelActionHistory from './PersonnelActionFormHistorySection';

interface IPersonnelActionForm {
  control: Control<IPersonnelActionFormData>;
  error: FieldErrors<IPersonnelActionFormData>;
  resetField: UseFormResetField<IPersonnelActionFormData>;
  data?: ITag | ICandidateTag;
}

const PersonnelActionForm: React.FC<IPersonnelActionForm> = ({
  control,
  error,
  resetField,
  data,
}) => {
  const staticText = strings.candidateScreen.personnelActionForm;

  const rateType = control._getWatch('rateType');
  const allowance = control._getWatch('allowance');

  useEffect(() => {
    if (control._getWatch('dailyRate')) {
      resetField('dailyRate');
    }
  }, [rateType]);

  useEffect(() => {
    if (control._getWatch('otherValue')) {
      resetField('otherValue');
    }
  }, [allowance]);

  return (
    <div>
      {data?.isPanForm ? (
        <PersonnelActionHistory
          candidateId={
            (typeof data?.candidate === 'object'
              ? (data?.candidate as { _id: string })?._id
              : data?.candidate) || ''
          }
          ticketId={data?.ticket as string}
          info={{
            ticketRate:
              (data as ITag)?.ticketRate ||
              (data as ICandidateTag)?.ticket?.rate ||
              0,
            author: GetFormattedName(
              (data as ITag)?.author || (data as ICandidateTag)?.ticket?.author,
            ),
            typeOfEmployment:
              (data as ITag)?.typeOfEmployment ||
              (data as ICandidateTag)?.ticket?.typeOfEmployment,
          }}
          inFormView
          job={
            (data as ITag)?.jobPosition ||
            (data as ICandidateTag)?.ticket?.jobTitle
          }
          mrfNumber={String(
            (data as ITag)?.ticketNumber ||
              (data as ICandidateTag)?.ticket?.ticketNumber ||
              0,
          )}
        />
      ) : (
        <>
          <div className='xs:grid xs:grid-cols-2 gap-4 space-y-4 xs:space-y-0'>
            {PAN_FIELDS(
              control._getWatch('startDate'),
              control._getWatch('endDate'),
            )?.map((field, index) => (
              <div key={`field-${index}`}>
                <Typography className='font-extrabold md:text-[15px]'>
                  {staticText[field.name as keyof typeof staticText]}
                  <span className='text-redColor text-xl ml-1'>*</span>
                </Typography>
                <Controller
                  control={control}
                  name={field.name as keyof IPersonnelActionFormData}
                  rules={{
                    required: INPUT_ERRORS.REQUIRED.required,
                    ...field.rules,
                  }}
                  render={({ field: { onChange, value, name } }) => {
                    switch (field.inputType) {
                      case FieldType.DatePicker: {
                        return (
                          <DatePickerField
                            value={value as Date}
                            setValue={onChange}
                            contanierClassName='w-full rounded-md h-10'
                            fromDate={
                              name === 'startDate'
                                ? new Date()
                                : control._getWatch('startDate')
                            }
                            name={name}
                            errors={error}
                          />
                        );
                      }
                      default:
                        return (
                          <FormField
                            {...field}
                            className='w-full'
                            labelClassName='md:text-[8px]'
                            onChange={onChange}
                            errors={error}
                            value={value as string}
                          />
                        );
                    }
                  }}
                />
              </div>
            ))}
          </div>
          <div className='xs:grid xs:grid-cols-2 gap-4 space-y-4 xs:space-y-0 my-4'>
            <div>
              <Typography className='font-extrabold md:text-[15px]'>
                {staticText.hireType}
                <span className='text-redColor text-xl ml-1'>*</span>
              </Typography>
              <Controller
                control={control}
                name='hireType'
                rules={{ required: INPUT_ERRORS.REQUIRED.required }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <RadioButtonGroup
                      name={name}
                      options={EnumToArray(HIRE_TYPE)}
                      errors={error}
                      value={value}
                      onChange={onChange}
                      containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                    />
                  );
                }}
              />
            </div>
            <div>
              <Typography className='font-extrabold md:text-[15px]'>
                {staticText.allowance}
                <span className='text-redColor text-xl ml-1'>*</span>
              </Typography>
              <Controller
                control={control}
                name='allowance'
                rules={{ required: INPUT_ERRORS.REQUIRED.required }}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <RadioButtonGroup
                      name={name}
                      options={EnumToArray(ALLOWANCE)}
                      errors={error}
                      value={value}
                      onChange={onChange}
                      containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                      contentClassName='flex w-full bg-yello-400'
                      conditionalContent={(option) => {
                        const isOtherSelected =
                          option === ALLOWANCE.OTHER &&
                          control._getWatch('allowance') === ALLOWANCE.OTHER;
                        return isOtherSelected ? (
                          <Controller
                            control={control}
                            name='otherValue'
                            rules={
                              isOtherSelected
                                ? {
                                    required: INPUT_ERRORS.REQUIRED.required,
                                  }
                                : {}
                            }
                            render={({ field: { name, value, onChange } }) => (
                              <FormField
                                name={name}
                                className='h-4 border-0 border-b rounded-none border-quickSilver/50 px-1'
                                onChange={onChange}
                                errors={error}
                                value={value}
                              />
                            )}
                          />
                        ) : null;
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div>
            <Typography className='font-extrabold md:text-[15px]'>
              {staticText.rateType}
              <span className='text-redColor text-xl ml-1'>*</span>
            </Typography>
            <Controller
              control={control}
              name='rateType'
              rules={{ required: INPUT_ERRORS.REQUIRED.required }}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <RadioButtonGroup
                    name={name}
                    options={EnumToArray(RATE)}
                    errors={error}
                    value={value}
                    onChange={onChange}
                    containerClassName='whitespace-nowrap flex h-fit flex-col gap-2 mt-1'
                    conditionalContent={(option) => {
                      const isDailyRateSelected =
                        option === RATE.DAILY_RATE &&
                        control._getWatch('rateType') === RATE.DAILY_RATE;
                      return isDailyRateSelected ? (
                        <Controller
                          control={control}
                          name='dailyRate'
                          rules={
                            isDailyRateSelected
                              ? {
                                  required: INPUT_ERRORS.REQUIRED.required,
                                }
                              : {}
                          }
                          render={({ field: { name, value, onChange } }) => (
                            <RadioButtonGroup
                              errors={error}
                              name={name}
                              value={value!}
                              onChange={onChange}
                              options={EnumToArray(DAILY_RATE_OPTIONS)}
                              containerClassName='pl-10 flex flex-col gap-4 h-full mt-2'
                            />
                          )}
                        />
                      ) : null;
                    }}
                  />
                );
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PersonnelActionForm;
