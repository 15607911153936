import React from 'react';

interface RatingReviewProps {
  rating: number;
  setRating?: (rating: number) => void;
  size?: string;
}

const RatingReview: React.FC<RatingReviewProps> = ({
  rating,
  setRating,
  size = '35px',
}) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => {
        return (
          <span
            key={star}
            className='star'
            style={{
              cursor: 'pointer',
              color: rating >= star ? 'gold' : 'gray',
              fontSize: size,
            }}
            onClick={() => {
              setRating?.(star);
            }}
          >
            {' '}
            ★{' '}
          </span>
        );
      })}
    </div>
  );
};

export default RatingReview;
