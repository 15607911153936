/* eslint-disable max-lines */
import React, { useMemo, useState } from 'react';
import { GoBell } from 'react-icons/go';
import { PiChecksBold } from 'react-icons/pi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath, useNavigate } from 'react-router-dom';

import { IPaginationResponse } from '@/@types';
import { INotification } from '@/@types/notification';
import CircularLoader from '@/components/ClipLoader';
import Container from '@/components/Container';
import StateIndicator from '@/components/StateIndicator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { APPROVAL_TABS } from '@/constants/approval';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import {
  NOTIFICATION_TABS,
  NOTIFICATIONS_ACTION,
  NotificationTabsList,
} from '@/constants/notifications';
import { REQUEST_TABS } from '@/constants/request';
import { TICKET_CANDIDATES_TABS, TICKET_TABS } from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  getNotifications,
  markAllNotificationsRead,
  markNotificationRead,
} from '@/services/notifications';
import {
  InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';

import NotificationItem from './NotificationItem';

const Notifications = () => {
  const staticText = strings.notificationScreen;
  const isSmallScreen = window.innerWidth < 768;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isCompanyClient, isAdmin } = useAuth();
  const { setShowNotifications, unreadNotificationsCount } =
    useSidebarContext();

  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.TAB_ALL);

  const { data, fetchNextPage, hasNextPage, isLoading, isRefetching } =
    useInfiniteQuery({
      queryKey: ['getNotifications', activeTab, unreadNotificationsCount],
      queryFn: ({ pageParam = 1 }) =>
        getNotifications(activeTab, pageParam.toString()),
      initialPageParam: 1,
      enabled: !!activeTab,
      getNextPageParam: (data, pages) => {
        if (typeof data === 'object' && pages.length < data.totalPages) {
          return pages.length + 1;
        }
        return undefined;
      },
    });

  const markNotificationReadMutation = useGenericMutation<string, boolean>(
    markNotificationRead,
    {
      onSuccess: (response, variables) => {
        if (response) {
          const notificationId = variables;

          queryClient.setQueryData(
            ['getNotifications', activeTab, unreadNotificationsCount],
            (oldData: InfiniteData<IPaginationResponse<INotification>>) => {
              if (!oldData?.pages) return oldData;
              const updatedPages = oldData.pages.map((page) => ({
                ...page,
                results: page.results.map((notification) => {
                  if (notification?._id === notificationId)
                    return activeTab === NOTIFICATION_TABS.TAB_ALL
                      ? { ...notification, seen: true }
                      : false;
                  return notification;
                }),
              }));

              return {
                ...oldData,
                pages: updatedPages,
              };
            },
          );
        }
      },
    },
  );

  const markAllNotificationsReadMutation = useGenericMutation<null, boolean>(
    markAllNotificationsRead,
    {
      onSuccess: (response) => {
        if (response) {
          queryClient.setQueryData(
            ['getNotifications', activeTab, unreadNotificationsCount],
            (oldData: InfiniteData<IPaginationResponse<INotification>>) => {
              if (!oldData?.pages) return oldData;

              const updatedPages = oldData.pages.map((page) => ({
                ...page,
                results: page.results.map((notification) => ({
                  ...notification,
                  seen: true,
                })),
              }));

              return activeTab === NOTIFICATION_TABS.TAB_ALL
                ? {
                    ...oldData,
                    pages: updatedPages,
                  }
                : {
                    pageParams: [1],
                    pages: [{ results: [], page: 1 }],
                  };
            },
          );
        }
      },
    },
  );

  const handleNotificationClick = (notification: INotification) => {
    const { _id: notificationId, action, message, extra } = notification;
    markNotificationReadMutation.mutate(notificationId);

    switch (action) {
      case NOTIFICATIONS_ACTION.CREATE_TICKET:
      case NOTIFICATIONS_ACTION.CLOSE_TICKET:
      case NOTIFICATIONS_ACTION.ASSIGN_TICKET:
        {
          if (extra)
            if (isAdmin) {
              navigate(`${ROUTES.TICKETS}/${JSON.parse(extra).ticketId}`);
            } else {
              const path = generatePath(
                isCompanyClient
                  ? ROUTES.TICKET_DETAIL
                  : ROUTES.TICKET_DETAIL_WITH_TAB,
                {
                  tab: TICKET_TABS.MY_TICKETS,
                  ticketId: JSON.parse(extra).ticketId,
                  ...(isCompanyClient
                    ? {}
                    : { innerTab: TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES }),
                },
              );
              navigate(path);
            }
          setShowNotifications(false);
        }

        break;
      case NOTIFICATIONS_ACTION.STATUS_UPDATE:
        {
          if (isCompanyClient) {
            const status = message
              .split(' ')
              [message.split(' ').length - 1]?.split('.')[0];

            const path = generatePath(ROUTES.REQUEST, {
              tab:
                status === CANDIDATE_STATUS.ENDORSED
                  ? REQUEST_TABS.ENDORSED
                  : REQUEST_TABS.CLOSURE,
            });
            setShowNotifications(false);
            navigate(path);
          }
        }
        break;
      case NOTIFICATIONS_ACTION.PAN_FORM:
        {
          if (isAdmin) {
            const path = generatePath(ROUTES.APPROVALS, {
              tab: APPROVAL_TABS.PENDING,
            });
            setShowNotifications(false);
            navigate(path);
          }
        }
        break;

      default:
        break;
    }
  };

  const notifications = useMemo(() => {
    return (
      data?.pages
        .filter(Boolean)
        .flatMap((page) => (page as IPaginationResponse<INotification>).results)
        .filter(Boolean) || []
    );
  }, [data]);

  const Content = () => {
    if (isLoading || isRefetching) return <StateIndicator state='Loading' />;
    if (!notifications?.length)
      return (
        <StateIndicator
          state='Empty'
          noDataMessage={staticText.noNotifications}
        />
      );
    return (
      <div id='scrollableDiv' className='h-full overflow-y-auto'>
        <InfiniteScroll
          dataLength={notifications?.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          loader={<CircularLoader />}
          scrollableTarget='scrollableDiv'
        >
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification?._id}
              notification={notification}
              onClick={() => handleNotificationClick(notification)}
            />
          ))}
        </InfiniteScroll>
      </div>
    );
  };

  return (
    <Container
      className={cn('p-0 md:p-0 flex flex-col overflow-hidden', {
        'w-[420px] h-[600px] shadow-lg': !isSmallScreen,
        'max-h-[calc(100vh-8rem)]': !isSmallScreen && window.innerHeight < 700,
        'border-0 w-auto': isSmallScreen,
      })}
    >
      <div className='p-4 flex gap-2 items-center'>
        <GoBell className='text-primaryBlack' />
        <Typography variant='subheading' className='font-bold md:text-sm'>
          {staticText.notifications}
        </Typography>
      </div>
      <>
        <div className='flex items-center gap-6 mx-4 mb-4'>
          {NotificationTabsList.map((tab) => (
            <Button
              key={tab}
              variant={activeTab === tab ? 'default' : 'link'}
              className={cn('p-0 text-sm w-auto rounded-lg capitalize h-8', {
                'bg-primary p-2': activeTab === tab,
                'text-mouseGrey no-underline': activeTab !== tab,
              })}
              onClick={() => setActiveTab(tab as NOTIFICATION_TABS)}
            >
              {tab}
            </Button>
          ))}
          <Button
            variant='link'
            className='px-0 w-auto ml-auto h-8 no-underline'
            textWithIconClassName='px-0'
            onClick={() => markAllNotificationsReadMutation.mutate(null)}
            icon={<PiChecksBold />}
            iconPosition='left'
            disabled={
              !notifications.some((notification) => notification.seen === false)
            }
          >
            {staticText.markAllRead}
          </Button>
        </div>
        {Content()}
      </>
    </Container>
  );
};

export default Notifications;
