import React, { Dispatch, SetStateAction } from 'react';
import { HiMiniArrowTopRightOnSquare } from 'react-icons/hi2';
import { IoChatbubbleEllipsesOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { FolderHeart } from 'lucide-react';

import { ICandidate, ITagType } from '@/@types/candidate';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import TooltipComponent from '@/components/Tooltip';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { TICKET_CANDIDATE_TABLE_ACTION } from '@/constants/ticket';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { EXTRACT_TYPE, getLabelOrValue, getTagVariant } from '@/utils/common';

import { Typography } from 'src/components/Typography';

interface TicketTooltipProps {
  candidate: ICandidate;
}

interface TicketCandidateActionCell {
  setAction: Dispatch<SetStateAction<TICKET_CANDIDATE_TABLE_ACTION | null>>;
  isHigherStatusThanActive?: boolean;
}

export const TicketTooltip: React.FC<TicketTooltipProps> = ({ candidate }) => {
  const navigate = useNavigate();
  return (
    <TooltipComponent
      align='start'
      trigger={
        <ProfileBadge
          className='justify-start'
          avatarClassName='size-[30px]'
          name={`${candidate?.userDetails?.firstName} ${candidate?.userDetails?.lastName}`}
          profilePicture={candidate?.userDetails?.avatar as string}
        />
      }
      className='max-w-72 md:max-w-full'
    >
      <div className='p-4 '>
        <div className='flex justify-between'>
          <div className='space-y-2'>
            <Typography className='md:[15px] font-bold'>
              {strings.ticketDetailScreen.tooltipText.name}
            </Typography>
            <ProfileBadge
              name={`${candidate?.userDetails?.firstName} ${candidate?.userDetails?.lastName}`}
              profilePicture={candidate?.userDetails?.avatar as string}
              className='justify-start'
              avatarClassName='size-9'
            />
          </div>
          <HiMiniArrowTopRightOnSquare
            onClick={() => navigate(`${ROUTES.CANDIDATE}/${candidate._id}`)}
            className='size-5 text-primary'
          />
        </div>
        <Separator className='my-2.5 ' />
        <div className='flex gap-5 md:gap-10 mb-5 flex-col md:flex-row'>
          <NameAndDesc
            title={strings.ticketDetailScreen.tooltipText.contactNumber}
            description={candidate.contactNo}
          />
          <NameAndDesc
            title={strings.ticketDetailScreen.tooltipText.emailAddress}
            description={candidate.email}
          />
        </div>
        <NameAndDesc
          title={strings.ticketDetailScreen.tooltipText.tag}
          tags={
            <div className='flex gap-3 flex-wrap'>
              {candidate.tags?.length
                ? candidate.tags.map((item: ITagType, index: number) => (
                    <Tags
                      key={index}
                      text={`${getLabelOrValue(item?.companyName, EXTRACT_TYPE.LABEL)} / ${getLabelOrValue(item?.status, EXTRACT_TYPE.LABEL)}`}
                      variant={getTagVariant(
                        getLabelOrValue(
                          item?.status,
                          EXTRACT_TYPE.LABEL,
                        ) as string,
                      )}
                      containerClassName='py-1 h-auto min-w-20'
                    />
                  ))
                : '-'}
            </div>
          }
        />
      </div>
    </TooltipComponent>
  );
};
export const TicketCandidateActionCell: React.FC<TicketCandidateActionCell> = ({
  setAction,
  isHigherStatusThanActive = false,
}) => {
  return (
    <div className='flex justify-end'>
      <div className='flex items-center justify-center gap-7'>
        <Button
          icon={
            <IoChatbubbleEllipsesOutline size={20} className='text-primary' />
          }
          onClick={() => setAction(TICKET_CANDIDATE_TABLE_ACTION.CHAT)}
          variant='link'
          className='px-0 w-auto'
        />
        {!isHigherStatusThanActive ? (
          <Button
            icon={<FolderHeart size={20} className='text-primary' />}
            onClick={() => setAction(TICKET_CANDIDATE_TABLE_ACTION.SHORTLIST)}
            variant='link'
            className='px-0 w-auto'
          />
        ) : null}
      </div>
    </div>
  );
};
