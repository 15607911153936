import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { ICandidate, ICandidateTag } from '@/@types/candidate';
import {
  IAssignMyselfTicketsPayload,
  IAssignTicketsPayload,
  ICompanyClientsResponse,
  IDeleteDocumentPayload,
  IGetTicketsParams,
  IMoveTicketsAppliedPayload,
  ITicket,
  IUpdateCandidateRankPayload,
} from '@/@types/tickets';
import { API } from '@/api/ApiInstance';
import { TICKET_TABS } from '@/constants/ticket';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';

export const getTickets = async ({
  tab,
  queryParams,
}: IGetTicketsParams): Promise<IPaginationResponse<ITicket> | boolean> => {
  const queryString = buildQueryParams(queryParams);
  const response = await API.Get<IPaginationResponse<ITicket>>(
    `${
      tab === TICKET_TABS.ALL_TICKETS
        ? REST_SUB_URL.TICKETS
        : REST_SUB_URL.GET_MY_TICKETS
    }?${queryString}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const getTicketDetail = async (
  id: string,
): Promise<ITicket | boolean> => {
  const response = await API.Get<ITicket>(`${REST_SUB_URL.TICKETS}/${id}`);
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const deleteTicket = async (id: string): Promise<boolean | boolean> => {
  const response = await API.Delete<string, boolean>(
    `${REST_SUB_URL.TICKETS}/${id}`,
  );
  if (response.status) return true;

  toast.error(response.message);
  return false;
};

export const assignTicketsToMyself = async (
  payload: IAssignMyselfTicketsPayload,
): Promise<ITicket | boolean> => {
  const response = await API.Patch<IAssignMyselfTicketsPayload, ITicket>(
    REST_SUB_URL.ASSIGN_TICKET_TO_SELF,
    payload,
  );

  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const assignTickets = async (
  payload: IAssignTicketsPayload,
): Promise<ITicket | boolean> => {
  const response = await API.Patch<IAssignTicketsPayload, ITicket>(
    REST_SUB_URL.ASSIGN_TICKET,
    payload,
  );

  if (response.status && response.data) {
    return response.data;
  }

  if (payload.isAssign) {
    toast.error(response.message);
    return false;
  }
  throw new Error(response.message);
};

export const createTicket = async (
  payload: ITicket,
): Promise<ITicket | boolean> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(payload)) {
    const excludedKeys = [
      'qualifications',
      'replacedEmployee',
      'dateOfNOR',
      'nature',
    ];

    if (!excludedKeys.includes(key) && value !== undefined) {
      formData.append(key, value);
    }
  }
  if (payload.qualifications) {
    payload.qualifications.forEach((file) => {
      formData.append('qualifications', file);
    });
  }

  const response = await API.Post<FormData, ITicket>(
    REST_SUB_URL.TICKETS,
    formData,
  );

  if (response.status && response.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const updateTicket = async (
  id: string,
  payload: ITicket,
): Promise<ITicket | boolean> => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(payload)) {
    if (key !== 'qualifications' && value !== undefined) {
      formData.append(key, value);
    }
  }
  if (payload.qualifications) {
    payload.qualifications.forEach((file) => {
      formData.append('qualifications', file);
    });
  }
  const response = await API.Patch<FormData, ITicket>(
    `${REST_SUB_URL.TICKETS}/${id}`,
    formData,
  );

  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const getTicketsCandidates = async (
  pageSize: number,
  page: number,
  ticketId: string,
  status: string,
): Promise<IPaginationResponse<ICandidateTag> | boolean> => {
  const response = await API.Get<IPaginationResponse<ICandidateTag>>(
    `${
      REST_SUB_URL.GET_TICKETS_CANDIDATES
    }?pageSize=${pageSize}&page=${page}&ticketId=${ticketId}&status=${status}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const shortlistCandidatesForTicket = async (
  payload: IMoveTicketsAppliedPayload,
): Promise<ITicket | boolean> => {
  const response = await API.Post<IMoveTicketsAppliedPayload, ITicket>(
    REST_SUB_URL.MOVE_TO_APPLIED_SHORTLIST,
    payload,
  );

  if (response.status && response.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const requestTicketClosure = async (id: string): Promise<boolean> => {
  const response = await API.Patch<undefined, ITicket>(
    `${REST_SUB_URL.REQUEST_TICKET_CLOSURE}/${id}`,
  );

  if (response.status && response.data) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const closeTicket = async (id: string): Promise<boolean> => {
  const response = await API.Patch<undefined, ITicket>(
    `${REST_SUB_URL.CLOSE_TICKET}/${id}`,
  );

  if (response.status && response.data) {
    return true;
  }
  toast.error(response.message);
  return false;
};

export const fetchCompanyClients = async (
  companyName: string,
  page: number,
  clientSearch?: string,
): Promise<IPaginationResponse<ICompanyClientsResponse> | boolean> => {
  const response = await API.Get<IPaginationResponse<ICompanyClientsResponse>>(
    `${REST_SUB_URL.COMPANY_CLIENTS}/${companyName}?page=${page}&search=${clientSearch}`,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const updateCandidateRankForTicket = async (
  candidateId: string,
  ticketId: string,
  rank: number,
): Promise<boolean | ICandidateTag> => {
  const payload: IUpdateCandidateRankPayload = { ticket: ticketId, rank };

  const response = await API.Patch<
    IUpdateCandidateRankPayload,
    boolean | ICandidateTag
  >(`${REST_SUB_URL.CANDIDATE_RANK_IN_TICKET}/${candidateId}`, payload);

  if (response.status && response.data) {
    return response.data;
  }

  toast.error(response.message);
  return false;
};

export const fetchCompanyEmployees = async (
  companyName: string,
  page: number,
  employeeSearch?: string,
): Promise<IPaginationResponse<ICandidate> | boolean> => {
  const response = await API.Get<IPaginationResponse<ICandidate>>(
    `${REST_SUB_URL.GET_EMPLOYEES}?companyName=${companyName}&page=${page}&search=${employeeSearch}`,
  );

  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const deleteDocument = async ({
  ticketId,
  fileName,
}: IDeleteDocumentPayload): Promise<boolean | boolean> => {
  const response = await API.Delete<string, boolean>(
    `${REST_SUB_URL.TICKET_DOCUMENTS}/${ticketId}?fileName=${fileName}`,
  );

  if (response.status) {
    toast.success(response.message);
    return true;
  }

  toast.error(response.message);
  return false;
};
