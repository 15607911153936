import React from 'react';

import { Skeleton } from '@/components/ui/skeleton';

const SkeletonItem: React.FC = () => (
  <Skeleton className='h-3 w-20 rounded-lg bg-gray-100' />
);

const ManpowerLoadingSkeleton: React.FC = () => (
  <>
    {Array.from({ length: 10 }).map((_, i) => (
      <div
        key={i}
        className='rounded-lg p-1 bg-secondary space-y-2 bg-opacity-60'
      >
        <Skeleton className='h-14 w-full grid grid-cols-3 items-center px-3'>
          <SkeletonItem />
          {Array.from({ length: 2 }).map((_, j) => (
            <div
              key={j}
              className='w-20 flex flex-col gap-3 h-full items-center justify-center'
            >
              <SkeletonItem />
              <SkeletonItem />
            </div>
          ))}
        </Skeleton>
        {i === 0 ? (
          <div className='h-16 w-full rounded-lg grid grid-cols-4 p-3 bg-gray-200'>
            {Array.from({ length: 4 }).map((_, k) => (
              <div
                key={k}
                className='w-20 flex flex-col gap-3 h-full items-center'
              >
                <SkeletonItem />
                <SkeletonItem />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    ))}
  </>
);

export default ManpowerLoadingSkeleton;
