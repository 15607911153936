/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BiChat } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { IoDuplicateOutline } from 'react-icons/io5';
import { PiWarning } from 'react-icons/pi';
import { TiLockClosedOutline } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';

import { IUser } from '@/@types/auth';
import { Department } from '@/@types/metas';
import { IConfirmationModal, ITicket } from '@/@types/tickets';
import BackHeader from '@/components/BackHeader';
import BannerAlert from '@/components/BannerAlert';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import NameAndDesc from '@/components/NameAndDesc';
import ProfileBadge from '@/components/ProfileBadge';
import StateIndicator from '@/components/StateIndicator';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import {
  JOB_HIRING_INFORMATION,
  MAN_POWER_INFORMATION,
  TICKET_DETAILS_INFORMATION,
  TICKET_STATUS,
} from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  closeTicket,
  getTicketDetail,
  requestTicketClosure,
} from '@/services/ticket';
import {
  getTagVariant,
  removeSegmentsFromUrl,
  snakeToTitleCase,
} from '@/utils/common';
import { JSONParse } from '@/utils/jsonparse';

import AttachmentPreview from '../Chat/ChatArea/AttachmentPreview';
import CreateTicket from '../CreateTicket';
import RequestScreen from '../Request';

import TableSection from './components/TableSection';

const TicketDetails = () => {
  const { common, ticketDetailScreen: staticText } = strings;

  const { ticketId } = useParams<{ ticketId: string }>();
  const {
    fromRequestScreen,
    setFromRequestScreen,
    fromApprovalScreen,
    setFromApprovalScreen,
  } = useSidebarContext();

  const navigate = useNavigate();
  const { setActiveChat } = useChatContext();
  const { user, isAdmin, isRecruiter, isCompanyClient, hasSubRole } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [duplicateTicket, setDuplicateTicket] = useState(false);
  const [confirmationModal, setConfirmationModalData] =
    useState<IConfirmationModal>({
      isOpen: false,
      action: null,
    });
  const [ticketDetails, setTicketDetails] = useState<ITicket>({} as ITicket);

  const { data, isLoading, refetch } = useGenericQuery(
    ['getTicketDetail', ticketId],
    () => getTicketDetail(ticketId!),
  );

  const requestClosureMutation = useGenericMutation<string, boolean>(
    requestTicketClosure,
    {
      onSuccess: () => {
        toast.success(staticText.success);
        setConfirmationModalData({ isOpen: false, action: null });
      },
    },
  );

  const closeTicketMutation = useGenericMutation<string, boolean>(closeTicket, {
    onSuccess: () => {
      toast.success(staticText.closeTicketSuccess);
      setConfirmationModalData({ isOpen: false, action: null });
      refetch();
    },
  });

  const employmentDetails =
    JSONParse(ticketDetails?.typeOfEmployment as string) || {};
  const [employmentTitle, employmentDescription] =
    Object.entries(employmentDetails)[0] || [];

  const handleChat = () => {
    if (typeof data === 'object' && user) {
      navigate(ROUTES.CHAT);
      setActiveChat({
        user: data.author as IUser,
        ticket: { ...data, department: (data.department as Department).name },
      });
    }
  };

  const openConfirmationModal = () => {
    setConfirmationModalData({
      isOpen: true,
      action: isRecruiter ? 'requestClosure' : 'closeTicket',
    });
  };

  const handleConfirmAction = () => {
    if (ticketDetails._id) {
      if (confirmationModal.action === 'requestClosure') {
        requestClosureMutation.mutate(ticketDetails._id);
      } else if (confirmationModal.action === 'closeTicket') {
        closeTicketMutation.mutate(ticketDetails._id);
      }
    }
  };

  const isTicketCloseable =
    ticketDetails?.numberOfHC === ticketDetails?.candidatesHired &&
    ticketDetails?.status !== TICKET_STATUS.INACTIVE;

  useEffect(() => {
    if (typeof data === 'object') {
      setTicketDetails(data);
    }
  }, [data]);

  if (!isLoading && !data)
    return (
      <StateIndicator state='Error' errorMessage={staticText.removedJob} />
    );

  const handleBackButton = () => {
    if (fromRequestScreen) {
      navigate(fromRequestScreen);
      setFromRequestScreen('');
    } else if (fromApprovalScreen) {
      navigate(fromApprovalScreen);
      setFromApprovalScreen('');
    } else {
      removeSegmentsFromUrl(navigate, location.pathname, isRecruiter ? 2 : 1);
    }
  };

  return (
    <>
      {confirmationModal.isOpen ? (
        <ConfirmationModal
          containerClassName='py-5'
          open={confirmationModal.isOpen}
          primaryBtnAction={handleConfirmAction}
          icon={<PiWarning className='size-6 text-primary' />}
          primaryBtnLabel={common[isRecruiter ? 'request' : 'close']}
          textDesc={
            common[isRecruiter ? 'confirmClosureText' : 'confirmTicketClose']
          }
          handleCloseModal={() =>
            setConfirmationModalData({ isOpen: false, action: null })
          }
          isLoading={
            (isRecruiter ? requestClosureMutation : closeTicketMutation)
              .isPending
          }
        />
      ) : null}
      {!isEditing ? (
        <div className='flex justify-between flex-col lg:flex-row gap-3'>
          <BackHeader
            loading={isLoading}
            title={`${staticText.title} ${ticketDetails?.ticketNumber}`}
            handleBackBtn={handleBackButton}
          />
          <div className='flex gap-3 flex-col xs:flex-row justify-end items-end'>
            {(isCompanyClient || (isAdmin && !hasSubRole)) &&
            isTicketCloseable &&
            ticketDetails.isRequestClose ? (
              <Button
                variant='outline'
                className='rounded-xl w-full xs:w-36 px-0 h-12 xs:h-9 lg:h-11 text-base'
                icon={<TiLockClosedOutline size={21} className='shrink-0' />}
                onClick={openConfirmationModal}
                disabled={closeTicketMutation.isPending}
                textWithIconClassName='leading-5'
              >
                {staticText.closeTicket}
              </Button>
            ) : null}
            {isCompanyClient || (isAdmin && !hasSubRole) ? (
              <Button
                variant='outline'
                className='rounded-xl w-full xs:w-36 px-0 h-12 xs:h-9 lg:h-11 text-base'
                icon={<IoDuplicateOutline size={21} className='shrink-0' />}
                onClick={() => {
                  setIsEditing(true);
                  setDuplicateTicket(true);
                }}
                textWithIconClassName='leading-5'
              >
                {staticText.duplicate}
              </Button>
            ) : null}
            {isAdmin && !hasSubRole ? (
              <Button
                onClick={() => setIsEditing(true)}
                variant='outline'
                className='w-full xs:w-36 rounded-xl px-0 h-12 xs:h-9 lg:h-11 text-base'
                icon={<FiEdit size={20} />}
              >
                {staticText.edit}
              </Button>
            ) : null}
          </div>
          {isRecruiter && ticketDetails.assignee?._id === user?._id ? (
            <div className='flex gap-3 flex-col xs:flex-row justify-end items-end'>
              {isTicketCloseable ? (
                <Button
                  variant='outline'
                  className='rounded-xl w-full xs:w-fit px-0 h-12 xs:h-9 lg:h-11 text-base'
                  icon={<TiLockClosedOutline size={21} className='shrink-0' />}
                  onClick={openConfirmationModal}
                  disabled={requestClosureMutation.isPending}
                  textWithIconClassName='leading-5'
                >
                  {staticText.requestClosure}
                </Button>
              ) : null}
              <Button
                variant='outline'
                className='rounded-xl w-full xs:w-fit px-0 h-12 xs:h-9 lg:h-11 text-base'
                icon={<BiChat size={21} />}
                onClick={handleChat}
              >
                {staticText.chat}
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className='space-y-6 mt-7 '>
        {isEditing ? (
          <CreateTicket
            initialState={ticketDetails}
            setIsEditing={setIsEditing}
            duplicate={duplicateTicket}
            setDuplicateTicket={setDuplicateTicket}
          />
        ) : (
          <>
            <Container className='p-4 md:p-7 space-y-7' loading={isLoading}>
              <BannerAlert
                text={
                  ticketDetails.override
                    ? staticText.overrideTrue
                    : staticText.overrideFalse
                }
                alertType='info'
              />
              <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-7'>
                {TICKET_DETAILS_INFORMATION(
                  ticketDetails,
                  isRecruiter,
                  isAdmin,
                ).map((item) => (
                  <NameAndDesc
                    key={item.title}
                    title={item.title}
                    description={item?.description?.toString()}
                  />
                ))}
                <NameAndDesc
                  title={staticText.status}
                  tags={
                    <Tags
                      text={snakeToTitleCase(ticketDetails?.status)}
                      variant={getTagVariant(
                        snakeToTitleCase(ticketDetails?.status),
                      )}
                    />
                  }
                />
                <NameAndDesc
                  title={staticText.description}
                  description={ticketDetails?.jobDescription}
                  avoidTruncate
                  className='col-span-1 sm:col-span-2 md:col-span-3 xl:col-span-4'
                />
              </div>
              {isRecruiter ? null : (
                <div className='flex flex-col gap-7'>
                  <Typography variant='heading' className='md:text-2xl'>
                    {staticText.jobHiringDetails}
                  </Typography>
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-7'>
                    {isAdmin ? (
                      <div>
                        <Typography className='block font-bold sm:text-[15px] mb-1'>
                          {staticText.assignee}
                        </Typography>
                        {ticketDetails.assignee ? (
                          <ProfileBadge
                            name={`${ticketDetails.assignee?.firstName} ${ticketDetails.assignee?.lastName}`}
                            profilePicture={ticketDetails.assignee?.avatar}
                            className='justify-start'
                          />
                        ) : (
                          <Typography>-</Typography>
                        )}
                      </div>
                    ) : null}
                    {JOB_HIRING_INFORMATION(ticketDetails).map((item) => (
                      <NameAndDesc
                        key={item.title}
                        title={item.title}
                        className='capitalize'
                        description={item.description}
                      />
                    ))}
                  </div>
                  <div
                    className={cn('grid grid-cols-1 gap-y-7', {
                      'lg:grid-cols-2':
                        MAN_POWER_INFORMATION(ticketDetails).length === 1,
                    })}
                  >
                    <div className='space-y-7'>
                      <Typography variant='heading' className='md:text-2xl'>
                        {staticText.reasonForManpowerRequest}
                      </Typography>
                      <div
                        className={cn(
                          'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-7',
                          {
                            'flex flex-col gap-7':
                              MAN_POWER_INFORMATION(ticketDetails).length === 1,
                          },
                        )}
                      >
                        {MAN_POWER_INFORMATION(ticketDetails).map((item) => (
                          <NameAndDesc
                            key={item.title as string}
                            title={item?.title as string}
                            description={item?.description as string}
                          />
                        ))}
                      </div>
                    </div>
                    <div className='space-y-7'>
                      <Typography variant='heading' className='md:text-2xl'>
                        {staticText.typeOfEmployment}
                      </Typography>
                      <NameAndDesc
                        title={employmentTitle || ''}
                        description={employmentDescription || ''}
                      />
                    </div>
                  </div>
                  {ticketDetails?.qualifications?.length ? (
                    <>
                      <Typography variant='heading' className='md:text-2xl'>
                        {staticText.qualifications}
                      </Typography>
                      <div className='flex gap-2 flex-col'>
                        {ticketDetails?.qualificationsDetails ? (
                          <NameAndDesc
                            title={''}
                            description={ticketDetails?.qualificationsDetails}
                            avoidTruncate
                            className='col-span-1 sm:col-span-2 md:col-span-3 xl:col-span-4'
                          />
                        ) : null}
                        <div className='flex flex-wrap gap-3'>
                          {ticketDetails?.qualifications?.map(
                            (attachment, index) => (
                              <AttachmentPreview
                                key={`attachment-${index}`}
                                attachment={attachment as string}
                              />
                            ),
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </Container>
            {isRecruiter && !isLoading ? (
              <TableSection ticketDetails={ticketDetails} />
            ) : null}
            {isCompanyClient && !isLoading ? (
              <RequestScreen
                ticketId={ticketDetails._id!}
                ticketStatus={ticketDetails?.status as TICKET_STATUS}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
};

export default TicketDetails;
