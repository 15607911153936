/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import { ICreateChatPayload, ICreateChatResponse } from '@/@types/chat';
import { Department } from '@/@types/metas';
import ReactDropdown from '@/components/Dropdown';
import Modal from '@/components/Modal';
import ProfileBadge from '@/components/ProfileBadge';
import Tags from '@/components/Tags';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import { USER_TYPE } from '@/constants';
import useDebounce from '@/hooks/useDebounce';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { createChat, getTicketsForAssociation } from '@/services/chat';
import { GetFormattedName } from '@/utils/common';
import { useQueryClient } from '@tanstack/react-query';

import RecipientModal from '../../ChatSidebar/recipientModal';

import TicketTooltip from './TicketTooltip';

const ChatHeader = () => {
  const staticText = strings.chatScreen;

  const queryClient = useQueryClient();
  const { isSmallScreen } = useSidebarContext();
  const { isCompanyClient, user: loggedInUser, hasSubRole } = useAuth();
  const {
    activeChat,
    selectedRecipients,
    setSelectedRecipients,
    setActiveChat,
  } = useChatContext();

  const { ticket, user } = activeChat || {};
  const { userDetails, positionDesired, department, experienceYears } =
    (user as ICandidate) || {};
  const recipient = (userDetails || user) as IUser;
  const isRecipientCandidate = recipient?.profileType === USER_TYPE.CANDIDATE;

  const [pageIndex, setPageIndex] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [selectedTicket, setSelectedTicket] = useState('');
  const [tickets, setTickets] = useState<IDropdownOption[]>([]);
  const [openRecipientsModal, setOpenRecipientsModal] = useState(false);
  const [openAssociateTicketModal, setOpenAssociateTicketModal] =
    useState(false);

  const debouncedSearchText = useDebounce(searchText);

  const { data, isLoading } = useGenericQuery(
    ['getTicketsForAssociation', pageIndex, debouncedSearchText],
    () =>
      getTicketsForAssociation({
        page: pageIndex + 1,
        search: debouncedSearchText,
      }),
    { enabled: openAssociateTicketModal },
  );

  const createConversationMutation = useGenericMutation<
    ICreateChatPayload,
    boolean | ICreateChatResponse
  >(createChat, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        queryClient.invalidateQueries({
          queryKey: ['chatList'],
        });
        toast.success(staticText.chatAssociationSuccess);
      }
      if (user && typeof data === 'object')
        setActiveChat({
          ticket: data?.results.find((ticket) => ticket._id === selectedTicket),
          user,
        });
      toggleAssociateTicketModal();
    },
  });

  const toggleRecipientsModal = () => {
    setOpenRecipientsModal((prev) => !prev);
  };

  const associateTicketWithConversation = () => {
    if (recipient && loggedInUser) {
      const payload: ICreateChatPayload = {
        user1: recipient._id!,
        user2: loggedInUser._id!,
        ticketId: selectedTicket,
      };
      createConversationMutation.mutate(payload);
    }
  };

  const toggleAssociateTicketModal = () => {
    setOpenAssociateTicketModal((prev) => !prev);
    setSelectedTicket('');
  };

  const handleRemoveSelectedRecipient = (id: string) => {
    setSelectedRecipients(
      selectedRecipients.filter((recipient) => recipient._id !== id),
    );
  };

  const handleAssociateTicket = () => {
    if (typeof data === 'object') {
      const ticket = data?.results.find(
        (ticket) => ticket._id === selectedTicket,
      );
      if (activeChat) {
        setActiveChat({
          ...activeChat,
          ticket,
        });
      }
      associateTicketWithConversation();
    }
  };

  useEffect(() => {
    if (searchText) {
      setPageIndex(0);
      setTickets([]);
    }
    if (typeof data === 'object') {
      setTickets((prev) => {
        const moreTickets = data?.results?.map((item) => ({
          label: `Tk#${item?.ticketNumber} | ${(item?.department as Department).name}`,
          value: item._id!,
        }));
        return searchText ? moreTickets : [...prev, ...moreTickets];
      });
    }
  }, [data, searchText]);

  const Content = () => {
    if (selectedRecipients.length)
      return (
        <div className='w-full flex justify-between items-start'>
          {selectedRecipients?.length ? (
            <div className='flex flex-wrap max-h-24 items-center overflow-x-scroll gap-3'>
              {selectedRecipients?.map((recipient) => (
                <Tags
                  key={`recipient-${recipient?._id}`}
                  text={GetFormattedName(recipient?.userDetails)}
                  variant='gray'
                  handleClose={() =>
                    handleRemoveSelectedRecipient(recipient._id!)
                  }
                  containerClassName='rounded-2xl min-w-fit justify-evenly'
                  textClassName='text-xs font-bold'
                />
              ))}
            </div>
          ) : null}
          <Button
            variant='link'
            className='w-fit h-auto no-underline gap-1 px-0'
            onClick={toggleRecipientsModal}
          >
            +<span className='underline'>{staticText.recipient}</span>
          </Button>
        </div>
      );
    if (!activeChat)
      return (
        <div className='flex items-center space-x-4 w-full'>
          <Skeleton className='size-11 rounded-full' />
          <div className='space-y-2 w-full'>
            <Skeleton className='h-4 w-1/6' />
            <Skeleton className='h-4 w-2/6' />
          </div>
        </div>
      );
    return (
      <div className='flex flex-col md:flex-row gap-4 w-full items-start md:items-center'>
        <ProfileBadge
          name={GetFormattedName(recipient)}
          profilePicture={recipient?.avatar as string}
          avatarClassName='size-11'
          attribute={
            isRecipientCandidate
              ? [
                  positionDesired?.[0],
                  department,
                  experienceYears ? `${experienceYears} Years` : '',
                ]
                  .filter(Boolean)
                  .join(' | ')
              : recipient.email
          }
          nameClassName='text-base font-bold'
          attributeClassName={cn('text-greyishBlack font-semibold', {
            lowercase: !isRecipientCandidate,
          })}
        />

        {ticket && !isSmallScreen ? (
          <>
            <Separator
              orientation='vertical'
              className='md:block hidden h-12 '
            />
            <TicketTooltip />
          </>
        ) : null}
        {!isCompanyClient && !hasSubRole && !ticket ? (
          <Button
            className='h-8 text-xs md:w-auto rounded-md md:ml-auto'
            onClick={toggleAssociateTicketModal}
          >
            {staticText.associateWithTicket}
          </Button>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {openRecipientsModal ? (
        <RecipientModal
          open={openRecipientsModal}
          handleClose={toggleRecipientsModal}
        />
      ) : null}
      {openAssociateTicketModal ? (
        <Modal
          open={openAssociateTicketModal}
          handleCloseModal={toggleAssociateTicketModal}
          title={staticText.associateWithTicket}
          primaryBtnLabel={staticText.associate}
          primaryBtnAction={handleAssociateTicket}
          buttonDisabled={!selectedTicket}
          isLoading={createConversationMutation.status === 'pending'}
        >
          <>
            <Typography className='block font-bold mb-1'>
              {staticText.selectTicket}
            </Typography>
            <ReactDropdown
              options={tickets}
              value={selectedTicket}
              onChange={(selectedOption) =>
                setSelectedTicket(selectedOption as string)
              }
              name={GetFormattedName(recipient)}
              isSearchable
              isLoading={isLoading}
              placeholder={staticText.ticketSearchPlaceholder}
              setInputSearch={setSearchText}
              setPage={setPageIndex}
            />
          </>
        </Modal>
      ) : null}
      <div className='flex items-center gap-4 w-full'>{Content()}</div>
    </>
  );
};

export default ChatHeader;
