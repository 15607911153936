import React, { ReactNode } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { PiWarningFill } from 'react-icons/pi';
import { TiDelete } from 'react-icons/ti';

import { cn } from '@/lib/utils';

type AlertType = 'info' | 'danger' | 'warning' | 'success';

type BannerAlertType = {
  text: string | ReactNode;
  alertType: AlertType;
};

const alertStyles: {
  [key in AlertType]: {
    borderColor: string;
    bgColor: string;
    icon: JSX.Element;
  };
} = {
  info: {
    borderColor: 'border-primary text-primary',
    bgColor: 'bg-sky-50',
    icon: <FaInfoCircle className='text-primary' />,
  },
  danger: {
    borderColor: 'border-red-400 text-red-400',
    bgColor: 'bg-red-50',
    icon: <TiDelete className='text-red-400' />,
  },
  warning: {
    borderColor: 'border-orange-400 text-orange-400',
    bgColor: 'bg-orange-50',
    icon: <PiWarningFill className='text-orange-400' />,
  },
  success: {
    borderColor: 'border-emerald-400 text-emerald-400',
    bgColor: 'bg-emerald-50',
    icon: <FaCircleCheck className='text-emerald-400' />,
  },
};

const BannerAlert: React.FC<BannerAlertType> = ({ text, alertType }) => {
  const { borderColor, bgColor, icon } = alertStyles[alertType];

  return (
    <div
      className={cn(
        'border py-1.5 px-2 rounded-md flex text-sm bg-opacity-50 items-center w-fit',
        bgColor,
        borderColor,
      )}
    >
      <div className='mr-2 text-base'>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

export default BannerAlert;
