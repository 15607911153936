import * as React from 'react';
import { useRef, useState } from 'react';

import { IDropdownOption } from '@/@types';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { EXTRACT_TYPE, getLabelOrValue } from '@/utils/common';

export interface DropdownProps {
  items: IDropdownOption[] | null;
  value?: string | IDropdownOption | null;
  setValue?: React.Dispatch<React.SetStateAction<string | IDropdownOption>>;
  onChange?: (value: string) => void;
  customButton?: React.ReactNode;
  customButtonClassName?: string;
  valueHide?: boolean;
  tagsDropdown?: boolean;
  isSearchable?: boolean;
  saveBothLabelAndName?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  haveMoreOptions?: boolean;
  setInputSearch?: React.Dispatch<React.SetStateAction<string>>;
  isDisabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  value,
  setValue,
  onChange,
  customButton,
  customButtonClassName,
}) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement | HTMLButtonElement>(null);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          {customButton ? (
            <div
              ref={triggerRef as React.RefObject<HTMLDivElement>}
              className={cn('flex items-center', customButtonClassName)}
            >
              {customButton}
            </div>
          ) : null}
        </PopoverTrigger>
        <PopoverContent
          align='end'
          style={{ minWidth: '20px' }}
          className='p-0 rounded-xl shadow-lg'
        >
          <Command className='rounded-xl'>
            <CommandGroup>
              <CommandList>
                {items?.map((item, index) => (
                  <CommandItem
                    key={`${typeof item === 'object' ? item.value : item}-${index}`}
                    className={cn('px-3 py-1.5 cursor-pointer', {
                      'text-redColor hover:text-redColor aria-selected:text-redColor':
                        item.label === 'Delete',
                      'bg-primary text-white': item.value === value,
                    })}
                    value={item.label}
                    onSelect={(currentValue) => {
                      const newValue =
                        currentValue === value ? '' : currentValue;
                      setValue?.(newValue);
                      onChange?.(newValue);
                      setOpen(false);
                    }}
                  >
                    {getLabelOrValue(item, EXTRACT_TYPE.LABEL)}
                  </CommandItem>
                ))}
              </CommandList>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default Dropdown;
