import { IStatisticValues } from '@/@types/dashboard';
import { strings } from '@/locales';
import { EnumToArray, formatCurrency } from '@/utils/common';

export const getDashboardCardsData = (
  allTicketsCount?: number | boolean,
  allTicketsLoading?: boolean,
  myTicketsCount?: number | boolean,
  myTicketsLoading?: boolean,
  salesCount?: number,
  totalHiredCandidate?: number,
  salesLoading?: boolean,
) => {
  return [
    {
      title: strings.dashboard.allTickets,
      count: allTicketsCount?.toString(),
      text: strings.dashboard.totalTickets,
      isLoading: allTicketsLoading,
    },
    {
      title: strings.dashboard.myTickets,
      count: myTicketsCount?.toString(),
      text: strings.dashboard.totalTickets,
      isLoading: myTicketsLoading,
    },
    {
      title: strings.dashboard.sales,
      count: formatCurrency(salesCount).replace('₱', ''),
      countUnit: 'PHP',
      text: strings.dashboard.perCandidateNumber,
      totalHiredCandidate: totalHiredCandidate,
      isLoading: salesLoading,
    },
  ];
};

export const DASHBOARD_MANPOWER_FORM_DATA = [
  {
    _id: '1',
    companyName: 'Agilent Technologies Inc.',
    activeRecruiter: 5,
    numberOfPositions: 7,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 24,
      },
      {
        _id: 't2',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 100,
      },
      {
        _id: 't3',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '3',
        completePercentage: 50,
      },
      {
        _id: 't4',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 40,
      },
    ],
  },
  {
    _id: '2',
    companyName: 'Geeks of Kolachi',
    activeRecruiter: 5,
    numberOfPositions: 72,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 34,
      },
      {
        _id: 't2',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 77,
      },
      {
        _id: 't3',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '3',
        completePercentage: 90,
      },
      {
        _id: 't4',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 0,
      },
    ],
  },
  {
    _id: '3',
    companyName: 'Tech Innovations Inc.',
    activeRecruiter: 5,
    numberOfPositions: 17,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 94,
      },
      {
        _id: 't2',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 85,
      },
      {
        _id: 't3',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '3',
        completePercentage: 50,
      },
      {
        _id: 't4',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 0,
      },
    ],
  },
  {
    _id: '4',
    companyName: 'Creative Minds Co.',
    activeRecruiter: 5,
    numberOfPositions: 7,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 24,
      },
      {
        _id: 't2',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 100,
      },
      {
        _id: 't3',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '3',
        completePercentage: 50,
      },
    ],
  },
  {
    _id: '5',
    companyName: 'Green Solutions Ltd',
    activeRecruiter: 5,
    numberOfPositions: 7,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 24,
      },
      {
        _id: 't2',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '1',
        completePercentage: 100,
      },
    ],
  },
];
export const DASHBOARD_MANPOWER_STATIC_FORM_DATA_FOR_MODAL = [
  {
    _id: '1',
    companyName: 'Agilent Technologies Inc.',
    activeRecruiter: 5,
    numberOfPositions: 7,
    tickets: [
      {
        _id: 't1',
        ticketNumber: 5529,
        jobPosition: 'Development',
        vacancies: '2',
        completePercentage: 24,
      },
    ],
  },
  {
    _id: '2',
    companyName: 'Geeks of Kolachi',
    activeRecruiter: 5,
    numberOfPositions: 72,
  },
  {
    _id: '3',
    companyName: 'Tech Innovations Inc.',
    activeRecruiter: 5,
    numberOfPositions: 17,
  },
];

export enum RECRUITMENT_SOURCE {
  PLATFORM = 'Platforms',
  LRA = 'LRA',
}

export const RecruitmentSourceList = EnumToArray(RECRUITMENT_SOURCE);

export enum PLATFORM_SOURCE {
  FACEBOOK = 'SRC_FB',
  INDEED = 'SRC_INDEED',
  JOBSTREET = 'SRC_JOBST',
  BLUESHIRT = 'SRC_BSHIRT',
  JORA = 'SRC_JORA',
  REFERRAL = 'SRC_REF',
  OTHER = 'SRC_OTHER',
}

export enum LOCAL_RECRUITMENT_ACTIVITY_SOURCE {
  PESO_OFFICE = 'LRA_PESO',
  JOB_FAIR = 'LRA_JOBF',
  MEGA_JOB_FAIR = 'LRA_MJFAIR',
  BARANGAY_JOB_FAIR = 'LRA_BJFAIR',
  INHOUSE_JOB_FAIR = 'LRA_INHOUSE',
  SMI_LIVE_JOB_HIRING = 'LRA_SMI',
  FLYERINGS = 'LRA_FLYER',
  SCHOOL_JOB_FAIR = 'LRA_SCHFAIR',
  OTHER = 'LRA_OTHER',
}

const PLATFORMS_AND_LRA_NAMES_AND_LABEL = [
  { name: PLATFORM_SOURCE.FACEBOOK, label: strings.dashboard.facebook },
  { name: PLATFORM_SOURCE.INDEED, label: strings.dashboard.indeed },
  { name: PLATFORM_SOURCE.JOBSTREET, label: strings.dashboard.jobStreet },
  { name: PLATFORM_SOURCE.BLUESHIRT, label: strings.dashboard.blueshirt },
  { name: PLATFORM_SOURCE.JORA, label: strings.dashboard.jora },
  { name: PLATFORM_SOURCE.REFERRAL, label: strings.dashboard.referral },
  { name: PLATFORM_SOURCE.OTHER, label: strings.dashboard.other },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.PESO_OFFICE,
    label: strings.dashboard.pesoOffice,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.JOB_FAIR,
    label: strings.dashboard.jobFair,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.MEGA_JOB_FAIR,
    label: strings.dashboard.megaJobFair,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.BARANGAY_JOB_FAIR,
    label: strings.dashboard.barangayJobFair,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.INHOUSE_JOB_FAIR,
    label: strings.dashboard.inhouseJobFair,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SMI_LIVE_JOB_HIRING,
    label: strings.dashboard.smiLiveJobHiring,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.FLYERINGS,
    label: strings.dashboard.flyerings,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SCHOOL_JOB_FAIR,
    label: strings.dashboard.schoolJobFair,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.OTHER,
    label: strings.dashboard.other,
  },
];

export const getLabelFromName = (name: string) => {
  const item = PLATFORMS_AND_LRA_NAMES_AND_LABEL.find(
    (entry) => entry.name === name,
  );
  return item ? item.label : name; // Return the label if found, else return the original name
};

export const LOCAL_RECRUITMENT_ACTIVITIES_DUMMY_DATA = [
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.PESO_OFFICE,
    count: 10,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.JOB_FAIR,
    count: 15,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.MEGA_JOB_FAIR,
    count: 20,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.BARANGAY_JOB_FAIR,
    count: 12,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.INHOUSE_JOB_FAIR,
    count: 7,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SMI_LIVE_JOB_HIRING,
    count: 10,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.FLYERINGS,
    count: 15,
  },
  {
    name: LOCAL_RECRUITMENT_ACTIVITY_SOURCE.SCHOOL_JOB_FAIR,
    count: 13,
  },
];

export enum STATISTICS_DATA {
  ENDORSED = 'Endorsed',
  ON_PROCESS = 'On Process',
  BACKED_OUT = 'Backed Out',
  PLACED = 'Placed',
  REPLACEMENT = 'Replacement',
  ON_CALL = 'On Call',
  TEMPORARY_DEPLOYED = 'Temporary Deployed',
}

export const STATISTICS_VALUES: IStatisticValues[] = [
  { name: STATISTICS_DATA.ENDORSED, count: 85 },
  { name: STATISTICS_DATA.ON_PROCESS, count: 65 },
  { name: STATISTICS_DATA.BACKED_OUT, count: 45 },
  { name: STATISTICS_DATA.PLACED, count: 75 },
  { name: STATISTICS_DATA.REPLACEMENT, count: 55 },
  { name: STATISTICS_DATA.ON_CALL, count: 35 },
  { name: STATISTICS_DATA.TEMPORARY_DEPLOYED, count: 25 },
];

export const DONUT_COLORS = [
  '#528328',
  '#3A6188',
  '#379494',
  '#EDB839',
  '#BA4D44',
  '#4340A3',
  '#49739D',
  '#DF495C',
  '#C65D54',
];

export const COLORS_FOR_MANPOWER_SECTION = [
  'bg-darkSalmon',
  'bg-granium',
  'bg-dullBlue',
  'bg-brillantBlue',
  'bg-pearlPink',
  'bg-goldenRod',
  'bg-steelBlue',
  'bg-darkSlateBlue',
  'bg-darkOliveGreen',
  'bg-honeyYellow',
  'bg-primary',
];

export const SelectorDrawerFormInitialValues = {
  listOfLocalRecruitmentActivities: false,
  statistics: false,
  // statistics: {
  //   endorse: false,
  //   onProcess: false,
  //   backedOut: false,
  //   placed: false,
  //   replacement: false,
  //   onCall: false,
  //   temporaryDeployed: false,
  // },
  manpowerFormVisibility: false,
};

export const STATISTICS_DATA_KEY_NAME: Record<string, string> = {
  endorse: 'Endorsed',
  onProcess: 'On Process',
  backedOut: 'Backed Out',
  placed: 'Placed',
  replacement: 'Replacement',
  onCall: 'On Call',
  temporaryDeployes: 'Temporary Deployed',
};
export const barsWidthPercentages = ['20%', '30%', '70%', '50%', '50%'];
