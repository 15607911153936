import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface ISidebarContext {
  sidebarExpand: boolean;
  setSidebarExpand: React.Dispatch<React.SetStateAction<boolean>>;
  sidebarClosing: boolean;
  setSidebarClosing: React.Dispatch<React.SetStateAction<boolean>>;
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
  isSmallScreen: boolean | null;
  showNotifications: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
  unreadNotificationsCount: number;
  setUnreadNotificationsCount: React.Dispatch<React.SetStateAction<number>>;
  setFromTicketScreen: React.Dispatch<React.SetStateAction<string>>;
  fromTicketScreen: string;
  setFromRequestScreen: React.Dispatch<React.SetStateAction<string>>;
  fromRequestScreen: string;
  setFromApprovalScreen: React.Dispatch<React.SetStateAction<string>>;
  fromApprovalScreen: string;
}

const SidebarContext = createContext<ISidebarContext>({
  sidebarExpand: true,
  setSidebarExpand: () => {},
  sidebarClosing: false,
  setSidebarClosing: () => {},
  currentTab: '',
  setCurrentTab: () => {},
  isSmallScreen: null,
  showNotifications: false,
  setShowNotifications: () => {},
  unreadNotificationsCount: 0,
  setUnreadNotificationsCount: () => {},
  setFromTicketScreen: () => {},
  fromTicketScreen: '',
  setFromRequestScreen: () => {},
  fromRequestScreen: '',
  setFromApprovalScreen: () => {},
  fromApprovalScreen: '',
});

interface ISidebarProvider {
  children: React.ReactNode;
}

export const SidebarProvider = ({ children }: ISidebarProvider) => {
  const location = useLocation();
  const [, pathSegment] = location.pathname.split('/');
  const isSmallScreen = window.innerWidth < 768;

  const [sidebarClosing, setSidebarClosing] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [sidebarExpand, setSidebarExpand] = useState(!isSmallScreen);
  const [currentTab, setCurrentTab] = useState(pathSegment || 'dashboard');
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [fromTicketScreen, setFromTicketScreen] = useState('');
  const [fromRequestScreen, setFromRequestScreen] = useState('');
  const [fromApprovalScreen, setFromApprovalScreen] = useState('');

  useEffect(() => {
    setCurrentTab(pathSegment || 'dashboard');
  }, [pathSegment]);

  const value = {
    currentTab,
    sidebarExpand,
    sidebarClosing,
    setSidebarClosing,
    setCurrentTab,
    setSidebarExpand,
    isSmallScreen,
    showNotifications,
    setShowNotifications,
    unreadNotificationsCount,
    setUnreadNotificationsCount,
    setFromTicketScreen,
    fromTicketScreen,
    setFromRequestScreen,
    fromRequestScreen,
    setFromApprovalScreen,
    fromApprovalScreen,
  };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
