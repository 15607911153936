import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FieldType } from '@/@types';
import { IInterviewEvaluationFormData } from '@/@types/tickets';
import ReactDropdown from '@/components/Dropdown';
import { Typography } from '@/components/Typography';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants';
import { ASSISMENT_FORM_DROPDOWN_OPTIONS } from '@/constants/ticket';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

interface IInterviewEvaluationFormRow {
  label: string;
  inputType?: FieldType;
  control: Control<IInterviewEvaluationFormData>;
  name: keyof IInterviewEvaluationFormData;
  errors?: FieldErrors<IInterviewEvaluationFormData>;
}

const InterviewEvaluationFormRow: React.FC<IInterviewEvaluationFormRow> = ({
  label,
  inputType = 'Dropdown',
  control,
  name,
  errors,
}) => {
  return (
    <div
      className={cn('mb-4', {
        'flex flex-col lg:flex-row': inputType === 'Dropdown',
      })}
    >
      <Typography
        className={cn('font-semibold min-w-52', {
          'font-extrabold md:text-sm': inputType !== 'Dropdown',
        })}
      >
        {label}
        <span className='text-redColor text-xl ml-1'>*</span>
      </Typography>
      <Controller
        control={control}
        rules={{
          required: INPUT_ERRORS.REQUIRED.required,
          validate: (value) => {
            if (inputType === FieldType.Dropdown) {
              const evaluationValue =
                typeof value === 'object' && value !== null
                  ? value.evaluation
                  : value;
              return (
                (evaluationValue !== undefined && evaluationValue !== '') ||
                INPUT_ERRORS.REQUIRED.required
              );
            }
            return true;
          },
        }}
        name={name}
        render={({ field: { onChange, value } }) => {
          const isEvaluationAndComments =
            typeof value === 'object' &&
            value !== null &&
            'evaluation' in value &&
            'comments' in value;

          switch (inputType) {
            case FieldType.Dropdown:
              return (
                <div className='grid grid-cols-2 w-full'>
                  <ReactDropdown
                    labelClassName='mt-0 font-extrabold'
                    options={ASSISMENT_FORM_DROPDOWN_OPTIONS}
                    value={
                      isEvaluationAndComments
                        ? value.evaluation
                        : (value as string)
                    }
                    onChange={(selectedValue) =>
                      onChange(
                        isEvaluationAndComments
                          ? {
                              ...value,
                              evaluation: selectedValue,
                            }
                          : selectedValue,
                      )
                    }
                    className='mr-5'
                    errors={errors}
                    isRequired={true}
                  />
                  <Textarea
                    className='w-full h-20 resize-none'
                    value={
                      (isEvaluationAndComments
                        ? value.comments
                        : value) as string
                    }
                    onChange={(e) =>
                      onChange(
                        isEvaluationAndComments
                          ? { ...value, comments: e.target.value }
                          : e.target.value,
                      )
                    }
                    maxLength={150}
                    errors={errors}
                  />
                </div>
              );
            case FieldType.Input:
              return (
                <>
                  <Input
                    placeholder={strings.common.typeHere}
                    className='mt-2'
                    value={value as string}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {errors && (
                    <HookFormErrorMessage
                      errors={errors}
                      name={name as string}
                      render={({ message }) => (
                        <p className='text-redColor text-xs'>{message}</p>
                      )}
                    />
                  )}
                </>
              );
            case FieldType.TextArea:
              return (
                <Textarea
                  className='w-full resize-none mt-2'
                  value={value as string}
                  onChange={(e) => onChange(e.target.value)}
                  errors={errors}
                  maxLength={500}
                />
              );
            default:
              return <></>;
          }
        }}
      />
    </div>
  );
};

export default InterviewEvaluationFormRow;
