/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { HiOutlinePlusSm, HiOutlineTrash } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

import { ICandidate } from '@/@types/candidate';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Button } from '@/components/ui/button';
import { CANDIDATE_FILTER_MODAL_FILEDS } from '@/constants/candidate';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useCommonDataContext } from '@/provider/CommonDataProvider';
import { useFilters } from '@/provider/FiltersProvider';
import { usePagination } from '@/provider/PaginationProvider';
import { ROUTES } from '@/routes';
import { deleteCandidates, fetchCandidates } from '@/services/candidate';
import { Table } from '@tanstack/react-table';

import AddCandidatesModal from './components/AddCandidatesModal';
import CandidateTopbar from './components/CandidateTopbar';
import EditModal from './components/EditModal';
import { CANDIDATE_DATA_COLUMNS } from './components/table/columns';

const CandidateScreen = () => {
  const staticText = strings.candidateScreen;

  const navigate = useNavigate();
  const { isRecruiter, hasSubRole } = useAuth();
  const {
    pageSize,
    setPageSize,
    pageCount,
    setPageCount,
    pageIndex,
    setPageIndex,
    // resetPagination,
  } = usePagination();

  // const { tab } = useParams<{ tab: CANDIDATE_TABS }>();

  const {
    candidateFilters,
    searchAiCandidateText,
    setCandidateFilters,
    setSearchAiCandidateText,
  } = useFilters();
  const { companies, setCompanies, department, setDepartment } =
    useCommonDataContext();

  const [tableData, setTabelData] = useState<ICandidate[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<ICandidate[]>([]);
  const [tableState, setTableState] = useState<Table<ICandidate> | null>(null);
  const [searchText, setSearchText] = useState('');
  const [openAddCandidatesModal, setOpenAddCandidatesModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  // const [activeTab, setActiveTab] = useState<CANDIDATE_TABS>(
  //   tab || CANDIDATE_TABS.ALL,
  // );

  const {
    editableCandidateData,
    setEditableCandidateData,
    openEditModal,
    setOpenEditModal,
  } = useCandidate();

  const {
    data: candidates,
    refetch,
    isFetching,
    isLoading,
  } = useGenericQuery(
    ['candidates', pageSize, pageIndex],
    () =>
      fetchCandidates({
        pageSize,
        page: pageIndex + 1,
        // type: activeTab,
        type: 'all',
        ...(searchAiCandidateText
          ? { search: searchAiCandidateText }
          : candidateFilters),
      }),
    // { enabled: CandidateTabsList.includes(activeTab) },
  );

  // const handleTabChange = (newTab: CANDIDATE_TABS) => {
  //   setActiveTab(newTab);
  //   const path = generatePath(ROUTES.CANDIDATE_WITH_TABS, { tab: newTab });
  //   navigate(path);
  //   setSelectedRows([]);
  //   tableState?.resetRowSelection();
  // };

  const handleRowClick = (data: ICandidate) => {
    if (data._id) {
      // if (isRecruiter) {
      //   const path = generatePath(ROUTES.CANDIDATE_DETAIL_WITH_TABS, {
      //     tab: activeTab,
      //     id: data._id,
      //   });
      //   navigate(path, { replace: true });
      // } else {
      navigate(`${ROUTES.CANDIDATE}/${data._id}`);
    }
    // }
  };

  const handleSelectionChange = useCallback((selectedRows: ICandidate[]) => {
    setSelectedRows(selectedRows);
  }, []);

  const columnsForTableData = CANDIDATE_DATA_COLUMNS(
    setOpenEditModal,
    setEditableCandidateData,
    setOpenDeleteModal,
    hasSubRole,
  );

  const deleteCandidateMutation = useGenericMutation<
    { ids: string[] },
    boolean
  >(deleteCandidates, {
    onSuccess: (response) => {
      if (response) {
        toast.success(staticText.deleted);
        setOpenDeleteModal(false);
        setEditableCandidateData(null);
        setSelectedRows([]);
        tableState?.resetRowSelection();
        refetch();
      }
    },
  });

  const handleDeleteModal = () => {
    const ids = editableCandidateData
      ? [editableCandidateData._id]
      : selectedRows.map((item) => item._id);
    const payload = { ids };
    deleteCandidateMutation.mutate(payload);
  };

  useEffect(() => {
    if (typeof candidates === 'object') {
      setTabelData(candidates?.results);
      setPageCount(candidates?.totalPages || 0);
      const searchParams = candidates?.searchParams;
      // Check if any search parameters are non-empty
      if (searchAiCandidateText.length) {
        if (Object.keys(searchParams).some((key) => searchParams[key] !== '')) {
          setCandidateFilters(searchParams);
          if (
            typeof candidates === 'object' &&
            (candidates?.searchParams?.[
              CANDIDATE_FILTER_MODAL_FILEDS.COMPANY
            ] ||
              candidates?.searchParams?.[
                CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT
              ])
          ) {
            setCompanies(
              Array.from(
                new Set([
                  String(
                    candidates?.searchParams?.[
                      CANDIDATE_FILTER_MODAL_FILEDS.COMPANY
                    ],
                  ),
                  ...companies,
                ]),
              ),
            );
            setDepartment(
              Array.from(
                new Set([
                  {
                    label: String(
                      candidates?.searchParams?.[
                        CANDIDATE_FILTER_MODAL_FILEDS.DEPARTMENT
                      ],
                    ),
                    value: 'false',
                  },
                  ...department,
                ]),
              ),
            );
          }

          setSearchAiCandidateText('');
          setSearchText('');
        } else {
          toast.error(staticText.promptError);
          setCandidateFilters({});
        }
      }
    }
  }, [candidates]);

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch(false);
    }
  }, [shouldRefetch, refetch]);

  // useEffect(() => {
  //   if (isRecruiter) {
  //     if (!tab || !CandidateTabsList.includes(tab)) {
  //       const path = generatePath(ROUTES.CANDIDATE_WITH_TABS, {
  //         tab: CANDIDATE_TABS.ALL,
  //       });
  //       navigate(path, { replace: true });
  //     } else {
  //       setActiveTab(tab);
  //     }
  //   }
  // }, [tab, navigate]);

  return (
    <>
      <Container className='flex flex-col h-[calc(100vh-180px)]'>
        <CandidateTopbar
          // activeTab={activeTab as CANDIDATE_TABS}
          hasSelectedRows={!selectedRows.length}
          selectedTopbarOption={() => setOpenDeleteModal(true)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableState={tableState}
          isFetching={isFetching}
          setShouldRefetch={setShouldRefetch}
          setSearchText={setSearchText}
          searchText={searchText}
        />
        {/* <Tabs
          value={activeTab}
          onValueChange={(value) => handleTabChange(value as CANDIDATE_TABS)}
        > */}
        {isRecruiter ? (
          <div className='flex justify-end'>
            {/* <TabsList className='p-0 h-auto gap-1 sm:gap-2 bg-transparent'>
                <TabsTrigger
                  value={CANDIDATE_TABS.ALL}
                  onClick={resetPagination}
                >
                  {staticText.all}
                </TabsTrigger>
                <TabsTrigger
                  value={CANDIDATE_TABS.SHORTLISTED}
                  onClick={resetPagination}
                >
                  {staticText.shortlisted}
                </TabsTrigger>
              </TabsList> */}
            <Button
              icon={<HiOutlinePlusSm className='text-white text-xl' />}
              iconPosition='left'
              onClick={() => setOpenAddCandidatesModal(!openAddCandidatesModal)}
              textWithIconClassName='gap-0 sm:gap-2'
              className='flex font-medium disabled:opacity-70 h-8 md:text-base w-36 px-0 md:px-2 md:h-auto sm:w-auto rounded-md md:rounded-xl'
            >
              {staticText.addCandidate}
            </Button>
          </div>
        ) : null}
        {/* <TabsContent
            value={
              activeTab === CANDIDATE_TABS.ALL
                ? CANDIDATE_TABS.ALL
                : CANDIDATE_TABS.SHORTLISTED
            }
          > */}
        <DataTable
          columns={columnsForTableData}
          data={tableData as ICandidate[]}
          onRowSelectionChange={handleSelectionChange}
          handleRowClick={handleRowClick}
          loading={
            isLoading ||
            (!!Object.keys(candidateFilters).length && isFetching) ||
            (!!searchAiCandidateText && isFetching)
          }
          pageCount={pageCount}
          setTablesState={setTableState}
        />
        {/* </TabsContent>
        </Tabs> */}
      </Container>
      {isFetching ? null : (
        <div className='flex justify-between items-center mt-5'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
      {openDeleteModal ? (
        <ConfirmationModal
          open={openDeleteModal}
          primaryBtnLabel={strings.common.delete}
          handleCloseModal={() => setOpenDeleteModal(!openDeleteModal)}
          primaryBtnAction={handleDeleteModal}
          icon={<HiOutlineTrash className='size-6 text-tomatoRed' />}
          textDesc={strings.deleteText}
          containerClassName='py-5'
          isLoading={deleteCandidateMutation.status === 'pending'}
        />
      ) : null}
      {openAddCandidatesModal ? (
        <AddCandidatesModal
          open={openAddCandidatesModal}
          handleCloseModal={() =>
            setOpenAddCandidatesModal(!openAddCandidatesModal)
          }
          refetch={refetch}
        />
      ) : null}
      {openEditModal && editableCandidateData ? (
        <EditModal refetch={refetch} />
      ) : null}
    </>
  );
};

export default CandidateScreen;
