import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { ICandidate } from '@/@types/candidate';
import {
  FetchRequestParams,
  ICreateCompanyClientIAFPayload,
  IRequest,
  SendEndorsementPayload,
  UpdateRequestStatusPayload,
} from '@/@types/request';
import { IInterviewEvaluationFormData } from '@/@types/tickets';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';

export const createCompanyClientIAF = async (
  payload: ICreateCompanyClientIAFPayload,
): Promise<ICreateCompanyClientIAFPayload | boolean> => {
  const response = await API.Post<
    ICreateCompanyClientIAFPayload,
    ICreateCompanyClientIAFPayload
  >(REST_SUB_URL.CREATE_COMPANY_CLIENT_IAF, payload);
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const sendEndorsement = async ({
  id,
  payload,
  setPercent,
}: {
  id: string;
  payload: SendEndorsementPayload;
  setPercent?: (percent: number) => void;
}): Promise<ICandidate | null | boolean> => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (key !== 'document' && value !== undefined) {
      formData.append(key, value);
    }
  }
  if (payload.document) {
    [payload.document].forEach((file) => {
      formData.append('document', file);
    });
  }

  const response = await API.Patch<FormData, ICandidate>(
    `${REST_SUB_URL.SEND_ENDORSEMENT}/${id}`,
    formData,
    {
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          setPercent?.(percentage);
        }
      },
    },
  );
  if (response.status || response.data) {
    return response.data ? response.data : true;
  }
  toast.error(response.message);
  return null;
};

export const fetchRequests = async (
  params: FetchRequestParams,
): Promise<IPaginationResponse<IRequest> | boolean> => {
  const queryString = buildQueryParams({
    ...params,
  });
  const response = await API.Get<IPaginationResponse<IRequest>>(
    `${REST_SUB_URL.REQUEST}?${queryString}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchRequestDetail = async (
  id: string,
): Promise<IRequest | boolean> => {
  const response = await API.Get<IRequest>(
    `${REST_SUB_URL.REQUEST_DETAIL}/${id}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const updateRequestStatus = async (
  payload: UpdateRequestStatusPayload,
): Promise<UpdateRequestStatusPayload | boolean> => {
  const response = await API.Patch<
    UpdateRequestStatusPayload,
    UpdateRequestStatusPayload
  >(REST_SUB_URL.REQUEST, payload);

  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const fetchClientsIAFDetail = async (
  candidateId: string,
  ticketId: string,
): Promise<IInterviewEvaluationFormData | null> => {
  const response = await API.Get<IInterviewEvaluationFormData>(
    `${REST_SUB_URL.COMPANY_CLIENT_IAF_DETAILS}?candidate=${candidateId}&ticket=${ticketId}`,
  );
  if (response.status && response.data) {
    return response.data;
  }
  toast.error(response.message);
  return null;
};
