/* eslint-disable camelcase */
import { City } from 'country-state-city';
import { z } from 'zod';

import { IUser } from '@/@types/auth';
import { USER_TYPE } from '@/constants';
import {
  DEPLOYMENT_DURATION,
  DIRECT_HIRED_DURATION,
  EMPLOYEE_CATEGORY,
  JOB_TYPE,
  MAN_POWER_REQUEST_REASON,
  REPLACEMENT_NATURE,
  TYPE_OF_EMPLOYMENT,
} from '@/constants/ticket';
import { OnlyNumericStrings } from '@/utils/common';
import { getUserDetailsFromLS } from '@/utils/localstorage';

const labelValueSchema = (required?: boolean) =>
  z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .superRefine((data, ctx) => {
      if (data.label === '' && data.value === '' && required) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
        });
      }
    });

const stringWithMinLengthAndNoNumbers = (minLength: number) =>
  z.string().superRefine((value, ctx) => {
    if (value.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Required',
      });
      return;
    }

    if (!OnlyNumericStrings.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Value cannot consist solely of numbers.',
      });
    }

    if (value.length < minLength) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_small,
        type: 'string',
        minimum: minLength,
        inclusive: true,
        message: `Value must be at least ${minLength} characters long.`,
      });
    }
  });

const positiveNumber = (fieldName: string) =>
  z
    .union([
      z
        .number({ invalid_type_error: 'Value must be a number.' })
        .min(1, { message: `${fieldName} must be greater than 0.` }),
      z.string(),
    ])
    .superRefine((value, ctx) => {
      if (value === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
        });
      }
    });

const enumValues = <T extends Record<string, string>>(enumObj: T) => {
  const values = Object.values(enumObj) as [string, ...string[]];
  return z.enum(values);
};

export const CreateTicketSchema = z
  .object({
    company: labelValueSchema(
      (getUserDetailsFromLS() as IUser)?.profileType === USER_TYPE.ADMIN,
    ),
    client: labelValueSchema(false),
    department: labelValueSchema(true),
    status: labelValueSchema(),
    jobTitle: stringWithMinLengthAndNoNumbers(4),
    experienceYears: positiveNumber('Experience years'),
    endorsementDate: z.union([z.date(), z.string()]).optional(),
    rate: positiveNumber('Rate'),
    targetOnBoard: z.union([z.date(), z.string()]).superRefine((value, ctx) => {
      if (value === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
        });
      }
    }),
    numberOfHC: positiveNumber('Number of HC'),
    assignmentCountry: labelValueSchema(true),
    assignmentCity: labelValueSchema(false),
    jobType: enumValues(JOB_TYPE),
    employeeCategory: enumValues(EMPLOYEE_CATEGORY),
    jobDescription: stringWithMinLengthAndNoNumbers(4),
    qualifications: z
      .array(z.union([z.instanceof(File), z.string().url()]))
      .nullable(),
    qualificationsDetails: z.string().optional(),
    reasonForManPowerRequests: enumValues(MAN_POWER_REQUEST_REASON),
    nature: enumValues(REPLACEMENT_NATURE).optional(),
    typeOfEmployment: enumValues(TYPE_OF_EMPLOYMENT),
    employmentDuration: z.union([
      enumValues(DIRECT_HIRED_DURATION),
      enumValues(DEPLOYMENT_DURATION),
    ]),
    replacedEmployee: labelValueSchema(false),
    dateOfNOR: z.date().optional(),
  })
  .superRefine((data, ctx) => {
    const replacementRequiredFields: Record<string, boolean> = {
      nature: !data.nature,
      dateOfNOR: !data.dateOfNOR,
      replacedEmployee: !data.replacedEmployee?.value,
    };

    if (
      data.reasonForManPowerRequests === MAN_POWER_REQUEST_REASON.REPLACEMENT
    ) {
      Object.keys(replacementRequiredFields).forEach((field) => {
        const key = field as keyof typeof replacementRequiredFields;
        if (replacementRequiredFields[key]) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Required',
            path: [key],
          });
        }
      });
    }
    if (City.getCitiesOfCountry(data?.assignmentCountry.value)?.length) {
      if (!data.assignmentCity?.value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Required',
          path: ['assignmentCity'],
        });
      }
    }
  });
