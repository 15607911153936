import React from 'react';
import Lottie from 'lottie-react';

import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useChatContext } from '@/provider/ChatProvider';

import ChatAnimation from '../../assets/animations/ChatAnimation.json';

interface Props {
  isEmpty?: boolean;
}

const StartChattingAnimationView: React.FC<Props> = ({ isEmpty = false }) => {
  const { isBroadcastMessage, selectedRecipients } = useChatContext();
  const staticText = strings.chatScreen;

  const isBroadcastScreen = isBroadcastMessage && selectedRecipients?.length;

  const Content = () => {
    if (isBroadcastScreen && selectedRecipients?.length)
      return staticText.broadcastMessage;
    if (isEmpty) return staticText.sendMessage;
    return staticText.startMessaging;
  };
  return (
    <Container
      width={isBroadcastScreen || isEmpty ? 'w-full' : 'lg:w-3/4'}
      className={cn('flex items-center justify-center flex-col md:flex-row', {
        'border-none': isBroadcastScreen || isEmpty,
      })}
    >
      <Lottie
        animationData={ChatAnimation}
        loop={true}
        className='w-1/2 md:w-1/5'
      />
      <Typography variant='subheading' className='self-center text-center'>
        {Content()}
      </Typography>
    </Container>
  );
};

export default StartChattingAnimationView;
