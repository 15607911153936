import { LogoutIcon, ProfileIcon } from '@/assets/svgs';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import { clearStorage } from '@/utils/localstorage';

import { Button } from '../ui/button';

import OptionText from './SidebarOption/OptionText';
import SidebarOption from './SidebarOption';

const SidebarFooter = () => {
  const staticText = strings.sidebarRoutes;
  const { currentTab, sidebarExpand } = useSidebarContext();

  const { setUser, setToken } = useAuth();

  const handleLogout = () => {
    window.location.href = ROUTES.LOGIN;
    clearStorage();
    setUser(null);
    setToken(null);
  };

  return (
    <div
      className={cn('mt-10 flex flex-col my-4 w-44 mx-3', {
        'items-center w-12': !sidebarExpand,
      })}
    >
      <SidebarOption
        selected={currentTab === staticText.profile}
        name={staticText.profile}
        location='/profile'
        icon={<ProfileIcon />}
      />
      <Button
        variant='link'
        onClick={handleLogout}
        className='flex items-center py-2 px-3 justify-between no-underline'
      >
        <div className='flex items-center gap-3'>
          <div className='w-5'>
            <LogoutIcon />
          </div>
          <OptionText name={staticText.logout} className='text-tomatoRed' />
        </div>
      </Button>
    </div>
  );
};

export default SidebarFooter;
