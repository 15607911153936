import { Dispatch, SetStateAction } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { IoMdCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { Clipboard, ClipboardCheck } from 'lucide-react';

import { IDropdownOption } from '@/@types';
import { IUser } from '@/@types/auth';
import { ICandidate } from '@/@types/candidate';
import { IRequest } from '@/@types/request';
import ProfileBadge from '@/components/ProfileBadge';
import Dropdown from '@/components/SelectDropdown';
import { TagsCell } from '@/components/Table/CellComponents/TagsCell';
import TableHeading from '@/components/Table/TableHeading';
import TooltipComponent from '@/components/Tooltip';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import {
  REQUEST_TABLE_ACTION,
  TICKET_CANDIDATE_TABLE_ACTION,
  TICKET_STATUS,
  TicketCandidatesTableAction,
} from '@/constants/ticket';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { EnumToArray, GetFormattedName } from '@/utils/common';
import { ColumnDef, Row } from '@tanstack/react-table';

const buttons = (isCompanyClientIAF: boolean) => [
  {
    icon: <FaCheckCircle size={20} />,
    action: CANDIDATE_STATUS.PRE_DEPLOY,
    disabled: !isCompanyClientIAF,
  },
  {
    icon: <IoMdCloseCircle size={22} color='#FF0000' />,
    action: CANDIDATE_STATUS.REJECTED,
  },
  ...(isCompanyClientIAF
    ? [
        {
          icon: <ClipboardCheck size={20} />,
          action: REQUEST_TABLE_ACTION.CLIENTS_IAF_HISTORY,
        },
      ]
    : [
        {
          icon: <Clipboard size={20} />,
          action: REQUEST_TABLE_ACTION.CLIENTS_IAF_SUBMISSION,
        },
      ]),
];

const {
  ticketScreen: { tableColumnsName },
} = strings;

export const REQUEST_TABLE_COLUMNS = (
  isClosureTable: boolean,
  setRequest: Dispatch<SetStateAction<IRequest | null>>,
  setAction: Dispatch<
    SetStateAction<
      | TICKET_CANDIDATE_TABLE_ACTION
      | REQUEST_TABLE_ACTION
      | CANDIDATE_STATUS
      | null
    >
  >,
  setEditableCandidateData: React.Dispatch<
    React.SetStateAction<ICandidate | null>
  >,
  ticketStatus?: TICKET_STATUS,
  setFromRequestScreen?: React.Dispatch<React.SetStateAction<string>>,
): ColumnDef<IRequest>[] => {
  const navigate = useNavigate();
  return [
    ...(location.pathname.includes('/request')
      ? [
          {
            id: 'ticketNo',
            header: () => (
              <TableHeading
                text={strings.ticketDetailScreen.tableColumnsName.ticketNo}
              />
            ),
            cell: ({ row }: { row: Row<IRequest> }) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setFromRequestScreen?.(location.pathname);
                  navigate(`${ROUTES.TICKETS}/${row?.original.ticket._id}`);
                }}
              >
                <Typography className='md:text-[15px] text-primary hover:text-primary'>
                  {`Tk#${row?.original.ticket.ticketNumber}`}
                </Typography>
              </div>
            ),
          },
        ]
      : []),
    {
      id: 'candidate',
      header: () => <TableHeading text={tableColumnsName.candidateName} />,
      cell: ({ row: { original } }) => (
        <ProfileBadge
          className='justify-start'
          nameClassName='font-medium'
          name={GetFormattedName<IUser>(original?.candidate?.userDetails)}
          profilePicture={original?.candidate?.userDetails?.avatar as string}
        />
      ),
    },
    {
      id: 'recruiter',
      header: () => <TableHeading text={tableColumnsName.recruiterName} />,
      cell: ({ row: { original } }) => (
        <ProfileBadge
          className='justify-start'
          nameClassName='font-medium'
          name={GetFormattedName<IUser>(original?.recruiter)}
          profilePicture={original?.recruiter?.avatar as string}
        />
      ),
    },
    {
      id: 'jobTitle',
      header: () => <TableHeading text={tableColumnsName.jobTitle} />,
      cell: ({ row: { original } }) => (
        <Typography className='md:text-[15px]'>
          {original?.ticket?.jobTitle}
        </Typography>
      ),
    },
    {
      id: 'department',
      header: () => <TableHeading text={tableColumnsName.department} />,
      cell: ({ row: { original } }) => (
        <Typography className='md:text-[15px]'>
          {original?.ticket?.department?.name}
        </Typography>
      ),
    },
    ...(isClosureTable
      ? [
          {
            id: 'status',
            header: () => <TableHeading text={tableColumnsName.status} />,
            cell: ({ row }: { row: Row<IRequest> }) => {
              return (
                <TagsCell
                  tags={
                    !row.original?.isPendingApproval
                      ? row.original.status
                      : strings.common.pendingApproval
                  }
                />
              );
            },
          },
        ]
      : []),
    ...(ticketStatus !== TICKET_STATUS.INACTIVE && !isClosureTable
      ? [
          // TODO: Add option to edit tags from here. If a ticket's status is in-active or candidate's status is rejected than don't show. Recheck
          {
            id: 'action',
            header: () => <TableHeading text={tableColumnsName.action} />,
            cell: ({ row }: { row: Row<IRequest> }) => {
              const isTicketInActive =
                row.original.ticket.status === TICKET_STATUS.INACTIVE;
              return (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className={cn('flex', {
                    'opacity-50 cursor-not-allowed': isTicketInActive,
                  })}
                >
                  {buttons(row.original.isCompanyClientIAF).map(
                    ({ icon, action, disabled }, index) => (
                      <TooltipComponent
                        key={action}
                        text={
                          strings.ticketDetailScreen.interviewEvaluation.title
                        }
                        align='center'
                        showTooltip={
                          !!EnumToArray(REQUEST_TABLE_ACTION).includes(action)
                        }
                        directionalArrow
                        trigger={
                          <Button
                            key={index}
                            variant='link'
                            className='w-fit px-2'
                            icon={icon}
                            onClick={() => {
                              if (!isTicketInActive) {
                                setEditableCandidateData(
                                  row.original.candidate,
                                );
                                setRequest(row.original);
                                setAction(action);
                              }
                            }}
                            disabled={disabled}
                          />
                        }
                      />
                    ),
                  )}
                </div>
              );
            },
          },
        ]
      : []),
    {
      id: 'options',
      cell: ({ row: { original } }: { row: Row<IRequest> }) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setRequest(original);
          }}
        >
          <Dropdown
            items={
              [
                ...TicketCandidatesTableAction.slice(
                  1,
                  isClosureTable && original.isCompanyClientIAF ? 4 : 3,
                ),
                ...(isClosureTable && original.isPendingApproval
                  ? [TicketCandidatesTableAction[4]]
                  : []),
              ] as IDropdownOption[]
            }
            setValue={(value) => {
              setEditableCandidateData(original.candidate);
              setAction(value as TICKET_CANDIDATE_TABLE_ACTION);
            }}
            customButton={<HiDotsVertical className='opacity-60 h-8' />}
            customButtonClassName='flex justify-center'
          />
        </div>
      ),
    },
  ];
};
