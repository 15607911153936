import React from 'react';

import { cn } from '@/lib/utils';

import { Skeleton } from './ui/skeleton';
import ProfileAvatar from './ProfileAvatar';

interface Props {
  name: string;
  profilePicture?: string;
  attribute?: string;
  className?: string;
  avatarClassName?: string;
  nameClassName?: string;
  attributeClassName?: string;
  isLoading?: boolean;
}

const ProfileBadge: React.FC<Props> = ({
  name,
  profilePicture,
  attribute,
  className,
  avatarClassName,
  nameClassName,
  isLoading = false,
  attributeClassName,
}) => {
  return (
    <div className={cn('flex justify-center items-center gap-2', className)}>
      <ProfileAvatar
        name={name}
        src={profilePicture}
        className={avatarClassName}
        isLoading={isLoading}
      />
      <div>
        <div
          className={cn('text-sm font-bold text-primaryBlack', nameClassName)}
        >
          {isLoading ? <Skeleton className='w-28 h-4 bg-black/10' /> : name}
        </div>
        {attribute ? (
          <div
            className={cn(
              'text-xs font-semibold text-primaryBlack capitalize',
              attributeClassName,
            )}
          >
            {isLoading ? (
              <Skeleton className='w-24 h-3 bg-black/10' />
            ) : (
              attribute
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ProfileBadge;
