/* eslint-disable max-lines */
import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

import { FieldType } from '@/@types';
import {
  ICandidateTag,
  IRecruiterInterviewEvaluationFormData,
  ITag,
} from '@/@types/candidate';
import RadioButtonGroup from '@/components/RadioButtonGroup';
import { Typography } from '@/components/Typography';
import { Textarea } from '@/components/ui/textarea';
import { RECRUITER_INTERVIEW_EVALUATION_FORM_FIELDS } from '@/constants/candidate';
import { cn } from '@/lib/utils';

import RecruiterInterviewEvaluationHistory from './RecruiterInterviewEvaluationHistory';

interface IRecruiterInterviewEvaluationForm {
  control?: Control<IRecruiterInterviewEvaluationFormData>;
  error?: FieldErrors<IRecruiterInterviewEvaluationFormData>;
  data?: ITag | ICandidateTag;
}

const RecruiterInterviewEvaluationForm: React.FC<
  IRecruiterInterviewEvaluationForm
> = ({ control, error, data }) => {
  return (
    <div>
      {data?.isRecruiterIAF ? (
        <RecruiterInterviewEvaluationHistory
          data={{
            mrfNumber: String(
              (data as ICandidateTag)?.ticket?.ticketNumber ||
                (data as ITag)?.ticketNumber ||
                '',
            ),
            job:
              (data as ICandidateTag)?.ticket?.jobTitle ||
              (data as ITag)?.jobPosition ||
              '',
            candidateId:
              (data as ICandidateTag)?.candidate?._id ||
              ((data as ITag)?.candidate as string) ||
              '',

            ticketId:
              (data as ICandidateTag)?.ticket?._id ||
              ((data as ITag)?.ticket as string) ||
              '',
          }}
        />
      ) : (
        <>
          {/* form section */}
          {RECRUITER_INTERVIEW_EVALUATION_FORM_FIELDS.map((field, index) => (
            <div key={index}>
              {field.label ? (
                <Typography
                  className={cn(
                    'md:text-sm capitalize font-semibold mb-2 pt-3',
                    {
                      'block font-bold md:text-lg my-2 truncate':
                        field.isHeadingText,
                    },
                  )}
                >
                  {field.label}
                  {field.subText ? (
                    <span className='text-[12px] ml-2'>{field.subText}</span>
                  ) : null}
                  {field?.error ? (
                    <span className='text-redColor text-xl ml-1'>*</span>
                  ) : null}
                </Typography>
              ) : null}
              {field.type === FieldType.TextArea && field.name ? (
                <Controller
                  name={
                    field.name as keyof IRecruiterInterviewEvaluationFormData
                  }
                  rules={{ required: field.error }}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Textarea
                      rows={2}
                      value={value as string}
                      onChange={onChange}
                      errors={error}
                      name={field.name}
                      className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver text-sm font-medium min-h-10 max-h-40 mt-1 p-2'
                    />
                  )}
                />
              ) : null}
              {field.type === FieldType.RadioGroup && field.name ? (
                <Controller
                  name={
                    field.name as keyof IRecruiterInterviewEvaluationFormData
                  }
                  rules={{
                    validate: (value) =>
                      value === false || value ? true : field.error,
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioButtonGroup
                      errors={error}
                      name={field.name}
                      value={value!}
                      onChange={onChange}
                      options={
                        field.options?.map((option) =>
                          typeof option === 'string'
                            ? option
                            : {
                                label: option.label,
                                value: String(option.value),
                              },
                        ) || []
                      }
                      containerClassName='w-fit flex gap-x-6'
                    />
                  )}
                />
              ) : null}
              {field.type === FieldType.TEXT_AREA_GROUP && field.fields ? (
                <div className='grid grid-cols-2 gap-4'>
                  {field.fields.map((subField, subIndex) => (
                    <div key={subIndex}>
                      <Typography className='md:text-sm capitalize font-semibold mb-2 mt-1'>
                        {subField.label}
                        {subField?.error && (
                          <span className='text-redColor text-xl ml-1'>*</span>
                        )}
                      </Typography>
                      <Controller
                        name={
                          subField.name as keyof IRecruiterInterviewEvaluationFormData
                        }
                        rules={{ required: subField.error }}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Textarea
                            rows={2}
                            value={value as string}
                            onChange={onChange}
                            name={subField.name}
                            errors={error}
                            className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver text-sm font-medium min-h-10 max-h-40 mt-1 p-2'
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default RecruiterInterviewEvaluationForm;
