/* eslint-disable max-lines */
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaCheckCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { PiWarning } from 'react-icons/pi';
import { RiDragMoveFill } from 'react-icons/ri';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { ICandidateTag } from '@/@types/candidate';
import { Department } from '@/@types/metas';
import {
  ClientData,
  IMoveTicketsAppliedPayload,
  ITicket,
  IUpdateCandidateRankParams,
} from '@/@types/tickets';
import ConfirmationModal from '@/components/ConfirmationModal';
import Container from '@/components/Container';
import { DataTable } from '@/components/DataTable';
import Modal from '@/components/Modal';
import TablePagination from '@/components/Table/TablePagination';
import TableSize from '@/components/Table/TableSize';
import { Button } from '@/components/ui/button';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import {
  TICKET_CANDIDATE_TABLE_ACTION,
  TICKET_CANDIDATES_TABS,
  TICKET_STATUS,
  TicketCandidatesTableAction,
  TicketCandidatesTabsList,
} from '@/constants/ticket';
import { useGenericMutation } from '@/hooks/useMutationData';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import EditModal from '@/pages/Candidate/components/EditModal';
import EndorsementHistorySection from '@/pages/Candidate/components/EditModal/EndorsementHistorySection';
import PersonnelActionHistory from '@/pages/Candidate/components/EditModal/PersonnelActionFormHistorySection';
import RecruiterInterviewEvaluationHistory from '@/pages/Candidate/components/EditModal/RecruiterInterviewEvaluationHistory';
import { useAuth } from '@/provider/AuthProvider';
import { useCandidate } from '@/provider/CandidateProvider';
import { useChatContext } from '@/provider/ChatProvider';
import { useSidebarContext } from '@/provider/SidebarProvider';
import { ROUTES } from '@/routes';
import {
  getTicketsCandidates,
  shortlistCandidatesForTicket,
  updateCandidateRankForTicket,
} from '@/services/ticket';
import { GetFormattedName, snakeToTitleCase } from '@/utils/common';
import { Table } from '@tanstack/react-table';

import { TicketsCandidatesColumns } from './table/columns';
import InterviewEvaluationModal from './InterviewEvaluationModal';

interface ITableSection {
  ticketDetails: ITicket;
}

const TableSection: React.FC<ITableSection> = ({ ticketDetails }) => {
  const { candidateScreen, ticketDetailScreen: staticText } = strings;

  const { innerTab } = useParams<{ innerTab?: string }>();

  const navigate = useNavigate();
  const { isRecruiter, user } = useAuth();
  const { setActiveChat } = useChatContext();
  const { setFromTicketScreen } = useSidebarContext();
  const {
    openEditModal,
    setOpenEditModal,
    setEditableCandidateData,
    editableCandidateData,
  } = useCandidate();

  const isRecruiterAssignee = ticketDetails.assignee?._id === user?._id;

  const [rank, setRank] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(-1);
  const [tag, setTag] = useState<ICandidateTag | null>(null);
  const [tableData, setTableData] = useState<ICandidateTag[]>([]);
  const [rowForUpdateTag, setRowForUpdateTag] = useState<ICandidateTag | null>(
    null,
  );
  const [selectedRows, setSelectedRows] = useState<ICandidateTag[]>([]);
  const [action, setAction] = useState<TICKET_CANDIDATE_TABLE_ACTION | null>(
    null,
  );
  const [tableState, setTableState] = useState<Table<ICandidateTag> | null>(
    null,
  );
  const [activeTab, setActiveTab] = useState(
    innerTab || TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES,
  );
  const [openShortlistConfirmationModal, setOpenShortlistConfirmationModal] =
    useState(false);

  const handleToggleTagEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const handleNavigateToChat = (selectedCandidate: ICandidateTag) => {
    if (selectedCandidate) {
      setActiveChat({
        ticket: {
          ...ticketDetails,
          department: (ticketDetails.department as Department).name,
        },
        user: selectedCandidate.candidate,
      });
      navigate(ROUTES.CHAT);
    }
  };

  const handleTabChange = (newTab: string) => {
    setActiveTab(newTab);
    const updatedPath = location.pathname.replace(
      new RegExp(`/${activeTab}(?=/|$)`),
      `/${newTab}`,
    );
    const path = generatePath(updatedPath, { innerTab: newTab });
    navigate(path);
    setAction(null);
    // Resetting pagination
    setPageSize(5);
    setPageIndex(0);
    setPageCount(-1);
  };

  const handleRowClick = (data: ICandidateTag) => {
    if (data._id) {
      setFromTicketScreen(location.pathname);
      // const path = generatePath(ROUTES.CANDIDATE_DETAIL_WITH_TABS, {
      //   tab: activeTab,
      //   id: data.candidate._id,
      // });
      const path = generatePath(ROUTES.CANDIDATE_DETAIL, {
        id: data.candidate._id,
      });
      navigate(path, { replace: true });
    }
  };

  const dropdownOptions = TicketCandidatesTableAction.filter((option) => {
    switch (option.label) {
      case TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY:
        return tag?.isEndorsed;
      case TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY:
        return tag?.isRecruiterIAF;
      case TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY:
        return tag?.isCompanyClientIAF;
      case TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY:
        return tag?.isPanForm;
      default:
        return true;
    }
  });

  const columnsforAppliedCandidatesData = TicketsCandidatesColumns({
    tag,
    setTag,
    action,
    setAction,
    rank,
    setRank,
    setEditTagData: setEditableCandidateData,
    handleToggleTagEditModal,
    showActions:
      isRecruiterAssignee && ticketDetails.status === TICKET_STATUS.ACTIVE,
    isHigherStatusThanActive:
      activeTab === TICKET_CANDIDATES_TABS.SHORTLISTED_CANDIDATES,
    dropdownOptions,
    setRowForUpdateTag,
    setEditableCandidateData,
  });

  const { data, isLoading, refetch } = useGenericQuery(
    ['getTicketsCandidates', activeTab, pageSize, pageIndex, ticketDetails._id],
    () =>
      getTicketsCandidates(
        pageSize,
        pageIndex + 1,
        ticketDetails._id!,
        activeTab === TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES
          ? 'all'
          : 'shortlisted',
      ),
    {
      enabled: !!ticketDetails?._id,
    },
  );

  const assignTicketsMutation = useGenericMutation<
    IUpdateCandidateRankParams,
    boolean | ICandidateTag
  >(
    ({ candidateId, ticket, rank }) =>
      updateCandidateRankForTicket(candidateId, ticket, rank),
    {
      onSuccess: (response) => {
        if (response) {
          refetch();
          setAction(null);
        }
      },
    },
  );

  const changeTicketsCandidateStatus = useGenericMutation<
    IMoveTicketsAppliedPayload,
    ITicket | boolean
  >(shortlistCandidatesForTicket, {
    onSuccess: (response) => {
      if (typeof response === 'object') {
        refetch();
        toast.success(candidateScreen.moveToShortlistedSuccess);
        tableState?.resetRowSelection();
        setSelectedRows([]);
        closeShortlistConfirmationModal();
      }
    },
  });

  const closeShortlistConfirmationModal = () => {
    setOpenShortlistConfirmationModal(false);
    setAction(null);
  };

  const handleCloseEditing = () => {
    setAction(null);
    setRank(0);
  };

  const handleEditRank = () => {
    if (tag)
      assignTicketsMutation.mutate({
        candidateId: tag.candidate._id!,
        ticket: ticketDetails._id!,
        rank,
      });
  };

  const handleChangeTicketsCandidateStatus = () => {
    const ids = selectedRows?.length
      ? selectedRows.map((row) => row.candidate._id)
      : [tag!.candidate._id!];

    changeTicketsCandidateStatus.mutate({
      ids,
      ticket: ticketDetails._id!,
      status: CANDIDATE_STATUS.SHORTLISTED,
    });
  };

  const handleSelectionChange = useCallback((selectedRows: ICandidateTag[]) => {
    setSelectedRows(selectedRows);
  }, []);

  useEffect(() => {
    if (typeof data === 'object') {
      setTableData(data?.results);
      setPageCount(data?.totalPages);
    }
  }, [data]);

  useEffect(() => {
    if (tag && action) {
      setRank(tag.rank || 0);
      switch (action) {
        case TICKET_CANDIDATE_TABLE_ACTION.CHAT:
          handleNavigateToChat(tag);
          break;
        case TICKET_CANDIDATE_TABLE_ACTION.SHORTLIST:
          setOpenShortlistConfirmationModal(true);
          break;
        default:
          break;
      }
    }
  }, [action]);

  useEffect(() => {
    if (isRecruiter) {
      if (
        !innerTab ||
        !Object.values(TICKET_CANDIDATES_TABS).includes(
          innerTab as TICKET_CANDIDATES_TABS,
        )
      ) {
        const path = generatePath(ROUTES.TICKETS_WITH_TAB, {
          tab: TICKET_CANDIDATES_TABS.APPLIED_CANDIDATES,
        });
        navigate(path, { replace: true });
      } else {
        setActiveTab(innerTab);
      }
    }
  }, [navigate]);

  return (
    <>
      {openShortlistConfirmationModal ? (
        <ConfirmationModal
          open={openShortlistConfirmationModal}
          primaryBtnLabel={staticText.shortlist}
          handleCloseModal={closeShortlistConfirmationModal}
          primaryBtnAction={handleChangeTicketsCandidateStatus}
          icon={<PiWarning className='size-6 text-primary' />}
          textDesc={staticText.shortlistConfirmation}
          containerClassName='py-5'
          isLoading={changeTicketsCandidateStatus.status === 'pending'}
        />
      ) : null}
      <Container>
        <div className='flex justify-between flex-col lg:flex-row gap-2'>
          <div className='flex gap-2'>
            {TicketCandidatesTabsList.map((tab) => (
              <Button
                key={tab}
                variant={activeTab === tab ? 'default' : 'secondary'}
                onClick={() => handleTabChange(tab)}
                className={cn(
                  'inline-flex items-center justify-center whitespace-nowrap rounded-md md:rounded-xl px-2 md:px-4 h-8 md:h-10 text-sm md:text-base transition-all disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 capitalize',
                  {
                    'font-bold shadow-sm': activeTab === tab,
                  },
                )}
              >
                {snakeToTitleCase(tab)}
              </Button>
            ))}
          </div>
          {selectedRows?.length ? (
            <Button
              onClick={handleChangeTicketsCandidateStatus}
              className='w-52 md:px-4 h-8 md:h-10'
              variant='outline'
              icon={<RiDragMoveFill className='size-4' />}
              iconPosition='left'
              loading={changeTicketsCandidateStatus.status === 'pending'}
            >
              {staticText.moveToShortlisted}
            </Button>
          ) : null}
          {action === TICKET_CANDIDATE_TABLE_ACTION.EDIT_RANK ? (
            <div className='flex gap-3 justify-end sm:justify-normal'>
              <Button
                variant='outline'
                className='rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base px-2 text-mouseGrey border-mouseGrey'
                icon={<IoMdCloseCircle size={20} />}
                onClick={handleCloseEditing}
                disabled={assignTicketsMutation.status === 'pending'}
              >
                {strings.cancel}
              </Button>
              <Button
                variant='outline'
                className='rounded-md sm:rounded-xl w-32 h-9 md:px-auto lg:h-11 text-base'
                icon={<FaCheckCircle size={16} />}
                onClick={handleEditRank}
                disabled={rank === tag?.rank}
                loading={assignTicketsMutation.status === 'pending'}
              >
                {strings.save}
              </Button>
            </div>
          ) : null}
        </div>
        <DataTable
          columns={columnsforAppliedCandidatesData}
          data={tableData}
          cellClassName='h-12 py-0'
          handleRowClick={handleRowClick}
          pageCount={pageCount}
          onRowSelectionChange={handleSelectionChange}
          loading={isLoading}
          setTablesState={setTableState}
        />
      </Container>
      {isLoading ? null : (
        <div className='flex justify-between items-center mt-5'>
          <TableSize
            tableState={tableState}
            setTableState={setTableState}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <TablePagination
            tableState={tableState}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </div>
      )}
      {openEditModal && editableCandidateData ? (
        <EditModal
          ticketData={{
            ticketId: ticketDetails._id,
            ticketNumber: ticketDetails.ticketNumber,
            department: (ticketDetails?.department as Department)?.name,
            tagStatus: rowForUpdateTag?.status as CANDIDATE_STATUS,
            override: ticketDetails.override,
            _id: rowForUpdateTag?._id,
          }}
          setRowForUpdateTag={setRowForUpdateTag}
          refetch={refetch}
          rowForUpdateTag={rowForUpdateTag}
        />
      ) : null}
      <Modal
        open={[
          TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY,
          TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY,
          TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY,
        ].includes(action as TICKET_CANDIDATE_TABLE_ACTION)}
        handleCloseModal={() => setAction(null)}
        title={action?.split(' ').slice(1).join(' ')}
      >
        <>
          {action ===
          TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY ? (
            <PersonnelActionHistory
              candidateId={tag?.candidate?._id || ''}
              ticketId={String(tag?.ticket?._id) || ''}
              mrfNumber={String(tag?.ticket?.ticketNumber) || ''}
              job={tag?.ticket?.jobTitle || ''}
              info={{
                ticketRate: tag?.ticket?.rate || 0,
                typeOfEmployment: tag?.ticket?.typeOfEmployment || '',
                author: GetFormattedName(
                  (ticketDetails?.client as ClientData)?.companyClientId
                    ? (ticketDetails?.client as ClientData)
                    : ticketDetails?.author,
                ),
              }}
            />
          ) : null}
          {action ===
          TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY ? (
            <RecruiterInterviewEvaluationHistory
              isTicketScreen
              data={{
                mrfNumber: String(tag?.ticket?.ticketNumber),
                job: tag?.ticket.jobTitle || '',
                candidateId: tag?.candidate?._id || '',
                ticketId: tag?.ticket?._id || '',
              }}
            />
          ) : null}
          {action === TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY ? (
            <EndorsementHistorySection
              isTicketScreen
              candidateId={tag?.candidate?._id}
              ticketId={tag?.ticket?._id}
              mrfNumber={String(tag?.ticket?.ticketNumber)}
              job={tag?.ticket?.jobTitle}
            />
          ) : null}
        </>
      </Modal>
      {action ===
      TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY ? (
        <InterviewEvaluationModal
          open={
            action ===
            TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY
          }
          handleCloseModal={() => setAction(null)}
          ticketId={tag?.ticket?._id}
          mrfNumber={String(tag?.ticket?.ticketNumber)}
          jobPosition={tag?.ticket?.jobTitle}
          candidate={tag?.candidate}
          showHistory={!!tag?.isCompanyClientIAF}
        />
      ) : null}
    </>
  );
};

export default TableSection;
