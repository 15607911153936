import React from 'react';

import { Typography } from '@/components/Typography';
import { DONUT_COLORS, getLabelFromName } from '@/constants/dashboard';
import { cn } from '@/lib/utils';

export interface ISource {
  name: string;
  count: number;
  percentage?: string;
}

interface Props {
  data?: ISource[];
  cardDisplay: boolean;
}

const SourcesKeyList: React.FC<Props> = ({ data, cardDisplay }) => {
  const dataWithPercentages = () => {
    const totalCount = data?.reduce((acc, item) => acc + item.count, 0) || 0;

    return data?.map((item) => ({
      ...item,
      percentage: ((item.count / totalCount) * 100).toFixed(2),
    }));
  };

  return (
    <div
      className={cn('flex flex-col gap-3 items-start', {
        'gap-0.5': cardDisplay,
      })}
    >
      {dataWithPercentages()?.map((item, index) => (
        <div
          key={index}
          className={cn('flex items-center gap-4 ', {
            'gap-1': cardDisplay,
          })}
        >
          <div
            className={cn('size-3 rounded-full', { 'size-1.5': cardDisplay })}
            style={{
              background: `linear-gradient(135deg, ${DONUT_COLORS[index]}95 0%, ${DONUT_COLORS[index]} 100%)`,
            }}
          />
          <div className='flex space-between items-center'>
            <Typography
              className={cn(
                'md:text-base font-medium text-mouseGrey text-ellipsis w-40',
                { 'text-[7px] md:text-[7px] w-16': cardDisplay },
              )}
            >
              {getLabelFromName(item.name)}
            </Typography>
            <Typography
              className={cn('md:text-base font-bold text-left', {
                'text-[7px] md:text-[7px]': cardDisplay,
              })}
            >{`${item.count.toFixed()}%`}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SourcesKeyList;
