import { Dispatch, SetStateAction } from 'react';
import { City, Country } from 'country-state-city';

import { FieldType, IDropdownOption } from '@/@types';
import { Department } from '@/@types/metas';
import {
  ClientData,
  IInterviewEvaluationAndComments,
  IInterviewEvaluationFormData,
  ITicket,
  TicketInformationParams,
} from '@/@types/tickets';
import { strings } from '@/locales';
import { CreateTicketType } from '@/pages/CreateTicket';
import {
  EnumToArray,
  formatCurrency,
  GetFormattedName,
  snakeToTitleCase,
} from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { JSONParse } from '@/utils/jsonparse';

const staticText = strings.ticketDetailScreen;

export enum TICKET_CANDIDATES_TABS {
  APPLIED_CANDIDATES = 'applied-candidates',
  SHORTLISTED_CANDIDATES = 'shortlisted-candidates',
}

export const TicketCandidatesTabsList = EnumToArray(TICKET_CANDIDATES_TABS);

export enum TICKET_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'IN_ACTIVE',
}

export const TICKET_STATUS_OPTIONS = [
  { label: 'ACTIVE', value: TICKET_STATUS.ACTIVE },
  { label: 'IN ACTIVE', value: TICKET_STATUS.INACTIVE },
];

export enum TICKET_TABS {
  ALL_TICKETS = 'all-tickets',
  MY_TICKETS = 'my-tickets',
}

export const TicketTabsList = EnumToArray(TICKET_TABS);

export enum JOB_TYPE {
  MANAGED = 'Managed',
  OTP = 'One Time Placement (OTP)',
}

export enum EMPLOYEE_CATEGORY {
  INTERNAL = 'Internal',
  DEPLOYED = 'Deployed',
}

export enum MAN_POWER_REQUEST_REASON {
  REPLACEMENT = 'Replacement',
  NEW = 'Newly Created Post',
  ADDITIONAL = 'Additional Manpower',
}

export const ManPowerRequestReasonList = EnumToArray(MAN_POWER_REQUEST_REASON);

export enum TYPE_OF_EMPLOYMENT {
  DIRECT_HIRED = 'Direct Hired (Internal)',
  DEPLOYMENT = 'For Deployment (Managed Service)',
}

export const EmploymentTypeList = EnumToArray(TYPE_OF_EMPLOYMENT);

export enum DIRECT_HIRED_DURATION {
  PROJECT_BASED = 'Project Based',
  CONTRACTUAL = 'Contractual',
  PROBATIONARY = 'Probationary',
}

export const DirectHiredDurationList = EnumToArray(DIRECT_HIRED_DURATION);

export enum DEPLOYMENT_DURATION {
  PROJECT_BASED = 'Project Based',
  PROBATIONARY = 'Probationary',
  ON_CALL = 'On Call',
  TEMPORARY_DEPLOYED = 'Temporary Deployed',
}

export const DeploymentDurationList = EnumToArray(DEPLOYMENT_DURATION);

export enum REPLACEMENT_NATURE {
  RESIGNATION = 'Resignation',
  TERMINATION = 'Termination',
  TRANSFER = 'Transfer',
  END_OF_CONTRACT = 'End of Contract',
  OTHERS = 'Other',
}

export const ReplacementNatureList = EnumToArray(REPLACEMENT_NATURE);

export const TICKET_INFORMATION = ({
  isAdmin,
  editing,
  departmentDropdownValues,
  companiesDropdownValues,
  clientsDropdownValues,
  loadingCompanies,
  loadingDepartments,
  loadingClients,
  setCompanyPage,
  setDepartmentPage,
  setDepartmentSearch,
  setCompanySearch,
  setClientPage,
  setClientSearch,
  disableClientDropdown,
  haveMoreCompanyPage,
  haveMoreDepartmentPage,
  haveMoreCompanyClients,
  duplicate,
}: TicketInformationParams) => {
  const list = [
    {
      name: 'company',
      inputType: 'Dropdown',
      options: companiesDropdownValues,
      loading: loadingCompanies,
      setPage: setCompanyPage,
      setInputSearch: setCompanySearch,
      haveMoreOptions: !!haveMoreCompanyPage,
      disabled: editing,
    },
    {
      name: 'client',
      inputType: 'Dropdown',
      options: clientsDropdownValues,
      loading: loadingClients,
      setPage: setClientPage,
      setInputSearch: setClientSearch,
      disabled: disableClientDropdown || editing,
      haveMoreOptions: haveMoreCompanyClients,
    },
    {
      name: 'department',
      inputType: 'Dropdown',
      options: departmentDropdownValues,
      loading: loadingDepartments,
      setPage: setDepartmentPage,
      setInputSearch: setDepartmentSearch,
      haveMoreOptions: !!haveMoreDepartmentPage,
      tooltipText: strings.ticketDetailScreen.departmentOverride,
      disabled: editing,
    },
    {
      name: 'jobTitle',
    },
    {
      name: 'experienceYears',
      type: 'number',
    },
    {
      name: 'rate',
      type: 'number',
      disabled: editing && !duplicate,
    },
    ...(editing && !duplicate
      ? [
          {
            name: 'status',
            inputType: 'Dropdown',
            options: TICKET_STATUS_OPTIONS,
          },
        ]
      : []),
  ];

  if (isAdmin) return list;
  return list.slice(2);
};

export const HIRING_INFORMATION = (
  searchCountry: string,
  searchCity: string,
  setSearchCountry: React.Dispatch<React.SetStateAction<string>>,
  setSearchCity: React.Dispatch<React.SetStateAction<string>>,
  countryCode: string,
  editing?: boolean,
  duplicate?: boolean,
) => [
  {
    name: 'endorsementDate',
    inputType: FieldType.DatePicker,
  },
  {
    name: 'targetOnBoard',
    inputType: FieldType.DatePicker,
  },
  {
    name: 'numberOfHC',
    type: 'number',
  },
  {
    name: 'assignmentCountry',
    inputType: FieldType.Dropdown,
    setInputSearch: setSearchCountry,
    options: Country.getAllCountries()
      .filter((country) =>
        country.name.toLowerCase().includes(searchCountry.toLowerCase()),
      )
      .map((item) => ({
        label: item.name,
        value: item.isoCode,
      })),
    disabled: editing && !duplicate,
  },
  ...(City.getCitiesOfCountry(countryCode)?.length
    ? [
        {
          name: 'assignmentCity',
          inputType: FieldType.Dropdown,
          setInputSearch: setSearchCity,
          options: City.getCitiesOfCountry(countryCode)
            ?.filter((city) =>
              city.name.toLowerCase().includes(searchCity.toLowerCase()),
            )
            .map((item) => ({
              label: item.name,
              value: item.name,
            })),
          disabled: (editing && !duplicate) || !countryCode,
        },
      ]
    : []),
  {
    name: 'jobType',
    inputType: FieldType.RadioGroup,
    options: EnumToArray(JOB_TYPE),
  },
  {
    name: 'employeeCategory',
    inputType: FieldType.RadioGroup,
    options: EnumToArray(EMPLOYEE_CATEGORY),
  },
];

export const TICKET_DETAILS_INFORMATION = (
  ticketDetails: ITicket,
  isRecruiter: boolean,
  isAdmin: boolean,
) => [
  {
    title: staticText.postedOn,
    description: getLocalDate(ticketDetails?.createdAt),
  },
  {
    title: staticText.department,
    description: (ticketDetails.department as Department)?.name,
  },
  { title: staticText.jobTitle, description: ticketDetails.jobTitle },
  ...(isRecruiter
    ? [
        {
          title: staticText.appliedCandidates,
          description:
            (ticketDetails.appliedCandidates?.length || 0) +
            (ticketDetails.shortlistedAppliedCandidates?.length || 0),
        },
      ]
    : []),
  ...(isAdmin || isRecruiter
    ? [
        {
          title: staticText.company,
          description: (ticketDetails.client as ClientData)?.companyName,
        },
      ]
    : []),
  {
    title: staticText.experienceYears,
    description: `${ticketDetails.experienceYears} ${staticText.years}`,
  },
  {
    title: staticText.rate,
    description: formatCurrency(ticketDetails.rate),
  },
];

export const JOB_HIRING_INFORMATION = (ticketDetails: ITicket) => [
  {
    title: staticText.endorsementDate,
    description: getLocalDate(ticketDetails.endorsementDate as string),
  },
  {
    title: staticText.targetOnBoard,
    description: getLocalDate(ticketDetails.targetOnBoard as string),
  },
  {
    title: staticText.jobType,
    description: ticketDetails.jobType?.toLowerCase(),
  },
  {
    title: staticText.employeeCategory,
    description: ticketDetails.employeeCategory?.toLowerCase(),
  },
  {
    title: staticText.numberOfHC,
    description: ticketDetails.numberOfHC,
  },
  {
    title: staticText.assignmentCountry,
    description: ticketDetails.assignmentCountry,
  },
  {
    title: staticText.assignmentCity,
    description: ticketDetails.assignmentCity,
  },
];

export const MAN_POWER_INFORMATION = (ticketDetails: ITicket) => {
  const parsedData =
    JSONParse(ticketDetails?.reasonForManPowerRequests as string) || {};
  const getValue = (key: string, value: string) => {
    if (key === 'dateOfNOR') return getLocalDate(value);
    if (key === 'replacedEmployee')
      return (JSONParse(value as string) as IDropdownOption).label;
    return value;
  };
  const formattedReasons = Object.entries(parsedData || {}).map(
    ([key, value]) => ({
      title:
        strings.ticketDetailScreen[
          key as keyof typeof strings.ticketDetailScreen
        ] || key,
      description: getValue(key, value),
    }),
  );

  return formattedReasons;
};

export const TICKETS_ACTION = [
  { value: 'unassign', label: 'Un-Assign' },
  { value: 'assignTo', label: 'Assign To' },
  { value: 'delete', label: 'Delete' },
];

export enum TICKETS_ACTION_ENUM {
  UN_ASSIGN = 'Un-Assign',
  ASSIGN_TO = 'Assign To',
  DELETE = 'Delete',
}

export const TicketTableColumnDefinitions = [
  {
    id: 'ticketNumber',
    name: strings.ticketScreen.tableColumnsName.ticketNumber,
  },
  {
    id: 'jobTitle',
    name: strings.ticketScreen.tableColumnsName.jobTitle,
  },
  { id: 'department', name: strings.ticketScreen.tableColumnsName.department },
  { id: 'createdAt', name: strings.ticketScreen.tableColumnsName.postedOn },
  {
    id: 'appliedCandidates',
    name: strings.ticketScreen.tableColumnsName.appliedCandidates,
  },
  { id: 'hired', name: strings.ticketScreen.tableColumnsName.hired },
  {
    id: 'targetOnBoard',
    name: strings.ticketScreen.tableColumnsName.targetOnBoard,
  },
  { id: 'rate', name: strings.ticketScreen.tableColumnsName.rate },
  {
    id: 'endorsementDate',
    name: strings.ticketScreen.tableColumnsName.endorsementDate,
  },
];

export const defaultTicketFormState = (initialState?: ITicket) => {
  if (!initialState)
    return {
      company: { label: '', value: '' },
      client: { label: '', value: '' },
      department: { label: '', value: '' },
      status: { label: TICKET_STATUS.ACTIVE, value: TICKET_STATUS.ACTIVE },
      jobTitle: '',
      jobDescription: '',
      assignmentCountry: { label: '', value: '' },
      assignmentCity: { label: '', value: '' },
      experienceYears: '',
      rate: '',
      numberOfHC: '',
      replacedEmployee: { label: '', value: '' },
      qualifications: null,
      targetOnBoard: '',
    };

  const parsedReasonForManPowerRequests =
    JSONParse(initialState.reasonForManPowerRequests) || {};

  const parsedTypeOfEmployment = JSONParse(initialState.typeOfEmployment) || {};
  const reasonKeys = Object.keys(parsedReasonForManPowerRequests);
  const reasonValues = Object.values(parsedReasonForManPowerRequests);
  const employemntKeys = Object.keys(parsedTypeOfEmployment);
  const employemntValues = Object.values(parsedTypeOfEmployment);

  return {
    ...initialState,
    status: {
      label: snakeToTitleCase(initialState.status),
      value: initialState.status,
    },
    company: {
      label: (initialState.client as ClientData)?.companyName,
      value: (initialState.client as ClientData)?.companyId,
    },
    client: (initialState?.client as ClientData)?.companyClientId
      ? {
          label: GetFormattedName(initialState.client as ClientData),
          value: (initialState.client as ClientData)?.companyClientId,
          avatar: (initialState.client as ClientData)?.avatar,
        }
      : { label: '', value: '' },
    department: {
      label: (initialState.department as Department)?.name,
      value: (initialState.department as Department)?._id,
    },
    ...(initialState?.endorsementDate
      ? {
          endorsementDate: new Date(initialState?.endorsementDate),
        }
      : {}),
    targetOnBoard: new Date(initialState?.targetOnBoard),
    assignmentCountry: {
      label: initialState.assignmentCountry,
      value: initialState.assignmentCountry,
    },
    assignmentCity: {
      label: initialState.assignmentCity,
      value: initialState.assignmentCity,
    },
    jobType:
      initialState?.jobType === JOB_TYPE.MANAGED.toUpperCase()
        ? JOB_TYPE.MANAGED
        : JOB_TYPE.OTP,
    employeeCategory:
      initialState?.employeeCategory ===
      EMPLOYEE_CATEGORY.INTERNAL.toUpperCase()
        ? EMPLOYEE_CATEGORY.INTERNAL
        : EMPLOYEE_CATEGORY.DEPLOYED,
    reasonForManPowerRequests: reasonKeys[0],
    typeOfEmployment: employemntKeys[0],
    employmentDuration: employemntValues[0],
    ...(reasonKeys[0] === MAN_POWER_REQUEST_REASON.REPLACEMENT
      ? {
          nature: reasonValues[0],
          dateOfNOR: new Date(reasonValues[1]),
          replacedEmployee: JSONParse(reasonValues[2]) as IDropdownOption,
        }
      : { replacedEmployee: { label: '', value: '' } }),
  };
};

export const getFormattedData = (data: CreateTicketType, editing?: boolean) => {
  const formatReasonForManPowerRequests = () => {
    if (
      data?.reasonForManPowerRequests === MAN_POWER_REQUEST_REASON.REPLACEMENT
    )
      return {
        [data.reasonForManPowerRequests]: data?.nature,
        dateOfNOR: data?.dateOfNOR,
        replacedEmployee: JSON.stringify(data?.replacedEmployee),
      };

    return { [data.reasonForManPowerRequests]: true };
  };

  return {
    ...data,
    company: editing ? data?.company?.label : data?.company?.value,
    client: data?.client?.value,
    department: data?.department?.label,
    assignmentCountry: data.assignmentCountry.label,
    assignmentCity: data.assignmentCity.label,
    qualifications: data?.qualifications?.filter(
      (qualification) => typeof qualification !== 'string',
    ),
    targetOnBoard: new Date(data?.targetOnBoard).toISOString() as string,
    reasonForManPowerRequests: JSON.stringify(
      formatReasonForManPowerRequests(),
    ),
    typeOfEmployment: JSON.stringify({
      [data.typeOfEmployment]: data?.employmentDuration,
    }),
    status: editing
      ? data.status!.value?.toUpperCase()
      : TICKET_STATUS.ACTIVE.toUpperCase(),
    ...(data?.endorsementDate
      ? {
          endorsementDate: new Date(
            data?.endorsementDate,
          ).toISOString() as string,
        }
      : null),
  };
};

export const getEmploymentOptions = (
  option: string,
  employmentType: string,
) => {
  const employmentDurationMap: {
    [key: string]: DIRECT_HIRED_DURATION[] | DEPLOYMENT_DURATION[];
  } = {
    [TYPE_OF_EMPLOYMENT.DIRECT_HIRED]:
      DirectHiredDurationList as DIRECT_HIRED_DURATION[],
    [TYPE_OF_EMPLOYMENT.DEPLOYMENT]:
      DeploymentDurationList as DEPLOYMENT_DURATION[],
  };

  if (option === employmentType) {
    return employmentDurationMap[employmentType];
  }

  return false;
};

export const ASSISMENT_FORM_DROPDOWN_OPTIONS = [
  'Poor',
  'Fair',
  'Good',
  'Excellent',
];

export const INTERVIEW_EVALUATION_HISTORY_SECTION_HEADINGS = (
  title: string,
) => [
  { label: title, className: 'col-span-6 sm:col-span-2' },
  {
    label: strings.ticketDetailScreen.interviewEvaluation.evaluation,
    className: 'col-span-1 hidden sm:block',
  },
  {
    label: strings.ticketDetailScreen.interviewEvaluation.comments,
    className: 'col-span-3 hidden sm:block',
  },
];

const creatInterviewEvaluationField = (
  name: keyof IInterviewEvaluationFormData,
  label: string,
  inputType: FieldType = FieldType.Dropdown,
) => ({ name, label, inputType });

const {
  contentAppearanceFields: {
    dress,
    grooming,
    bodyLanguage,
    eyeContact,
    communication,
    verbalAndPersuasive,
    cooperation,
    maturity,
    professional,
    englishProficiency,
    openMindedness,
    dedicated,
    availabilityForFinalInterview,
    availabilityForEmployment,
    recommendation,
  },
} = strings.ticketDetailScreen.interviewEvaluation;
export const INTERVIEW_EVALUATION_FIELDS = [
  {
    sectionTitle: staticText.interviewEvaluation.contentAppearance,
    fields: [
      creatInterviewEvaluationField('dress', dress),
      creatInterviewEvaluationField('grooming', grooming),
      creatInterviewEvaluationField('bodyLanguage', bodyLanguage),
      creatInterviewEvaluationField('eyeContact', eyeContact),
    ],
  },
  {
    sectionTitle: staticText.interviewEvaluation.contentCharacteristics,
    fields: [
      creatInterviewEvaluationField('communication', communication),
      creatInterviewEvaluationField('verbalAndPersuasive', verbalAndPersuasive),
      creatInterviewEvaluationField('englishProficiency', englishProficiency),
      creatInterviewEvaluationField('cooperation', cooperation),
      creatInterviewEvaluationField('maturity', maturity),
      creatInterviewEvaluationField('openMindedness', openMindedness),
      creatInterviewEvaluationField('professional', professional),
      creatInterviewEvaluationField('dedicated', dedicated),
    ],
  },
  {
    fields: [
      creatInterviewEvaluationField(
        'availabilityForFinalInterview',
        availabilityForFinalInterview,
        FieldType.Input,
      ),
      creatInterviewEvaluationField(
        'availabilityForEmployment',
        availabilityForEmployment,
        FieldType.Input,
      ),
      creatInterviewEvaluationField(
        'recommendation',
        recommendation,
        FieldType.TextArea,
      ),
    ],
  },
];

export const INTERVIEW_EVALUATION_DATA = (
  IAFDetail?: IInterviewEvaluationFormData | null,
) => ({
  contentAppearance: {
    dress: IAFDetail?.dress,
    grooming: IAFDetail?.grooming,
    bodyLanguage: IAFDetail?.bodyLanguage,
    eyeContact: IAFDetail?.eyeContact,
  },
  contentCharacteristics: {
    communication: IAFDetail?.communication,
    verbalAndPersuasive: IAFDetail?.verbalAndPersuasive,
    englishProficiency: IAFDetail?.englishProficiency,
    cooperation: IAFDetail?.cooperation,
    maturity: IAFDetail?.maturity,
    openMindedness: IAFDetail?.openMindedness,
    professional: IAFDetail?.professional,
    dedicated: IAFDetail?.dedicated,
  },
  availabilityForFinalInterview: IAFDetail?.availabilityForFinalInterview,
  availabilityForEmployment: IAFDetail?.availabilityForEmployment,
  recommendation: IAFDetail?.recommendation,
});

export const TICKET_FILTER_MODAL_FIELDS = (
  department: IDropdownOption[],
  loadingDepartments: boolean | null,
  haveMoreDepartmentPage: boolean | null,
  setDepartmentPage: Dispatch<SetStateAction<number>>,
  setDepartmentSearch: Dispatch<SetStateAction<string>>,
  companies: string[],
  loadingCompanies: boolean | null,
  haveMoreCompanyPage: boolean | null,
  setCompanyPage: Dispatch<SetStateAction<number>>,
  setCompanySearch: Dispatch<SetStateAction<string>>,
  recruiterDropdownValues: IDropdownOption[],
  isLoadingRecruiters: boolean | null,
  isCompanyClient: boolean,
  isRecruiter: boolean,
) => [
  {
    name: 'postedOn',
    inputType: FieldType.DatePicker,
  },
  {
    name: 'department',
    items: department,
    isLoading: loadingDepartments,
    props: {
      haveMoreOptions: !!haveMoreDepartmentPage,
      setPage: setDepartmentPage,
      setInputSearch: setDepartmentSearch,
      isSearchable: true,
    },
  },
  ...(!isRecruiter
    ? [
        {
          name: 'assignee',
          items: recruiterDropdownValues,
          isLoading: isLoadingRecruiters,
          props: {
            isSearchable: true,
            saveBothLabelAndValue: true,
          },
        },
      ]
    : []),
  ...(!isCompanyClient
    ? [
        {
          name: 'companyName',
          items: companies,
          isLoading: loadingCompanies,
          props: {
            haveMoreOptions: !!haveMoreCompanyPage,
            setPage: setCompanyPage,
            setInputSearch: setCompanySearch,
            isSearchable: true,
          },
        },
      ]
    : []),
  {
    name: 'status',
    items: TICKET_STATUS_OPTIONS,
    props: {
      tagsDropdown: true,
      hasSearchBox: false,
      saveBothLabelAndValue: true,
    },
  },
  {
    name: 'rate',
    inputType: FieldType.Range,
  },
];

const defaultInterviewEvaluationAndComments: IInterviewEvaluationAndComments = {
  evaluation: '',
  comments: '',
};

export const InterviewEvaluationFormInitialState: IInterviewEvaluationFormData =
  {
    dress: defaultInterviewEvaluationAndComments,
    grooming: defaultInterviewEvaluationAndComments,
    bodyLanguage: defaultInterviewEvaluationAndComments,
    eyeContact: defaultInterviewEvaluationAndComments,
    communication: defaultInterviewEvaluationAndComments,
    verbalAndPersuasive: defaultInterviewEvaluationAndComments,
    englishProficiency: defaultInterviewEvaluationAndComments,
    cooperation: defaultInterviewEvaluationAndComments,
    maturity: defaultInterviewEvaluationAndComments,
    openMindedness: defaultInterviewEvaluationAndComments,
    professional: defaultInterviewEvaluationAndComments,
    dedicated: defaultInterviewEvaluationAndComments,
    availabilityForFinalInterview: '',
    availabilityForEmployment: '',
    recommendation: '',
  };
export const AVAILABILITY_FIELDS_EVALUATION_FORM = (
  IAFDetail?: IInterviewEvaluationFormData | null,
) => [
  {
    label:
      strings.ticketDetailScreen.interviewEvaluation.contentAppearanceFields
        .availabilityForFinalInterview,
    value: INTERVIEW_EVALUATION_DATA(IAFDetail).availabilityForFinalInterview,
  },
  {
    label:
      strings.ticketDetailScreen.interviewEvaluation.contentAppearanceFields
        .availabilityForEmployment,
    value: INTERVIEW_EVALUATION_DATA(IAFDetail).availabilityForEmployment,
  },
];

export enum TICKET_CANDIDATE_TABLE_ACTION {
  CHAT = 'Chat',
  SHORTLIST = 'Shortlist',
  EDIT_RANK = 'Edit Rank',
  ENDORSEMENT_FORM_HISTORY = 'View Endorsement Form History',
  INTERVIEW_ASSESSMENT_FORM_HISTORY = 'View Interview Assessment Form History',
  CLIENTS_INTERVIEW_ASSESSMENT_HISTORY = "View Client's Interview Evaluation Form",
  PERSONNEL_ACTION_SLIP_HISTORY = 'View Personnel Action Slip History',
}

export enum REQUEST_TABLE_ACTION {
  APPROVE = 'Approve',
  REJECT = 'Reject',
  CLIENTS_IAF_SUBMISSION = 'Clients IAF Submission',
  CLIENTS_IAF_HISTORY = 'Clients IAF History',
}

export const TicketCandidatesTableAction = [
  { value: 'editRank', label: TICKET_CANDIDATE_TABLE_ACTION.EDIT_RANK },
  {
    value: 'viewInterviewAssessmentForm',
    label: TICKET_CANDIDATE_TABLE_ACTION.INTERVIEW_ASSESSMENT_FORM_HISTORY,
  },
  {
    value: 'viewEndorsementForm',
    label: TICKET_CANDIDATE_TABLE_ACTION.ENDORSEMENT_FORM_HISTORY,
  },
  {
    value: 'viewClientsInterviewAssessment',
    label: TICKET_CANDIDATE_TABLE_ACTION.CLIENTS_INTERVIEW_ASSESSMENT_HISTORY,
  },
  {
    value: 'viewPersonnelActionSlip',
    label: TICKET_CANDIDATE_TABLE_ACTION.PERSONNEL_ACTION_SLIP_HISTORY,
  },
];
