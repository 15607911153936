import React, { BaseSyntheticEvent } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  // useWatch,
} from 'react-hook-form';

import { ISelectorDrawerFormValues } from '@/@types/dashboard';
import Container from '@/components/Container';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';

import ManpowerRequestFormSection from './ManpowerRequestFormSection';
import SourceSection from './SourceSection';
import StatisticsChart from './StatisticsChart';

interface ILayoutSelectorDrawer {
  isClosing: boolean;
  openDrawer: boolean;
  handleOpenDrawer: () => void;
  // toogleAllStatisticsSelect: () => void;
  control: Control<ISelectorDrawerFormValues>;
  setValue: UseFormSetValue<ISelectorDrawerFormValues>;
  onSubmit: (e?: BaseSyntheticEvent<object>) => Promise<void>;
  buttonDisabled?: boolean;
  isLoading?: boolean;
}

const LayoutSelectorDrawer: React.FC<ILayoutSelectorDrawer> = ({
  control,
  onSubmit,
  // setValue,
  isClosing,
  openDrawer,
  isLoading,
  buttonDisabled,
  handleOpenDrawer,
  // toogleAllStatisticsSelect,
}) => {
  const staticText = strings.dashboard;

  // Use useWatch to watch the 'statistics' form values
  // const watchedStatistics = useWatch({
  //   control,
  //   name: 'statistics',
  // });

  return (
    <Modal
      open={openDrawer}
      handleCloseModal={handleOpenDrawer}
      isLoading={isLoading}
      buttonDisabled={buttonDisabled}
      primaryBtnAction={onSubmit}
      primaryBtnLabel='Apply'
      title={staticText.cards}
      contentClassName={cn(
        'max-h-[calc(100vh-10vh)] md:max-h-[calc(100vh-100px)] custom-modal-content md:top-[88px] w-[460px] md:right-6',
        {
          'slide-in-right': openDrawer,
          'slide-out-right': isClosing,
        },
      )}
    >
      <div
        className={cn('flex flex-col gap-3 overflow-scroll scrollbarHidden', {
          'max-h-[400px]': window.innerHeight < 900,
          'max-h-[320px]': window.innerHeight < 700,
        })}
      >
        <Container className='md:p-3 grid grid-cols-5 h-full overflow-visible gap-4'>
          <div className='col-span-2 flex items-start gap-2'>
            <Controller
              name='listOfLocalRecruitmentActivities'
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className='bg-simplyViolet border-greyWhite'
                />
              )}
            />
            <Typography className='block font-bold -mt-1'>
              {staticText.listOfSources}
            </Typography>
          </div>
          <div className='col-span-3 flex justify-center items-center pointer-events-none select-none'>
            <SourceSection cardDisplay />
          </div>
        </Container>
        {/* if statistics is boolean */}
        <Container className='md:p-3 grid grid-cols-5 h-full overflow-visible gap-4'>
          <div className='col-span-2 flex items-start gap-2'>
            <Controller
              name='statistics'
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className='bg-simplyViolet border-greyWhite'
                />
              )}
            />
            <Typography className='block font-bold -mt-1'>
              {staticText.statistics}
            </Typography>
          </div>
          <div className='col-span-3 flex justify-center items-center pointer-events-none select-none'>
            <StatisticsChart cardDisplay />
          </div>
        </Container>
        {/* <Container className='md:p-3 h-full overflow-visible relative'>
          <div className='col-span-2'>
            <Controller
              name='statistics'
              control={control}
              render={({ field }) => (
                <>
                  <Checkbox
                    checked={Object.values(watchedStatistics || {}).every(
                      Boolean,
                    )}
                    onCheckedChange={toogleAllStatisticsSelect}
                    className='bg-simplyViolet border-greyWhite'
                  />
                  <Typography className='block font-bold'>
                    {staticText.statistics}
                  </Typography>
                  <div className='ml-2 space-y-0.5'>
                    {Object.keys(field.value).map((key) => (
                      <div key={key} className='flex items-center gap-1'>
                        <Checkbox
                          checked={field.value[key as keyof typeof field.value]}
                          onCheckedChange={(checked) => {
                            setValue(
                              `statistics.${key}` as `statistics.${keyof IStatistics}`,
                              !!checked,
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          className='bg-simplyViolet border-greyWhite mr-1'
                        />
                        <Typography className='text-oceanBlue'>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </>
              )}
            />
          </div>
          <div className='col-span-3 flex justify-center items-center absolute top-3 right-3 w-60 h-36'>
            <StatisticsChart cardDisplay />
          </div>
        </Container> */}
        <Container className='md:p-3 grid grid-cols-5 h-full overflow-visible gap-4'>
          <div className='col-span-2 flex items-start gap-2'>
            <Controller
              name='manpowerFormVisibility'
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  className='bg-simplyViolet border-greyWhite'
                />
              )}
            />
            <Typography className='block font-bold -mt-1'>
              {staticText.manpowerRequestForm}
            </Typography>
          </div>
          <div className='col-span-3 h-44 flex justify-center items-center'>
            <ManpowerRequestFormSection cardDisplay />
          </div>
        </Container>
      </div>
    </Modal>
  );
};

export default LayoutSelectorDrawer;
